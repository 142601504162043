import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { BILLING_CONTRACTS_PATH } from "./billingContract";
import { CAMPAIGNS_PATH } from "./campaigns";
import { CONTRACTS_PATH } from "./contracts";
import {
  createAuthenticatedRequest,
  defaultMutationOptions,
  executeRequest,
} from "./utils";

export const CREDIT_SETTINGS_PATH = "creditSettings";

export function useUpdateCreditSettings() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(({ body }) => updateCreditSettings(body, dispatch), {
    ...defaultMutationOptions,
    onSuccess: data => {
      queryClient.invalidateQueries([CREDIT_SETTINGS_PATH]);
      queryClient.invalidateQueries(CONTRACTS_PATH);
      queryClient.invalidateQueries(CAMPAIGNS_PATH);
      queryClient.invalidateQueries(BILLING_CONTRACTS_PATH);
    },
  });
}

// Execute a PUT to update credit settings
export async function updateCreditSettings(body, dispatch) {
  const creditSettingsUpdatePath = `${CREDIT_SETTINGS_PATH}`;

  const requestObject = await createAuthenticatedRequest(
    creditSettingsUpdatePath,
    dispatch,
    {
      method: "PUT",
      body: JSON.stringify(body),
    }
  );

  return executeRequest(requestObject);
}
