import React, { useEffect, useState } from "react";
import { AutoComplete } from "antd";
import { bool, func, object, string } from "prop-types";
import { useCategories } from "../../../api/categories";
import { useTagTypes } from "../../../api/tagTypes";

const categoryQuery = "category:";

const isValidQuery = (tagTypeOptions, query) => {
  const queries = query.split(/,|&/);
  const firstQuery = queries.find(Boolean);
  if (!firstQuery) {
    return false;
  }
  const tagTypes = Object.keys(tagTypeOptions);
  for (const tagType of tagTypes) {
    let [queryType, queryValue] = firstQuery.split(":");
    queryType += ":";
    if (!queryType || !queryValue) {
      return false;
    }
    if (queryType === categoryQuery) {
      if (
        tagTypeOptions[categoryQuery].find(
          category => `${category.categoryId}` === `${queryValue}`
        )
      ) {
        return true;
      }
    }
    if (queryType === tagType && tagTypeOptions[tagType].includes(queryValue)) {
      return true;
    }
  }
  return false;
};

const getQueryValue = (input, queryType, tagTypeOptions) => {
  // const lastIndexOfComma = input.lastIndexOf(",");
  // if (lastIndexOfColon < lastIndexOfComma) {
  //   return null;
  // }
  const autocompleteInput = document.getElementById("query-autocomplete");
  const cursorPosition = autocompleteInput.selectionStart;
  const inputToSearch = input.substring(0, cursorPosition);
  const lastIndexOfColon = inputToSearch.lastIndexOf(":");
  if (
    input.substring(
      lastIndexOfColon - queryType.length + 1,
      lastIndexOfColon + 1
    ) === queryType
  ) {
    const indexCutOff = input.lastIndexOf(queryType) + queryType.length;
    const searchTerm = input.substring(indexCutOff).toLowerCase();
    if (isValidQuery(tagTypeOptions, searchTerm)) {
      return "";
    }
    return searchTerm;
  }
  return null;
};

const parseTagOptions = (
  tagTypeOptions,
  value,
  queryValue,
  options,
  labelProperty,
  valueProperty
) => {
  const newTagOptions = [];
  if (queryValue !== null) {
    for (const option of options) {
      let queryTermFound = false;

      if (labelProperty) {
        if (option[labelProperty].toLowerCase().includes(queryValue)) {
          queryTermFound = true;
        }
      } else if (option.toLowerCase().includes(queryValue)) {
        queryTermFound = true;
      }

      if (queryTermFound) {
        const autocompleteInput = document.getElementById("query-autocomplete");
        const cursorPosition = autocompleteInput.selectionStart;
        const inputToSearch = value.substring(0, cursorPosition);
        const lastIndexOfColon = inputToSearch.lastIndexOf(":");
        const valuePart1 = value.substring(0, lastIndexOfColon + 1);
        let valuePart2 = value.substring(lastIndexOfColon + 1, value.length);
        if (!isValidQuery(tagTypeOptions, valuePart2)) {
          valuePart2 = "";
        }
        const newValue = `${valuePart1}${
          valueProperty ? option[valueProperty] : option
        }${valuePart2}`;
        newTagOptions.push({
          label: labelProperty ? option[labelProperty] : option,
          value: newValue,
          replacedlabel: valueProperty
            ? `${option[valueProperty]}`
            : `${option}`,
        });
      }
    }
  }
  return newTagOptions;
};

function QueryAutocomplete({ onChange, disabled, value, style }) {
  const { data: categories } = useCategories({
    params: {
      parents: true,
      sortColumn: "categories.name",
      sortDirection: "asc",
    },
  });
  const [tagOptions, setTagOptions] = useState([]);
  const [tagTypeOptions, setTagTypeOptions] = useState({});
  const { data: tagTypes } = useTagTypes({ includes: ["tags"] });

  useEffect(() => {
    if (tagTypes && categories && Object.keys(tagTypeOptions).length === 0) {
      const newTagTypeOptions = { ...tagTypeOptions };
      for (const tagType of tagTypes) {
        if (tagType.relations?.tags) {
          for (const tag of tagType.relations.tags) {
            if (!newTagTypeOptions[`${tag.tagType}:`]) {
              newTagTypeOptions[`${tag.tagType}:`] = [];
            }
            newTagTypeOptions[`${tag.tagType}:`].push(tag.tagValue);
          }
        }
      }
      newTagTypeOptions["category:"] = categories;
      setTagTypeOptions(newTagTypeOptions);
    }
  }, [categories, tagTypeOptions, tagTypes]);

  const onSearch = searchedValue => {
    const queryTypes = Object.keys(tagTypeOptions);
    let newTagOptions = [];
    for (const query of queryTypes) {
      const queryValue = getQueryValue(searchedValue, query, tagTypeOptions);
      if (queryValue !== null) {
        if (query === categoryQuery) {
          newTagOptions = parseTagOptions(
            tagTypeOptions,
            searchedValue,
            queryValue,
            tagTypeOptions[query],
            "name",
            "categoryId"
          );
        } else {
          newTagOptions = parseTagOptions(
            tagTypeOptions,
            searchedValue,
            queryValue,
            tagTypeOptions[query]
          );
        }
        break;
      }
    }
    setTagOptions(newTagOptions);
    if (onChange) {
      onChange(searchedValue);
    }
  };

  const _onSelect = (selectedValue, selectedOption) => {
    const autocompleteInput = document.getElementById("query-autocomplete");
    const cursorStart =
      autocompleteInput.selectionStart + selectedOption.replacedlabel.length;
    const cursorEnd =
      autocompleteInput.selectionEnd + selectedOption.replacedlabel.length;
    setTimeout(() => {
      autocompleteInput.setSelectionRange(cursorStart, cursorEnd);
    }, 10);
    setTagOptions([]);
    if (onChange) {
      onChange(selectedValue);
    }
  };

  return (
    <AutoComplete
      id="query-autocomplete"
      options={tagOptions}
      style={style}
      onSearch={onSearch}
      onSelect={_onSelect}
      value={value}
      placeholder="Type to enter a tag query"
      disabled={disabled}
    />
  );
}

QueryAutocomplete.propTypes = {
  onChange: func,
  disabled: bool,
  value: string,
  style: object,
};

export default QueryAutocomplete;
