import React from "react";
import { Descriptions } from "antd";
import get from "lodash.get";
import { any } from "prop-types";
import { camelCaseToSentenceCase } from "../../utils/stringUtils";
import clipboard from "../../utils/clipboard";

export default function DescriptionTab(props) {
  const { descriptionRender, data } = props;

  return (
    <Descriptions className="main-no-padding" column={4} bordered size="middle">
      {descriptionRender.map(array => {
        const keyLabel = camelCaseToSentenceCase(array[0]); //label
        //get nested property from string in config object ie. relations.users.firstName
        const value =
          array[1] && array[1].path ? get(data, array[1].path) : data[array[0]];
        const stringifiedValue = String(value);

        //get from array[1]
        let renderValue;
        if (array[1] && array[1].render) {
          renderValue = array[1].render(value && stringifiedValue, data); //text,record
        } else if (value !== undefined && value !== null) {
          renderValue = stringifiedValue;
        }

        //render
        return (
          <Descriptions.Item
            key={keyLabel}
            label={
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <span
                style={{ width: "100%", height: "100%", position: "relative" }}
                className="clickable"
                onClick={() => {
                  if (value !== undefined) {
                    // setClipboard(value);
                    clipboard(value);
                  }
                }}
              >
                {keyLabel}
              </span>
            }
          >
            {renderValue}
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );
}

DescriptionTab.propTypes = {
  descriptionRender: any,
  data: any,
};
