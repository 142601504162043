import React, { useEffect, useState } from "react";
import { Tag } from "antd";
import moment from "moment";
import "./Invites.less";
//redux
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getContract } from "../../redux/contracts/contractActions";
import { inviteIncludeString } from "../../redux/invites/invitesActions";
//react router
//components
import DataTable from "../shared/DataTable/DataTable";
import SendInvites from "./SendInvites/SendInvites.js";
//TODO: replace with InviteGroups
import InviteGroups from "./InviteGroups";
//utils
import { getColumnsFromDataSource } from "../shared/DataTable/utils";
import GenericPageHeader from "../shared/GenericPageHeader/GenericPageHeader";
import GoToSendInvitesButton from "./InvitesHeader/GoToSendInvitesButton";
import GoToSendJoinButton from "./InvitesHeader/GoToSendJoinButton";
import SendJoinInvites from "./SendInvites/SendJoinInvites";
import { getTagColor } from "../../utils/getTagColor";
import StateFilter from "../Contracts/ContractHeader/StateFilter";
import { displayDate } from "../../utils/dates.js";

export default function Invites(props) {
  //router
  const history = useHistory();
  const params = useParams();

  //redux
  const dispatch = useDispatch();
  const data = useSelector(state => state.contracts.data);

  const [filter, setFilter] = useState("not_disqualified");

  const filteredContracts = data
    ? data.filter(contract => {
        switch (filter) {
          case "not_disqualified":
            return contract.state !== "disqualified";
          case "none":
            return true;
          default:
            return contract.state === filter;
        }
      })
    : null;

  // get data on load
  useEffect(() => {
    dispatch(getContract(inviteIncludeString));
  }, []);

  //if id in path, render ContractById component
  // TODO: Push this logic to router
  if (params.optionalPathParam) {
    if (params.optionalPathParam === "send") return <SendInvites />;
    if (params.optionalPathParam === "join") return <SendJoinInvites />;
    return <InviteGroups contractId={params.optionalPathParam} />;
  }

  //only render if data loaded
  if (!filteredContracts) return null;

  return (
    <div>
      <GenericPageHeader
        title="Invites"
        extra={[
          <StateFilter filter={filter} setFilter={setFilter} />,
          <GoToSendJoinButton key="sendJoin" />,
          <GoToSendInvitesButton key="sendInvites" />,
        ]}
      />
      <DataTable
        size="small"
        dataSource={filteredContracts}
        rowKey="contractId"
        pagination={{ defaultPageSize: 300 }}
        columns={getColumnsFromDataSource(
          data,
          //column names
          [
            "companyName",
            "name",
            "state",
            "startDate",
            "campaigns",
            "inviteGroups",
            "totalOnboards",
            "not_started",
            "started",
            "done",
            // ...(checkAuth.has(roles, ["supervisor"]) ? ["not_interested"] : []), //show only to supervisor
          ],
          {
            //set column props by column name
            state: {
              // eslint-disable-next-line react/display-name
              render: text => <Tag color={getTagColor(text)}>{text}</Tag>,
            },
            campaigns: {
              render: (text, record) => `${record.relations.campaigns.length}`,
            },
            inviteGroups: {
              render: (text, record) =>
                `${
                  record.relations.inviteGroups
                    ? record.relations.inviteGroups.length
                    : ""
                }`,
            },
            name: {
              title: "Contract Name",
            },
            startDate: {
              render: text => `${displayDate(text).format("MMM Do YYYY")}`,
            },
          }
        )}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => history.push(`/invites/${record.contractId}`), // click row
            // onDoubleClick: event => {}, // double click row
            // onContextMenu: event => {}, // right button click row
            // onMouseEnter: event => {}, // mouse enter row
            // onMouseLeave: event => {} // mouse leave row
          };
        }}
      />
    </div>
  );
}
