import { pushLoading, popLoading, result } from "../shared/sharedActions";
import { getToken } from "../auth/authActions";

export const types = {
  GET_POSTS_SUCCESS: "GET_POSTS_SUCCESS", //on successful GET fetch call
  UPLOAD_SCREENSHOT_SUCCESS: "UPLOAD_SCREENSHOT_SUCCESS",
};

/*
 * ACTIONS
 */

//TODO: for patchPostById and manual IG submission, create reducer type that injects the JSON object into the array

export const getPostsSuccess = data => {
  return { type: types.GET_POSTS_SUCCESS, data };
};

export const uploadScreenshotSuccess = (id, url) => {
  return { type: types.UPLOAD_SCREENSHOT_SUCCESS, id, url };
};

export const submitInstagramStep = (id, body) => async dispatch => {
  //loading
  const loadingId = `submitInstagramStep${id}`;
  dispatch(pushLoading(loadingId));
  try {
    //auth
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/onboards/${id}/steps`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body), //update
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    // return dispatch(getPostsSuccess([json]));
    //TODO:FIXME: REFACTOR CAMPAIGN AND POST UPDATES STRUCTURE
    window.location.reload(false);
  } catch (err) {
    dispatch(
      result({
        status: `error`,
        title: `Submit Instagram step failed`,
        subTitle: err.message,
      })
    );
    dispatch(popLoading(loadingId));
  }
};

export const patchPostById = (id, body) => async dispatch => {
  //loading
  const loadingId = `patchPostById${id}`;
  dispatch(pushLoading(loadingId));
  try {
    //auth
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/onboards/${id}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body), //update
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    // return dispatch(getPostsSuccess([json]));
    //TODO:FIXME: REFACTOR CAMPAIGN AND POST UPDATES STRUCTURE
    window.location.reload(false);
  } catch (err) {
    dispatch(
      result({
        status: `error`,
        title: `Patch onboards failed`,
        subTitle: err.message,
      })
    );
    dispatch(popLoading(loadingId));
  }
};

export const searchPosts = query => async dispatch => {
  //loading
  const loadingId = `searchPosts${query}`;
  dispatch(pushLoading(loadingId));
  try {
    //auth
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/onboards?search=${query}&include=payouts,campaigns,users,shortcodes,products,contracts`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    return dispatch(getPostsSuccess(json));
  } catch (err) {
    dispatch(
      result({
        status: `error`,
        title: `Search onboards failed`,
        subTitle: err.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};

export const submitManualScreenshot = () => async (dispatch, getState) => {
  //loading
  const loadingId = `submitManualScreenshot`;
  const { screenshot } = getState().posts;
  const body = {
    done: true,
    metadata: {
      manual_screenshot: screenshot.url,
    },
  };
  dispatch(pushLoading(loadingId));
  try {
    //auth
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/onboards/${screenshot.onboardId}?forceDone=true`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body), //update
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    // return dispatch(getPostsSuccess([json]));
    //TODO:FIXME: REFACTOR CAMPAIGN AND POST UPDATES STRUCTURE
    window.location.reload(false);
  } catch (err) {
    dispatch(
      result({
        status: `error`,
        title: `Submit manual screenshot failed`,
        subTitle: err.message,
      })
    );
    dispatch(popLoading(loadingId));
  }
};

export const uploadManualScreenshot =
  (id, formData, onSuccess) => async dispatch => {
    //loading
    const loadingId = `uploadManualScreenshot`;
    dispatch(pushLoading(loadingId));
    try {
      //auth
      const token = await dispatch(getToken());
      const requestObject = new Request(
        `${process.env.REACT_APP_API_HOST}api/services/upload?onboardId=${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "multipart/form-data",
          },
          body: formData, //includes onboardId, file
        }
      );
      const res = await fetch(requestObject);
      const json = await res.json();
      if (!res.ok) throw new Error(json.message || res.statusText);
      if (!json.url || json.url.length !== 1)
        throw new Error("Failed to return URL list");
      onSuccess(json.url);
      dispatch(uploadScreenshotSuccess(id, json.url[0]));
    } catch (err) {
      dispatch(
        result({
          status: `error`,
          title: `Upload manual screenshot failed`,
          subTitle: err.message,
        })
      );
    } finally {
      dispatch(popLoading(loadingId));
    }
  };

export const submitSendPayout = body => async (dispatch, getState) => {
  //loading
  const loadingId = `submitSendPayout`;
  dispatch(pushLoading(loadingId));
  try {
    //auth
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/payouts`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ ...body, manual: false, upfront: true }), //update and send $$ via paypal
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    // return dispatch(getPostsSuccess([json]));
    //TODO:FIXME: REFACTOR CAMPAIGN AND POST UPDATES STRUCTURE
    window.location.reload(false);
  } catch (err) {
    dispatch(
      result({
        status: `error`,
        title: `Send payout failed`,
        subTitle: err.message,
      })
    );
    dispatch(popLoading(loadingId));
  }
};

export const updatePayout = (payout, body) => async (dispatch, getState) => {
  const { payoutId } = payout;

  //auto resolve if transactionId or refundId provided
  if (
    payout.metadata &&
    payout.metadata.resolved === false &&
    (body.transactionId || body.refundId)
  )
    body.metadata = {
      ...payout.metadata,
      resolved: true,
    };

  //loading
  const loadingId = `updatePayout${payoutId}`;
  dispatch(pushLoading(loadingId));
  try {
    //auth
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/payouts/${payoutId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body), //update and send $$ via paypal
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    if (
      payout.payoutType === "product" &&
      body.refundId &&
      body.refundId.trim().length
    )
      //if refunding product, mark onboard as not interested
      return dispatch(patchPostById(payout.onboardId, { interested: false }));
    window.location.reload(false);
  } catch (err) {
    dispatch(
      result({
        status: `error`,
        title: `Update payout failed`,
        subTitle: err.message,
      })
    );
    dispatch(popLoading(loadingId));
  }
};

export const undoSubmission = id => async dispatch => {
  //loading
  const loadingId = `undoSubmission${id}`;
  dispatch(pushLoading(loadingId));
  try {
    //auth
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/onboards/${id}/undo`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    // return dispatch(getPostsSuccess([json]));
    //TODO:FIXME: REFACTOR CAMPAIGN AND POST UPDATES STRUCTURE
    window.location.reload(false);
  } catch (err) {
    dispatch(
      result({
        status: `error`,
        title: `Undo Instagram step failed`,
        subTitle: err.message,
      })
    );
    dispatch(popLoading(loadingId));
  }
};
