import React, { useEffect, useState } from "react";
import { Checkbox, Col, Empty, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useCampaign } from "../../api/campaigns";
import CampaignByIdHeader from "./CampaignHeader/CampaignByIdHeader";
import Posts from "../Posts/PostsDataTable";
import CompletedGallery from "../Posts/CompletedGallery";
import DescriptionTab from "../shared/DescriptionTab";
import campaignDescriptionRender from "./campaignDescriptionRender";
import {
  onboardIsAudit,
  onboardIsDone,
  onboardIsRefund,
  parseCampaignPosts,
} from "../../redux/campaigns/campaignUtils";
import { isLoading as setLoading } from "../../redux/shared/sharedActions";
import { useUpdateOnboard } from "../../api/onboards";
import checkAuth from "../../utils/checkAuth";

const getDefaultPage = tab => (tab === "campaign" ? null : 1);

const getDefaultPerPage = tab => {
  if (tab === "done") {
    return 8;
  }
  if (tab === "campaign") {
    return null;
  }
  return 10;
};

/*
COMPONENT
*/

export default function CampaignId() {
  const { campaignId } = useParams();
  //redux
  const dispatch = useDispatch();

  //router
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);

  //tab
  const [currentTab, setCurrentTab] = useState(
    searchParams.has("tab") ? searchParams.get("tab") : "done" //default
  );

  //page
  const defaultPage = getDefaultPage(currentTab);
  const [page, setPage] = useState(
    searchParams.has("page") ? Number(searchParams.get("page")) : defaultPage
  );

  //perPageconsole.log('object', object)
  const defaultPerPage = getDefaultPerPage(currentTab);
  const [perPage, setPerPage] = useState(
    searchParams.has("perPage")
      ? Number(searchParams.get("perPage"))
      : defaultPerPage
  );

  const roles = useSelector(state => state.auth.roles);
  const isSupervisor = checkAuth.has(roles, ["supervisor"]);

  const [campaignPosts, setCampaignPosts] = useState([]);
  const [auditedPosts, setAuditedPosts] = useState([]);
  const [refundedPosts, setRefundedPosts] = useState([]);
  const [donePosts, setDonePosts] = useState([]);

  const [reasonsSelected, setReasonsSelected] = useState({});

  const { data: campaign, isLoading } = useCampaign({
    campaignId,
    includes: [
      "onboards",
      "shortcodes",
      "shortcodeMedia",
      "products",
      "productPrices",
      "contracts",
    ],
    params: { stats: true },
  });

  const updateOnboard = useUpdateOnboard();

  useEffect(() => {
    if (campaign) {
      const _hasBilling = !!campaign.relations.contracts.billingContractId;

      setCampaignPosts(
        parseCampaignPosts(
          campaign,
          currentTab,
          onboard => onboard.state === currentTab,
          { page, perPage }
        )
      );
      setDonePosts(
        parseCampaignPosts(
          campaign,
          currentTab,
          onboard => onboardIsDone(onboard, _hasBilling),
          { page, perPage }
        )
      );
      setAuditedPosts(
        parseCampaignPosts(
          campaign,
          currentTab,
          onboard => onboardIsAudit(onboard),
          {
            page,
            perPage,
          }
        )
      );
      setRefundedPosts(
        parseCampaignPosts(
          campaign,
          currentTab,
          onboard => onboardIsRefund(onboard),
          { page, perPage }
        )
      );
    }
  }, [campaign, currentTab, page, perPage]);

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  //update url params on pagination/tab change
  useEffect(() => {
    //build params
    const params = {};
    if (currentTab) params.tab = currentTab;
    if (page) params.page = page;
    if (perPage) params.perPage = perPage;

    const newSearchParams = new URLSearchParams(params).toString();

    history.replace(`${history.location.pathname}?${newSearchParams}`);
  }, [currentTab, history, page, perPage]);

  //only render if data loaded
  if (!campaign) return isLoading ? null : <Empty className="centered" />;

  // Only show meta & audits/refunds tabs if contract has billingContract
  const hasBilling = !!campaign.relations.contracts.billingContractId;
  const numDone = campaign.relations.onboards.filter(onboard =>
    onboardIsDone(onboard, hasBilling)
  ).length;

  const getPostStatus = post => {
    if (post?.metadata?.billingNotes) {
      return post?.metadata?.billingNotes;
    }
    if (post.audited || reasonsSelected[post.onboardId]) {
      if (post.platformCreditCharged && post.productCreditCharged) {
        return "Billed";
      }
      if (!post.platformCreditCharged && !post.productCreditCharged) {
        return "Full refund";
      }
      if (!post.productCreditCharged && post.platformCreditCharged) {
        return "Product credit refund";
      }
      if (!post.platformCreditCharged && post.productCreditCharged) {
        return "Platform credit refund";
      }
    }
  };

  const getAuditsAndRefundsMeta = post => {
    if (isSupervisor && hasBilling) {
      const postStatus = getPostStatus(post);
      return (
        <div style={{ padding: "8px" }}>
          <Row>
            <Col span={24} style={{ marginBottom: "8px" }}>
              <Select
                defaultValue={postStatus}
                onSelect={async option => {
                  setReasonsSelected({ [post.onboardId]: option });
                  switch (option) {
                    case "Billed": {
                      await updateOnboard.mutateAsync({
                        onboardId: `${post.onboardId}/toggleCredits`,
                        body: {
                          productCreditCharged: true,
                          platformCreditCharged: true,
                        },
                      });
                      break;
                    }
                    case "Fraudulent": {
                      await updateOnboard.mutateAsync({
                        onboardId: `${post.onboardId}/toggleCredits`,
                        body: {
                          productCreditCharged: false,
                          platformCreditCharged: false,
                          billingNotes: "Fraudulent",
                        },
                      });
                      break;
                    }
                    case "No product placement": {
                      await updateOnboard.mutateAsync({
                        onboardId: `${post.onboardId}/toggleCredits`,
                        body: {
                          productCreditCharged: true,
                          platformCreditCharged: false,
                          billingNotes: "No product placement",
                        },
                      });
                      break;
                    }
                    case "Deleted post early": {
                      await updateOnboard.mutateAsync({
                        onboardId: `${post.onboardId}/toggleCredits`,
                        body: {
                          productCreditCharged: true,
                          platformCreditCharged: false,
                          billingNotes: "Deleted post early",
                        },
                      });
                      break;
                    }
                    case "Resubmission requested": {
                      await updateOnboard.mutateAsync({
                        onboardId: `${post.onboardId}/toggleCredits`,
                        body: {
                          productCreditCharged: false,
                          platformCreditCharged: false,
                          billingNotes: "Resubmission requested",
                        },
                      });
                      break;
                    }
                    case "Under investigation": {
                      await updateOnboard.mutateAsync({
                        onboardId: `${post.onboardId}/toggleCredits`,
                        body: {
                          productCreditCharged: false,
                          platformCreditCharged: false,
                          billingNotes: "Under investigation",
                        },
                      });
                      break;
                    }
                    case "Full refund": {
                      await updateOnboard.mutateAsync({
                        onboardId: `${post.onboardId}/toggleCredits`,
                        body: {
                          productCreditCharged: false,
                          platformCreditCharged: false,
                        },
                      });
                      break;
                    }
                    case "Product credit refund": {
                      await updateOnboard.mutateAsync({
                        onboardId: `${post.onboardId}/toggleCredits`,
                        body: {
                          productCreditCharged: false,
                          platformCreditCharged: true,
                        },
                      });
                      break;
                    }
                    case "Platform credit refund": {
                      await updateOnboard.mutateAsync({
                        onboardId: `${post.onboardId}/toggleCredits`,
                        body: {
                          productCreditCharged: true,
                          platformCreditCharged: false,
                        },
                      });
                      break;
                    }
                    default: {
                      break;
                    }
                  }
                }}
                style={{ width: "100%" }}
                options={[
                  {
                    value: "Billed",
                  },
                  {
                    value: "Fraudulent",
                  },
                  {
                    value: "No product placement",
                  },
                  {
                    label: (
                      <span style={{ color: "red" }}>Resubmission requested</span>
                    ),
                    value: "Resubmission requested",
                  },
                  {
                    label: (
                      <span style={{ color: "red" }}>Under investigation</span>
                    ),
                    value: "Under investigation",
                  },
                  {
                    label: <s>Deleted post early</s>,
                    value: "Deleted post early",
                  },
                  {
                    label: <s>Full refund</s>,
                    value: "Full refund",
                  },
                  {
                    label: <s>Product credit refund</s>,
                    value: "Product credit refund",
                  },
                  {
                    label: <s>Platform credit refund</s>,
                    value: "Platform credit refund",
                  },
                ]}
                placeholder="Select an Audit Reason"
              />
            </Col>
            <Col span={8}>
              <Checkbox disabled checked={post.platformCreditCharged}>
                Platform
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox disabled checked={post.productCreditCharged}>
                Product
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox
                disabled={!postStatus}
                defaultChecked={post.audited}
                onChange={async e => {
                  await updateOnboard.mutateAsync({
                    onboardId: post.onboardId,
                    body: { audited: e.target.checked },
                  });
                }}
              >
                Audit
              </Checkbox>
            </Col>
          </Row>
        </div>
      );
    }
  };

  //tab to Render
  let tabToRender;
  switch (currentTab) {
    case "done":
      tabToRender = (
        <CompletedGallery
          setPerPage={setPerPage}
          loadMore={() => setPerPage(perPage + defaultPerPage)}
          total={numDone} // Varies depending on whether we have billing enabled or not
          campaignPosts={donePosts}
          getMeta={getAuditsAndRefundsMeta}
        />
      );
      break;
    case "audits":
      tabToRender = (
        <CompletedGallery
          setPerPage={setPerPage}
          loadMore={() => setPerPage(perPage + defaultPerPage)}
          total={campaign.totalNotAudited}
          campaignPosts={auditedPosts}
          getMeta={getAuditsAndRefundsMeta}
        />
      );
      break;
    case "refunds":
      tabToRender = (
        <CompletedGallery
          setPerPage={setPerPage}
          loadMore={() => setPerPage(perPage + defaultPerPage)}
          total={campaign.totalRefunded}
          campaignPosts={refundedPosts}
          getMeta={getAuditsAndRefundsMeta}
        />
      );
      break;
    case "campaign":
      tabToRender = (
        <DescriptionTab
          descriptionRender={campaignDescriptionRender}
          data={campaign}
        />
      );
      break;
    default:
      //all other tabs
      tabToRender = (
        <Posts
          posts={campaignPosts}
          campaign={campaign}
          pagination={{
            current: page,
            pageSize: perPage,
            total: campaign[currentTab],
            onChange: newPage => setPage(newPage),
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            // showSizeChanger: true,
            // onShowSizeChange: (current, size) =>
            //   dispatch(getData({ per_page: size, page: current }))
          }}
        />
      );
      break;
  }

  return (
    <div>
      <CampaignByIdHeader
        currentTab={currentTab}
        setCurrentTab={tab => {
          //reset all settings on setting current tab
          // if (tab == "done") {
          //   dispatch(getCampaignPostsSuccess(props._id, null)); //reset so gallery shows nothing
          // }
          setPerPage(getDefaultPerPage(tab)); //set based on tab
          setPage(getDefaultPage(tab));
          setCurrentTab(tab);
        }}
        campaign={campaign}
        hasBilling={hasBilling}
        numDone={numDone}
        numRefunds={campaign.totalRefunded}
        numAudits={campaign.totalNotAudited}
      />
      {tabToRender}
    </div>
  );
}
