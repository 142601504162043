import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { result } from "../redux/shared/sharedActions";
import {
  createAuthenticatedRequest,
  defaultMutationOptions,
  executeRequest,
} from "./utils";

export const SERVICES_PATH = "services";

export function useUploadImage() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const path = `${SERVICES_PATH}/images`;

  return useMutation(
    ({ file, imageName, containerName, mimeType, extension, variants }) =>
      uploadImage(
        path,
        { file, imageName, containerName, mimeType, extension, variants },
        dispatch
      ),
    {
      ...defaultMutationOptions,
      onSuccess: () => {
        queryClient.invalidateQueries(path);
      },
    }
  );
}

// Execute a POST to upload a product image
export async function uploadImage(
  path,
  {
    file,
    imageName,
    containerName,
    mimeType = "image/webp",
    extension = "webp",
    variants = {
      thumbnail: { resize: [250, 250] },
      full: { resize: [750, 750] },
    },
  },
  dispatch
) {
  const formData = new FormData();
  formData.append("imageName", imageName);
  formData.append("containerName", containerName);
  formData.append("mimeType", mimeType);
  formData.append("extension", extension);
  formData.append("variants", JSON.stringify(variants));
  formData.append("file", file);
  const requestObject = await createAuthenticatedRequest(
    path,
    dispatch,
    {
      method: "POST",
      body: formData,
    },
    true
  );

  return executeRequest(requestObject);
}

export function useInstagramRescrapeUsers() {
  const dispatch = useDispatch();

  const path = `${SERVICES_PATH}/instagram/rescrapeUsers?saveProfilePic=true`;

  return useMutation(body => rescrapeUsers(path, body, dispatch), {
    ...defaultMutationOptions,
  });
}

// Execute a PUT request to rescrapeUsers
export async function rescrapeUsers(path, body, dispatch) {
  const requestObject = await createAuthenticatedRequest(path, dispatch, {
    method: "PUT",
    body: JSON.stringify(body),
  });

  return executeRequest(requestObject);
}

export function useScrapeLink() {
  const dispatch = useDispatch();

  const path = `${SERVICES_PATH}/scrapeLink`;

  return useMutation(body => scrapeLink(path, body, dispatch), {
    ...defaultMutationOptions,
  });
}

// Execute a POST request to scrapeLink
export async function scrapeLink(path, body, dispatch) {
  const requestObject = await createAuthenticatedRequest(path, dispatch, {
    method: "POST",
    body: JSON.stringify(body),
  });

  const response = await fetch(requestObject);
  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.message || response.statusText);
  }
  return response.text();
}

export function useRefreshThumbnails() {
  const dispatch = useDispatch();

  const path = `${SERVICES_PATH}/instagram/refreshThumbnails`;

  return useMutation(body => refreshThumbnails(path, body, dispatch), {
    ...defaultMutationOptions,
  });
}

// Execute a PUT request to refreshThumbnails
export async function refreshThumbnails(path, body, dispatch) {
  const requestObject = await createAuthenticatedRequest(path, dispatch, {
    method: "PUT",
    body: JSON.stringify(body),
  });

  return executeRequest(requestObject);
}

export function useUploadManualScreenshot(options) {
  const dispatch = useDispatch();

  return useMutation(
    ({ file, onboardId }) => {
      const path = `${SERVICES_PATH}/upload?onboardId=${onboardId}`;
      return uploadManualScreenshot({ path, file, dispatch });
    },
    { ...defaultMutationOptions, ...options }
  );
}

export async function uploadManualScreenshot({ path, file, dispatch }) {
  const formData = new FormData();
  formData.append("file", file);
  const requestObject = await createAuthenticatedRequest(
    path,
    dispatch,
    {
      method: "POST",
      body: formData,
    },
    true
  );

  return executeRequest(requestObject);
}
