import moment from "moment";
import { pushLoading, popLoading, result } from "../shared/sharedActions";
import { getToken } from "../auth/authActions";

export const types = {
  GET_CONTRACT_SUCCESS: "GET_CONTRACT_SUCCESS", //on successful GET fetch call
};

/*
 * ACTIONS
 */
export const getContractSuccess = data => {
  return { type: types.GET_CONTRACT_SUCCESS, data };
};

export const getContract =
  (includeString = `&include=companies,products`) =>
  async dispatch => {
    const loadingId = "getContract";
    dispatch(pushLoading(loadingId));
    try {
      const token = await dispatch(getToken());
      const requestObject = new Request(
        `${process.env.REACT_APP_API_HOST}api/contracts?stats=true${includeString}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );
      const res = await fetch(requestObject);
      const json = await res.json();
      if (!res.ok) throw new Error(json.message || res.statusText);
      dispatch(getContractSuccess(json));
    } catch (err) {
      console.error(err);
      //handle
      dispatch(
        result({
          status: `error`,
          title: `Get contract data failed`,
          subTitle: err.message,
        })
      );
    } finally {
      dispatch(popLoading(loadingId));
    }
  };

//download posts to csv
export const csvIncludeString =
  "onboards,shortcodes,campaigns,users,products,payouts";
export const downloadContractPosts = contract => async dispatch => {
  //loading
  const loadingId = `downloadContractPosts${contract.contractId}`;
  dispatch(pushLoading(loadingId));
  //auth
  const token = await dispatch(getToken());
  return fetch(
    `${process.env.REACT_APP_API_HOST}api/contracts/${contract.contractId}?csv=true&include=${csvIncludeString}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    }
  )
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${contract.name} - ${moment().format("YYYY-MM-DD")}.csv`; //filename
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    })
    .catch(err => {
      //error handling
      dispatch(
        result({
          status: `error`,
          title: `Download contract data failed`,
          subTitle: err.message,
        })
      );
    })
    .finally(() => dispatch(popLoading(loadingId))); //finish loading
};
