import React, { useEffect, useState } from "react";
import styles from "./ThumbnailGrid.module.css";
import { PlayCircleFilled } from "@ant-design/icons";

// Renders a grid of thumbnails, given a user.
// If the user does not have thumbnails, or any of the thumbnails error,
// we'll refresh them on the backend.
const ROW_LENGTH = 6;
function ThumbnailGrid({
  userId,
  batchId,
  refreshThumbnails,
  shortcodes,
  videos,
}) {
  const [thumbnails, setThumbnails] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  // Refresh thumbnails on load if they're missing
  useEffect(() => {
    if (!thumbnails || thumbnails.length === 0) {
      setShouldRefresh(true);
    }
  }, [thumbnails]);

  // If we get any error in our thumbnails, refresh them if we
  // havn't refreshed already.
  const onError = () => {
    if (!shouldRefresh) {
      setShouldRefresh(true);
    }
  };

  // Actually trigger the refresh
  useEffect(() => {
    (async () => {
      if (shouldRefresh) {
        const newThumbnails = await refreshThumbnails({
          userId,
          batchId,
          profileLocation: "UserInbound",
        });
        setThumbnails(newThumbnails);
      }
    })();
  }, [batchId, refreshThumbnails, shouldRefresh, userId]);

  const rows =
    thumbnails && thumbnails.length
      ? [
          thumbnails.slice(0, ROW_LENGTH),
          thumbnails.slice(ROW_LENGTH, 2 * ROW_LENGTH),
        ]
      : [];

  if (!thumbnails || !thumbnails.length) {
    return "Loading";
  }

  // https://css-tricks.com/adaptive-photo-layout-with-flexbox/#all-hail-flexbox
  return rows.map((rowItems, i) => (
    <ul className={styles.wrapper} key={i}>
      {rowItems.map((thumb, index) => {
        const idx = index + i * ROW_LENGTH;
        const shortcode = shortcodes[idx];
        const video = videos[idx];
        return (
          <li key={shortcode} className={styles.item}>
            <a
              href={`https://instagram.com/p/${shortcode}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={thumb}
                onError={onError}
                alt=""
                className={styles.image}
              />
              {video && <PlayCircleFilled className={styles.videoIcon} />}
            </a>
          </li>
        );
      })}
    </ul>
  ));
}

export default ThumbnailGrid;
