import React, { useState } from "react";
import moment from "moment";
import { CalendarOutlined } from "@ant-design/icons";
import { Button, message, Modal, Tooltip, Typography } from "antd";
import { any, string } from "prop-types";
import { useUpdateOnboard } from "../../../api/onboards";
import { toCalendarDate } from "../../../utils/dates";

// convert "2022-08-13T04:59:00.000Z" -> "August 12th 11:59 PM EST"
function friendlyDateESTFormat(date) {
  return moment(date).utcOffset("-0500").format("MMMM Do [11:59 PM EST]");
}

const ExtendDeadlineButton = props => {
  const { text, post, contract } = props;
  const updateOnboard = useUpdateOnboard({});
  const { isLoading } = updateOnboard;
  const [modalVisible, setModalVisible] = useState(false);
  const [extendLoading, setExtendLoading] = useState(false);

  // date variables for deadline logic
  const nowAtMidnightEST = new Date(
    `${toCalendarDate(new Date())} 23:59-05:00`
  );

  const deadlineDateStart = Math.max(
    new Date(post.deadlineDate).getTime(),
    nowAtMidnightEST.getTime()
  );

  const updatedDeadlineDate = moment(deadlineDateStart).add(14, "d"); //extend by 14days

  // disable when the new extension date would be past the contract end date
  let disabledReason = "";

  if (post.deadlineDate) {
    // This is a bit problematic because deadlineDate is a moment in time, but endDate is a calendar
    // date. We'll assume that contract.endDate is is 11:59 Eastern time on the day of the calendar
    // date.
    if (
      updatedDeadlineDate.isAfter(
        moment(contract.endDate).utcOffset("-0500").endOf("day")
      )
    ) {
      disabledReason =
        "Extended deadline date can't be more than 2 weeks ahead of the contract deadline date";
    }
  } else {
    disabledReason = "No deadline date is set for this onboard";
  }

  return (
    <span>
      <Tooltip title={disabledReason}>
        <Button
          loading={isLoading}
          disabled={!!disabledReason}
          key="extendDeadline"
          icon={<CalendarOutlined />}
          onClick={async () => {
            setModalVisible(true);
          }}
        >
          {text}
        </Button>
      </Tooltip>
      <Modal
        title={`Extend Deadline for ${post.igHandle}`}
        visible={modalVisible}
        okText="Confirm"
        onOk={async () => {
          try {
            setExtendLoading(true);
            await updateOnboard.mutateAsync({
              onboardId: `${post.onboardId}/extension`,
              body: {},
            });
            setModalVisible(false);
            message.success("Deadline extended!");
            window.location.reload(false);
          } catch (err) {
            message.error(err?.message || "An error occurred");
          } finally {
            setExtendLoading(false);
          }
        }}
        confirmLoading={extendLoading}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <Typography.Paragraph copyable>
          {`The current deadline date of ${friendlyDateESTFormat(
            post.deadlineDate
          )} will be extended by
          2 weeks from today. The new deadline is ${friendlyDateESTFormat(
            updatedDeadlineDate.toISOString()
          )}.`}
        </Typography.Paragraph>
      </Modal>
    </span>
  );
};

ExtendDeadlineButton.propTypes = {
  post: any,
  contract: any,
  text: string,
};

export default ExtendDeadlineButton;
