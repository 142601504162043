import React from "react";
import { CopyOutlined, FileOutlined, PlusOutlined } from "@ant-design/icons";
import { PageHeader, Button, message, Tag } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { object } from "prop-types";
import { getBreadcrumbProps } from "../../../AppRouter";
import { downloadContractPosts } from "../../../redux/contracts/contractActions";
import ContractByIdStats from "./ContractByIdStats";
import checkAuth from "../../../utils/checkAuth";
import EditContractButton from "./EditContractButton";
import { getTagColor } from "../../../utils/getTagColor";
import { displayDate } from "../../../utils/dates";

function ContractByIdHeader({ contract }) {
  const history = useHistory();

  const dispatch = useDispatch();

  const roles = useSelector(state => state.auth.roles);
  const isAdmin = checkAuth.has(roles, ["admin"]);

  const shouldDisplayBillingDashboardButton = contract.billingContractId;

  const buttons = [
    ...(shouldDisplayBillingDashboardButton
      ? [
          <Button
            icon={<CopyOutlined />}
            key="0"
            type="default"
            onClick={async () => {
              await navigator.clipboard.writeText(
                `${process.env.REACT_APP_INFLUENCER_BRAND_HOST}brand/billing/${contract.billingContractId}`
              );
              message.success("Link copied to clipboard");
            }}
          >
            Billing Dashboard
          </Button>,
        ]
      : []),
    <Button
      icon={<CopyOutlined />}
      key="1"
      type="default"
      onClick={async () => {
        await navigator.clipboard.writeText(
          `${process.env.REACT_APP_INFLUENCER_BRAND_HOST}campaigns/${contract.contractId}`
        );
        message.success("Link copied to clipboard");
      }}
    >
      Landing Page
    </Button>,
    ,
    <Button
      icon={<FileOutlined />}
      key="2"
      onClick={
        () => dispatch(downloadContractPosts(contract)) //download all posts belonging to contract
      }
    >
      Export Data to CSV
    </Button>,
  ];

  if (isAdmin) {
    buttons.push(
      <EditContractButton key="3" text="Edit" contract={contract} />,
      <Button
        key="4"
        type="primary"
        onClick={() =>
          history.push(
            `/campaigns/add/${contract.companyId}/${contract.contractId}`
          )
        }
      >
        <PlusOutlined />
        New Campaign
      </Button>
    );
  }

  return (
    <div className="main-no-padding">
      <PageHeader
        ghost={false}
        breadcrumb={getBreadcrumbProps(history.location)} //see https://ant.design/components/breadcrumb/#Use-with-browserHistory
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "0",
            }}
          >
            {contract.name}
            <Tag
              color={getTagColor(contract.state)}
              style={{ marginLeft: "10px" }}
            >
              {contract.state}
            </Tag>
          </div>
        }
        subTitle={`${displayDate(contract.startDate).format("MMM Do YYYY")}${
          //conditional if end date
          contract.endDate
            ? ` - ${displayDate(contract.endDate).format("MMM Do YYYY")}`
            : ""
        }`}
        extra={buttons}
      >
        <ContractByIdStats contract={contract} />
      </PageHeader>
    </div>
  );
}

ContractByIdHeader.propTypes = {
  contract: object,
};

export default ContractByIdHeader;
