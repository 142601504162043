import React, { useState } from "react";
import { InstagramOutlined, CameraOutlined } from "@ant-design/icons";
import { Button, Modal, Input, Typography, Upload, Space } from "antd";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  submitInstagramStep,
  submitManualScreenshot,
  uploadManualScreenshot,
} from "../../../redux/posts/postActions";
//utils
import checkAuth from "../../../utils/checkAuth";

const { Text } = Typography;

const shortcodeRegex = new RegExp(/[\S]{11}/, "i");

export default props => {
  const { post } = props;

  //state
  const [invalidShortcode, setInvalidShortcode] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalInput, setModalInput] = useState();
  const [defaultFileList, setDefaultFileList] = useState([]);

  //redux
  const dispatch = useDispatch();
  const roles = useSelector(state => state.auth.roles);
  const screenshot = useSelector(state => state.posts.screenshot);

  const manualSubmit = () => {
    if (
      screenshot &&
      defaultFileList.length &&
      screenshot.onboardId === post.onboardId
    )
      dispatch(submitManualScreenshot());
    else validateAndSubmitShortcode();
  };

  const validateAndSubmitShortcode = () => {
    const shortcodeId = modalInput && modalInput.trim();
    const match = shortcodeRegex[Symbol.match](shortcodeId);
    //if valid shortcode regex, submit as json body
    if (match && match[0] === shortcodeId) {
      dispatch(
        submitInstagramStep(post.onboardId, {
          stepType: "instagram",
          shortcodeId,
        })
      );
      //reset
      setModalInput(null);
      setVisible(false);
    } else setInvalidShortcode(true);
  };

  const uploadImage = async options => {
    const { onSuccess, file } = options;
    const formData = new FormData();
    formData.append("file", file);
    dispatch(uploadManualScreenshot(post.onboardId, formData, onSuccess));
  };

  const handleOnChange = ({ file, fileList, event }) => {
    // console.log(file, fileList, event);
    //Using Hooks to update the state to the current filelist
    setDefaultFileList(fileList);
    //filelist - [{uid: "-1",url:'Some url to image'}]
  };

  if (!post || post.done || !checkAuth.has(roles, ["manager"])) return null;
  return (
    <span>
      <Button
        type="primary"
        key="close"
        icon={<InstagramOutlined />}
        onClick={() => setVisible(true)}
      >
        {props.text}
      </Button>
      <Modal
        title={`Submit Instagram step for ${post.igHandle}`}
        visible={visible}
        okText="Submit"
        onOk={() => manualSubmit()}
        // confirmLoading={confirmLoading}
        onCancel={() => {
          //close and reset input
          setModalInput(null);
          setVisible(false);
        }}
      >
        <Input
          placeholder="B-vf01dnj7m"
          value={modalInput}
          onChange={({ target: { value } }) => {
            setModalInput(value);
            setInvalidShortcode(false);
          }}
        />
        {invalidShortcode && (
          <Text type="danger">
            Shortcode format must be 11 characters (starts with a capital
            letter)
          </Text>
        )}
        {/* from https://stackblitz.com/edit/so-58128062-upload-progress */}
        <Space direction="vertical">
          <Upload
            accept="image/*"
            listType="picture"
            customRequest={uploadImage}
            onChange={handleOnChange}
            openFileDialogOnClick={!defaultFileList.length}
            defaultFileList={defaultFileList}
          >
            or <Typography.Link>upload a screenshot</Typography.Link>
          </Upload>
          {!post.interested && (
            <Text type="danger">
              You are submitting completion for an onboard marked as "
              <b>Not Interested</b>"
            </Text>
          )}
        </Space>
      </Modal>
    </span>
  );
};
