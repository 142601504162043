import React from "react";
import { Button, Modal, Form, Input, Select, Tag } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { array, bool, func, string } from "prop-types";
import QueryAutocomplete from "./SendInvites/QueryAutocomplete";
import { getTagColor } from "../../utils/getTagColor";

const { Option } = Select;

export default function NewInviteGroupModal({
  contractName,
  campaigns,
  visible,
  onCreate,
  onCancel,
}) {
  const campaignOptions =
    campaigns &&
    campaigns.map(campaign => {
      const label = campaign.name;
      return (
        <Option
          key={label.toLowerCase()}
          value={campaign.campaignId}
          disabled={campaign.state !== "active"}
        >
          {label}{" "}
          <Tag color={getTagColor(campaign.state)}>{campaign.state}</Tag>
        </Option>
      );
    });
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Create a new Invite Group"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            values.woodpeckerCampaigns = values.woodpeckerCampaigns
              ? values.woodpeckerCampaigns.filter(Boolean)
              : [];
            form.resetFields();
            onCreate(values);
          })
          .catch(info => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          name: contractName,
          defaultTagQuery: "all=gender:female",
        }}
      >
        <Form.Item
          name="name"
          label="Invite Group Name"
          rules={[
            {
              required: true,
              message: "name is a required field",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="defaultTagQuery"
          label="Default Tag Query"
          extra="e.g. all=gender:female&any=niche:fitness,niche:food"
          rules={[
            {
              required: true,
              message: "defaultTagQuery is a required field",
            },
          ]}
        >
          <QueryAutocomplete
            onChange={value => {
              form.setFieldsValue({ defaultTagQuery: value });
            }}
          />
        </Form.Item>
        <Form.Item
          name="campaigns"
          label="Campaigns from Contract"
          rules={[
            {
              required: true,
              message: "At least one Campaign selection is required",
            },
          ]}
        >
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Select one or more Campaigns to include"
            filterOption={
              //case insensitive search (option should be lowercase already)
              (inputValue, option) =>
                option.key.includes(inputValue.toLowerCase())
            }
          >
            {campaignOptions}
          </Select>
        </Form.Item>
        <Form.List name="woodpeckerCampaigns">
          {(fields, { add, remove }) => {
            //create at least 1 woodpecker campaign
            if (!fields.length) {
              fields.push({ name: 0, key: 0, fieldKey: 0 });
            }
            return (
              <div>
                {fields.map((field, index) => {
                  return (
                    <Form.Item
                      label={index === 0 ? "Woodpecker Campaign IDs" : ""}
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        noStyle
                      >
                        <Input
                          placeholder="Woodpecker ID"
                          style={{ width: "85%" }}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          style={{ margin: "0 0 0 8px" }}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      ) : null}
                    </Form.Item>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    style={{ width: "60%" }}
                  >
                    <PlusOutlined /> Add Woodpecker Campaign
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
}

NewInviteGroupModal.propTypes = {
  contractName: string,
  campaigns: array,
  visible: bool,
  onCreate: func,
  onCancel: func,
};
