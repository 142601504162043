import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  createAuthenticatedRequest,
  defaultQueryOptions,
  executeRequest,
} from "./utils";

export const TAG_TYPES_PATH = "tagTypes";

export function useTagTypes({ includes, params, ...overrideOptions }) {
  const dispatch = useDispatch();

  return useQuery(
    [TAG_TYPES_PATH, includes, params],
    () => fetchTagTypes(TAG_TYPES_PATH, dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for tag types
export async function fetchTagTypes(path, dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    path,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}
