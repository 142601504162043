// Architecture Guide:  https://medium.freecodecamp.org/react-redux-architecture-part-1-separation-of-concerns-812da3b08b46
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
//reducers
import authReducer from "./auth/authReducer";
import campaignReducer from "./campaigns/campaignReducer";
import contractReducer from "./contracts/contractReducer";
import invitesReducer from "./invites/invitesReducer";
import payoutReducer from "./payouts/payoutReducer";
import postReducer from "./posts/postReducer";
import sharedReducer from "./shared/sharedReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  campaigns: campaignReducer,
  contracts: contractReducer,
  invites: invitesReducer,
  payouts: payoutReducer,
  posts: postReducer,
  shared: sharedReducer,
});

// https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//export createStore
const storeConfig = initialState => {
  //accepts an intial state
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  );
};

export default storeConfig;
