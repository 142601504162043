import React from "react";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { Button } from "antd";
//utils
import clipboard from "../../../utils/clipboard";

export default props => {
  const { post, campaign } = props;
  if (!post || !campaign) return null;
  return (
    <Button
      key="shoppingCart"
      icon={<ShoppingCartOutlined />}
      onClick={() => {
        clipboard(
          `${process.env.REACT_APP_CONFIRM_UI_HOST}?id=${post.onboardId}&tab=order&url=${campaign.link}`
        );
      }}
    >
      {props.text}
    </Button>
  );
};
