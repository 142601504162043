import React from "react";
import { Card, Button, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { object } from "prop-types";
import styles from "./AddNewCampaign.module.css";

function CampaignDetails({ data }) {
  if (!data) {
    return null;
  }

  return (
    <div className={styles.campaignDetailsContainer}>
      {Object.keys(data).map((title, cardIndex) => (
        <Card
          bordered={false}
          key={title}
          style={cardIndex > 0 ? { marginTop: "24px" } : {}}
          title={title}
          extra={
            data[title].edit && (
              <Button onClick={data[title].edit} icon={<EditOutlined />}>
                Edit
              </Button>
            )
          }
        >
          {Object.keys(data[title]).map(key => {
            if (key === "Image" && typeof data[title][key] === "string") {
              return (
                <p key={key}>
                  <strong>{key}</strong>:
                  <img
                    style={{ width: "100px", marginLeft: "10px" }}
                    src={data[title][key]}
                    alt="product"
                  />
                </p>
              );
            }

            if (key === "Photo Examples" && Array.isArray(data[title][key])) {
              return (
                <p key={key}>
                  <strong>{key}</strong>:
                  {data[title][key].map((photoExampleUrl, i) => {
                    return (
                      <img
                        key={i}
                        style={{ width: "100px", marginLeft: "10px" }}
                        src={photoExampleUrl}
                        alt={`product example ${i + 1}`}
                      />
                    );
                  })}
                </p>
              );
            }

            if (
              typeof data[title][key] === "string" ||
              typeof data[title][key] === "number"
            ) {
              return (
                <p key={key}>
                  <strong>{key}</strong>
                  {`: ${data[title][key]}`}
                </p>
              );
            }

            if (data[title][key] && data[title][key].length) {
              const tags = data[title][key];
              return (
                <p key={key}>
                  <strong>{key}</strong>:
                  <span style={{ marginLeft: "10px" }}>
                    {tags.map((tag, tagIndex) => {
                      return <Tag key={tag + tagIndex}>{tag}</Tag>;
                    })}
                  </span>
                </p>
              );
            }

            return null;
          })}
        </Card>
      ))}
    </div>
  );
}

CampaignDetails.propTypes = {
  data: object,
};

export default CampaignDetails;
