import React, { useEffect } from "react";
import { PageHeader, Space } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
//router
import { useHistory } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  getPaypalTransactions,
  downloadUnresolved,
  uploadResolvedPaypalTransactions,
} from "../../../redux/payouts/payoutActions";
//components
import DownloadUnresolved from "../ActionButtons/DownloadUnresolved";
import UploadResolved from "../ActionButtons/UploadResolved";
import AmountToSendCalculator from "../ActionButtons/AmountToSendCalculator";

export default props => {
  //redux
  const dispatch = useDispatch();
  const paypalTransactionsCount = useSelector(
    state => state.payouts.paypalTransactionsCount
  );

  //pass to button for download to csv
  const downloadUnresolvedPaypalTransactions = () =>
    dispatch(downloadUnresolved("paypalTransactions"));

  //get paypalTransactionsCount on mount
  useEffect(() => {
    dispatch(getPaypalTransactions());
  }, []);

  return (
    <div className="main-no-padding border">
      <PageHeader
        extra={
          <Space align="center">
            <AmountToSendCalculator key="calculator" />
            <DownloadUnresolved
              action={downloadUnresolvedPaypalTransactions}
              text="Download Transactions"
              key="download"
            />
            <UploadResolved
              action={uploadResolvedPaypalTransactions}
              text="Upload Transactions"
              key="upload"
            />
          </Space>
        }
        ghost={false}
        title={
          <span>
            Paypal Transactions{" "}
            {Number.isInteger(paypalTransactionsCount) ? (
              paypalTransactionsCount ? (
                `(${paypalTransactionsCount})`
              ) : (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              )
            ) : (
              ""
            )}
          </span>
        }
      ></PageHeader>
    </div>
  );
};
