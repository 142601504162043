import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input } from "antd";
import React, { useState, useEffect, useRef } from "react";
import "../../AppLayout/Header/Header.less";
//router
import { useHistory } from "react-router-dom";

export default function (props) {
  //router
  const history = useHistory();

  //state
  const refInput = useRef();
  const [searchMode, setSearchMode] = useState(false);
  useEffect(() => {
    if (searchMode) refInput.current.focus();
  }, [searchMode]);

  // class="ant-input-search ant-select-selection-search-input ant-input-affix-wrapper"
  return (
    <span className="headerSearch" onClick={() => setSearchMode(true)}>
      <SearchOutlined key="Icon" />
      <AutoComplete
        key="AutoComplete"
        className={"input" + (searchMode ? " show" : "")}
      >
        <Input
          ref={refInput}
          onBlur={() => setSearchMode(false)}
          onPaste={event =>
            history.push(
              `/onboards?search=${event.clipboardData.getData("text").trim()}`
            )
          }
          // value={"ayor.bush@gmail.com"}
          onPressEnter={e =>
            history.push(
              `/onboards?search=${refInput.current.input.value.trim()}`
            )
          }
        />
      </AutoComplete>
    </span>
  );
}

// export default class HeaderSearch extends Component {
//   static defaultProps = {
//     defaultActiveFirstOption: false,
//     onPressEnter: () => {},
//     onSearch: () => {},
//     onChange: () => {},
//     className: "",
//     placeholder: "",
//     dataSource: [],
//     defaultOpen: false,
//     onVisibleChange: () => {}
//   };

//   constructor(props) {
//     super(props);
//     this.state = {
//       searchMode: props.defaultOpen,
//       value: props.defaultValue
//     };
//   }

//   onChange = value => {
//     if (typeof value === "string") {
//       const { onSearch, onChange } = this.props;
//       this.setState({
//         value
//       });

//       if (onSearch) {
//         onSearch(value);
//       }

//       if (onChange) {
//         onChange(value);
//       }
//     }
//   };
//   enterSearchMode = () => {
//     const { onVisibleChange } = this.props;
//     onVisibleChange(true);
//     this.setState(
//       {
//         searchMode: true
//       },
//       () => {
//         const { searchMode } = this.state;

//         if (searchMode && this.inputRef) {
//           this.inputRef.focus();
//         }
//       }
//     );
//   };

//   leaveSearchMode = () => {
//     this.setState({
//       searchMode: false
//     });
//   };

//   enterPressed = () => {
//     console.log("enter pressed");
//     const { onPressEnter } = this.props;
//     const { value } = this.state;
//     onPressEnter(value || "");
//   };

//   render() {

//     return (
//       <span className="headerSearch" onClick={this.enterSearchMode}>
//         <SearchOutlined key="Icon" />
//         <Input
//           ref={node => {
//             this.inputRef = node;
//           }}
//           onBlur={this.leaveSearchMode}
//           onPaste={event =>
//             dispatch(searchPosts(event.clipboardData.getData("text")))
//           }
//           // value={"ayor.bush@gmail.com"}
//           onSearch={value => dispatch(searchPosts(value))}
//         />
//         {/* </AutoComplete> */}
//       </span>
//     );
//   }
// }
