import React, { useEffect, useState } from "react";
import { func, object, string } from "prop-types";
import styles from "./AddNewCampaign.module.css";
import ImageUpload from "../../shared/ImageUpload";

export default function PhotoExamplesUpload({
  productName,
  onUpload,
  defaultImageUrls,
}) {
  const [imageList, setImageList] = useState([
    {
      thumbnailUrl: "",
      fullImageUrl: "",
      loading: false,
    },
    {
      thumbnailUrl: "",
      fullImageUrl: "",
      loading: false,
    },
    {
      thumbnailUrl: "",
      fullImageUrl: "",
      loading: false,
    },
  ]);

  useEffect(() => {
    if (defaultImageUrls) {
      const newImageList = [...imageList];
      newImageList.forEach((image, index) => {
        image.thumbnailUrl = defaultImageUrls.thumbnailUrls[index];
        image.fullImageUrl = defaultImageUrls.fullImageUrls[index];
      });
      setImageList(newImageList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultImageUrls]);

  const onUploadImage = async (urls, index) => {
    const newImageList = [...imageList];
    newImageList[index].thumbnailImageUrl = urls.thumbnailImageUrl;
    newImageList[index].fullImageUrl = urls.fullImageUrl;
    if (onUpload) {
      const thumbnailUrls = newImageList.map(image => image.thumbnailImageUrl);
      const fullImageUrls = newImageList.map(image => image.fullImageUrl);
      onUpload({
        thumbnailUrls,
        fullImageUrls,
      });
    }
  };

  return (
    <div className={styles.photoExamplesContainer}>
      {imageList.map((image, index) => {
        return (
          <ImageUpload
            key={index}
            imageSizeValidation={(width, height) =>
              width >= 250 && width <= 1200 && width === height
            }
            altText={`Product Example ${index + 1}`}
            errorMessage="Image dimensions must be at least 250x250 pixels and at most 1200x1200 pixels and must be a square (equal width and height)"
            imageName={`${productName}_example_${index + 1}`}
            onUpload={urls => onUploadImage(urls, index)}
            containerName="product-images"
            defaultImageUrl={image.thumbnailUrl}
            mimeTypes={["image/jpeg", "image/png", "image/gif"]}
            variants={{
              thumbnail: { resize: [250, 250] },
              full: { resize: [500, 500] },
            }}
          />
        );
      })}
    </div>
  );
}

PhotoExamplesUpload.propTypes = {
  productName: string,
  onUpload: func,
  defaultImageUrls: object,
};
