import React from "react";
import { PageHeader } from "antd";
//router
import { getBreadcrumbProps } from "../../../AppRouter";
import { useHistory } from "react-router-dom";

export default props => {
  const history = useHistory();
  return (
    <div className="main-no-padding border">
      <PageHeader
        ghost={false}
        breadcrumb={getBreadcrumbProps(history.location)} //see https://ant.design/components/breadcrumb/#Use-with-browserHistory
        title="Onboards Search"
        extra={props.children}
      ></PageHeader>
    </div>
  );
};
