import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import checkAuth from "../../utils/checkAuth";
import ContractByIdHeader from "./ContractHeader/ContractByIdHeader";
import CampaignsTable from "../Campaigns/CampaignsTable";
import BillingStatusTag from "./BillingContract/BillingStatusTag";
import { useContractById } from "../../api/contracts";
import { isLoading as setLoading } from "../../redux/shared/sharedActions";
import BillingContract from "./BillingContract/BillingContract";
import {
  useBillingContractById,
  useBillingContractSetup,
} from "../../api/billingContract";

const { TabPane } = Tabs;

export default function ContractById() {
  const { contractId } = useParams();
  const dispatch = useDispatch();

  const { data: contract, isLoading: isLoadingContract } = useContractById({
    contractId,
    includes: ["campaigns", "products", "companies"],
    params: { stats: true },
  });

  // billing requires admin auth
  const roles = useSelector(state => state.auth.roles);
  const isAdmin = checkAuth.has(roles, ["admin"]);

  const { data: billingContractSetupData } = useBillingContractSetup({
    billingContractId: contract?.billingContractId,
    // requires admin auth to enable
    enabled: isAdmin && !!contract?.billingContractId,
  });

  const { data: billingContract } = useBillingContractById({
    billingContractId: contract?.billingContractId,
    includes: ["contracts"],
    // requires admin auth to enable
    enabled: isAdmin && !!contract?.billingContractId,
  });

  useEffect(() => {
    dispatch(setLoading(isLoadingContract));
  }, [isLoadingContract, dispatch]);

  useEffect(() => {
    dispatch(setLoading(!contract));
  }, [contract, dispatch]);

  //dont render if byId data not loaded
  if (!contract) return null;
  return (
    <div>
      <ContractByIdHeader
        // currentTab={currentTab}
        // setCurrentTab={setCurrentTab}
        contract={contract}
      />
      <Tabs
        defaultActiveKey="campaigns"
        tabBarStyle={{ backgroundColor: "white", paddingLeft: "24px" }}
      >
        <TabPane tab="Campaigns" key="campaigns">
          <CampaignsTable
            campaigns={contract.relations.campaigns}
            useFilter={false}
            showSelection
            contractId={contractId}
          />
        </TabPane>

        {billingContractSetupData && (
          <TabPane
            tab={
              <span>Billing {BillingStatusTag(billingContractSetupData)}</span>
            }
            key="billing"
          >
            <BillingContract
              title="Billing Settings"
              setup={billingContractSetupData}
              contract={contract}
              billingContract={billingContract}
            />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
}
