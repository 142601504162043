import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import React, { useRef } from "react";

// Renders a carousel or single image/video
export function InstagramMedia({ post, ...rest }) {
  const manualScreenshot =
    !post.shortcodeId && post.metadata && post.metadata.manual_screenshot;

  const isCarousel =
    post.relations?.shortcodes?.relations?.shortcodeMedia?.length > 1;

  if (isCarousel) {
    return (
      <InstagramCarousel
        post={post}
        shortcodeMedia={post.relations?.shortcodes?.relations?.shortcodeMedia}
      />
    );
  }

  return (
    <InstagramMediaItem
      shortcodeMedia={post.relations?.shortcodes?.relations?.shortcodeMedia[0]}
      manualScreenshot={manualScreenshot}
      post={post}
    />
  );
}

export function InstagramCarousel({
  shortcodeMedia,
  manualScreenshot,
  post,
  ...rest
}) {
  const carouselRef = useRef(null);
  // const [rightArrowVisible, setRightArrowVisible] = useState(true);
  // const [leftArrowVisible, setLeftArrowVisible] = useState(false);

  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    adaptiveHeight: false,
    rows: 1,
  };

  return (
    <Slider ref={carouselRef} {...settings}>
      {shortcodeMedia.map(s => (
        <InstagramMediaItem
          key={s.index}
          shortcodeMedia={s}
          post={post}
          manualScreenshot={manualScreenshot}
        />
      ))}
    </Slider>
  );
}

// Renders a video, or single image depending on the post's shortcodeMedia
export function InstagramMediaItem({
  post,
  shortcodeMedia,
  manualScreenshot,
  ...rest
}) {
  const link = `https://instagram.com/${
    //if screenshot, link to profile rather than post
    manualScreenshot ? post.igHandle : `p/${post.shortcodeId}`
  }`;

  const media =
    shortcodeMedia && shortcodeMedia.type === "video" ? (
      <InstagramVideo shortcodeMedia={shortcodeMedia} />
    ) : (
      <img
        alt=""
        src={
          manualScreenshot ||
          (shortcodeMedia && shortcodeMedia.mediaUrl) ||
          `${process.env.REACT_APP_SHORTCODES_HOST}${post.shortcodeId}.jpg`
        }
        height={310}
        style={{
          width: "100%",
          objectFit: "cover",
        }}
      />
    );

  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="card-container clickable"
    >
      {media}
    </a>
  );
}

function InstagramVideo({ shortcodeMedia }) {
  return (
    <video
      image={shortcodeMedia.mediaUrl}
      poster={shortcodeMedia.thumbnailUrl}
      controls
      height={310}
      style={{
        width: "100%",
        objectFit: "cover",
      }}
    >
      <source src={shortcodeMedia.mediaUrl} type="video/mp4" />
    </video>
  );
}
