import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ManualPayouts from "./ManualPayouts";
import PayoutsHome from "./PayoutsHome";

export default function Campaigns() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/manualPayouts`}>
        <ManualPayouts />
      </Route>
      <Route path={`${path}/`} exact>
        <PayoutsHome />
      </Route>
    </Switch>
  );
}
