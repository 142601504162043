import React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip, Empty } from "antd";
import moment from "moment";
//redux
import { useSelector } from "react-redux";
//router
import { useHistory } from "react-router-dom";
//components
import DataTable from "../shared/DataTable/DataTable";
import {
  shortcodeModalMouseEnter,
  shortcodeModalMouseLeave,
} from "./InstaPostModal";
//utils
import { getColumnsFromDataSource } from "../shared/DataTable/utils";
import { currentStep } from "./postDescriptionRender";

import checkAuth from "../../utils/checkAuth";

//TODO: Modify campaign posts API to include support for pagination
//TODO: Create PUT API that accepts pagination parameters for a campaign, loops through

export default function PostsDataTable(props) {
  //redux
  const isLoading = useSelector(state => state.shared.isLoading);
  const roles = useSelector(state => state.auth.roles);

  //router
  const history = useHistory();

  //only render if data loaded
  if (!isLoading && (!props.posts || !props.posts.length))
    return <Empty className="centered" />;

  return (
    <div>
      <DataTable
        size="small"
        dataSource={props.posts}
        rowKey="onboardId"
        pagination={
          //API:  https://ant.design/components/pagination/#API
          props.pagination
        }
        onRow={(record, rowIndex) => {
          return checkAuth.has(roles, ["manager"])
            ? //manager actions
              {
                //navigate to post by id
                onDoubleClick: e =>
                  history.push(`/onboards/${record.onboardId}`),
              }
            : null;
        }}
        columns={getColumnsFromDataSource(
          props.posts,
          //column names
          [
            ...(checkAuth.has(roles, ["manager"]) ? ["onboardId"] : []), //show only to manager
            "step",
            "igHandle",
            "createdAt",
            "completedDate",
          ],
          {
            onboardId: {
              title: "Id",
              render: text => (
                <span className="ant-breadcrumb-link">{text}</span>
              ),
            },
            igHandle: {
              title: "Handle",
              render: text => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`http://instagram.com/${text}`}
                >
                  {text}
                </a>
              ),
            },
            createdAt: {
              title: "Onboarded",
              render: text => text && moment(text).format("ll"),
            },
            ordered: {
              render: text => text && moment(text).format("ll"),
            },
            completedDate: {
              title: "Completed",
              render: text => text && moment(text).format("ll"),
            },
            doneFollowupAt: {
              title: "CTR",
              render: text => text && moment(text).format("ll"),
            },
            //set column props by column name
            step: {
              title: (
                <span>
                  Step{" "}
                  <Tooltip title="Hover over 'Completed' to view post">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              ),
              onCell: (record, rowIndex) => {
                return {
                  // onClick: event =>
                  //   history.push(`${history.location.pathname}/${record.onboardId}`), // click row
                  // onDoubleClick: event => {}, // double click row
                  // onContextMenu: event => {}, // right button click row
                  onMouseEnter: event => shortcodeModalMouseEnter(record),
                  onMouseLeave: event => shortcodeModalMouseLeave(),
                };
              },
              render: currentStep,
            },
            // ,remindersSent: {
            //   render: (text, record) =>
            //     record.done || !record.emailsSent.length
            //       ? null
            //       : record.emailsSent[record.currentStep] +
            //         record.emailsSent[record.emailsSent.length - 1] //sum of current step + last case emails
            // }
          }
        )}
      />
    </div>
  );
}
