import React, { useState } from "react";
import { Form, Input, Button, Select, Tag } from "antd";
import { array, bool, func, string } from "prop-types";
import ColorHash from "color-hash";
import { WarningOutlined, FieldTimeOutlined } from "@ant-design/icons";
import TwoComponentSwitch from "../shared/TwoComponentSwitch/TwoComponentSwitch";
import styles from "./Test.module.css";
import {
  defaultButtonWrapperCol,
  defaultFormItemLayout,
} from "../../utils/formLayout";
import { selectFilter } from "../../utils/filters";
import { useCreateUser, useDeleteUser } from "../../api/users";
import { useInstagramRescrapeUsers } from "../../api/services";
import GenericModal from "./GenericModal";

const colorHash = new ColorHash();

const FirstForm = ({ options, name, loading, selectedTestUserId }) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const deleteUser = useDeleteUser({});

  const { isError, isLoading, error } = deleteUser;

  const onDeleteUser = async () => {
    deleteUser.mutate(selectedTestUserId, {
      onSuccess: () => {
        window.location.reload();
      },
      onError: () => {
        setDeleteModalVisible(false);
      },
    });
  };

  return (
    <>
      <GenericModal
        visible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        onOk={onDeleteUser}
        loading={isLoading}
        message="Are you sure you want to delete this test user and all related data?"
        title={
          <>
            <WarningOutlined /> Delete Confirmation
          </>
        }
      />
      <Form.Item
        label="Test User"
        name={name}
        rules={[{ required: true, message: "required" }]}
      >
        <Select
          filterOption={selectFilter}
          loading={loading}
          showSearch
          className={styles.fullWidth}
          placeholder="Type to search test users"
          options={options}
        />
      </Form.Item>
      <Form.Item wrapperCol={defaultButtonWrapperCol}>
        <Button
          disabled={!selectedTestUserId}
          type="primary"
          onClick={() => setDeleteModalVisible(true)}
        >
          Delete User
        </Button>
        {isError && (
          <h4 className={styles.error}>
            <WarningOutlined fill="red" /> {error.message}
          </h4>
        )}
      </Form.Item>
    </>
  );
};

FirstForm.propTypes = {
  options: array,
  name: string,
  loading: bool,
  selectedTestUserId: string,
};

const SecondForm = ({
  toggleVisibleComponent,
  options,
  setOptions,
  addSelectedOption,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const createUser = useCreateUser();
  const rescrapeUsers = useInstagramRescrapeUsers();
  const deleteUser = useDeleteUser({});

  const onSubmit = async () => {
    try {
      setLoading(true);
      setError("");
      await form.validateFields();
      const values = form.getFieldsValue(true);
      try {
        const userData = await createUser.mutateAsync({
          firstName: "Test User",
          email: values.email.trim().toLowerCase(),
          activeProfile: values.igHandle.trim().toLowerCase(),
          relations: {
            userProfiles: [
              {
                igHandle: values.igHandle.trim().toLowerCase(),
              },
            ],
            userTags: [
              {
                tagType: "app",
                tagValue: "test_user",
              },
            ],
          },
        });
        try {
          const rescrapeResult = await rescrapeUsers.mutateAsync([
            userData.userId,
          ]);
          if (rescrapeResult.errors[userData.userId]) {
            deleteUser.mutate(userData.userId);
            setError(rescrapeResult.errors[userData.userId]);
            setLoading(false);
            return;
          }
        } catch (rescrapeUsersError) {
          setError(
            rescrapeUsersError.message ||
              "An error occurred while rescraping instagram user"
          );
          deleteUser.mutate(userData.userId);
          setLoading(false);
          return;
        }
        const newOption = {
          value: userData.userId,
          label: (
            <span>
              {userData.email} - {userData.activeProfile}{" "}
              <Tag color={colorHash.hex("new")}>new</Tag>
            </span>
          ),
        };
        setOptions([newOption, ...options]);
        addSelectedOption(newOption.value);
        setLoading(false);
        toggleVisibleComponent();
      } catch (userCreationError) {
        setLoading(false);
        setError(
          userCreationError.message ||
            "An error occurred while creating the user"
        );
      }
    } catch (error) {
      //ignore since ant design will handle validation based on form.item rules
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      component={false}
      name="createNewTestUser"
      {...defaultFormItemLayout}
      labelAlign="right"
      layout="horizontal"
      onFinish={onSubmit}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: "a valid email is required",
            type: "email",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="IG Handle"
        name="igHandle"
        rules={[{ required: true, message: "required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={defaultButtonWrapperCol}>
        <Button loading={loading} onClick={onSubmit}>
          Submit
        </Button>
        {loading && (
          <p className={styles.warning}>
            <FieldTimeOutlined /> Please wait. This may take some time.
          </p>
        )}
        {error && (
          <h4 className={styles.error}>
            <WarningOutlined /> {error}
          </h4>
        )}
      </Form.Item>
    </Form>
  );
};

SecondForm.propTypes = {
  options: array,
  addSelectedOption: func,
  toggleVisibleComponent: func,
  setOptions: func,
};

function TestUserForm({
  options,
  setOptions,
  name,
  addSelectedOption,
  loading,
  onSwitch,
  selectedTestUserId,
}) {
  return (
    <TwoComponentSwitch
      activateFirstText="Select Existing Test Users"
      activateSecondText="Create New Test User"
      className={styles.fullWidth}
      onSwitch={onSwitch}
      firstComponent={
        <FirstForm
          options={options}
          name={name}
          loading={loading}
          selectedTestUserId={selectedTestUserId}
        />
      }
      secondComponent={
        <SecondForm
          options={options}
          setOptions={setOptions}
          addSelectedOption={addSelectedOption}
        />
      }
    />
  );
}

TestUserForm.propTypes = {
  options: array,
  setOptions: func,
  addSelectedOption: func,
  name: string,
  loading: bool,
  onSwitch: func,
  selectedTestUserId: string,
};

export default TestUserForm;
