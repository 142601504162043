import React from "react";
import { Checkbox, Col, Divider, Form, Typography } from "antd";
import { any } from "prop-types";
import styles from "./BillingContract.module.css";

export default function ProductCreditsConfiguration({
  productCreditSettings,
  campaignsByProductId,
}) {
  const getCheckboxLabel = index => {
    const { suggestedCredits } = productCreditSettings[index];
    if (suggestedCredits === 0) {
      return "Credits for current target minted";
    }
    return `${suggestedCredits > 0 ? "Add" : "Delete"} ${Math.abs(
      suggestedCredits
    )} credits?`;
  };

  if (productCreditSettings) {
    return productCreditSettings.map((productSetting, index) => {
      const [contractCampaign] =
        campaignsByProductId[productSetting.productId] || [];
      const { campaignId } = contractCampaign || {};
      return (
        <React.Fragment key={index}>
          <Col
            span={24}
            key={productSetting.name}
            className={styles.formColumn}
          >
            <Divider orientation="left" style={{ marginTop: 0 }}>
              <img
                src={contractCampaign?.relations.products.thumbnailUrl}
                alt="product"
                style={{ height: "32px", marginRight: "16px" }}
              />
              <Typography.Link href={`/#/campaigns/${campaignId}?tab=campaign`}>
                {productSetting.name || "-"}
              </Typography.Link>
            </Divider>
          </Col>

          <Col span={4} key="productPrice" className={styles.textFormColumn}>
            <Typography.Text>
              <span className={styles.formLabel}>Product Price: </span>$
              {productSetting.fee || "-"}
            </Typography.Text>
          </Col>

          <Col
            span={4}
            key="productServiceFees"
            className={styles.textFormColumn}
          >
            <Typography.Text>
              <span className={styles.formLabel}>Product Service Fees: </span>$
              {productSetting.serviceFee >= 0 ? productSetting.serviceFee : "-"}
            </Typography.Text>
          </Col>

          <Col
            span={4}
            key="serviceFeeDescription"
            className={styles.textFormColumn}
          >
            <Typography.Text>
              <span className={styles.formLabel}>
                Service Fee Description:{" "}
              </span>
              {productSetting.serviceFeeDescription || "-"}
            </Typography.Text>
          </Col>

          <Col span={4} key="creditPrice" className={styles.textFormColumn}>
            <Typography.Text>
              <span className={styles.formLabel}>Credit Price: </span>$
              {(
                Number(productSetting.fee) + Number(productSetting.serviceFee)
              ).toFixed(2)}
            </Typography.Text>
          </Col>

          <Col span={4} key="target" className={styles.textFormColumn}>
            <Typography.Text>
              <span className={styles.formLabel}>Target: </span>
              {productSetting.target}
            </Typography.Text>
          </Col>

          <Col span={4} key="totalCredits" className={styles.textFormColumn}>
            <Typography.Text>
              <span className={styles.formLabel}>Total Credits: </span>
              {productSetting.total || 0}
            </Typography.Text>
          </Col>

          <Col span={4} key="usedCredits" className={styles.textFormColumn}>
            <Typography.Text>
              <span className={styles.formLabel}>Used Credits: </span>
              {productSetting.consumed}
            </Typography.Text>
          </Col>

          <Col
            span={4}
            key="availableCredits"
            className={styles.textFormColumn}
          >
            <Typography.Text>
              <span className={styles.formLabel}>Available Credits: </span>
              {productSetting.total - productSetting.consumed}
            </Typography.Text>
          </Col>

          <Col
            span={4}
            key="newCredits"
            className={styles.formColumn}
            style={{ display: "flex" }}
          >
            <span className={styles.formLabel}>{getCheckboxLabel(index)}</span>
            <Form.Item
              className={styles.formItem}
              name={`newCredits-${index}`}
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
              <Checkbox
                disabled={productSetting.suggestedCredits === 0}
                style={{ marginLeft: "8px", marginBottom: "8px" }}
              />
            </Form.Item>
          </Col>
        </React.Fragment>
      );
    });
  }

  return null;
}

ProductCreditsConfiguration.propTypes = {
  productCreditSettings: any,
  campaignsByProductId: any,
};
