import React from "react";
import { oneOf, object } from "prop-types";
import { flowTypes } from "../../../utils/campaignFlowTypes";
import SubmitFlipshop from "./SubmitFlipshop";
import SubmitInstagram from "./SubmitInstagram";

export default function SubmitOnboard({
  type = flowTypes.INSTAGRAM,
  onboard,
  ...rest
}) {
  switch (type) {
    case flowTypes.FLIPSHOP:
      return <SubmitFlipshop onboard={onboard} {...rest} />;
    case flowTypes.INSTAGRAM:
    default:
      return <SubmitInstagram post={onboard} {...rest} />;
  }
}

SubmitOnboard.propTypes = {
  type: oneOf(Object.values(flowTypes)),
  onboard: object,
};
