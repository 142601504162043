import React from "react";
import { HeartOutlined, MessageOutlined } from "@ant-design/icons";

import "./Gallery.less";

export const LikesAndComments = props => {
  return (
    <div style={{ padding: 12 }}>
      <HeartOutlined className="icon-marginRight" />
      {props.likes}
      <span style={{ marginRight: "16px" }} />
      <MessageOutlined className="icon-marginRight" />
      {props.comments}
    </div>
  );
};
