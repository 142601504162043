import React, { useState } from "react";
import { object, string } from "prop-types";
import { FileImageOutlined, UploadOutlined } from "@ant-design/icons";
import { Alert, Button, Modal, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useUploadManualScreenshot } from "../../../api/services";
import checkAuth from "../../../utils/checkAuth";
import { useUpdateOnboard } from "../../../api/onboards";

export default function SubmitFlipshop({ onboard, text = "Manual Submit" }) {
  const [visible, setVisible] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [error, setError] = useState(null);

  // Redux usage for compatibility
  const dispatch = useDispatch();
  const roles = useSelector(state => state.auth.roles);

  // React-query mutations for handling image upload
  const uploadManualScreenshot = useUploadManualScreenshot();
  const [screenshotUrl, setScreenshotUrl] = useState(null);
  const uploadImage = async ({ file, onSuccess }) => {
    setError(null);
    try {
      const resp = await uploadManualScreenshot.mutateAsync({
        file,
        onboardId: onboard.onboardId,
      });
      setScreenshotUrl(resp.url[0]);
      onSuccess(resp);
    } catch (err) {
      setError(err.message);
    }
  };

  // Once image is uploaded, update the onboard
  const updateOnboard = useUpdateOnboard();
  const submitManualScreenshot = async () => {
    setError(null);
    try {
      await updateOnboard.mutateAsync(
        {
          onboardId: onboard.onboardId,
          body: { done: true, metadata: { manual_screenshot: screenshotUrl } },
          params: {
            forceDone: true,
          },
        },
        dispatch
      );
      setVisible(false);
    } catch (err) {
      setError(err.message);
    }
  };

  if (!onboard || onboard.done || !checkAuth.has(roles, ["manager"])) {
    return null;
  }

  return (
    <>
      <Button
        type="primary"
        icon={<FileImageOutlined />}
        onClick={() => setVisible(true)}
      >
        {text}
      </Button>
      <Modal
        title={`Submit Flipshop step for ${onboard.igHandle}`}
        visible={visible}
        okText="Submit"
        onOk={submitManualScreenshot}
        onCancel={() => {
          setVisible(false);
        }}
      >
        <Upload
          accept="image/*"
          listType="picture"
          customRequest={uploadImage}
          onChange={({ fileList }) => setDefaultFileList(fileList)}
          openFileDialogOnClick={!defaultFileList.length}
          defaultFileList={defaultFileList}
        >
          <Button
            type="primary"
            icon={<UploadOutlined />}
            onClick={() => setVisible(true)}
          >
            Upload Manual Screenshot
          </Button>
        </Upload>
        {error && <Alert message={error} type="error" />}
      </Modal>
    </>
  );
}

SubmitFlipshop.propTypes = {
  onboard: object,
  text: string,
};
