// allTagsRegex.exec(string)[1] will be the "all" tags
const allTagsRegex = /^all=(([a-z_A-Z]+:[a-zA-Z0-9_]+,?)*)(&any=.*|$)/;
// anyTagsRegex.exec(string)[2] will be the "any" tags
const anyTagsRegex = /(all=.*&|^)any=(([a-z_A-Z]+:[a-zA-Z0-9_]+,?)*)$/;

/**
 * Extracts the tags from a tag query string and returns them
 * as a formatted object.
 *
 * @param {string} tagQuery Query to extract tags from
 * @returns {Object} Tags in the query. Each tag is in
 *   DB-compatible format (e.g. {tagType, tagValue}) and keyed
 *   by whether it's in the "all" query or "any" query.
 */
export default function (tagQuery) {
  let allTags = [];
  let anyTags = [];
  const allTagsResult = allTagsRegex.exec(tagQuery);
  const anyTagsResult = anyTagsRegex.exec(tagQuery);

  if (allTagsResult) {
    allTags = allTagsResult[1].split(",");
  }

  if (anyTagsResult) {
    anyTags = anyTagsResult[2].split(",");
  }

  const results = {
    all: [],
    any: [],
  };

  for (const tag of allTags) {
    const [tagType, tagValue] = tag.split(":");
    results.all.push({ tagType, tagValue });
  }

  for (const tag of anyTags) {
    const [tagType, tagValue] = tag.split(":");
    results.any.push({ tagType, tagValue });
  }

  return results;
}
