import { camelCaseToSentenceCase } from "../../../utils/stringUtils";

//turn json array into columns array
//for example: [ { title: "Name", dataIndex: "name" }, { title: "Age", dataIndex: "age" } ];
export const getColumnsFromDataSource = (
  dataSource,
  columnsToInclude = false,
  props = {} //pass props to return
) => {
  //validate
  if (!dataSource || !dataSource.length || typeof dataSource[0] !== "object")
    return null;

  //if columnsToInclude is not array, then include all properties
  const columnKeys = Array.isArray(columnsToInclude)
    ? columnsToInclude
    : Object.keys(dataSource[0]);

  return columnKeys.map(dataIndex => {
    //pass props if dataIndex is included in props parameter
    if (props[dataIndex])
      return {
        title: camelCaseToSentenceCase(dataIndex), //convert lastName to Last Name
        dataIndex,
        ...props[dataIndex]
      };
    //no props
    return {
      title: camelCaseToSentenceCase(dataIndex), //convert lastName to Last Name
      dataIndex
    };
  });
};
