import React, { useEffect, useState } from "react";
import { Tag } from "antd";
import { string } from "prop-types";
import getTagsFromTagQuery from "./utils/getTagsFromTagQuery";
import clipboard from "../../utils/clipboard";
import { useCategories } from "../../api/categories";
import { useTagTypes } from "../../api/tagTypes";

/**
 * Map of tag_type as root object and tag_value props as 2nd level object
 */
// const TAG_STYLE_MAP = {
//   niche: {
//     beauty: { emoji: "👠 " },
//     fitness: { emoji: "🏋 " },
//     food: { emoji: "🥑 " },
//     parent: { emoji: "👶 " },
//     travel: { emoji: "🏖 " },
//     art: { emoji: "🎵 " },
//   },
//   photos: {
//     dog: { emoji: "🐶 " },
//     baby: { emoji: "👶 " },
//   },
//   gender: {
//     male: {
//       color: "blue",
//     },
//     female: {
//       color: "magenta",
//     },
//     nonbinary: true,
//   },
//   in_USA: {
//     true: { emoji: "🇺🇸 " },
//     maybe: { emoji: "🇺🇸 " },
//   },
//   user_state: {
//     new: { emoji: "🧍 " },
//     joined: { emoji: "🧍 " },
//     invited: { emoji: "🧍 " },
//   },
// };

const tagPropsFromMap = (tagStyleMap, tagArray) => {
  if (tagStyleMap && tagArray) {
    return tagArray.map(tag => {
      const props =
        tagStyleMap[tag.tagType] && tagStyleMap[tag.tagType][tag.tagValue];

      if (!props) return "";
      return (
        <Tag key={tag.tagValue} {...props}>
          {props.emoji || ""}
          {props.name || tag.tagValue}
        </Tag>
      );
    });
  }
  return [];
};

export default function TagQueryStyledTags({ tagQuery }) {
  const { data: categories } = useCategories({
    params: {
      parents: true,
      sortColumn: "categories.name",
      sortDirection: "asc",
    },
  });

  const [tagStyleMap, setTagStyleMap] = useState({});

  const { data: tagTypes } = useTagTypes({ includes: ["tags"] });

  useEffect(() => {
    if (tagTypes && categories && Object.keys(tagStyleMap).length === 0) {
      const newTagStyleMap = { ...tagStyleMap };
      for (const tagType of tagTypes) {
        if (tagType.relations?.tags) {
          for (const tag of tagType.relations.tags) {
            if (!newTagStyleMap[tag.tagType]) {
              newTagStyleMap[tag.tagType] = {};
            }
            newTagStyleMap[tag.tagType][tag.tagValue] = true;
          }
        }
      }
      newTagStyleMap.category = {};
      for (const category of categories) {
        newTagStyleMap.category[category.categoryId] = {
          emoji: category.emoji ? `${category.emoji} ` : "",
          name: category.name,
        };
      }
      setTagStyleMap(newTagStyleMap);
    }
  }, [categories, tagStyleMap, tagTypes]);

  // convert string to [all,any] array of objects ie. {tagType: "gender" tagValue: "female"}
  let tagArrays;
  try {
    tagArrays = getTagsFromTagQuery(tagQuery);
  } catch (error) {
    //do nothing
  }

  if (typeof tagArrays !== "object") return "";

  //props for react component
  const allTagProps = tagArrays.all.length
    ? tagPropsFromMap(tagStyleMap, tagArrays.all)
    : [];
  const anyTagProps = tagArrays.any.length
    ? tagPropsFromMap(tagStyleMap, tagArrays.any)
    : [];

  return (
    <span onClick={() => clipboard(tagQuery)} aria-hidden="true">
      {tagArrays.all.length ? <span>all={allTagProps}</span> : null}
      {tagArrays.all.length && tagArrays.any.length ? `& ` : null}
      {tagArrays.any.length ? <span>any={anyTagProps}</span> : null}
    </span>
  );
}

TagQueryStyledTags.propTypes = {
  tagQuery: string,
};
