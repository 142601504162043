import React from "react";
import { Button } from "antd";
import { string } from "prop-types";
import RetoolIcon from "../../../assets/retool.png";

function RetoolButton({ campaignId, contractId }) {
  if (!campaignId && !contractId)
    throw Error("campaignId or contractId prop required");

  const url = campaignId
    ? `${process.env.REACT_APP_RETOOL_HOST}?campaignId=${campaignId}`
    : `${process.env.REACT_APP_RETOOL_HOST}?contractId=${contractId}`;

  return (
    <Button
      onClick={() =>
        window.open(
          `${url}&_environment=${process.env.REACT_APP_RETOOL_ENV}`,
          "_blank"
        )
      }
      icon={
        <img
          style={{ margin: 5 }}
          width="15"
          height="15"
          alt="Retool Button"
          src={RetoolIcon}
        />
      }
    >
      Retool
    </Button>
  );
}

RetoolButton.propTypes = {
  contractId: string,
  campaignId: string,
};

export default RetoolButton;
