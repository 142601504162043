import React from "react";
import { Tag } from "antd";
import { StopTwoTone, CloseCircleFilled } from "@ant-design/icons";
// import { StopTwoTone } from "@ant-design/icons";

export const woodpeckerUserId = process.env.REACT_APP_WOODPECKER_USER;

export default ({ woodpeckerCampaignId, metadata }) => (
  <a
    key={woodpeckerCampaignId}
    target="_blank"
    rel="noopener noreferrer"
    href={`https://app.woodpecker.co/panel?u=${woodpeckerUserId}&user=${woodpeckerUserId}#campaigns/${woodpeckerCampaignId}/stats`}
  >
    <Tag
      icon={
        metadata &&
        metadata.status !== "RUNNING" &&
        metadata.status !== "COMPLETED" ? (
          //if not running or completed, either backend returned error OR stopped
          metadata.status === "WOODPECKER_ERROR" ? (
            <CloseCircleFilled twoToneColor="#f5222d" />
          ) : (
            <StopTwoTone twoToneColor="#f5222d" />
          )
        ) : null
      }
      className="clickable"
    >
      {woodpeckerCampaignId}
    </Tag>
  </a>
);
