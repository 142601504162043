import React from "react";
import { Table } from "antd";
import "./DataTable.less";
//redux
import { useDispatch, useSelector } from "react-redux";

export default props => {
  const isLoading = useSelector(state => state.shared.isLoading);
  return (
    <Table
      className="main-no-padding"
      loading={isLoading ? { indicator: <span></span> } : false} //handle loading animation
      bordered
      {...props} //inherit props such as columns, dataSource
    />
  );
};
