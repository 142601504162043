import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { result } from "../redux/shared/sharedActions";
import {
  createAuthenticatedRequest,
  defaultQueryOptions,
  executeRequest,
} from "./utils";

export const PROFILE_REVIEW_PATH = "profileReview";

export function useProfileReviewCount({
  includes,
  params,
  ...overrideOptions
}) {
  const dispatch = useDispatch();

  return useQuery(
    [PROFILE_REVIEW_PATH, includes, params],
    () => fetchProfileReviewCount(dispatch, { includes, params }),
    {
      ...defaultQueryOptions,
      onError: err => {
        dispatch(
          result({
            status: "error",
            title: "Oops, something went wrong",
            subTitle: err.message,
          })
        );
      },
      ...overrideOptions,
    }
  );
}

// Execute fetch request for count of profile review users
export async function fetchProfileReviewCount(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    PROFILE_REVIEW_PATH,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}

export function useNextUser({ includes, params, ...overrideOptions }) {
  const dispatch = useDispatch();

  const path = `${PROFILE_REVIEW_PATH}/nextUser`;

  return useQuery(
    [path, includes, params],
    () => fetchNextUser(path, dispatch, { includes, params }),
    {
      ...defaultQueryOptions,
      onError: err => {
        dispatch(
          result({
            status: "error",
            title: "Oops, something went wrong",
            subTitle: err.message,
          })
        );
      },
      ...overrideOptions,
    }
  );
}

// Execute fetch request for next user for profile review
export async function fetchNextUser(path, dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    path,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}
