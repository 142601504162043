import React from "react";
import { notification, Avatar } from "antd";
import instagramFormatter from "../../utils/instagramFormatter";
//components
import { LikesAndComments } from "./instaUtils";

let timeout; //controls timing of closing notification
const openInstaPostModal = post => {
  const manualScreenshot = post.metadata && post.metadata.manual_screenshot;

  const { shortcodes } = post.relations;
  const config = {
    key: "post", //single key
    message: (
      <a
        rel="noopener noreferrer"
        href={`https://instagram.com/p/${post.shortcodeId}`}
        target="_blank"
      >
        <Avatar
          className="icon-marginRight"
          size="small"
          src={`${process.env.REACT_APP_PROFILES_HOST}${post.igHandle}.jpg`}
        />
        {post.igHandle}
      </a>
    ),
    description: (
      <div>
        <img
          alt=""
          style={{ maxHeight: "50vh" }}
          src={
            manualScreenshot ||
            `${process.env.REACT_APP_SHORTCODES_HOST}${post.shortcodeId}.jpg`
          }
        />
        <LikesAndComments
          likes={shortcodes.likes}
          comments={shortcodes.comments}
        />
        {shortcodes.caption && (
          <p
            dangerouslySetInnerHTML={{
              __html: instagramFormatter(shortcodes.caption),
            }}
            style={{ lineHeight: "95%" }}
          />
        )}
      </div>
    ),
    // btn,
    // key,
    //layout
    placement: "topRight",
    top: 48,
    duration: 0,
  };
  notification.open(config);
};

//import below 2 functions as OnMouseEnter and OnMouseLeave events to show/clsoe modals
export const shortcodeModalMouseEnter = record => {
  clearTimeout(timeout);
  //open modal if shortcode
  if (record.done === true) openInstaPostModal(record);
};

export const shortcodeModalMouseLeave = () => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    notification.close("post");
  }, 1000); //close modal in 2 sec
};
