import React, { useState } from "react";
import { any, bool, func, string } from "prop-types";
import { Button, Col, Form, Row, Card, message } from "antd";

export default function AdvancedForm({
  formItems,
  title,
  formProps,
  onSubmit,
  submitText = "Save",
  additionalComponent,
  submitButtonDisabled = false,
}) {
  const [loading, setLoading] = useState(false);

  const _onSubmit = async () => {
    setLoading(true);
    try {
      await onSubmit();
    } catch (err) {
      message.error(err?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginBottom: "16px" }}>
      <Card
        key={title}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {title}
            {onSubmit && (
              <Row>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    onClick={_onSubmit}
                    loading={loading}
                    disabled={submitButtonDisabled}
                  >
                    {submitText}
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        }
      >
        <Form layout="vertical" {...formProps}>
          <Row gutter={24}>{formItems}</Row>
        </Form>
      </Card>

      {additionalComponent}
    </div>
  );
}

AdvancedForm.propTypes = {
  formProps: any,
  formItems: any,
  title: string,
  onSubmit: func,
  submitText: string,
  additionalComponent: any,
  submitButtonDisabled: bool,
  showSuccessMessage: bool,
};
