import React, { useEffect, useState } from "react";
import { string } from "prop-types";

//redux
import { useHistory } from "react-router-dom";

//components
import PostByIdHeader from "./PostHeader/PostByIdHeader";
import DescriptionTab from "../shared/DescriptionTab";
import postDescriptionRender from "./postDescriptionRender";
import campaignDescriptionRender from "../Campaigns/campaignDescriptionRender";
import PayoutsDataTable from "./PayoutsDataTable";
import PostsSearchDataTable from "./Search/PostsSearchDataTable";
import { useOnboards } from "../../api/onboards";

export default function PostById({ onboardId }) {
  //router
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);

  // We load our posts fresh so that we can reuse this component outside of the search component.
  // We might get a cache hit in some cases, but usually this will be fresh data.
  // We need to load 2 sets of data. 1 is all of the relations for the current onboard by ID
  const { data: onboard, isLoading: isLoadingOnboard } = useOnboards({
    onboardId,
    includes: [
      "payouts",
      "campaigns",
      "users",
      "shortcodes",
      "contracts",
      "products",
      "campaignFlows",
      "userEvents",
    ],
  });

  // The other is all of the onboards from the user that created the current onboard
  const { data: onboards, isLoading: isLoadingOnboards } = useOnboards({
    includes: ["payouts", "campaigns", "users", "shortcodes", "campaignFlows"],
    params: {
      search: onboard && onboard.relations.users.email,
    },
    enabled: !!onboard,
  });

  //tab state
  const [currentTab, setCurrentTab] = useState(
    searchParams.has("tab") ? searchParams.get("tab") : "post" //default
  );

  //update url search params on tab change
  useEffect(() => {
    //build params
    const params = {};
    if (currentTab) params.tab = currentTab;
    const newSearchParams = new URLSearchParams(params).toString();

    history.replace(`${history.location.pathname}?${newSearchParams}`);
  }, [currentTab, history]);

  if (!onboard) return null;

  const {
    campaigns: campaign,
    payouts,
    campaignFlows: campaignFlow,
  } = onboard && onboard.relations;
  const { contracts: contract } = campaign && campaign.relations;

  return (
    <>
      <PostByIdHeader
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        post={onboard}
        campaign={campaign}
        contract={contract}
        campaignFlow={campaignFlow}
      />
      {(currentTab === "post" || currentTab === "campaign") && (
        <DescriptionTab
          descriptionRender={
            currentTab === "post"
              ? postDescriptionRender
              : campaignDescriptionRender
          }
          data={currentTab === "post" ? onboard : campaign}
        />
      )}
      {currentTab === "allOnboards" && (
        <PostsSearchDataTable posts={onboards} setCurrentTab={setCurrentTab} />
      )}
      {currentTab !== "allOnboards" && <PayoutsDataTable payouts={payouts} />}
    </>
  );
}

PostById.propTypes = {
  onboardId: string,
};
