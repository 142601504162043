import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import {
  createAuthenticatedRequest,
  defaultMutationOptions,
  defaultQueryOptions,
  executeRequest,
} from "./utils";

export const CAMPAIGN_STEPS_PATH = "campaignSteps";

export function useCampaignSteps({ includes, params, ...overrideOptions }) {
  const dispatch = useDispatch();

  return useQuery(
    [CAMPAIGN_STEPS_PATH, includes, params],
    () => fetchCampaignSteps(dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for all campaign steps
export async function fetchCampaignSteps(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    CAMPAIGN_STEPS_PATH,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}

export function useCreateCampaignSteps() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(steps => createCampaignSteps(steps, dispatch), {
    ...defaultMutationOptions,
    onSuccess: () => {
      queryClient.invalidateQueries(CAMPAIGN_STEPS_PATH);
    },
  });
}

// Execute a POST to create new campaign steps
export async function createCampaignSteps(steps, dispatch) {
  const requestObject = await createAuthenticatedRequest(
    CAMPAIGN_STEPS_PATH,
    dispatch,
    {
      method: "POST",
      body: JSON.stringify(steps),
    }
  );

  return executeRequest(requestObject);
}
