import React from "react";
import { any, bool, string } from "prop-types";
import DepositInvoice from "./DepositInvoice";
import DeleteCreditsInvoice from "./DeleteCreditsInvoice";

export default function DepositDetails({
  platformCreditFee,
  platformCreditServiceFee,
  billingSetup,
  isGenericProductCredits,
}) {
  if (!billingSetup) {
    return null;
  }

  return (
    <div>
      <DeleteCreditsInvoice
        billingSetup={billingSetup}
        isGenericProductCredits={isGenericProductCredits}
      />
      <DepositInvoice
        platformCreditFee={platformCreditFee}
        platformCreditServiceFee={platformCreditServiceFee}
        billingSetup={billingSetup}
        isGenericProductCredits={isGenericProductCredits}
      />
    </div>
  );
}

DepositDetails.propTypes = {
  platformCreditFee: string,
  platformCreditServiceFee: string,
  billingSetup: any,
  isGenericProductCredits: bool,
};
