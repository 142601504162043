import React, { useReducer } from "react";
import {
  Form,
  Divider,
  InputNumber,
  Space,
  Typography,
  Tooltip,
  Button,
} from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { any, array, func } from "prop-types";
import { MinusCircleOutlined } from "@ant-design/icons";
import TagQueryStyledTags from "../TagQueryStyledTags";
import WoodpeckerTag from "../WoodpeckerTag";
import { defaultFormItemLayout } from "../../../utils/formLayout";

/**
 * ANTD
 */
const { Text } = Typography;

const reducer = (state, newState) => ({
  ...state,
  ...newState,
});

//Antd Form w/ Redux example - https://ant.design/components/form/#components-form-demo-global-state
function GenericInvitesForm({
  form,
  onValuesChange,
  formFields,
  groupData,
  onRemoveWoodpeckerCampaign,
}) {
  const [removingWoodpeckerCampaign, setRemovingWoodpeckerCampaign] =
    useReducer(reducer, {});
  const sendState = useSelector(state => state.invites.send);

  const inPreviewTab = sendState.tab === "preview";

  const fields = formFields.map(field => {
    return {
      name: field.name,
      value: field.value,
    };
  });

  return (
    <Form
      form={form}
      {...defaultFormItemLayout}
      className="main"
      name="send"
      fields={fields}
      onValuesChange={onValuesChange}
    >
      {formFields.map(formField => formField.component)}
      {groupData.map((group, index) => (
        <div key={index}>
          <Divider orientation="left">
            <Space size="large">
              {group.link && group.name && (
                <Link to={group.link}>{group.name}</Link>
              )}
              {Array.isArray(sendState.userIds) &&
                `${sendState.userIds.length} users`}
              {sendState.sendEmails && !Array.isArray(sendState.userIds) && (
                <TagQueryStyledTags
                  tagQuery={sendState.tagQuery || group.defaultTagQuery}
                />
              )}
            </Space>
          </Divider>
          <br />
          {/* Woodpecker Campaign */}
          {group.woodpeckerCampaigns.map(campaign => (
            <Form.Item
              key={campaign.woodpeckerCampaignId}
              label={
                <p style={{ whiteSpace: "normal" }}>{campaign.metadata.name}</p>
              }
            >
              <Space size="large">
                <Form.Item name={campaign.woodpeckerCampaignId} noStyle>
                  <InputNumber
                    disabled={inPreviewTab}
                    style={{ maxWidth: 200 }}
                    min={0}
                    max={99999}
                  />
                </Form.Item>
                <WoodpeckerTag
                  metadata={campaign.metadata}
                  woodpeckerCampaignId={campaign.woodpeckerCampaignId}
                />
                <Text type="secondary">
                  Queue: {campaign.metadata.stats.queue}
                </Text>
                <Text type="secondary">
                  Sent: {campaign.metadata.stats.sent}
                </Text>
                <Text type="secondary">
                  Opened:{" "}
                  {`${parseFloat(
                    (campaign.metadata.stats.opened /
                      campaign.metadata.stats.sent) *
                      100
                  ).toFixed(1)}%`}
                </Text>
                <Text type="secondary">
                  Clicked:{" "}
                  {`${parseFloat(
                    (campaign.metadata.stats.clicked /
                      campaign.metadata.stats.sent) *
                      100
                  ).toFixed(1)}%`}
                </Text>
                {onRemoveWoodpeckerCampaign && (
                  <Tooltip
                    title={
                      !removingWoodpeckerCampaign[campaign.woodpeckerCampaignId]
                        ? "Remove Woodpecker Campaign"
                        : ""
                    }
                  >
                    <Button
                      loading={
                        removingWoodpeckerCampaign[
                          campaign.woodpeckerCampaignId
                        ]
                      }
                      icon={<MinusCircleOutlined style={{ color: "red" }} />}
                      onClick={() => {
                        setRemovingWoodpeckerCampaign({
                          [campaign.woodpeckerCampaignId]: true,
                        });
                        onRemoveWoodpeckerCampaign(campaign);
                      }}
                    />
                  </Tooltip>
                )}
              </Space>
            </Form.Item>
          ))}
          {group.formComponents}
        </div>
      ))}
    </Form>
  );
}

GenericInvitesForm.propTypes = {
  formFields: array,
  groupData: array,
  onValuesChange: func,
  onRemoveWoodpeckerCampaign: func,
  form: any,
};

export default GenericInvitesForm;
