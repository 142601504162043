import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PageHeader, Avatar, Tabs, Tag } from "antd";
import moment from "moment";
import { object, number, func } from "prop-types";
//router
import { useHistory } from "react-router-dom";
import { getBreadcrumbProps } from "../../../AppRouter";

//components
//buttons
import CopyLandingPageLink from "../ActionButtons/CopyLandingPageLink";
import CopyOrderLink from "../ActionButtons/CopyOrderLink";
import CopySubmitLink from "../ActionButtons/CopySubmitLink";
import InterestedToggle from "../ActionButtons/InterestedToggle";
import SubmitOnboard from "../ActionButtons/SubmitOnboard";
import SendPayout from "../ActionButtons/SendPayout";
import UndoSubmission from "../ActionButtons/UndoSubmission";
import ExtendDeadlineButton from "../ActionButtons/ExtendDeadlineButton";
import UpdateUserStateModal from "./UpdateUserStateModal";
import checkAuth from "../../../utils/checkAuth";
import "./postByIdHeaderStyles.css";

//antd
const { TabPane } = Tabs;

const getUserStateTagColor = state => {
  if (state === "done") {
    return "red";
  }
  if (state === "onboarded") {
    return "blue";
  }
};

export default function PostByIdHeader({
  post,
  campaign,
  contract,
  currentTab,
  setCurrentTab,
  campaignFlow,
}) {
  const [updateUserStateModalVisible, setUpdateUserStateModalVisible] =
    useState(false);

  // auth for UpdateUserStateModal
  const roles = useSelector(state => state.auth.roles);
  const isSupervisor = checkAuth.has(roles, ["supervisor"]);

  //for breadcrumbs
  const history = useHistory();

  const { users: user } = post.relations;

  return (
    <div className="main-no-padding border">
      {isSupervisor && (
        <UpdateUserStateModal
          igHandle={post.igHandle}
          setVisible={setUpdateUserStateModalVisible}
          visible={updateUserStateModalVisible}
          user={post.relations.users}
        />
      )}
      <PageHeader
        ghost={false}
        breadcrumb={getBreadcrumbProps(history.location)} //see https://ant.design/components/breadcrumb/#Use-with-browserHistory
        title={
          <>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`http://instagram.com/${post.igHandle}`}
            >
              {post.profilePic && (
                <Avatar
                  className="icon-marginRight"
                  size="small"
                  src={post.profilePic}
                />
              )}
              {post.igHandle}{" "}
            </a>
            <Tag
              color={getUserStateTagColor(user.userState)}
              onClick={() => setUpdateUserStateModalVisible(true)}
              style={{
                cursor: "pointer",
              }}
            >
              {user.userState}
            </Tag>
          </>
        }
        subTitle={moment(post.createdAt).format("MMM Do YYYY")}
        extra={[
          <CopyLandingPageLink
            key="CopyLandingPageLink"
            campaign={campaign}
            text="Landing Page"
          />,
          <CopyOrderLink
            key="CopyOrderLink"
            post={post}
            campaign={campaign}
            text="Order Link"
          />,
          <CopySubmitLink
            key="CopySubmitLink"
            post={post}
            text="Submit Link"
            campaignFlow={campaignFlow}
          />,
          <ExtendDeadlineButton
            key="extendDeadline"
            text="Extend Deadline"
            post={post}
            contract={contract}
          />,
          <SubmitOnboard
            key="SubmitOnboard"
            onboard={post}
            type={campaignFlow.type}
            text="Manual Submit"
          />,
          <UndoSubmission
            key="UndoSubmission"
            onboard={post}
            text="Undo Submit"
          />,
          <SendPayout key="SendPayout" post={post} text="Send Payout" />,
          <InterestedToggle key="InterestedToggle" post={post} />,
        ]}
        footer={
          <Tabs
            activeKey={currentTab}
            onChange={activeKey => setCurrentTab(activeKey)}
          >
            <TabPane tab="Post" key="post" />
            <TabPane tab="Campaign" key="campaign" />
            <TabPane tab="All Onboards" key="allOnboards" />
            {/* <TabPane tab="Paypal" key="paypal" /> */}
          </Tabs>
        }
      />
    </div>
  );
}

PostByIdHeader.propTypes = {
  post: object,
  campaign: object,
  contract: object,
  currentTab: number,
  setCurrentTab: func,
  campaignFlow: object,
};
