import React from "react";
import { Empty, Row, Affix, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import "./Gallery.less";
import InfiniteScroll from "react-infinite-scroll-component";
//redux
import { useSelector } from "react-redux";
//components
import { array, func, number } from "prop-types";
import GalleryCard from "./GalleryCard";

//TODO: Modify campaign posts API to include support for pagination
//TODO: Create PUT API that accepts pagination parameters for a campaign, loops through

//
function chunkArrayInGroups(arr, unit) {
  const results = [];
  const length = Math.ceil(arr.length / unit);

  for (let i = 0; i < length; i++) {
    results.push(arr.slice(i * unit, (i + 1) * unit));
  }
  return results;
}

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); //if Mobile
const perRow = isMobile ? 2 : 4;

export default function CompletedGallery(props) {
  //if id in path, render CampaignById component
  const isLoading = useSelector(state => state.shared.isLoading);

  const { campaignPosts, loadMore, getMeta, total, setPerPage } = props;

  //only render if data loaded
  if (!isLoading && (!campaignPosts || !campaignPosts.length))
    return <Empty className="centered" />;

  if (campaignPosts && campaignPosts.length) {
    let gutter;
    if (window.innerWidth > 1440) gutter = 96;
    else if (window.innerWidth > 1200) gutter = 64;
    else if (window.innerWidth > 1080) gutter = 24;
    else gutter = 12;
    const rowsOfFour = chunkArrayInGroups(campaignPosts, perRow);
    return (
      <InfiniteScroll
        style={{ overflow: "none", paddingBottom: "16px" }}
        dataLength={campaignPosts.length}
        next={loadMore}
        hasMore={campaignPosts.length < total}
        scrollableTarget="mainLayout"
      >
        {rowsOfFour.map((row, index) => (
          <Row
            key={index}
            className="row-container"
            gutter={isMobile ? 12 : gutter}
          >
            {row.map(post => (
              <GalleryCard
                post={post}
                key={post.onboardId}
                meta={getMeta ? getMeta(post) : undefined}
              />
            ))}
          </Row>
        ))}
        <Affix offsetBottom={36}>
          <Button
            className="load-all hide-after-5s"
            onClick={() => setPerPage(props.total)}
            icon={<DownOutlined />}
          >
            Load all
          </Button>
        </Affix>
      </InfiniteScroll>
    );
  }
  return null;
}

CompletedGallery.propTypes = {
  campaignPosts: array,
  loadMore: func,
  getMeta: func,
  total: number,
  setPerPage: func,
};
