import React from "react";
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Menu, Spin, Dropdown } from "antd";
import "./Header.less";
//redux
import { useSelector } from "react-redux";
//auth
import { logout } from "../../../redux/auth/authActions";

export default props => {
  const onMenuClick = event => {
    const { key } = event;
    if (key === "logout") {
      logout();
    }
  };

  const user = useSelector(state => state.auth && state.auth.user);

  const menuHeaderDropdown = (
    <Menu className="menu" selectedKeys={[]} onClick={onMenuClick}>
      {/* <Menu.Item key="profile">
        <UserOutlined />
        Profile
      </Menu.Item>
      <Menu.Item key="settings">
        <SettingOutlined />
        Settings
      </Menu.Item>
      <Menu.Divider /> */}
      <Menu.Item key="logout">
        <LogoutOutlined />
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown class="container" overlay={menuHeaderDropdown}>
      <span className="action account">
        <Avatar
          size="small"
          className="avatar"
          icon={<UserOutlined />}
          alt="avatar"
        />
        <span className="name">
          {(user && (user.name || user.emails[0])) || null}
        </span>
      </span>
    </Dropdown>
  );
};
