import { types } from "./authActions";

export default (state = null, action) => {
  switch (action.type) {
    case types.GET_AUTH_SUCCESS: //successful auth
      return {
        ...state,
        user: action.token.account,
        expiresOn: action.token.expiresOn,
        token: action.token.accessToken,
        roles: action.token.idTokenClaims.jobTitle.split(";"), //azure b2c string ie. "admin;manager"
      };
    case types.REDIRECTING_TO_LOGIN: //show redirecting spinner if true
      return { ...state, redirecting: action.boolean };
    default:
      return state;
  }
};
