import * as csv from "csvtojson";

export async function readCsvFile(file, customOptions = {}) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async () => {
      return resolve(
        await csv({
          nullObject: true,
          ignoreEmpty: true,
          //override above
          ...customOptions,
        }).fromString(reader.result)
      );
    };
    reader.readAsText(file);
  });
}
