import React from "react";
import { MailOutlined } from "@ant-design/icons";
import LinkButton from "../../shared/LinkButton/LinkButton";

export default function GoToSendJoinButton() {
  return (
    <LinkButton linkPath="/invites/join" key="sendJoin" icon={<MailOutlined />}>
      Send Join
    </LinkButton>
  );
}
