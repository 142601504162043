import React from "react";
import { PageHeader } from "antd";
import { useHistory } from "react-router-dom";
import { bool, string } from "prop-types";
import { getBreadcrumbProps } from "../../../AppRouter";

function GenericPageHeader({ showBreadcrumb = true, title, ...rest }) {
  const history = useHistory();

  return (
    <div className="main-no-padding border">
      <PageHeader
        ghost={false}
        breadcrumb={showBreadcrumb && getBreadcrumbProps(history.location)} //see https://ant.design/components/breadcrumb/#Use-with-browserHistory
        title={title}
        {...rest}
      />
    </div>
  );
}

GenericPageHeader.propTypes = {
  showBreadcrumb: bool,
  title: string.isRequired,
};

export default GenericPageHeader;
