import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
//redux
import { useDispatch, useSelector } from "react-redux";
import { patchPostById } from "../../../redux/posts/postActions";
//utils
import checkAuth from "../../../utils/checkAuth";

export default props => {
  const { post } = props;

  //redux
  const dispatch = useDispatch();
  const roles = useSelector(state => state.auth.roles);

  if (
    !post ||
    // post.done || //incomplete posts only
    // post.relations.payouts.find(payout => payout.payoutType === "product")
    //   .upfront || //rebate only
    !checkAuth.has(roles, ["manager"])
  )
    return null;
  return post.interested === true ? (
    <Button
      type="primary"
      key="close"
      icon={<CloseOutlined />}
      onClick={() =>
        dispatch(
          patchPostById(post.onboardId, {
            interested: false,
            campaignId: post.campaignId,
          })
        )
      }
    >
      {post.done ? "Didnt Like Product" : "Not Interested"}
    </Button>
  ) : (
    <Button
      type="primary"
      key="check"
      icon={<CheckOutlined />}
      onClick={() =>
        dispatch(
          patchPostById(post.onboardId, {
            interested: true,
            campaignId: post.campaignId,
          })
        )
      }
    >
      {post.done ? "Liked Product" : "Interested"}
    </Button>
  );
};
