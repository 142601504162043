import React from "react";
import { Button } from "antd";

import { useHistory } from "react-router-dom";
import { string, element, node } from "prop-types";

function LinkButton({ linkPath, icon, children, type }) {
  const history = useHistory();

  return (
    <Button icon={icon} type={type} onClick={() => history.push(linkPath)}>
      {children}
    </Button>
  );
}

LinkButton.propTypes = {
  linkPath: string,
  icon: element,
  children: node,
  type: string,
};

export default LinkButton;
