import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchTransactionsSuccess } from "../../redux/payouts/serviceActions";
import PayoutHeader from "./PayoutHeader/ServiceSearchHeader";
import TransactionsSearchResultTable from "./PaypalService/TransactionsSearchResultTable";
import PayoutsReconcileHeader from "./PayoutHeader/PayoutsReconcileHeader";
import TransactionsReconcileHeader from "./PayoutHeader/TransactionsReconcileHeader";
import ManualPayoutsHeader from "./PayoutHeader/ManualPayoutsHeader";
import checkAuth from "../../utils/checkAuth";

export default function PayoutsHome() {
  //redux
  const dispatch = useDispatch();
  const transactionSearch = useSelector(
    state => state.payouts.transactionSearch
  );

  const roles = useSelector(state => state.auth.roles);
  const hasAdminRole = checkAuth.has(roles, ["admin"]);

  //on unmount, reset posts
  useEffect(() => {
    dispatch(searchTransactionsSuccess(null)); //cleanup
  }, [dispatch]);

  console.log("hasAdminRole", hasAdminRole);

  return (
    <div>
      <PayoutHeader showBalances={hasAdminRole} />

      {hasAdminRole && (
        <>
          {transactionSearch ? (
            <TransactionsSearchResultTable
              transactionSearch={transactionSearch}
            />
          ) : (
            <div>
              <TransactionsReconcileHeader />
              <PayoutsReconcileHeader />
              {/* <ManualPayoutsHeader /> */}
            </div>
          )}
        </>
      )}
    </div>
  );
}
