import { types } from "./contractActions";
import { flattenContracts } from "./contractUtils";

export default (state = {}, action) => {
  switch (action.type) {
    case types.GET_CONTRACT_SUCCESS: {
      //flatten data
      const flattenedContracts = flattenContracts(action.data);
      return { ...state, data: flattenedContracts };
    }
    case types.GET_CONTRACT_BY_ID_SUCCESS:
      return { ...state, [action._id]: action.data };
    case types.POST_CONTRACT_SUCCESS:
      return { ...state, data: action.data };
    default:
      return state;
  }
};
