import React, { useEffect, useState } from "react";
import { Button, Checkbox, Tooltip } from "antd";
import { array, func, object } from "prop-types";
import { WarningOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "../shared/DataTable/DataTable";
import { setInviteGroupSelections } from "../../redux/invites/sendInvitesActions";
import { getColumnsFromDataSource } from "../shared/DataTable/utils";
import TagQueryStyledTags from "./TagQueryStyledTags";
import WoodpeckerTag from "./WoodpeckerTag";
import { useUpdateInviteGroup } from "../../api/inviteGroups";

//1 to many invitegroup to woodpecker campaigns relations, combined stats
const reduceStatsToOneRow = (woodpeckerCampaigns, stat) =>
  woodpeckerCampaigns.reduce((accum, campaign) => {
    // eslint-disable-next-line no-param-reassign
    accum += campaign.metadata.stats[stat]; //ie. sent
    return accum;
  }, 0);

function InviteGroupsDataTable({
  contract,
  inviteGroups,
  setAdditionalAction,
}) {
  const updateInviteGroup = useUpdateInviteGroup();
  const [isUpdatingInviteGroup, setIsUpdatingInviteGroup] = useState(false);
  const [isUpdateInviteGroupError, setIsUpdateInviteGroupError] = useState("");

  //checklist for invite groups add to send invites form
  //https://ant.design/components/checkbox/#components-checkbox-demo-check-all
  const dispatch = useDispatch();
  const checkedList = useSelector(state => state.invites.inviteGroupSelections);
  const [indeterminate, setIndeterminate] = useState(
    checkedList &&
      inviteGroups &&
      !!checkedList.length &&
      checkedList.length < inviteGroups.length
  );
  const [checkAll, setCheckAll] = useState(
    checkedList && inviteGroups && checkedList.length === inviteGroups.length
  );
  const [campaignCheckedList, setCampaignCheckedList] = useState({});
  const [originalCampaignCheckedList, setOriginalCampaignCheckedList] =
    useState({});
  const [initalized, setInitialized] = useState(false);
  useEffect(() => {
    if (inviteGroups && !initalized) {
      const newCampaignCheckedList = { ...campaignCheckedList };
      for (const inviteGroup of inviteGroups) {
        let anyActive = false;
        if (inviteGroup.relations?.campaignInviteGroups) {
          for (const campaignInviteGroup of inviteGroup.relations
            .campaignInviteGroups) {
            const rowId = `${campaignInviteGroup.inviteGroupId}@${campaignInviteGroup.campaignId}`;
            newCampaignCheckedList[rowId] = campaignInviteGroup;
            if (campaignInviteGroup.active) {
              anyActive = true;
            }
          }
        }
      }
      setCampaignCheckedList(newCampaignCheckedList);
      setOriginalCampaignCheckedList(
        JSON.parse(JSON.stringify(newCampaignCheckedList))
      );

      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteGroups]);

  useEffect(() => {
    const campaignCheckListChanged = () => {
      const currentCampaignCheckedListIds = Object.keys(campaignCheckedList);
      for (const id of currentCampaignCheckedListIds) {
        if (
          originalCampaignCheckedList[id].active !==
          campaignCheckedList[id].active
        ) {
          return true;
        }
      }
      return false;
    };

    if (campaignCheckListChanged()) {
      setAdditionalAction(
        <div style={{ display: "inline-block" }} key="save-invite-groups">
          <Button
            type="primary"
            loading={isUpdatingInviteGroup}
            onClick={updateInviteGroups}
          >
            Save
          </Button>
          {isUpdateInviteGroupError && (
            <h4 style={{ color: "red", marginTop: "10px" }}>
              <WarningOutlined /> {isUpdateInviteGroupError}
            </h4>
          )}
        </div>
      );
    } else {
      setAdditionalAction(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignCheckedList, isUpdateInviteGroupError, isUpdatingInviteGroup]);

  const onChange = (checked, inviteGroupId) => {
    let updatedCheckedList;
    //add or remove inviteGroupId from list
    if (checked) {
      updatedCheckedList = [...checkedList, inviteGroupId];
    } else {
      updatedCheckedList = checkedList.filter(id => id !== inviteGroupId);
    }

    dispatch(setInviteGroupSelections(updatedCheckedList)); //redux
    setIndeterminate(
      updatedCheckedList &&
        !!updatedCheckedList.length &&
        updatedCheckedList.length < inviteGroups.length
    );
    setCheckAll(updatedCheckedList.length === inviteGroups.length);
  };

  const onCheckAllChange = e => {
    dispatch(
      setInviteGroupSelections(
        e.target.checked ? inviteGroups.map(ig => ig.inviteGroupId) : []
      )
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const toggleCampaignsActive = async () => {
    const selectedCampaignKeys = Object.keys(campaignCheckedList);
    const inviteGroupIdsSet = new Set();
    for (const id of selectedCampaignKeys) {
      const inviteGroupId = id.split("@")[0];
      inviteGroupIdsSet.add(inviteGroupId);
    }
    const inviteGroupIds = Array.from(inviteGroupIdsSet);
    const markInviteGroupActiveRequests = [];
    for (const inviteGroupId of inviteGroupIds) {
      const inviteGroup = inviteGroups.find(
        group => group.inviteGroupId === inviteGroupId
      );
      markInviteGroupActiveRequests.push(
        updateInviteGroup.mutateAsync(inviteGroup)
      );
    }
    await Promise.all(markInviteGroupActiveRequests);
    setOriginalCampaignCheckedList(
      JSON.parse(JSON.stringify(campaignCheckedList))
    );
  };

  const updateInviteGroups = async () => {
    setIsUpdatingInviteGroup(true);
    setIsUpdateInviteGroupError("");
    try {
      await toggleCampaignsActive();
    } catch (err) {
      setIsUpdateInviteGroupError(err.message || "An error occurred");
    } finally {
      setIsUpdatingInviteGroup(false);
    }
  };

  const isAnyCampaignActive = inviteGroupId => {
    const inviteGroup = inviteGroups.find(
      group => group.inviteGroupId === inviteGroupId
    );

    let anyActive = false;
    if (inviteGroup.relations?.campaignInviteGroups) {
      for (const campaignInviteGroup of inviteGroup.relations
        .campaignInviteGroups) {
        if (campaignInviteGroup.active) {
          anyActive = true;
        }
      }
    }
    return anyActive;
  };

  if (!contract || !inviteGroups) return null;

  //create map
  const campaignMap = contract.relations.campaigns.reduce((accum, campaign) => {
    accum[campaign.campaignId] = campaign;
    return accum;
  }, {});

  //start index and stop index of rowSpan grouped together by inviteGroupId
  //see: https://ant.design/components/table/#components-table-demo-colspan-rowspan
  const rowSpanCount = [];

  //to render
  const rows = [];
  for (const inviteGroup of inviteGroups) {
    const numOfCampaigns = inviteGroup.relations.campaignInviteGroups.length;
    rowSpanCount.push(numOfCampaigns);
    rowSpanCount.length += numOfCampaigns - 1; //add empty values,

    for (const campaign of inviteGroup.relations.campaignInviteGroups) {
      rows.push({ ...campaignMap[campaign.campaignId], ...inviteGroup });
    }
  }

  //redux
  return (
    <>
      <DataTable
        size="small"
        style={{ marginBottom: "12px" }}
        dataSource={rows}
        rowClassName="compact-row"
        //unique key
        rowKey={row => `${row.inviteGroupId}${row.campaignId}`}
        pagination={false}
        columns={getColumnsFromDataSource(
          rows,
          //column names
          [
            "name",
            "defaultTagQuery",
            "woodpeckerCampaigns",
            "queue",
            "sent",
            "opened",
            "clicked",
            "campaignName",
            "target",
            "totalOnboards",
            "done",
          ],
          {
            name: {
              title: (
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  Invite Group Name
                </Checkbox>
              ),
              width: "200px",
              render: (value, row, index) => {
                return {
                  children: (
                    <Checkbox
                      checked={checkedList.indexOf(row.inviteGroupId) > -1}
                      onChange={e =>
                        onChange(e.target.checked, row.inviteGroupId)
                      }
                    >
                      {value}
                    </Checkbox>
                  ),
                  //look up rowSpanCount either returns integer grouping together or zero
                  props: { rowSpan: rowSpanCount[index] || 0 },
                };
              },
            },
            totalOnboards: {
              title: "Total",
            },
            queue: {
              render: (value, row, index) => {
                return {
                  children: reduceStatsToOneRow(
                    row.relations.emailGroups.relations.woodpeckerCampaigns,
                    "queue"
                  ),
                  //look up rowSpanCount either returns integer grouping together or zero
                  props: { rowSpan: rowSpanCount[index] || 0 },
                };
              },
            },
            sent: {
              render: (value, row, index) => {
                return {
                  children: reduceStatsToOneRow(
                    row.relations.emailGroups.relations.woodpeckerCampaigns,
                    "sent"
                  ),
                  //look up rowSpanCount either returns integer grouping together or zero
                  props: { rowSpan: rowSpanCount[index] || 0 },
                };
              },
            },
            opened: {
              render: (value, row, index) => {
                const sent = reduceStatsToOneRow(
                  row.relations.emailGroups.relations.woodpeckerCampaigns,
                  "sent"
                );
                const opened = reduceStatsToOneRow(
                  row.relations.emailGroups.relations.woodpeckerCampaigns,
                  "opened"
                );

                return {
                  children: `${parseFloat((opened / sent) * 100).toFixed(1)}%`,
                  //look up rowSpanCount either returns integer grouping together or zero
                  props: { rowSpan: rowSpanCount[index] || 0 },
                };
              },
            },
            clicked: {
              render: (value, row, index) => {
                const sent = reduceStatsToOneRow(
                  row.relations.emailGroups.relations.woodpeckerCampaigns,
                  "sent"
                );
                const clicked = reduceStatsToOneRow(
                  row.relations.emailGroups.relations.woodpeckerCampaigns,
                  "clicked"
                );

                return {
                  children: `${parseFloat((clicked / sent) * 100).toFixed(1)}%`,
                  //look up rowSpanCount either returns integer grouping together or zero
                  props: { rowSpan: rowSpanCount[index] || 0 },
                };
              },
            },
            defaultTagQuery: {
              render: (value, row, index) => {
                return {
                  children: <TagQueryStyledTags tagQuery={value} />,
                  //look up rowSpanCount either returns integer grouping together or zero
                  props: { rowSpan: rowSpanCount[index] || 0 },
                };
              },
            },
            woodpeckerCampaigns: {
              width: "100px",
              render: (value, row, index) => {
                const campaignsAsTags =
                  row.relations.emailGroups.relations.woodpeckerCampaigns.map(
                    campaign => (
                      <WoodpeckerTag
                        metadata={campaign.metadata}
                        key={campaign.woodpeckerCampaignId}
                        woodpeckerCampaignId={campaign.woodpeckerCampaignId}
                      />
                    )
                  );
                return {
                  children: campaignsAsTags,
                  //look up rowSpanCount either returns integer grouping together or zero
                  props: { rowSpan: rowSpanCount[index] || 0 },
                };
              },
            },
            campaignName: {
              width: "200px",
              render: (value, row, index) => {
                const { campaignId, inviteGroupId } = row;
                const rowId = `${inviteGroupId}@${campaignId}`;
                return {
                  children: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{value}</span>
                      <Checkbox
                        checked={
                          !!campaignCheckedList[rowId] &&
                          campaignCheckedList[rowId].active
                        }
                        onChange={e => {
                          const newCampaignCheckedList = {
                            ...campaignCheckedList,
                          };
                          newCampaignCheckedList[rowId].active =
                            e.target.checked;

                          setCampaignCheckedList(newCampaignCheckedList);
                        }}
                      />
                    </div>
                  ),
                };
              },
            },
          }
        )}
      />
    </>
  );
}

InviteGroupsDataTable.propTypes = {
  contract: object,
  inviteGroups: array,
  setAdditionalAction: func,
};

export default InviteGroupsDataTable;
