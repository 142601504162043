import { types } from "./sharedActions";

export default (state = { loadingIds: [] }, action) => {
  switch (action.type) {
    case types.IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case types.PUSH_LOADING:
      //add loading
      return {
        ...state,
        isLoading: true,
        loadingIds: [...state.loadingIds, action.id],
      };
    case types.POP_LOADING:
      //remove id from array and if array is empty, loading = true
      const loadingIds = state.loadingIds.filter(id => id !== action.id);
      const isLoading = loadingIds.length > 0;
      return { ...state, isLoading, loadingIds };
    case types.RESULT:
      return { ...state, result: action.result };
    default:
      return state;
  }
};
