import React, { useEffect, useState } from "react";
import { array, bool, func, number, object, string } from "prop-types";
import {
  Form,
  Select,
  Input,
  Button,
  InputNumber,
  Cascader,
  Typography,
} from "antd";
import Decimal from "decimal.js";
import TwoComponentSwitch from "../../shared/TwoComponentSwitch/TwoComponentSwitch";
import styles from "./AddNewCampaign.module.css";
import { camelize } from "../../../utils/stringUtils";
import {
  defaultFormItemLayout,
  defaultButtonWrapperCol,
} from "../../../utils/formLayout";
import { cascaderFilter, selectFilter } from "../../../utils/filters";
import optionOrNew from "./optionOrNew";
import ImageUpload from "../../shared/ImageUpload";
import MoneyInput from "../../shared/MoneyInput";

const { TextArea } = Input;

const FirstForm = ({
  options,
  name,
  loading,
  toggleVisibleComponent,
  setExistingOptionIndex,
}) => {
  const getOptions = () => {
    const optionComponents = [];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const optionValue = JSON.parse(option.value);
      const onNewClick = () => {
        setExistingOptionIndex(i);
        toggleVisibleComponent();
      };
      optionComponents.push(
        optionOrNew({
          option,
          optionData: optionValue,
          idProperty: "productId",
          onNewClick,
        })
      );
    }
    return optionComponents;
  };

  return (
    <Form.Item
      label="Product Name"
      className={styles.formItem}
      name={name}
      rules={[{ required: true, message: "required" }]}
    >
      <Select
        loading={loading}
        showSearch
        className={styles.fullWidth}
        placeholder="Type to search products"
        filterOption={(inputValue, option) => {
          return (
            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          );
        }}
      >
        {getOptions()}
      </Select>
    </Form.Item>
  );
};

FirstForm.propTypes = {
  options: array,
  name: string,
  loading: bool,
  toggleVisibleComponent: func,
  setExistingOptionIndex: func,
};

const SecondForm = ({
  toggleVisibleComponent,
  options,
  setOptions,
  setSelectedOption,
  categories,
  existingOptionIndex,
  productPlatformOptions,
}) => {
  const [form] = Form.useForm();
  const [productName, setProductName] = useState("");
  const [defaultImageUrl, setDefaultImageUrl] = useState("");

  const [calculatedServiceFees, setCalculatedServiceFees] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (existingOptionIndex > -1) {
      const optionData = JSON.parse(options[existingOptionIndex].value);
      form.setFieldsValue(optionData);
      setProductName(optionData.name);
      setDefaultImageUrl(optionData.picture.thumbnailImageUrl);
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingOptionIndex]);

  const onSubmit = async () => {
    try {
      const values = form.getFieldsValue(true);

      await form.validateFields();

      const productPrices = {
        productPrice: values.productReimbursement,
        serviceFee: values.productServiceFees,
        serviceFeeDescription: values.serviceFeeDescription,
      };

      delete values.productReimbursement;
      delete values.fixedFee;
      delete values.percentageOfProduct;
      delete values.productServiceFees;
      delete values.serviceFeeDescription;

      const newOption = {
        label: values.name,
        value: JSON.stringify({ ...values, productPrices }),
      };

      console.log({ newOption });

      if (existingOptionIndex >= 0) {
        options.splice(existingOptionIndex, 1);
      }
      setOptions([newOption, ...options]);
      setSelectedOption(newOption);
      form.resetFields();
      toggleVisibleComponent();
    } catch (error) {
      //ignore as Ant Design will handle validation based on form.item rules
    }
  };

  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    if (categories) {
      setCategoryOptions(mapCategoriesToCascader(categories));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const onUploadImage = urls => {
    form.setFieldsValue({
      picture: {
        fullImageUrl: urls.fullImageUrl,
        thumbnailUrl: urls.thumbnailImageUrl,
      },
    });
  };

  const validateProductName = value => {
    if (!value) {
      return Promise.reject(new Error("required"));
    }
    if (!value.trim()) {
      return Promise.reject(new Error("product name can't be all spaces"));
    }
    if (existingOptionIndex < 0) {
      const existingOption = options.find(o => o.label === value);
      if (existingOption) {
        return Promise.reject(new Error("product name already exists"));
      }
    } else {
      const existingOption = options.find(o => o.label === value);
      const previousExistingOption = options[existingOptionIndex];
      if (
        existingOption &&
        previousExistingOption.label !== existingOption.label
      ) {
        return Promise.reject(new Error("product name already exists"));
      }
    }
    return Promise.resolve();
  };

  const onValuesChange = (changedValues, allValues) => {
    const {
      productReimbursement,
      percentageOfProduct,
      productServiceFees,
      fixedFee = 0,
    } = allValues;
    if (productReimbursement) {
      const newProductServiceFee = new Decimal(productReimbursement)
        .mul((percentageOfProduct || 0) / 100)
        .add(fixedFee || 0)
        .mul(100)
        .ceil()
        .div(100);

      setCalculatedServiceFees(newProductServiceFee.toFixed(2));
    }

    if (productReimbursement) {
      setTotalPrice(
        new Decimal(productReimbursement)
          .add(productServiceFees || 0)
          .toFixed(2)
      );
    }
  };

  return (
    <Form
      {...defaultFormItemLayout}
      component={false}
      form={form}
      labelAlign="right"
      layout="horizontal"
      name="createProductForm"
      onValuesChange={onValuesChange}
      initialValues={{
        percentageOfProduct: 7, //average tax rate in USA
        productServiceFees: "0",
        serviceFeeDescription: "Average tax rate",
      }}
    >
      <Form.Item
        label="Product Name"
        name="name"
        rules={[
          {
            required: true,
            validator: (rule, value) => validateProductName(value),
          },
        ]}
      >
        <Input onChange={e => setProductName(e.target.value.trim())} />
      </Form.Item>

      <Form.Item
        name="productPlatformId"
        label="Platform"
        rules={[{ required: true, message: "required" }]}
      >
        <Select
          showSearch
          options={productPlatformOptions}
          placeholder="Type to search platforms"
          filterOption={selectFilter}
        />
      </Form.Item>
      <Form.Item
        label="Category"
        name="category"
        rules={[{ required: true, message: "required" }]}
      >
        <Cascader
          placeholder="Type to search categories"
          showSearch={{ filter: cascaderFilter }}
          options={categoryOptions}
          getPopupContainer={() => document.getElementById("root")}
        />
      </Form.Item>

      <Form.Item
        label="Product Reimbursement"
        name="productReimbursement"
        rules={[
          {
            required: true,
            message: "valid price is required",
          },
        ]}
      >
        <MoneyInput />
      </Form.Item>

      <Form.Item
        label="Service fee calculator"
        style={{ marginBottom: 0 }}
        extra="Use these fields to calculate the total service fee. Copy and paste into the Product Service Fees box below"
      >
        <div style={{ display: "flex", gap: "24px" }}>
          <Form.Item>
            <Form.Item>
              $
              <Typography.Text copyable>
                {calculatedServiceFees}
              </Typography.Text>
            </Form.Item>
          </Form.Item>

          <Form.Item label="Percentage of Product" name="percentageOfProduct">
            <InputNumber min={0} max={100} addonAfter="%" />
          </Form.Item>

          <Form.Item label="Fixed Fee" name="fixedFee">
            <MoneyInput />
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item label="Product Service Fees" name="productServiceFees">
        <MoneyInput />
      </Form.Item>

      <Form.Item label="Total Price">
        <Typography.Text>${Number(totalPrice).toFixed(2)}</Typography.Text>
      </Form.Item>

      <Form.Item name="serviceFeeDescription" label="Service Fee Description">
        <Input />
      </Form.Item>

      <Form.Item
        label="Code"
        name="code"
        rules={[{ required: true, message: "required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, type: "string", max: 400 }]}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item
        label="Short Description"
        name="shortDescription"
        rules={[{ required: true, type: "string", max: 140 }]}
      >
        <TextArea rows={2} />
      </Form.Item>
      {productName && (
        <>
          <Form.Item
            label="Picture"
            name="picture"
            rules={[{ required: true, message: "required" }]}
          >
            <ImageUpload
              imageSizeValidation={(width, height) =>
                width >= 750 && width === height
              }
              altText="product"
              errorMessage="Image dimensions must be at least 1000x1000 pixels and must be a square (equal width and height)"
              imageName={camelize(productName)}
              onUpload={onUploadImage}
              defaultImageUrl={defaultImageUrl}
              containerName="product-images"
            />
          </Form.Item>
        </>
      )}
      <Form.Item wrapperCol={defaultButtonWrapperCol}>
        <Button onClick={onSubmit}>Submit</Button>
      </Form.Item>
    </Form>
  );
};

SecondForm.propTypes = {
  options: array,
  setSelectedOption: func,
  toggleVisibleComponent: func,
  setOptions: func,
  categories: object,
  existingOptionIndex: number,
  productPlatformOptions: array,
};

function ProductForm({
  options,
  setOptions,
  setSelectedOption,
  name,
  loading,
  onSwitch,
  categories,
  productPlatformOptions,
}) {
  const [existingOptionIndex, setExistingOptionIndex] = useState(-1);

  const _onSwitch = newActiveComponent => {
    if (newActiveComponent === 1) {
      setExistingOptionIndex(-1);
    }
    onSwitch(newActiveComponent);
  };

  return (
    <TwoComponentSwitch
      className={styles.fullWidth}
      activateFirstText="Select Existing Product"
      activateSecondText="Create New Product"
      onSwitch={_onSwitch}
      firstComponent={
        <FirstForm
          options={options}
          setSelectedOption={setSelectedOption}
          name={name}
          loading={loading}
          setExistingOptionIndex={setExistingOptionIndex}
        />
      }
      secondComponent={
        <SecondForm
          existingOptionIndex={existingOptionIndex}
          options={options}
          setOptions={setOptions}
          setSelectedOption={setSelectedOption}
          categories={categories}
          productPlatformOptions={productPlatformOptions}
        />
      }
    />
  );
}

ProductForm.propTypes = {
  options: array,
  setOptions: func,
  setSelectedOption: func,
  name: string,
  loading: bool,
  onSwitch: func,
  categories: object,
  productPlatformOptions: array,
};

export default ProductForm;

const mapCategoriesToCascader = categories => {
  return Object.values(categories).map(category => {
    return {
      value: category.categoryId,
      label: category.name,
      children: Object.values(category.children).map(subcategory => {
        return {
          value: subcategory.categoryId,
          label: subcategory.name,
        };
      }),
    };
  });
};
