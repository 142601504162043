import React, { useEffect, useState } from "react";
import { Form, Space, Button } from "antd";
import { any, array, bool, func, object, string } from "prop-types";
import StepCreationForm from "./StepCreationForm";
import styles from "./AddNewCampaign.module.css";
import {
  defaultFormItemLayout,
  defaultButtonWrapperCol,
} from "../../../utils/formLayout";

function GenericStep({
  form,
  onFormDisabled,
  stepType,
  contractName,
  children,
  formPreSubmit,
  invisibleFormFields,
  visible,
  campaignSteps,
  companyName,
  platform,
  link,
  emailTemplates,
  addEmailTemplate,
  onExistingFieldSelect,
}) {
  const [stepOptions, setStepOptions] = useState([]);
  const [showingNextButton, setShowingNextButton] = useState(true);

  const toggleSubmitButton = activeComponent => {
    //if the product creation form is showing, hide the top-level form submission button
    setShowingNextButton(activeComponent === 1);
    if (onFormDisabled) {
      //indicate to the top level parent that this form is disabled
      onFormDisabled(activeComponent === 2);
    }
  };

  useEffect(() => {
    if (campaignSteps) {
      setStepOptions(
        Object.values(campaignSteps)
          .filter(c => c.stepType === stepType)
          .map(c => ({
            label: `${c.name}`,
            value: JSON.stringify({ campaignStepId: c.campaignStepId }),
          }))
      );
    }
  }, [campaignSteps, stepType]);

  useEffect(() => {
    form.setFieldsValue({ campaignStep: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValuesChange = changedValues => {
    if (changedValues.name) {
      try {
        const stepData = JSON.parse(changedValues.name);
        form.setFieldsValue({
          maxReminders: stepData.maxReminders,
          hoursForReminder: stepData.hoursForReminder,
        });
      } catch (err) {
        //new step selected
      }
    }
  };

  return (
    <Form
      className={styles.formContainer}
      name={`${stepType}Form`}
      {...defaultFormItemLayout}
      labelAlign="right"
      layout="horizontal"
      form={form}
      onValuesChange={onValuesChange}
    >
      <Space direction="vertical" className={styles.form} size="large">
        <StepCreationForm
          onExistingFieldSelect={onExistingFieldSelect}
          onSwitch={toggleSubmitButton}
          name="campaignStep"
          options={stepOptions}
          setOptions={setStepOptions}
          setSelectedOption={option => {
            form.setFieldsValue({ campaignStep: option.value });
          }}
          contractName={contractName}
          stepType={stepType}
          invisibleFormFields={invisibleFormFields}
          formPreSubmit={formPreSubmit}
          visible={visible}
          companyName={companyName}
          platform={platform}
          link={link}
          emailTemplates={emailTemplates}
          addEmailTemplate={addEmailTemplate}
        >
          {children}
        </StepCreationForm>

        {showingNextButton && (
          <Form.Item
            className={styles.buttonsContainer}
            wrapperCol={defaultButtonWrapperCol}
          >
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        )}
      </Space>
    </Form>
  );
}

GenericStep.propTypes = {
  form: any,
  onFormDisabled: func,
  stepType: string,
  contractName: string,
  children: any,
  formPreSubmit: func,
  invisibleFormFields: array,
  onExistingFieldSelect: func,
  visible: bool,
  campaignSteps: object,
  companyName: string,
  platform: string,
  link: string,
  emailTemplates: object,
  addEmailTemplate: func,
};

export default GenericStep;
