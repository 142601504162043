import React, { useState, useEffect } from "react";
import { array, bool, func, number, string } from "prop-types";
import { Form, Select, Input, InputNumber, Button, Checkbox } from "antd";
import TwoComponentSwitch from "../../shared/TwoComponentSwitch/TwoComponentSwitch";
import styles from "./AddNewCampaign.module.css";
import {
  defaultFormItemLayout,
  defaultButtonWrapperCol,
  miniFormItemLayout,
} from "../../../utils/formLayout";
import optionOrNew from "./optionOrNew";

const FirstForm = ({
  options,
  name,
  loading,
  setExistingOptionIndex,
  toggleVisibleComponent,
}) => {
  const getOptions = () => {
    const optionComponents = [];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const optionValue = JSON.parse(option.value);
      const onNewClick = () => {
        setExistingOptionIndex(i);
        toggleVisibleComponent();
      };
      optionComponents.push(
        optionOrNew({
          option,
          optionData: optionValue,
          idProperty: "campaignFlowId",
          onNewClick,
        })
      );
    }

    return optionComponents;
  };

  return (
    <Form.Item
      label="Campaign Flow"
      className={styles.formItem}
      name={name}
      rules={[{ required: true, message: "required" }]}
    >
      <Select
        loading={loading}
        showSearch
        className={styles.fullWidth}
        filterOption={(inputValue, option) => {
          return (
            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          );
        }}
        placeholder="Type to search campaign flows"
      >
        {getOptions()}
      </Select>
    </Form.Item>
  );
};

FirstForm.propTypes = {
  options: array,
  name: string,
  loading: bool,
  toggleVisibleComponent: func,
  setExistingOptionIndex: func,
};

const step1Options = [
  {
    label: "Order",
    value: "order",
  },
];

const step2Options = [
  {
    label: "Instagram",
    value: "instagram",
  },
  {
    label: "Flipshop",
    value: "flipshop",
  },
];

const normalizeRequiredSteps = data => {
  const numOfRequiredSteps = 2;
  const requiredSteps = [];

  for (let i = 1; i <= numOfRequiredSteps; i++) {
    requiredSteps.push({
      name: data[`requiredStep${i}`],
      stepType: "required",
    });
  }

  return requiredSteps;
};

const normalizeOptionalSteps = data => {
  const optionalSteps = [];

  if (data.includeReview) {
    optionalSteps.push({
      name: "review",
      stepType: "optional",
    });
  }

  return optionalSteps;
};

const SecondForm = ({
  contractName,
  toggleVisibleComponent,
  options,
  setOptions,
  setSelectedOption,
  existingOptionIndex,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (existingOptionIndex > -1) {
      const optionData = JSON.parse(options[existingOptionIndex].value);
      form.setFieldsValue(optionData);
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingOptionIndex]);

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue(true);
      const requiredSteps = normalizeRequiredSteps(values);
      const optionalSteps = normalizeOptionalSteps(values);

      const steps = [...requiredSteps, ...optionalSteps];
      const newOption = {
        label: values.name,
        value: JSON.stringify({ ...values, steps }),
      };
      if (existingOptionIndex >= 0) {
        options.splice(existingOptionIndex, 1);
      }
      setOptions([newOption, ...options]);
      setSelectedOption(newOption);
      form.resetFields();
      toggleVisibleComponent();
    } catch (error) {
      //ignore as Ant Design will handle validation based on form.item rules
    }
  };

  const validateCampaignFlowName = value => {
    if (!value) {
      return Promise.reject(new Error("required"));
    }
    if (!value.trim()) {
      return Promise.reject(
        new Error("campaign flow name can't be all spaces")
      );
    }

    if (existingOptionIndex < 0) {
      const existingOption = options.find(o => o.label === value);
      if (existingOption) {
        return Promise.reject(new Error("campaign flow name already exists"));
      }
    } else {
      const existingOption = options.find(o => o.label === value);
      const previousExistingOption = options[existingOptionIndex];
      if (
        existingOption &&
        previousExistingOption.label !== existingOption.label
      ) {
        return Promise.reject(new Error("campaign flow name already exists"));
      }
    }
    return Promise.resolve();
  };

  return (
    <Form
      {...defaultFormItemLayout}
      component={false}
      form={form}
      labelAlign="right"
      layout="horizontal"
      name="createCampaignFlowForm"
      initialValues={{
        name: contractName,
        requiredStep1: "order",
        requiredStep2: "instagram",
        includeReview: true,
      }}
    >
      <Form.Item
        label="Campaign Flow Name"
        name="name"
        rules={[
          {
            validator: (rule, value) => validateCampaignFlowName(value),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <div className={styles.miniFormContainer}>
        <Form.Item
          {...miniFormItemLayout}
          label="Step 1"
          name="requiredStep1"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            options={step1Options}
            className={styles.fullWidth}
            placeholder="Select step 1"
            disabled
          />
        </Form.Item>

        <Form.Item
          {...miniFormItemLayout}
          label="Step 2"
          name="requiredStep2"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            options={step2Options}
            className={styles.fullWidth}
            placeholder="Select step 2"
          />
        </Form.Item>
      </div>

      <Form.Item
        name="includeReview"
        label="Optional Review Step"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>

      <Form.Item wrapperCol={defaultButtonWrapperCol}>
        <Button onClick={onSubmit}>Submit</Button>
      </Form.Item>
    </Form>
  );
};

SecondForm.propTypes = {
  contractName: string,
  options: array,
  setSelectedOption: func,
  toggleVisibleComponent: func,
  setOptions: func,
  existingOptionIndex: number,
};

function FlowForm({
  contractName,
  options,
  setOptions,
  setSelectedOption,
  name,
  loading,
  onSwitch,
}) {
  const [existingOptionIndex, setExistingOptionIndex] = useState(-1);

  const _onSwitch = newActiveComponent => {
    if (newActiveComponent === 1) {
      setExistingOptionIndex(-1);
    }
    onSwitch(newActiveComponent);
  };

  return (
    <TwoComponentSwitch
      className={styles.fullWidth}
      activateFirstText="Select Existing Flow"
      activateSecondText="Create New Flow"
      onSwitch={_onSwitch}
      firstComponent={
        <FirstForm
          options={options}
          setSelectedOption={setSelectedOption}
          name={name}
          loading={loading}
          setExistingOptionIndex={setExistingOptionIndex}
        />
      }
      secondComponent={
        <SecondForm
          existingOptionIndex={existingOptionIndex}
          contractName={contractName}
          options={options}
          setOptions={setOptions}
          setSelectedOption={setSelectedOption}
        />
      }
    />
  );
}

FlowForm.propTypes = {
  contractName: string,
  options: array,
  setOptions: func,
  setSelectedOption: func,
  name: string,
  loading: bool,
  onSwitch: func,
};

export default FlowForm;
