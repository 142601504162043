import React, { useEffect, useReducer } from "react";
import { Space, Form, Divider, Tag, Typography } from "antd";
import ColorHash from "color-hash";
import TestUserForm from "./TestUserForm";
import { defaultFormItemLayout } from "../../utils/formLayout";
import styles from "./Test.module.css";
import genericReducer from "../../utils/genericUseReducer";
import JoinForm from "./JoinForm";
import InvitesForm from "./InvitesForm";
import { useEmailGroups } from "../../api/emailGroups";
import { useInviteGroups } from "../../api/inviteGroups";
import { useInfluencerToken, useUsers } from "../../api/users";
import { useUserStates } from "../../api/userStates";
import DataTable from "../shared/DataTable/DataTable";
import { getColumnsFromDataSource } from "../shared/DataTable/utils";
import GenericPageHeader from "../shared/GenericPageHeader/GenericPageHeader";
import OnboardsForm from "./OnboardsForm";
import { useCampaigns } from "../../api/campaigns";
import SelectionForm from "./SelectionForm";

const colorHash = new ColorHash();

export default function Test() {
  const [form] = Form.useForm();

  const [state, setState] = useReducer(genericReducer, {
    selectedTestUserId: null,
    selectedJoinWoodpeckerCampaignId: null,
    testUserCreationFormVisible: false,
    testUserOptions: [],
    allTestUsers: {},
    allUserStates: {},
    joinWoodpeckerCampaignIds: [],
    joinEmailGroup: null,
    allInviteGroups: {},
    inviteGroupOptions: [],
    selectedInviteGroupId: null,
    inviteWoodpeckerCampaignIds: [],
    selectedInviteWoodpeckerCampaignId: null,
    campaignOptions: [],
  });

  const { data: campaigns, isLoading: loadingCampaigns } = useCampaigns({});
  useEffect(() => {
    if (campaigns) {
      setState({
        campaignOptions: campaigns.map(c => {
          return {
            label: `${c.name} - ${c.campaignId}`,
            value: c.campaignId,
          };
        }),
      });
    }
  }, [campaigns]);

  const { data: inviteGroups, isLoading: loadingInviteGroups } =
    useInviteGroups({
      includes: ["emailGroups", "woodpeckerCampaigns"],
    });

  useEffect(() => {
    if (inviteGroups) {
      const allInviteGroups = inviteGroups.reduce((acc, inviteGroup) => {
        return { ...acc, [inviteGroup.inviteGroupId]: inviteGroup };
      }, {});
      const inviteGroupOptions = inviteGroups.map(inviteGroup => {
        return {
          value: inviteGroup.inviteGroupId,
          label: inviteGroup.name,
        };
      });
      setState({
        allInviteGroups,
        inviteGroupOptions,
      });
    }
  }, [inviteGroups]);

  const { data: joinEmailGroups, isLoading: loadingJoinEmailGroup } =
    useEmailGroups({
      params: { type: "join" },
      includes: ["woodpeckerCampaigns"],
    });

  useEffect(() => {
    if (joinEmailGroups && joinEmailGroups.length > 0) {
      const joinEmailGroup = joinEmailGroups[0];
      const joinWoodpeckerCampaignIds =
        joinEmailGroup.relations?.woodpeckerCampaigns?.map(campaign => {
          return {
            value: campaign.woodpeckerCampaignId,
          };
        });
      setState({
        joinWoodpeckerCampaignIds,
        joinEmailGroup,
      });
    }
  }, [joinEmailGroups]);

  const { data: userEvents, isLoading: loadingUserEvents } = useUsers({
    includes: ["userProfiles", "userEvents"],
    params: { all: "app:test_user" },
  });

  const { data: userOnboards, isLoading: loadingUserOnboards } = useUsers({
    includes: ["onboards", "campaignFlows"],
    params: { all: "app:test_user" },
  });

  const { data: userInvites, isLoading: loadingUserInvites } = useUsers({
    includes: ["invites"],
    params: { all: "app:test_user" },
  });

  const { data: userEligibility, isLoading: loadingUserEligibility } = useUsers(
    {
      includes: ["userEligibility"],
      params: { all: "app:test_user" },
    }
  );

  const loadingUsers =
    loadingUserEvents ||
    loadingUserOnboards ||
    loadingUserInvites ||
    loadingUserEligibility;

  let hasUsers =
    userEvents &&
    userOnboards &&
    userInvites &&
    userEligibility &&
    !loadingUsers;

  let users = [];

  if (hasUsers) {
    for (const user of userEvents) {
      user.relations.onboards = userOnboards.find(
        u => u.userId === user.userId
      )?.relations?.onboards;

      user.relations.invites = userInvites.find(
        u => u.userId === user.userId
      )?.relations?.invites;

      user.relations.userEligibility = userEligibility.find(
        u => u.userId === user.userId
      )?.relations?.userEligibility;

      users.push(user);
    }
  }

  useEffect(() => {
    if (users && users.length > 0) {
      const newAllTestUsers = users.reduce((acc, user) => {
        return { ...acc, [user.userId]: user };
      }, {});
      const newTestUserOptions = users.map(u => {
        return {
          label: (
            <span>
              {u.email} - {u.activeProfile}{" "}
              <Tag color={colorHash.hex(u.userState)}>{u.userState}</Tag>
            </span>
          ),
          value: u.userId,
        };
      });
      setState({
        allTestUsers: newAllTestUsers,
        testUserOptions: newTestUserOptions,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEvents, userOnboards, userInvites, userEligibility]);

  const { data: userStates } = useUserStates({});

  useEffect(() => {
    if (userStates) {
      const allUserStates = userStates.reduce((acc, userState) => {
        return { ...acc, [userState.userState]: userState };
      }, {});
      setState({
        allUserStates,
      });
    }
  }, [userStates]);

  const onValuesChange = values => {
    if (values.testUserId) {
      setState({
        selectedTestUserId: values.testUserId,
      });
    } else if (values.joinWoodpeckerCampaignId) {
      setState({
        selectedJoinWoodpeckerCampaignId: values.joinWoodpeckerCampaignId,
      });
    } else if (values.inviteGroupId) {
      const inviteWoodpeckerCampaignIds = state.allInviteGroups[
        values.inviteGroupId
      ].relations.emailGroups.relations.woodpeckerCampaigns.map(campaign => {
        return {
          value: campaign.woodpeckerCampaignId,
        };
      });
      setState({
        selectedInviteGroupId: values.inviteGroupId,
        inviteWoodpeckerCampaignIds,
        selectedInviteWoodpeckerCampaignId: "",
      });
      form.setFieldsValue({ inviteWoodpeckerCampaignId: null });
    } else if (values.inviteWoodpeckerCampaignId) {
      setState({
        selectedInviteWoodpeckerCampaignId: values.inviteWoodpeckerCampaignId,
      });
    } else if (values.onboardCampaignId) {
      setState({
        selectedOnboardCampaignId: values.onboardCampaignId,
      });
    }
  };

  const selectedUser =
    state.selectedTestUserId && state.allTestUsers[state.selectedTestUserId];

  const userStatePriority =
    state.allUserStates &&
    state.allUserStates[selectedUser?.userState]?.priority;

  return (
    <div>
      <GenericPageHeader title="Test Users" />
      <Form
        form={form}
        {...defaultFormItemLayout}
        labelAlign="right"
        layout="horizontal"
        className={styles.formContainer}
        onValuesChange={onValuesChange}
      >
        <Space direction="vertical" className={styles.form} size="small">
          <TestUserForm
            loading={loadingUsers}
            name="testUserId"
            onSwitch={activeComponent =>
              setState({ testUserCreationFormVisible: activeComponent === 2 })
            }
            options={state.testUserOptions}
            setOptions={testUserOptions => setState({ testUserOptions })}
            addSelectedOption={userId => {
              form.setFieldsValue({
                testUserId: userId,
              });
              setState({
                selectedTestUserId: userId,
              });
            }}
            selectedTestUserId={state.selectedTestUserId}
          />
          {selectedUser?.relations?.userEvents && (
            <Form.Item label={<div />} colon={false}>
              <Space align="center">
                <Typography.Text strong>User Event History</Typography.Text>
                {selectedUser?.userId && (
                  <Typography.Text type="secondary" copyable>
                    {selectedUser.userId}
                  </Typography.Text>
                )}
              </Space>
              <div className={styles.eventsContainer}>
                <DataTable
                  size="small"
                  style={{ marginBottom: "12px" }}
                  dataSource={selectedUser.relations.userEvents}
                  rowClassName="compact-row"
                  rowKey={row => `${row.createdAt}`}
                  pagination={false}
                  columns={getColumnsFromDataSource(
                    selectedUser.relations.userEvents,
                    ["previousUserState", "eventCode", "createdAt"],
                    {
                      previousUserState: {
                        title: "User State",
                      },
                      createdAt: {
                        title: "Updated Timestamp",
                      },
                    }
                  )}
                />
              </div>
            </Form.Item>
          )}
          <Divider className={styles.divider} />
          <JoinForm
            showLink={
              state.selectedTestUserId &&
              state.allUserStates &&
              userStatePriority >= state.allUserStates.join_sent?.priority
            }
            disabled={
              !state.selectedTestUserId ||
              selectedUser?.userState !== "new" ||
              state.testUserCreationFormVisible
            }
            loading={loadingJoinEmailGroup}
            options={state.joinWoodpeckerCampaignIds}
            buttonDisabled={
              !state.selectedTestUserId ||
              !state.selectedJoinWoodpeckerCampaignId
            }
            selectedWoodpeckerCampaignId={
              state.selectedJoinWoodpeckerCampaignId
            }
            userId={state.selectedTestUserId}
          />

          <Divider className={styles.divider} />
          <SelectionForm user={selectedUser} />
          <Divider className={styles.divider} />
          <InvitesForm
            disabled={
              !state.selectedTestUserId ||
              !state.allUserStates ||
              userStatePriority < state.allUserStates.joined?.priority ||
              state.testUserCreationFormVisible
            }
            loading={loadingInviteGroups}
            woodpeckerCampaignOptions={state.inviteWoodpeckerCampaignIds}
            inviteGroupOptions={state.inviteGroupOptions}
            buttonDisabled={
              !state.selectedTestUserId || !state.selectedInviteGroupId
            }
            selectedWoodpeckerCampaignId={
              state.selectedInviteWoodpeckerCampaignId
            }
            selectedInviteGroupId={state.selectedInviteGroupId}
            userState={selectedUser?.userState}
            user={state.allTestUsers[state.selectedTestUserId]}
            campaignOptions={state.campaignOptions}
          />

          <Divider className={styles.divider} />
          <OnboardsForm
            disabled={
              !state.selectedTestUserId || state.testUserCreationFormVisible
            }
            loading={loadingCampaigns}
            options={state.campaignOptions}
            buttonDisabled={
              !state.selectedTestUserId || !state.selectedOnboardCampaignId
            }
            selectedCampaignId={state.selectedOnboardCampaignId}
            userState={selectedUser?.userState}
            user={state.allTestUsers[state.selectedTestUserId]}
          />
        </Space>
      </Form>
    </div>
  );
}
