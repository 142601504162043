import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { any, array, bool, func, number, object, string } from "prop-types";
import {
  Form,
  Select,
  Input,
  Button,
  InputNumber,
  Timeline,
  Space,
} from "antd";
import { MailOutlined } from "@ant-design/icons";
import TwoComponentSwitch from "../../shared/TwoComponentSwitch/TwoComponentSwitch";
import styles from "./AddNewCampaign.module.css";
import {
  defaultFormItemLayout,
  defaultButtonWrapperCol,
} from "../../../utils/formLayout";
import { toTitleCase } from "../../../utils/stringUtils";
import EmailTemplateForm from "./EmailTemplateForm";
import optionOrNew from "./optionOrNew";

const FirstForm = ({
  options,
  name,
  loading,
  setExistingOptionIndex,
  toggleVisibleComponent,
  onExistingFieldSelect,
}) => {
  const getOptions = () => {
    const optionComponents = [];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const optionValue = JSON.parse(option.value);
      const onNewClick = () => {
        setExistingOptionIndex(i);
        if (onExistingFieldSelect) {
          onExistingFieldSelect(optionValue);
        }
        toggleVisibleComponent();
      };
      optionComponents.push(
        optionOrNew({
          option,
          optionData: optionValue,
          idProperty: "campaignStepId",
          onNewClick,
        })
      );
    }

    return optionComponents;
  };

  return (
    <Form.Item
      label="Step Name"
      name={name}
      rules={[{ required: true, message: "required" }]}
    >
      <Select
        loading={loading}
        showSearch
        className={styles.fullWidth}
        filterOption={(inputValue, option) => {
          return (
            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          );
        }}
        placeholder="Type to search campaign steps"
      >
        {getOptions()}
      </Select>
    </Form.Item>
  );
};

FirstForm.propTypes = {
  options: array,
  name: string,
  loading: bool,
  toggleVisibleComponent: func,
  setExistingOptionIndex: func,
  onExistingFieldSelect: func,
};

const numberOfReminderTemplates = 1;

const defaultHoursForReminder = {
  order: 60,
  instagram: 132,
  review: 132,
  flipshop: 132,
};

const defaultMaxReminders = {
  order: 4,
  instagram: 4,
  flipshop: 4,
  review: 1,
};

const SecondForm = ({
  toggleVisibleComponent,
  options,
  setOptions,
  setSelectedOption,
  contractName,
  stepType,
  invisibleFormFields,
  children,
  setButtonHidden,
  companyName,
  platform,
  link,
  emailTemplates,
  addEmailTemplate,
  existingOptionIndex,
  formPreSubmit,
}) => {
  const [form] = Form.useForm();

  const [templatesFlow, setTemplatesFlow] = useState([]);

  const [templateFormVisible, setTemplateFormVisible] = useState(-1);

  const [templatesError, setTemplatesError] = useState("");

  const [stepName, setStepName] = useState(
    `${contractName} ${toTitleCase(stepType)} Step`
  );

  useEffect(() => {
    if (existingOptionIndex > -1) {
      const optionData = JSON.parse(options[existingOptionIndex].value);
      form.setFieldsValue(optionData);
      const emailTemplateFlow = JSON.parse(optionData.emailTemplateFlow);
      setTemplatesFlow(emailTemplateFlow);
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingOptionIndex]);

  const onSubmit = async () => {
    try {
      if (templatesFlow.length === 0) {
        setTemplatesError("An email template is required");
        return;
      }
      await form.validateFields();
      const values = form.getFieldsValue(true);
      const newOption = {
        label: values.name,
        value: JSON.stringify(values),
      };
      if (formPreSubmit) {
        formPreSubmit(newOption);
      }
      if (existingOptionIndex >= 0) {
        options.splice(existingOptionIndex, 1);
      }
      setOptions([newOption, ...options]);
      setSelectedOption(newOption);
      form.resetFields();
      toggleVisibleComponent();
    } catch (error) {
      //ignore as Ant Design will handle validation based on form.item rules
    }
  };

  useEffect(() => {
    setButtonHidden(templateFormVisible > -1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateFormVisible]);

  const validateStepName = value => {
    if (!value) {
      return Promise.reject(new Error("required"));
    }
    if (!value.trim()) {
      return Promise.reject(new Error("step name can't be all spaces"));
    }
    if (existingOptionIndex < 0) {
      const existingOption = options.find(o => o.label === value);
      if (existingOption) {
        return Promise.reject(new Error("step name already exists"));
      }
    } else {
      const existingOption = options.find(o => o.label === value);
      const previousExistingOption = options[existingOptionIndex];
      if (
        existingOption &&
        previousExistingOption.label !== existingOption.label
      ) {
        return Promise.reject(new Error("step name already exists"));
      }
    }
    return Promise.resolve();
  };

  const reminderTemplateCount =
    numberOfReminderTemplates - templatesFlow.length;

  return (
    <Form
      {...defaultFormItemLayout}
      component={false}
      form={form}
      labelAlign="right"
      layout="horizontal"
      name="createCampaignStepForm"
      initialValues={{
        name: `${contractName} ${toTitleCase(stepType)} Step`,
        maxReminders: defaultMaxReminders[stepType],
        hoursForReminder: defaultHoursForReminder[stepType],
      }}
    >
      <div
        className={clsx({
          [styles.visible]: templateFormVisible <= -1,
          [styles.hidden]: templateFormVisible > -1,
        })}
      >
        {/* This is required in order to add an additional "invisible" form fields to the form so that they can be set (e.g. instaTags) */}
        {invisibleFormFields &&
          invisibleFormFields.map(name => (
            <Form.Item key={name} name={name} className={styles.displayNone}>
              <></>
            </Form.Item>
          ))}
        <Space direction="vertical" className={styles.form} size="large">
          <Form.Item
            label="Step Name"
            name="name"
            rules={[
              {
                validator: (rule, value) => validateStepName(value),
              },
            ]}
          >
            <Input onChange={e => setStepName(e.target.value.trim())} />
          </Form.Item>

          <Form.Item name="maxReminders" label="Maximum Reminders">
            <InputNumber />
          </Form.Item>
          <Form.Item name="hoursForReminder" label="Hours b/w Reminders">
            <InputNumber />
          </Form.Item>

          {children}

          {stepName && (
            <div>
              <div className={styles.templatesHeader}>
                Template Flow
                {templatesError && (
                  <div className={styles.inputError}>{templatesError}</div>
                )}
              </div>
              <Timeline>
                {templatesFlow.map((template, i) => (
                  <Timeline.Item key={i} dot={<MailOutlined />}>
                    <span
                      className={clsx(styles.lightBold, styles.pointerCursor)}
                      onClick={() => setTemplateFormVisible(i)}
                      aria-hidden="true"
                    >
                      {template.name}
                    </span>
                  </Timeline.Item>
                ))}
                {[
                  ...Array(
                    reminderTemplateCount > -1 ? reminderTemplateCount : 0
                  ),
                ].map((_, i) => (
                  <Timeline.Item key={i}>
                    <span
                      className={styles.pointerCursor}
                      onClick={() =>
                        setTemplateFormVisible(templatesFlow.length)
                      }
                      aria-hidden="true"
                    >
                      Click to select a reminder template
                    </span>
                  </Timeline.Item>
                ))}
              </Timeline>
            </div>
          )}
        </Space>
        <Form.Item wrapperCol={defaultButtonWrapperCol}>
          <Button onClick={onSubmit}>Submit</Button>
        </Form.Item>
      </div>

      <div
        className={clsx({
          [styles.visible]: templateFormVisible > -1,
          [styles.hidden]: templateFormVisible <= -1,
        })}
      >
        <EmailTemplateForm
          emailTemplates={emailTemplates}
          onBack={() => {
            setTemplateFormVisible(-1);
          }}
          campaignStepName={stepName}
          value={templatesFlow[templateFormVisible]}
          name="emailTemplate"
          campaignStepType={stepType}
          companyName={companyName}
          platform={platform}
          campaignLink={link}
          onSubmit={value => {
            const { emailTemplate } = value;
            const emailTemplateData = JSON.parse(emailTemplate);
            if (!emailTemplateData.emailTemplateId) {
              addEmailTemplate(emailTemplateData, stepType);
            }
            const newTemplatesFlow = [...templatesFlow];
            if (templateFormVisible > -1) {
              newTemplatesFlow[templateFormVisible] = emailTemplateData;
            } else {
              newTemplatesFlow.push(emailTemplateData);
            }
            setTemplatesFlow(newTemplatesFlow);
            form.setFieldsValue({
              emailTemplateFlow: JSON.stringify(newTemplatesFlow),
            });
            setTemplateFormVisible(-1);
            setTemplatesError("");
          }}
        />
      </div>
    </Form>
  );
};

SecondForm.propTypes = {
  options: array,
  setSelectedOption: func,
  toggleVisibleComponent: func,
  setOptions: func,
  contractName: string,
  stepType: string,
  invisibleFormFields: array,
  children: any,
  setButtonHidden: func,
  companyName: string,
  platform: string,
  link: string,
  emailTemplates: object,
  addEmailTemplate: func,
  existingOptionIndex: number,
  formPreSubmit: func,
};

const initialStartingComponent = 2; //show the creation form first

function StepCreationForm({
  options,
  setOptions,
  setSelectedOption,
  name,
  loading,
  onSwitch,
  contractName,
  stepType,
  children,
  invisibleFormFields,
  visible,
  companyName,
  platform,
  link,
  emailTemplates,
  addEmailTemplate,
  onExistingFieldSelect,
  formPreSubmit,
}) {
  const [buttonHidden, setButtonHidden] = useState(false);
  const [initialOnSwitch, setInitialOnSwitch] = useState(false);
  const [existingOptionIndex, setExistingOptionIndex] = useState(-1);

  useEffect(() => {
    if (visible && !initialOnSwitch) {
      // since the initial starting component is the 2nd form,
      // indicate to the higher level form that the second form is showing so that it can hide the "Next" button
      onSwitch(initialStartingComponent);
      setInitialOnSwitch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, initialOnSwitch]);

  const _onSwitch = newActiveComponent => {
    if (newActiveComponent === 1) {
      setExistingOptionIndex(-1);
    }
    onSwitch(newActiveComponent);
  };

  return (
    <TwoComponentSwitch
      buttonHidden={buttonHidden}
      defaultComponent={initialStartingComponent}
      className={styles.fullWidth}
      activateFirstText="Select Existing Step"
      activateSecondText="Create New Step"
      onSwitch={_onSwitch}
      firstComponent={
        <FirstForm
          options={options}
          setSelectedOption={setSelectedOption}
          name={name}
          loading={loading}
          setExistingOptionIndex={setExistingOptionIndex}
          onExistingFieldSelect={onExistingFieldSelect}
        />
      }
      secondComponent={
        <SecondForm
          options={options}
          setOptions={setOptions}
          setSelectedOption={setSelectedOption}
          contractName={contractName}
          stepType={stepType}
          invisibleFormFields={invisibleFormFields}
          setButtonHidden={setButtonHidden}
          companyName={companyName}
          platform={platform}
          link={link}
          emailTemplates={emailTemplates}
          addEmailTemplate={addEmailTemplate}
          existingOptionIndex={existingOptionIndex}
          formPreSubmit={formPreSubmit}
        >
          {children}
        </SecondForm>
      }
    />
  );
}

StepCreationForm.propTypes = {
  options: array,
  setOptions: func,
  setSelectedOption: func,
  name: string,
  loading: bool,
  onSwitch: func,
  contractName: string,
  stepType: string,
  invisibleFormFields: array,
  children: any,
  visible: bool,
  companyName: string,
  platform: string,
  link: string,
  emailTemplates: object,
  addEmailTemplate: func,
  onExistingFieldSelect: func,
  formPreSubmit: func,
};

export default StepCreationForm;
