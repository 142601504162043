import React, { useState, useEffect } from "react";
import { Form, Space, Button } from "antd";
import { any, bool, func, object, string } from "prop-types";
import styles from "./AddNewCampaign.module.css";
import {
  defaultFormItemLayout,
  defaultButtonWrapperCol,
} from "../../../utils/formLayout";
import RequirementsForm from "./RequirementsForm";

function RequirementsStep({
  form,
  onFormDisabled,
  requirements,
  loading,
  categories,
  contractName,
}) {
  const [requirementOptions, setRequirementOptions] = useState([]);
  const [showingNextButton, setShowingNextButton] = useState(true);

  useEffect(() => {
    if (requirements) {
      setRequirementOptions(
        Object.values(requirements).map(requirement => {
          return {
            value: JSON.stringify(requirement),
            label: requirement.name,
          };
        })
      );
    }
  }, [requirements]);

  const toggleSubmitButton = activeComponent => {
    //if the product creation form is showing, hide the top-level form submission button
    setShowingNextButton(activeComponent === 1);
    if (onFormDisabled) {
      //indicate to the top level parent that this form is disabled
      onFormDisabled(activeComponent === 2);
    }
  };

  return (
    <>
      <Form
        className={styles.formContainer}
        name="requirementsForm"
        {...defaultFormItemLayout}
        labelAlign="right"
        layout="horizontal"
        form={form}
      >
        <Space direction="vertical" className={styles.form} size="large">
          <RequirementsForm
            onSwitch={toggleSubmitButton}
            form={form}
            loading={loading}
            name="campaignRequirements"
            options={requirementOptions}
            setOptions={setRequirementOptions}
            setSelectedOption={option => {
              form.setFieldsValue({ campaignRequirements: option.value });
            }}
            categories={categories}
            contractName={contractName}
          />
          {showingNextButton && (
            <Form.Item
              className={styles.buttonsContainer}
              wrapperCol={defaultButtonWrapperCol}
            >
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
          )}
        </Space>
      </Form>
    </>
  );
}

RequirementsStep.propTypes = {
  companyId: string,
  form: any,
  onFormDisabled: func,
  requirements: object,
  loading: bool,
  categories: object,
  contractName: string,
};

export default RequirementsStep;
