import React, { useState } from "react";
import { Form, Select, Button } from "antd";
import { array, bool, number, string } from "prop-types";
import {
  WarningOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { selectFilter } from "../../utils/filters";
import styles from "./Test.module.css";
import { defaultButtonWrapperCol } from "../../utils/formLayout";
import { usePushEmailGroups } from "../../api/emailGroups";

function JoinForm({
  loading,
  options,
  buttonDisabled,
  disabled,
  userId,
  showLink,
  selectedWoodpeckerCampaignId,
}) {
  const joinLink = `${process.env.REACT_APP_INFLUENCER_BRAND_HOST}influencer/join?_u_id=${userId}`;
  const pushEmailGroups = usePushEmailGroups();

  const { isError, isLoading, error } = pushEmailGroups;

  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const sendJoin = async () => {
    setSuccess(false);
    setFailure(false);
    const joinEmailGroupPushRequest = {
      type: "join",
      woodpeckerCampaigns: {
        [selectedWoodpeckerCampaignId]: 1,
      },
      userIds: [userId],
    };
    const joinResponse = await pushEmailGroups.mutateAsync(
      joinEmailGroupPushRequest
    );
    if (
      joinResponse?.woodpeckerCampaigns[selectedWoodpeckerCampaignId]
        .emailJobLog
    ) {
      const { emailJobLog } =
        joinResponse.woodpeckerCampaigns[selectedWoodpeckerCampaignId];
      if (emailJobLog.successes >= emailJobLog.failures) {
        setSuccess(true);
      } else {
        setFailure(true);
      }
    }
  };

  return (
    <>
      <Form.Item label="Join">
        <Form.Item name="joinWoodpeckerCampaignId" className={styles.formItem}>
          <Select
            disabled={disabled}
            filterOption={selectFilter}
            loading={loading}
            showSearch
            className={styles.fullWidth}
            placeholder="Woodpecker Campaign ID"
            options={options}
          />
        </Form.Item>
        {showLink && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
            href={joinLink}
          >
            {joinLink}
          </a>
        )}
      </Form.Item>
      <Form.Item wrapperCol={defaultButtonWrapperCol}>
        <Button
          loading={isLoading}
          disabled={disabled || buttonDisabled}
          onClick={sendJoin}
          type="primary"
        >
          Send Join
        </Button>
        {isError && (
          <h4 className={styles.error}>
            <WarningOutlined fill="red" /> {error.message}
          </h4>
        )}
        {success && (
          <h4 className={styles.success}>
            <CheckOutlined fill="green" /> Success
          </h4>
        )}
        {failure && (
          <h4 className={styles.error}>
            <CloseOutlined fill="red" /> Failure
          </h4>
        )}
      </Form.Item>
    </>
  );
}

JoinForm.propTypes = {
  loading: bool,
  options: array,
  buttonDisabled: bool,
  disabled: bool,
  showLink: bool,
  userId: string,
  selectedWoodpeckerCampaignId: number,
};

export default JoinForm;
