export const camelCaseToSentenceCase = str =>
  str
    .replace(/[_-]([^_-])/g, function ($0, $1) {
      return $1.toUpperCase();
    }) //convert underscore to sentence
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase())
    .trim(); //convert str = 'lastName' to 'Last Name'

export const numberToCurrency = num =>
  `$${num
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`;

//converts normal string number to a monetery value string, e.g. converting "100000" to "$ 100,000"
export const formatToMoney = value => {
  return `$${Number(value).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//parses number from monetery value string, e.g. converting "$ 100,000" to "100000"
export const parseMoneyString = value => {
  return value.replace(/\$\s?|(,*)/g, "");
};

export const camelize = str => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const toTitleCase = str => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const minifyHtml = html => {
  return html
    .replace(/\r\n|\n|\t/g, " ")
    .replace(/> *</g, "><")
    .replace(/"/g, "'");
};
