import React, { useEffect, useState } from "react";
import {
  Space,
  DatePicker,
  Input,
  Form,
  Button,
  InputNumber,
  Alert,
  Checkbox,
} from "antd";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { WarningOutlined } from "@ant-design/icons";
import styles from "./AddNewContract.module.css";
import ContractHeader from "../ContractHeader/ContractHeader";
import CompanyForm from "./CompanyForm";
import { useCompanies } from "../../../api/companies";
import { useB2CUsers } from "../../../api/b2cUsers";
import UsersForm from "./UsersForm";
import { useCreateContract } from "../../../api/contracts";
import {
  defaultButtonWrapperCol,
  defaultFormItemLayout,
} from "../../../utils/formLayout";
import {
  camelize,
  formatToMoney,
  parseMoneyString,
} from "../../../utils/stringUtils";
import ImageUpload from "../../shared/ImageUpload";
import BillingContractForm from "./BillingContractForm";
import {
  useBillingContracts,
  useCreateBillingContract,
} from "../../../api/billingContract";
import { toCalendarDate } from "../../../utils/dates";

const { RangePicker } = DatePicker;

function AddNewContract() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [contractName, setContractName] = useState("");

  const {
    isLoading: isLoadingBillingContracts,
    data: billingContracts,
    isError: isBillingContractsError,
  } = useBillingContracts({
    includes: ["contracts"],
  });

  const {
    isLoading: isLoadingCompanies,
    data: companies,
    isError: isCompaniesError,
  } = useCompanies({
    params: { sortColumn: "companies.name", sortDirection: "asc" },
  });

  const {
    isLoading: isLoadingUsers,
    data: users,
    isError: isUsersError,
  } = useB2CUsers({
    params: { emailOnly: true, role: "client" },
  });

  const [userOptions, setUserOptions] = useState([]);
  const [companyCreationFormVisible, setCompanyCreationFormVisible] =
    useState(false);
  const [userCreationFormVisible, setUserCreationFormVisible] = useState(false);
  const [
    billingContractCreationFormVisible,
    setBillingContractCreationFormVisible,
  ] = useState(false);

  const [billingContractOptions, setBillingContractOptions] = useState([]);

  useEffect(() => {
    if (
      billingContracts?.length &&
      !isLoadingBillingContracts &&
      !isBillingContractsError
    ) {
      setBillingContractOptions(
        (billingContracts || []).map(b => {
          const label = `${b.relations.contracts
            .map(c => c.name)
            .join(",")} - ${b.billingContractId}`;
          return {
            label,
            value: JSON.stringify({ billingContractId: b.billingContractId }),
          };
        })
      );
    }
  }, [billingContracts, isLoadingBillingContracts, isBillingContractsError]);

  useEffect(() => {
    if (users && !isLoadingUsers && !isUsersError) {
      setUserOptions(
        users
          .map(u => ({
            value: u.email,
            label: `${u.email} - ${u.displayName}`,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    }
  }, [users, isLoadingUsers, isUsersError]);

  const [companyOptions, setCompanyOptions] = useState([]);

  useEffect(() => {
    if (companies && !isLoadingCompanies && !isCompaniesError) {
      setCompanyOptions(
        companies.map(c => ({
          label: c.name,
          value: JSON.stringify({ name: c.name, companyId: c.companyId }),
        }))
      );
    }
  }, [companies, isLoadingCompanies, isCompaniesError]);

  const handleContractNameGeneration = (startingDate, selectedCompany) => {
    if (startingDate && selectedCompany) {
      const companyData = JSON.parse(selectedCompany);
      const newContractName = generateContractName(
        companyData.name,
        startingDate.toDate()
      );
      setContractName(newContractName);
      form.setFieldsValue({
        contractName: newContractName,
      });
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.contractDates || changedValues.selectedCompany) {
      const { contractDates, selectedCompany } = allValues;
      const startingDate = contractDates[0];
      if (startingDate && selectedCompany) {
        handleContractNameGeneration(startingDate, selectedCompany);
      }
    }
  };

  const createContract = useCreateContract();

  const {
    isError: isContractCreationError,
    isLoading: isCreatingContract,
    error: contractCreationError,
  } = createContract;

  const createBillingContract = useCreateBillingContract();

  const onSubmit = async values => {
    const {
      selectedUsers,
      contractName: _contractName,
      contractDates,
      selectedCompany,
      heroImageUrl,
      fee,
      manualTarget,
      optinTarget,
      selectedBillingContract,
      throttle,
      selectLimit,
    } = values;
    const companyData = JSON.parse(selectedCompany);

    const newContract = {
      companyId: companyData.companyId,
      active: true,
      auth: selectedUsers,
      name: _contractName,
      startDate: toCalendarDate(contractDates[0]),
      endDate: toCalendarDate(contractDates[1]),
      heroImageUrl,
      fee: Number(fee),
      manualTarget,
      optinTarget,
      throttle,
      selectLimit,
    };

    if (companyData?.companyId) {
      newContract.companyId = companyData.companyId;
    } else {
      newContract.relations = {
        companies: {
          ...companyData,
        },
      };
    }

    // 3 cases for selectedBillingContract:
    // - undefined: no billing contract, use legacy billing
    // - string w/o billingContractId: create new billing contract
    // - string w/ billingContractId: use existing billing contract
    if (selectedBillingContract) {
      const selectedBillingContractData = JSON.parse(selectedBillingContract);
      if (selectedBillingContractData.billingContractId) {
        newContract.billingContractId =
          selectedBillingContractData.billingContractId;
      } else {
        const newBillingContract = await createBillingContract.mutateAsync({});
        newContract.billingContractId = newBillingContract.billingContractId;
      }
    }

    createContract.mutate(newContract, {
      onSuccess: data => {
        history.push(`/contracts/${data.contractId}`);
      },
    });
  };

  const onUploadImage = urls => {
    form.setFieldsValue({
      heroImageUrl: urls.fullImageUrl,
    });
  };

  const validateContractDates = value => {
    if (!value || !value[0] || !value[1]) {
      return Promise.reject(new Error("required"));
    }
    return Promise.resolve();
  };

  return (
    <>
      <ContractHeader />
      <Form
        {...defaultFormItemLayout}
        labelAlign="right"
        layout="horizontal"
        className={styles.formContainer}
        initialValues={{
          contractDates: [moment(), null],
          throttle: false,
          selectLimit: 1,
        }}
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onSubmit}
      >
        <Space direction="vertical" className={styles.form} size="large">
          <CompanyForm
            onSwitch={activeComponent =>
              setCompanyCreationFormVisible(activeComponent === 2)
            }
            loading={isLoadingCompanies}
            name="selectedCompany"
            options={companyOptions}
            setOptions={setCompanyOptions}
            setSelectedOption={selectedCompany => {
              form.setFieldsValue({ selectedCompany });
              const contractDates = form.getFieldValue("contractDates");
              const startingDate = contractDates[0];
              handleContractNameGeneration(startingDate, selectedCompany);
            }}
          />
          <UsersForm
            onSwitch={activeComponent =>
              setUserCreationFormVisible(activeComponent === 2)
            }
            loading={isLoadingUsers}
            name="selectedUsers"
            options={userOptions}
            setOptions={setUserOptions}
            addSelectedOption={user => {
              const existingSelectedUsers = form.getFieldValue("selectedUsers");
              if (existingSelectedUsers) {
                form.setFieldsValue({
                  selectedUsers: [...existingSelectedUsers, user],
                });
              } else {
                form.setFieldsValue({
                  selectedUsers: [user],
                });
              }
            }}
          />

          {/* <Text type="secondary">Starting Date</Text> */}
          <Form.Item
            label="Contract Dates"
            className={styles.formItem}
            name="contractDates"
            rules={[
              {
                required: true,
                validator: (rule, value) => validateContractDates(value),
              },
            ]}
          >
            <RangePicker />
          </Form.Item>

          {/* <Text type="secondary">Contract Name</Text> */}
          <Form.Item
            label="Contract Name"
            className={styles.formItem}
            name="contractName"
            rules={[
              {
                required: true,
                message: "required",
              },
            ]}
          >
            <Input
              placeholder="Type contract name"
              onChange={e => setContractName(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="Fee"
            name="fee"
            rules={[
              {
                required: true,
                message: "valid fee is required",
              },
            ]}
          >
            <InputNumber
              addonBefore="$"
              stringMode
              min="0"
              step="0.01"
              parser={parseMoneyString}
            />
          </Form.Item>

          <Form.Item
            label="Manual Target"
            name="manualTarget"
            rules={[
              {
                type: "number",
              },
            ]}
            extra="Only set this target if there are no Campaign level targets"
          >
            <InputNumber min="0" step="1" />
          </Form.Item>

          <Form.Item
            label="Opt-In Target"
            name="optinTarget"
            rules={[
              {
                type: "number",
              },
            ]}
            extra="Only set this target if the contract needs brand validation"
          >
            <InputNumber min="0" step="1" />
          </Form.Item>

          {contractName && (
            <>
              <Form.Item
                label="Hero Image"
                name="heroImageUrl"
                rules={[{ required: true, message: "required" }]}
              >
                <ImageUpload
                  imageSizeValidation={(width, height) =>
                    width >= 750 && width === height
                  }
                  altText="product"
                  errorMessage="Image dimensions must be at least 750x750 pixels and must be a square (equal width and height)"
                  imageName={`${camelize(contractName)}_hero`}
                  onUpload={onUploadImage}
                  containerName="contract-images"
                />
              </Form.Item>
            </>
          )}

          <BillingContractForm
            onSwitch={activeComponent =>
              setBillingContractCreationFormVisible(activeComponent === 2)
            }
            loading={isLoadingBillingContracts}
            name="selectedBillingContract"
            options={billingContractOptions}
            contractName={contractName}
            setOptions={setBillingContractOptions}
            setSelectedOption={selectedBillingContract => {
              console.log({ selectedBillingContract });
              form.setFieldsValue({ selectedBillingContract });
            }}
          />

          <Form.Item name="throttle" valuePropName="checked" label="Throttle">
            <Checkbox />
          </Form.Item>

          <Form.Item
            name="selectLimit"
            label="Select Limit"
            extra="Only change this if influencers may select more than 1 campaign from this contract"
          >
            <InputNumber min={1} step={1} />
          </Form.Item>

          {!companyCreationFormVisible &&
            !userCreationFormVisible &&
            !billingContractCreationFormVisible && (
              <Form.Item wrapperCol={defaultButtonWrapperCol}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isCreatingContract}
                >
                  Submit
                </Button>
              </Form.Item>
            )}
          {isContractCreationError && (
            <h4 className={styles.error}>
              <WarningOutlined fill="red" /> {contractCreationError.message}
            </h4>
          )}
        </Space>
      </Form>
    </>
  );
}

const generateContractName = (companyName, startingDate) => {
  const startingDateQuarter = Math.floor((startingDate.getMonth() + 3) / 3);
  return `${companyName} Q${startingDateQuarter} ${startingDate.getUTCFullYear()}`;
};

export default AddNewContract;
