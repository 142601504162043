import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { USERS_PATH } from "./users";
import {
  createAuthenticatedRequest,
  defaultMutationOptions,
  defaultQueryOptions,
  executeRequest,
} from "./utils";

export const EMAIL_GROUPS_PATH = "emailGroups";

export function useEmailGroups({
  emailGroupId,
  includes,
  params,
  ...overrideOptions
}) {
  const dispatch = useDispatch();

  const path = emailGroupId
    ? `${EMAIL_GROUPS_PATH}/${emailGroupId}`
    : EMAIL_GROUPS_PATH;

  return useQuery(
    [path, includes, params],
    () => fetchEmailGroups(path, dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for email groups
export async function fetchEmailGroups(path, dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    path,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}

export function usePushEmailGroups() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(body => pushEmailGroups(body, dispatch), {
    onSuccess: () => {
      queryClient.invalidateQueries(USERS_PATH);
    },
    ...defaultMutationOptions,
  });
}

// Execute a POST to push users to woodpecker campaigns
export async function pushEmailGroups(body, dispatch) {
  const requestObject = await createAuthenticatedRequest(
    `${EMAIL_GROUPS_PATH}/push`,
    dispatch,
    {
      method: "POST",
      body: JSON.stringify(body),
    }
  );

  return executeRequest(requestObject);
}
