import moment from "moment";
import React, { useEffect, useState } from "react";
import "./EmailJobLogs.less";
import { Typography, Badge, Empty, Space } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
//redux
import { useDispatch, useSelector } from "react-redux";
import { getEmailJobLogs } from "../../../redux/invites/invitesActions";
import { copyLogsToForm } from "../../../redux/invites/sendInvitesActions";
//react router
import { useHistory } from "react-router-dom";
//components
import DataTable from "../../shared/DataTable/DataTable";
import TagQueryStyledTags from "../TagQueryStyledTags";
import WoodpeckerTag from "../WoodpeckerTag";
//utils
import { getColumnsFromDataSource } from "../../shared/DataTable/utils";

const { Text, Link } = Typography;

export default function EmailJobLogs() {
  const dispatch = useDispatch();

  //router
  const history = useHistory();

  // get 1st page on load
  useEffect(() => {
    dispatch(getEmailJobLogs(1));
  }, [dispatch]);

  //selectors
  //array, 0 = date, 1 = array of logs
  const data = useSelector(
    state => state.invites.logs && Object.entries(state.invites.logs)
  );
  const logsPage = useSelector(state => state.invites.logsPage);

  //only render if data loaded
  if (!data) return null;

  for (const arr of data) {
    for (const log of arr[1]) {
      if (log?.type === "invite" && log?.relations?.inviteGroups?.name) {
        log.name = log.relations.inviteGroups.name;
      }
    }
  }

  return (
    <InfiniteScroll
      style={{ overflow: "none" }}
      dataLength={logsPage * 50}
      next={() => {
        dispatch(getEmailJobLogs(logsPage + 1));
      }}
      hasMore //user probably wont reach end of list
    >
      {data &&
        data.map(logsGroupedByDate => (
          <Badge.Ribbon
            key={`${logsGroupedByDate[0]}${logsGroupedByDate[1].length}`}
            text="New"
            style={
              //show new ribbon badge if job date is less than 1 hour old
              moment(logsGroupedByDate[0]).isBefore(
                moment().subtract(1, "hours")
              ) && { display: "none" }
            }
          >
            <DataTable
              key={`${logsGroupedByDate[0]}${logsGroupedByDate[1].length}`}
              rowClassName="compact-row"
              title={() => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text strong>
                    {moment(logsGroupedByDate[0]).format("LLLL")}
                  </Text>
                  {/* get first object tagQuery */}
                  {logsGroupedByDate[1][0].tagQuery === "manual" ? (
                    <Text strong>MANUAL</Text>
                  ) : (
                    <TagQueryStyledTags
                      tagQuery={logsGroupedByDate[1][0].tagQuery}
                    />
                  )}
                </div>
              )}
              size="small"
              dataSource={logsGroupedByDate[1]}
              rowKey={record => `${logsGroupedByDate[0]}${record.jobId}`}
              columns={getColumnsFromDataSource(
                logsGroupedByDate[1],
                //column names
                [
                  "name",
                  "jobId",
                  "requested",
                  "scheduled",
                  "successes",
                  "failures",
                ],
                //column props
                {
                  requested: {
                    title: (
                      <Link
                        onClick={() =>
                          dispatch(
                            copyLogsToForm(logsGroupedByDate[0], "requested")
                          )
                        }
                        copyable
                      >
                        Requested
                      </Link>
                    ),
                  },
                  scheduled: {
                    title: (
                      <Link
                        onClick={() =>
                          dispatch(
                            copyLogsToForm(logsGroupedByDate[0], "scheduled")
                          )
                        }
                        copyable
                      >
                        Scheduled
                      </Link>
                    ),
                  },
                  name: {
                    width: "256px",
                    title: "Invite Group Name",
                    // // eslint-disable-next-line react/display-name
                    // render: (text, record) => (
                    //   <Link
                    //     onClick={() =>
                    //       history.push(`/invites/${record.contractId}`)
                    //     }
                    //   >
                    //     {text}
                    //   </Link>
                    // ),
                  },
                  jobId: {
                    title: "Woodpecker ID",
                    // eslint-disable-next-line react/display-name
                    render: text => (
                      <WoodpeckerTag woodpeckerCampaignId={text} />
                    ),
                  },
                  failures: {
                    // eslint-disable-next-line react/display-name
                    render: text =>
                      text > 0 ? (
                        <Text strong type="danger">
                          {text}
                        </Text>
                      ) : (
                        text
                      ),
                  },
                }
              )}
              onHeaderRow={() => ({
                className: "compact-row",
              })}
              pagination={false}
            />
          </Badge.Ribbon>
        ))}
    </InfiniteScroll>
  );
}
