import React from "react";
import { Select } from "antd";
import { string, func } from "prop-types";

export default function StateFilter({ filter, setFilter }) {
  return (
    <Select
      value={filter}
      onChange={val => setFilter(val)}
      style={{ width: 150 }}
      options={[
        {
          label: "all",
          value: "none",
        },
        {
          label: "not disqualified",
          value: "not_disqualified",
        },
        {
          label: "in review",
          value: "in_review",
        },
        {
          value: "active",
        },
        {
          value: "closing",
        },
        {
          value: "paused",
        },
        {
          value: "done",
        },
        {
          value: "disqualified",
        },
      ]}
    />
  );
}

StateFilter.propTypes = {
  filter: string,
  setFilter: func,
};
