import React, { useState } from "react";
import { DollarOutlined } from "@ant-design/icons";
import { Form, Button, Modal, Input, InputNumber, Select } from "antd";
//redux
import { useDispatch, useSelector } from "react-redux";
import { submitSendPayout } from "../../../redux/posts/postActions";
//utils
import checkAuth from "../../../utils/checkAuth";
import { camelCaseToSentenceCase } from "../../../utils/stringUtils";

const { Option } = Select;
const payoutTypeOptions = ["product_fees", "product_difference"];

export default props => {
  const { post } = props;

  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);

  //redux
  const dispatch = useDispatch();
  const roles = useSelector(state => state.auth.roles);

  if (!post || !checkAuth.has(roles, ["supervisor"])) return null;

  const { payouts } = post.relations;
  const productPayout = payouts.find(p => p.payoutType === "product");
  //TODO: send from same payoutAccountid as product - for now send from account 1
  const payoutAccountId = 6;

  const onSubmit = values => {
    //form values to submit
    const { payoutType, paypalEmail, payout } = values;
    //onboard values to submit
    const { userId, onboardId } = post;
    dispatch(
      submitSendPayout({
        userId,
        onboardId,
        payoutAccountId,
        //form
        paypalEmail,
        payoutType,
        payout,
      })
    );
    setVisible(false);
  };
  return (
    <span>
      <Button
        type="primary"
        key="close"
        icon={<DollarOutlined />}
        onClick={() => setVisible(true)}
      >
        {props.text}
      </Button>

      <Modal
        title={`Send automated payout to ${post.igHandle}`}
        visible={visible}
        okText="Submit"
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              onSubmit(values);
            })
            .catch(e => null); //do nothing on validate fail
        }}
        // confirmLoading={confirmLoading}
        onCancel={() => {
          //close and reset input
          setVisible(false);
        }}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="payoutType"
            label="Payout Type"
            initialValue="product_fees"
            required
          >
            <Select>
              {payoutTypeOptions.map(payoutType => (
                <Option key={payoutType} value={payoutType}>
                  {camelCaseToSentenceCase(payoutType)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            required
            name="paypalEmail"
            initialValue={productPayout.paypalEmail}
            label="Paypal Email"
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            required
            name="payout"
            label={
              <span>
                Payout from Account: <b>{payoutAccountId}</b>
              </span>
            }
          >
            <InputNumber min={0} max={50} />
          </Form.Item>
          <Form.Item
            name="confirmPayout"
            label="Confirm Payout amount"
            dependencies={["payout"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm the dollar amount to payout",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("payout") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    "The two payout amounts that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <InputNumber min={0} max={50} />
          </Form.Item>
        </Form>
      </Modal>
    </span>
  );
};
