/**
 * index.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code
 */

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
//Redux imports below
import storeConfig from "./redux/storeConfig";
import { getAuth } from "./redux/auth/authActions";

//config redux
const store = storeConfig();
store.dispatch(getAuth()); // loads user data
const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);
