import React from "react";

//react router
import { useHistory } from "react-router-dom";
/* CAMPAIGN DESCRIPTION TAB */

const CampaignId = text => {
  const history = useHistory();
  return (
    //eslint-disable-next-line
    <a
      onClick={() => history.push(`/campaigns/${text}`)}
      className="ant-breadcrumb-link"
    >
      {text}
    </a>
  );
};

const ContractId = text => {
  const history = useHistory();
  return (
    //eslint-disable-next-line
    <a
      onClick={() => history.push(`/contracts/${text}`)}
      className="ant-breadcrumb-link"
    >
      {text}
    </a>
  );
};

export default [
  //row 1
  [
    "campaignId",
    {
      render: CampaignId,
    },
  ],
  ["campaignName", { path: "name" }],
  ["productName", { path: "relations.products.name" }],
  [
    "productPrice",
    { render: text => `$${text}`, path: "relations.products.defaultPrice" },
  ],
  //row 2
  [
    "contractId",
    {
      render: ContractId,
    },
  ],
  [
    "productCode",
    {
      render: function ProductCode(text) {
        return (
          <a
            href={`https://amazon.com/dp/${text}`}
            target="_blank"
            rel="noreferrer"
          >
            {text}
          </a>
        );
      },
      path: "relations.products.code",
    },
  ],
  ["target"],
  [
    "link",
    {
      render: function Link(text) {
        return (
          <a href={text} target="_blank" rel="noreferrer">
            {text}
          </a>
        );
      },
    },
  ],
];
