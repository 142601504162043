import React from "react";
import { Layout, Menu } from "antd";
import { Link, useParams, useHistory } from "react-router-dom";
import logo from "../../../assets/logoWhite.svg";
import logoIconOnly from "../../../assets/icon.svg";
import "./SiderMenu.less";
//redux auth
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
//utils
import checkAuth from "../../../utils/checkAuth";
//constants
import { ROUTES, routerPaths } from "../../../AppRouter";

const { Sider } = Layout;
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); //if Mobile

let collapseTimeoutID; //animation timeout

//render routes based on user roles
const selectRoles = state => state.auth.roles;
const selectRoutesToRender = createSelector(selectRoles, roles =>
  routerPaths.reduce((accum, path) => {
    //check user auth
    if (checkAuth.has(roles, ROUTES[path].roles))
      accum.push(
        <Menu.Item key={path}>
          <Link to={path}>
            {ROUTES[path].icon}
            <span className="nav-text">{ROUTES[path].title}</span>
          </Link>
        </Menu.Item>
      ); //dynamically render ROUTES
    return accum;
  }, [])
);

function SiderMenu(props) {
  const history = useHistory();

  //select
  const routesToRender = useSelector(state => selectRoutesToRender(state));

  return (
    <Sider
      onMouseEnter={() => {
        clearTimeout(collapseTimeoutID);
        collapseTimeoutID = setTimeout(() => {
          props.setCollapsed(false);
        }, 800);
      }}
      onMouseLeave={() => {
        clearTimeout(collapseTimeoutID);
        collapseTimeoutID = setTimeout(() => {
          props.setCollapsed(true);
        }, 500);
      }}
      breakpoint="lg"
      collapsedWidth={isMobile ? 0 : 80}
      trigger={null}
      collapsible
      collapsed={props.collapsed} //passed down from AppLayout
    >
      <div className="logo" onClick={() => history.push(`/`)}>
        <img
          src={logoIconOnly}
          className={`logo ${props.collapsed ? "fade-in" : "fade-out"}`}
        />
        <img
          src={logo}
          className={`logo ${props.collapsed ? "fade-out" : "fade-in"}`}
        />
      </div>
      <Menu
        onClick={() => props.setCollapsed(true)}
        theme="dark"
        mode="inline"
        selectedKeys={["/" + (useParams().filter || "")]} //selected keys based on url params
      >
        {
          //dynamically display from ROUTES object
          routesToRender
        }
      </Menu>
    </Sider>
  );
}

export default SiderMenu;
