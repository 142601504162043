import React from "react";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import "./Header.less";
//redux
import { useSelector } from "react-redux";
//components
import HeaderSearch from "../../Posts/Search/HeaderSearch";
import HeaderAvatarDropdown from "./HeaderAvatarDropdown";
//utils
import checkAuth from "../../../utils/checkAuth";

const { Header } = Layout;

export default function (props) {
  //select
  const roles = useSelector(state => state.auth.roles);

  return (
    <Header className="header">
      <div
        className="action"
        onClick={() => props.setCollapsed(!props.collapsed)}
      >
        {props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
      <div className="right">
        {checkAuth.has(roles, ["manager"]) && <HeaderSearch />}
        <HeaderAvatarDropdown />
      </div>
    </Header>
  );
}
