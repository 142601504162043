import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { PageHeader, Button } from "antd";
import moment from "moment";
//router
import { useHistory } from "react-router-dom";
import { any, func, object } from "prop-types";
import { getBreadcrumbProps } from "../../../AppRouter";
//components
import ContractByIdStats from "../../Contracts/ContractHeader/ContractByIdStats";
import GoToSendInvitesButton from "./GoToSendInvitesButton";
import { displayDate } from "../../../utils/dates";

function InviteGroupsHeader({ contract, setVisible, additionalAction }) {
  //for breadcrumbs
  const history = useHistory();

  const extraButtons = [
    <Button
      icon={<PlusOutlined />}
      key="newInvite"
      type="secondary"
      onClick={
        () => setVisible(true) //open new invite group modal
      }
    >
      Add Invite Group to Contract
    </Button>,
    <GoToSendInvitesButton key="sendInvites" />,
  ];

  if (additionalAction) {
    extraButtons.unshift(additionalAction);
  }

  return (
    <div className="main-no-padding border">
      <PageHeader
        ghost={false}
        breadcrumb={getBreadcrumbProps(history.location)} //see https://ant.design/components/breadcrumb/#Use-with-browserHistory
        title={`${contract.name} - Invite Groups`}
        subTitle={displayDate(contract.startDate).format("MMM Do YYYY")}
        extra={extraButtons}
      >
        <ContractByIdStats contract={contract} />
      </PageHeader>
    </div>
  );
}

InviteGroupsHeader.propTypes = {
  contract: object,
  setVisible: func,
  additionalAction: any,
};

export default InviteGroupsHeader;
