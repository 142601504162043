import React from "react";
import { Result, Button } from "antd";
//redux
import { useSelector } from "react-redux";

//see Result component docs https://ant.design/components/result/#API
export default props => {
  const result = useSelector(state => state.shared.result);
  //default to 404
  if (!result || typeof result !== "object")
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button onClick={props.goBack} type="primary">
            Back
          </Button>
        }
      />
    );
  //from store.shared.result
  return (
    <Result
      status={result.status || "404"}
      title={result.title || "404"}
      subTitle={
        result.subTitle || "Sorry, the page you visited does not exist." //404 default
      }
      extra={
        result.extra || (
          <Button onClick={props.goBack} type="primary">
            Refresh
          </Button>
        )
      }
    />
  );
};
