import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  createAuthenticatedRequest,
  defaultQueryOptions,
  executeRequest,
} from "./utils";

export const USER_STATES_PATH = "userStates";

export function useUserStates({ includes, params, ...overrideOptions }) {
  const dispatch = useDispatch();

  return useQuery(
    [USER_STATES_PATH, includes, params],
    () => fetchUserStates(dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for user state
export async function fetchUserStates(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    USER_STATES_PATH,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}
