import React, { useMemo } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./App.less";
//router
import { HashRouter, Route } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";
//components
import Router from "./AppRouter";
import AppLayout from "./components/AppLayout/AppLayout";

//TODO: Standardize derived state selectors with 'reselect' using PreviewForm.js as an example
//TODO: Standardize redux state as map of primary keys, use invites as example
//TODO: For each action, check if still loading via same loading ID state exists before dispatching again

//Invites TODO:
// make the woodpecker stats the combined sum of all campaigns. Currently only first campagin stats include
// shared validation function for tagQueries and render preview in 1. NewInviteGroupModal and 2. Preview
// logs button for contracts and inviteGroups data table, that find email jobs based on ID
// When submit successful go to logs section
// Invite Groups Header - Toggle Stats button that will replace all columns except woodpecker
//  campaign name, with a dashboard (fit 1 summary, 2 charts horizontally) using emailJobLogs and metabase
// TODO: REMOVE FORCE - add in preview tab a Force parameter before checkbox()

const App = props => {
  //performance boost see: https://reactjs.org/docs/hooks-faq.html#how-to-memoize-calculations
  //never rerender
  const memoizedAppLayout = useMemo(
    () => (
      <HashRouter>
        <Route path="/:filter?">
          <AppLayout>
            <Router />
          </AppLayout>
        </Route>
      </HashRouter>
    ),
    []
  );

  //if true, show spinner in center of screen
  const isLoading = useSelector(state => state.shared.isLoading);

  //don't load app until user authenticated
  const user = useSelector(state => state.auth && state.auth.user);
  if (!user)
    return (
      <div>
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
          className="centered"
        />
      </div>
    );

  return (
    <div>
      {isLoading && (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 72 }} spin />}
          className="centered"
        />
      )}
      {memoizedAppLayout}
    </div>
  );
};

export default App;
