export const defaultFormItemLayout = {
  labelCol: {
    xs: { span: 8 },
    md: { span: 6 },
    lg: { span: 4 },
  },
  // wrapperCol: {
  //   xs: { span: 6 },
  //   sm: { span: 4 },
  // },
};

export const defaultButtonWrapperCol = {
  sm: { offset: 8 },
  md: { offset: 6 },
  lg: { offset: 4 },
};

export const miniFormItemLayout = {
  labelCol: {
    xs: { offset: 2 },
    lg: { offset: 1 },
  },
  wrapperCol: {
    xs: { offset: 2 },
    lg: { offset: 0 },
  },
};
