import React, { useEffect } from "react";
import { Descriptions, Typography } from "antd";
//redux
import { useDispatch, useSelector } from "react-redux";
import { getBalances } from "../../../redux/payouts/serviceActions";
//utils
import { numberToCurrency } from "../../../utils/stringUtils";

const { Text } = Typography;

function formattedBalance(entryValue) {
  const amount = Number(entryValue.available_balance.value);
  const formattedAmount = numberToCurrency(amount);
  //highlight in red if less than
  return formattedAmount;
  // return amount < 1000 ? (
  //   <Text strong type="danger">
  //     {formattedAmount}
  //   </Text>
  // ) : (
  //   formattedAmount
  // );
}

export default function (props) {
  //redux
  const dispatch = useDispatch();
  const balances = useSelector(state => state.payouts.balances);

  //get balances on mount
  useEffect(() => {
    if (!balances) dispatch(getBalances());
  }, [balances]);

  //convert {1: object,...} to nested array [[1,object],...]
  const entries = balances && Object.entries(balances);
  if (!entries || !entries.length) return null;

  return (
    <Descriptions size="small" column={5}>
      {entries.map(entry => (
        <Descriptions.Item key={entry[0]} label={entry[0]}>
          {formattedBalance(entry[1])}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
}
