import { message } from "antd";
//redux
import { result } from "../shared/sharedActions";
import {
  getInviteGroupById,
  pushUsersToEmailGroup,
  getJoinEmailGroup,
  newWoodpeckerCampaignJoin,
  putEmailGroup,
  pushInvites,
} from "./invitesActions";
//utils
import { isUuid } from "../../utils/regexUtils";
import { readCsvFile } from "../../utils/csvUtils";

export const types = {
  UPDATE_SEND_STATE: "UPDATE_SEND_STATE", //updates by spreading action value
  RESET_SEND_STATE: "RESET_SEND_STATE", //resets inviteGroupSelections and send to default
  SET_INVITE_GROUP_SELECTIONS: "SET_INVITE_GROUP_SELECTIONS", //array of selected invite groups
  PREVIEW_INPUT_OUTPUT: "PREVIEW_INPUT_OUTPUT", //input and output of POST api/invites?preview=true
  UPDATE_TYPE_STATE: "UPDATE_TYPE_STATE", //to set either invite or join type for invites
};

/*
 * ACTIONS
 */

export const updateSendState = update => {
  return { type: types.UPDATE_SEND_STATE, update };
};

export const resetSendState = () => {
  return { type: types.RESET_SEND_STATE };
};

export const updateTypeState = data => {
  return { type: types.UPDATE_TYPE_STATE, data };
};

export const previewInputOutputEmailGroups = data => {
  if (data.output) {
    //transform preview output. original format: {woodpeckerCampaigns: {name, numberOfEmails}}
    const transformedOutput = {};
    //count output
    let outputCount = 0;
    for (const [woodpeckerCampaignId, value] of Object.entries(
      data.output.woodpeckerCampaigns
    )) {
      const { numberOfEmails } = value;
      transformedOutput[woodpeckerCampaignId] = numberOfEmails;
      outputCount += numberOfEmails;
    }
    data.outputCount = outputCount;
    data.output.woodpeckerCampaigns = transformedOutput;
  }
  return { type: types.PREVIEW_INPUT_OUTPUT, data };
};

export const previewInputOutputInvites = data => {
  if (data.output) {
    //transform preview output. original format: {woodpeckerCampaigns: {name, numberOfEmails}}
    const transformedOutput = {};
    //count output
    let outputCount = 0;
    for (const [inviteGroupId, value] of Object.entries(
      data.output.inviteGroups
    )) {
      const { numberOfInvites } = value;
      transformedOutput[inviteGroupId] = numberOfInvites;
      outputCount += numberOfInvites;
    }
    data.outputCount = outputCount;
    data.output.woodpeckerCampaigns = transformedOutput;
  }
  return { type: types.PREVIEW_INPUT_OUTPUT, data };
};

export const setInviteGroupSelections =
  inviteGroupSelections => async (dispatch, getState) => {
    const { data, send } = getState().invites;
    for (const inviteGroupId of inviteGroupSelections) {
      //fetch if selected ID is not in store
      if (!data[inviteGroupId]) dispatch(getInviteGroupById(inviteGroupId));
    }
    //if there is a selection set sendInvites tab to form
    if (inviteGroupSelections.length && send.tab !== "form")
      dispatch(updateSendState({ tab: "form" }));

    return dispatch({
      type: types.SET_INVITE_GROUP_SELECTIONS,
      inviteGroupSelections,
    });
  };

export const copyLogsToForm =
  (createdAtDate, propertyToCopy) => async (dispatch, getState) => {
    //get logs from state
    const logs = getState().invites.logs[createdAtDate];

    //loop through all logs and set unique invite groups to load
    const inviteGroupIdUnique = new Set();

    //send state update - change form + woodpeckerCampaigns
    const sendStateUpdate = { tab: "form" };

    for (const log of logs) {
      inviteGroupIdUnique.add(log.inviteGroupId); //load data
      sendStateUpdate[log.jobId] = log[propertyToCopy]; //woodpecker # of invites
      //override if different than default
      if (log.tagQuery !== log.defaultTagQuery)
        sendStateUpdate.tagQuery = log.tagQuery;
    }

    const inviteGroupSelections = Array.from(inviteGroupIdUnique);

    dispatch(resetSendState());
    dispatch(setInviteGroupSelections(inviteGroupSelections));
    dispatch(updateSendState(sendStateUpdate));
  };

const setWoodpeckerPreviewValues = (send, preview, woodpeckerCampaigns) => {
  for (const campaign of woodpeckerCampaigns) {
    const { woodpeckerCampaignId } = campaign;
    const woodpeckerFormValue = send[woodpeckerCampaignId];
    //inc count
    preview.inputCount += woodpeckerFormValue || 0;
    //req.body
    if (woodpeckerFormValue > 0) {
      preview.input.woodpeckerCampaigns[woodpeckerCampaignId] =
        woodpeckerFormValue;
    }
  }
};

export const submitInvitesPreview = () => async (dispatch, getState) => {
  //assemble POST body from shared redux state
  const { send, inviteGroupSelections, data, joinEmailGroup, type } =
    getState().invites;

  const preview = {
    inputCount: 0,
    input: { woodpeckerCampaigns: {}, type },
  };

  if (send.tagQuery.trim()) preview.input.tagQuery = send.tagQuery.trim();

  //build post body only for selected, ie. sometimes left over woodpeckerCampaigns that dont get cleaned up
  if (type === "invite") {
    if (send.sendEmails) {
      for (const inviteGroupId of inviteGroupSelections) {
        const currentInviteGroup = data[inviteGroupId];
        setWoodpeckerPreviewValues(
          send,
          preview,
          currentInviteGroup.relations.emailGroups.relations.woodpeckerCampaigns
        );
      }
    } else if (send.inviteGroups) {
      const inviteGroupKeys = Object.keys(send.inviteGroups);
      preview.inputCount = inviteGroupKeys.reduce(
        (accumulator, current) => accumulator + send.inviteGroups[current] || 0,
        0
      );
    }
  } else if (type === "join") {
    setWoodpeckerPreviewValues(
      send,
      preview,
      joinEmailGroup.relations.woodpeckerCampaigns
    );
  }

  if (send.sendSMSReminder && !send.sendEmails) {
    return message.error("Sending SMS without sending emails is not supported");
  }

  if (!send.userIds && !send.sendEmails) {
    return message.error(
      "User ID list is required when send emails is not checked"
    );
  }

  //if using user ID list, only accept equal to user Id length
  if (send.userIds && send.userIds.length !== preview.inputCount) {
    return message.error(
      `Total count of invites (${preview.inputCount}) must equal User ID list (${send.userIds.length})`
    );
  }

  //validation
  if (!preview.inputCount) {
    return message.error("Total count of invites must be greater than zero");
  }

  dispatch(previewInputOutputInvites(preview)); //save input body and inputCount to state
  dispatch(updateSendState({ tab: "preview" })); //switch tabs to view preview output

  if (send.sendEmails) {
    dispatch(pushUsersToEmailGroup(true)); //POST api/emailGroups/push?preview=true
  } else {
    dispatch(pushInvites(true)); //POST api/invites?preview=true
  }
};

export const uploadUserCsv = file => async dispatch => {
  try {
    const jsonArray = await readCsvFile(file);
    const userIdArray = jsonArray
      .map(row => row.user_id)
      .filter(userId => isUuid(userId));
    if (!userIdArray.length) throw new Error("Empty userIdArray");

    dispatch(updateSendState({ userIds: userIdArray, tagQuery: "" }));
  } catch (error) {
    return dispatch(
      result({
        status: `warning`,
        title: `Invalid rows. CSV should contain a 'user_id' column`,
        subTitle: error.message,
      })
    );
  }
};

export const fetchJoinEmailGroup = () => async (dispatch, getState) => {
  try {
    const fetchedJoinEmailGroup = getState().invites.joinEmailGroup;
    if (!fetchedJoinEmailGroup) {
      return dispatch(getJoinEmailGroup());
    }
  } catch (error) {
    return dispatch(
      result({
        status: `error`,
        title: `An Error Occurred`,
        subTitle: error.message,
      })
    );
  }
};

export const addWoodpeckerCampaignJoin =
  woodpeckerCampaignIds => async (dispatch, getState) => {
    try {
      const fetchedJoinEmailGroup = getState().invites.joinEmailGroup;
      if (!fetchJoinEmailGroup) {
        throw new Error("No Email Group of type Join");
      }
      const postWoodpeckerCampaignsRequest = woodpeckerCampaignIds.map(
        woodpeckerCampaignId => {
          return {
            emailGroupId: fetchedJoinEmailGroup.emailGroupId,
            woodpeckerCampaignId,
          };
        }
      );
      return dispatch(
        newWoodpeckerCampaignJoin(postWoodpeckerCampaignsRequest)
      );
    } catch (error) {
      return dispatch(
        result({
          status: `error`,
          title: `An Error Occurred`,
          subTitle: error.message,
        })
      );
    }
  };

export const updateEmailGroup = (emailGroupId, body) => async dispatch => {
  try {
    return dispatch(putEmailGroup(emailGroupId, body));
  } catch (error) {
    return dispatch(
      result({
        status: `error`,
        title: `An Error Occurred`,
        subTitle: error.message,
      })
    );
  }
};
