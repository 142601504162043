import React, { useState, useEffect } from "react";
import { Form, Space, Button } from "antd";
import { any, bool, func, object, string } from "prop-types";
import FlowForm from "./FlowForm";
import styles from "./AddNewCampaign.module.css";
import {
  defaultFormItemLayout,
  defaultButtonWrapperCol,
} from "../../../utils/formLayout";

function FlowStep({
  form,
  onFormDisabled,
  contractName,
  onBack,
  campaignFlows,
  loading,
}) {
  const [campaignFlowOptions, setCampaignFlowOptions] = useState([]);
  const [showingNextButton, setShowingNextButton] = useState(true);

  useEffect(() => {
    if (campaignFlows) {
      setCampaignFlowOptions(
        Object.values(campaignFlows).map(c => {
          return {
            value: JSON.stringify({
              campaignFlowId: c.campaignFlowId,
            }),
            label: c.name,
          };
        })
      );
    }
  }, [campaignFlows]);

  const toggleSubmitButton = activeComponent => {
    //if the flow creation form is showing, hide the top-level form submission button
    setShowingNextButton(activeComponent === 1);
    if (onFormDisabled) {
      //indicate to the top level parent that this form is disabled
      onFormDisabled(activeComponent === 2);
    }
  };

  return (
    <>
      <Form
        className={styles.formContainer}
        name="flowForm"
        {...defaultFormItemLayout}
        labelAlign="right"
        layout="horizontal"
        form={form}
      >
        <Space direction="vertical" className={styles.form} size="large">
          <FlowForm
            contractName={contractName}
            onSwitch={toggleSubmitButton}
            form={form}
            loading={loading}
            name="campaignFlow"
            options={campaignFlowOptions}
            setOptions={setCampaignFlowOptions}
            setSelectedOption={option => {
              form.setFieldsValue({ campaignFlow: option.value });
            }}
          />
          {showingNextButton && (
            <Form.Item
              className={styles.buttonsContainer}
              wrapperCol={defaultButtonWrapperCol}
            >
              <Space direction="horizontal" size="large">
                {onBack && (
                  <Button type="secondary" onClick={onBack}>
                    Previous
                  </Button>
                )}
                <Button type="primary" htmlType="submit">
                  Next
                </Button>
              </Space>
            </Form.Item>
          )}
        </Space>
      </Form>
    </>
  );
}

FlowStep.propTypes = {
  onBack: func,
  contractName: string,
  form: any,
  onFormDisabled: func,
  campaignFlows: object,
  loading: bool,
};

export default FlowStep;
