import React from "react";
import { array, bool, func, string } from "prop-types";
import { Form, Select, Input, Button } from "antd";
import TwoComponentSwitch from "../../shared/TwoComponentSwitch/TwoComponentSwitch";
import styles from "./CompanyForm.module.css";
import {
  defaultButtonWrapperCol,
  defaultFormItemLayout,
} from "../../../utils/formLayout";
import optionOrNew from "../../Campaigns/AddNewCampaign/optionOrNew";

const FirstForm = ({ options, name, loading }) => {
  console.log({ options });
  const getOptions = () => {
    const optionComponents = [];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const optionValue = JSON.parse(option.value);
      optionComponents.push(
        optionOrNew({
          option,
          optionData: optionValue,
          idProperty: "billingContractId",
        })
      );
    }
    return optionComponents;
  };

  return (
    <Form.Item label="Billing Contract" className={styles.formItem} name={name}>
      <Select
        loading={loading}
        showSearch
        onSearch={value => {
          console.log("searching for", value);
        }}
        className={styles.fullWidth}
        filterOption={(inputValue, option) => {
          console.log({ inputValue, option });
          return (
            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          );
        }}
        placeholder="Type to search billing contracts"
      >
        {getOptions()}
      </Select>
    </Form.Item>
  );
};

FirstForm.propTypes = {
  options: array,
  name: string,
  loading: bool,
};

const SecondForm = ({
  toggleVisibleComponent,
  options,
  setOptions,
  setSelectedOption,
  contractName,
}) => {
  const [form] = Form.useForm();

  const onSubmit = async () => {
    try {
      const newOption = {
        label: `${contractName}`,
        value: JSON.stringify({ contractName }),
      };
      setOptions([newOption, ...options]);
      setSelectedOption(newOption.value);
      toggleVisibleComponent();
    } catch (error) {
      //ignore since ant design will handle validation based on form.item rules
    }
  };

  //   const validateContractName = value => {
  //     const existingOption = options.find(o => o.value === value);
  //     if (!value) {
  //       return Promise.reject(new Error("required"));
  //     }
  //     if (!value.trim()) {
  //       return Promise.reject(new Error("contract name can't be all spaces"));
  //     }
  //     if (existingOption) {
  //       return Promise.reject(new Error("contract name already exists"));
  //     }
  //     return Promise.resolve();
  //   };

  return (
    <Form
      component={false}
      {...defaultFormItemLayout}
      labelAlign="right"
      layout="horizontal"
      form={form}
      initialValues={{
        name: contractName,
      }}
    >
      <Form.Item
        label="Contract Name"
        name="name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item wrapperCol={defaultButtonWrapperCol}>
        <Button onClick={onSubmit}>Submit</Button>
      </Form.Item>
    </Form>
  );
};

SecondForm.propTypes = {
  options: array,
  setSelectedOption: func,
  toggleVisibleComponent: func,
  setOptions: func,
  contractName: string,
};

function BillingContractForm({
  options,
  setOptions,
  setSelectedOption,
  name,
  loading,
  onSwitch,
  contractName,
}) {
  return (
    <TwoComponentSwitch
      buttonHidden={!contractName}
      className={styles.fullWidth}
      activateFirstText="Select Existing Billing Contract"
      activateSecondText="Create New Billing Contract"
      onSwitch={onSwitch}
      firstComponent={
        <FirstForm
          options={options}
          setSelectedOption={setSelectedOption}
          name={name}
          loading={loading}
        />
      }
      secondComponent={
        <SecondForm
          options={options}
          setOptions={setOptions}
          setSelectedOption={setSelectedOption}
          contractName={contractName}
        />
      }
    />
  );
}

BillingContractForm.propTypes = {
  options: array,
  setOptions: func,
  setSelectedOption: func,
  name: string,
  loading: bool,
  onSwitch: func,
  contractName: string,
};

export default BillingContractForm;
