import { types } from "./campaignActions";
import { flattenCampaigns } from "./campaignUtils";

export default (state = {}, action) => {
  switch (action.type) {
    case types.GET_CAMPAIGN_SUCCESS:
      return { ...state, data: flattenCampaigns([action.data]) };
    case types.GET_CAMPAIGN_POSTS_SUCCESS:
      return { ...state, [action.campaignId]: action.data };
    case types.POST_CAMPAIGN_SUCCESS:
      return { ...state, data: action.data };
    default:
      return state;
  }
};
