import React, { useState } from "react";
import { PageHeader, Space, Tabs } from "antd";
import {
  CheckCircleTwoTone,
  WarningTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { string } from "prop-types";
import { getBreadcrumbProps } from "../../../../AppRouter";
import {
  addWoodpeckerCampaignJoin,
  updateSendState,
} from "../../../../redux/invites/sendInvitesActions";
import {
  PreviewButton,
  SubmitButton,
  ResetButton,
  AddWoodpeckerCampaignButton,
} from "./SendInvitesButtons";
import AddWoodpeckerCampaignModal from "../AddWoodpeckerCampaignModal";

//antd
const { TabPane } = Tabs;

const defaultTitle = "Send Invites";

function SendInvitesHeader({ title, type = "invite" }) {
  const history = useHistory();
  //redux
  const dispatch = useDispatch();
  const send = useSelector(state => state.invites.send);
  const preview = useSelector(state => state.invites.preview);
  const pushUsersToEmailGroupLoading = useSelector(
    state => state.shared.loadingIds.indexOf("pushUsersToEmailGroup") > -1
  );

  const currentTab = send.tab;

  const extraButtons = [
    <ResetButton key="reset" />,
    <PreviewButton key="preview" />,
  ];

  const [
    addWoodpeckerCampaignModalVisible,
    setAddWoodpeckerCampaignModalVisible,
  ] = useState(false);

  if (type === "join") {
    extraButtons.unshift(
      <AddWoodpeckerCampaignButton
        key="addWoodpeckerCampaign"
        onClick={() => {
          setAddWoodpeckerCampaignModalVisible(true);
        }}
      />
    );
  }

  //content in header based on tab
  let headerProps;
  switch (currentTab) {
    case "logs":
      break;
    case "form":
      headerProps = {
        extra: extraButtons,
      };
      break;
    case "preview": {
      const inputOutputPercent = preview.inputCount / preview.outputCount;
      const getPreviewIcon = () => {
        if (pushUsersToEmailGroupLoading) {
          return <SyncOutlined spin />;
        }
        if (inputOutputPercent === 1) {
          return (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ marginRight: 8 }}
            />
          );
        }
        return (
          <WarningTwoTone twoToneColor="#faad14" style={{ marginRight: 8 }} />
        );
      };
      headerProps = {
        title: (
          <Space size="large">
            <span>{title || defaultTitle}</span>
            <span>
              {getPreviewIcon()}
              {pushUsersToEmailGroupLoading ? " " : preview.outputCount} /{" "}
              {preview.inputCount}
            </span>
          </Space>
        ),
        extra: [<ResetButton key="reset" />, <SubmitButton key="preview" />],
      };
      break;
    }
    default:
      break;
  }

  return (
    <div className="main-no-padding border">
      <AddWoodpeckerCampaignModal
        visible={addWoodpeckerCampaignModalVisible}
        onCancel={() => setAddWoodpeckerCampaignModalVisible(false)}
        onCreate={values => {
          setAddWoodpeckerCampaignModalVisible(false);
          dispatch(addWoodpeckerCampaignJoin(values.woodpeckerCampaigns));
        }}
      />
      <PageHeader
        ghost={false}
        breadcrumb={getBreadcrumbProps(history.location)} //see https://ant.design/components/breadcrumb/#Use-with-browserHistory
        title={title || defaultTitle}
        footer={
          <Tabs
            activeKey={currentTab}
            onChange={activeKey =>
              dispatch(updateSendState({ tab: activeKey }))
            }
          >
            <TabPane tab="Form" key="form" />
            <TabPane tab="Preview" disabled key="preview" />
            <TabPane tab="Logs" key="logs" />
          </Tabs>
        }
        {...headerProps}
      />
    </div>
  );
}

SendInvitesHeader.propTypes = {
  title: string,
  type: string,
};

export default SendInvitesHeader;
