import React, { useState, useEffect, Suspense } from "react";
import { any, array, bool, func, number, object, string } from "prop-types";
import { Form, Select, Input, Button, Space, Spin } from "antd";
import TwoComponentSwitch from "../../shared/TwoComponentSwitch/TwoComponentSwitch";
import styles from "./AddNewCampaign.module.css";
import {
  defaultButtonWrapperCol,
  defaultFormItemLayout,
} from "../../../utils/formLayout";
import optionOrNew from "./optionOrNew";
import { minifyHtml } from "../../../utils/stringUtils";

const EmailTemplateEditor = React.lazy(() => import("./EmailTemplateEditor"));

const FirstForm = ({
  options,
  name,
  loading,
  form,
  onSubmit,
  onBack,
  toggleVisibleComponent,
  setExistingOptionIndex,
}) => {
  const getOptions = () => {
    const optionComponents = [];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const optionValue = JSON.parse(option.value);
      const onNewClick = () => {
        setExistingOptionIndex(i);
        toggleVisibleComponent();
      };
      optionComponents.push(
        optionOrNew({
          option,
          optionData: optionValue,
          idProperty: "emailTemplateId",
          onNewClick,
        })
      );
    }

    return optionComponents;
  };

  const _onSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue(true);
      onSubmit(values);
    } catch (error) {
      //ignore as Ant Design will handle validation based on form.item rules
    }
  };

  return (
    <Form
      {...defaultFormItemLayout}
      component={false}
      labelAlign="right"
      layout="horizontal"
      name="createTemplateForm"
      form={form}
    >
      <Form.Item
        name={name}
        label="Email Template Name"
        className={styles.formItem}
        rules={[{ required: true, message: "required" }]}
      >
        <Select
          loading={loading}
          showSearch
          className={styles.fullWidth}
          placeholder="Type to search email templates"
          filterOption={(inputValue, option) => {
            return (
              option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            );
          }}
        >
          {getOptions()}
        </Select>
      </Form.Item>
      <Form.Item wrapperCol={defaultButtonWrapperCol} className={styles.mt24}>
        <Space direction="horizontal" size="large">
          <Button type="secondary" onClick={onBack}>
            Back
          </Button>
          <Button onClick={_onSubmit}>Submit</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

FirstForm.propTypes = {
  options: array,
  name: string,
  loading: bool,
  form: any,
  onSubmit: func.isRequired,
  onBack: func.isRequired,
  toggleVisibleComponent: func,
  setExistingOptionIndex: func,
};

const getDefaultHtmlTemplate = ({
  campaignStepType,
  campaignLink,
  companyName,
  platform,
}) => {
  const defaultHtmlTemplates = {
    order: `<p>Step 1.</p><table width="100%"><tbody><tr><td><table border="0" cellspacing="0" cellpadding="0"><tbody><tr><td style="line-height: 20px;" width="100%" height="20">&nbsp;</td></tr></tbody></table><table style="margin: 0 auto;" border="0" cellspacing="0" cellpadding="0" align="left"><tbody><tr><td align="left"><table style="margin: 0 auto;" border="0" cellspacing="0" cellpadding="0"><tbody><tr><td style="-moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;" align="center" bgcolor="#ff4364" width="180"><a style="padding: 10px; width: 180px; display: block; text-align: center; font-size: 16px; font-weight: 500; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; line-height: 20px; text-decoration: none !important; border: 1px solid #ff4364; color: #ffffff; background: #ff4364;" href="%CAMPAIGN_LINK%?id=%ID%&amp;stepType=order">Order Product </a></td></tr></tbody></table></td></tr></tbody></table><table border="0" cellspacing="0" cellpadding="0"><tbody><tr><td style="font-size: 16px; line-height: 20px;" width="100%" height="20">&nbsp;</td></tr></tbody></table></td></tr></tbody></table><p>${
      platform === "Amazon"
        ? `<p style="color: gray;">We use Amazon purchases for shipping products. <a href="https://stackinfluence.com/amazon-order-process/">Learn more</a></p>`
        : ``
    } <br />Step 2.</p><ul style="padding: 0 20px;" type="circle"><li>Post on Instagram with your face and the product visible. See <a href="https://app.stackinfluence.com/campaigns/%CONTRACT_ID%/%CAMPAIGN_ID%" target="_blank">campaign and content guidelines</a></li><li>Caption must mention product use and experience</li><li>Required tags: <span style="font-weight: 600;">#ad</span></li><li>Submit before deadline: <span style="font-weight: 600;">%DEADLINE_DATE%</span></li></ul><p>Step 3.</p><table width="100%"><tbody><tr><td><table border="0" cellspacing="0" cellpadding="0"><tbody><tr><td style="line-height: 20px;" width="100%" height="20">&nbsp;</td></tr></tbody></table><table style="margin: 0 auto;" border="0" cellspacing="0" cellpadding="0" align="left"><tbody><tr><td align="left"><table style="margin: 0 auto;" border="0" cellspacing="0" cellpadding="0"><tbody><tr><td style="-moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;" align="center" bgcolor="#ff4364" width="180"><a style="padding: 10px; width: 180px; display: block; text-align: center; font-size: 16px; font-weight: 500; -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px; line-height: 20px; text-decoration: none !important; border: 1px solid #ff4364; color: #ff4364; background: #ffffff;" href="https://app.stackinfluence.com/influencer/submit/%ID%/instagram">Submit Post </a></td></tr></tbody></table></td></tr></tbody></table><table border="0" cellspacing="0" cellpadding="0"><tbody><tr><td style="font-size: 16px; line-height: 20px;" width="100%" height="20">&nbsp;</td></tr></tbody></table></td></tr></tbody></table>`,
    review: `<div style='font-size: inherit;'>We sincerely appreciate feedback, please share your product experience on the <a href="%CAMPAIGN_LINK%?id=%ID%&amp;stepType=review" target="_blank"><span style="font-weight: 500;">${companyName} Website here</span></a></div>`,
  };
  return defaultHtmlTemplates[
    campaignStepType === "instagram" ? "order" : campaignStepType
  ];
};

const SecondForm = ({
  toggleVisibleComponent,
  options,
  setOptions,
  campaignStepName,
  setSelectedOption,
  campaignStepType,
  campaignLink,
  companyName,
  platform,
  existingOptionIndex,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (existingOptionIndex > -1) {
      const optionData = JSON.parse(options[existingOptionIndex].value);
      form.setFieldsValue(optionData);
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingOptionIndex]);

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue(true);
      values.html = minifyHtml(values.html);
      const newOption = {
        label: values.name,
        value: JSON.stringify(values),
      };
      if (existingOptionIndex >= 0) {
        options.splice(existingOptionIndex, 1);
      }
      setOptions([newOption, ...options]);
      setSelectedOption(newOption);
      form.resetFields();
      toggleVisibleComponent();
    } catch (error) {
      //ignore as Ant Design will handle validation based on form.item rules
    }
  };

  const validateTemplateName = value => {
    if (!value) {
      return Promise.reject(new Error("required"));
    }
    if (existingOptionIndex < 0) {
      const existingOption = options.find(o => o.label === value);
      if (existingOption) {
        return Promise.reject(new Error("template name already exists"));
      }
    } else {
      const existingOption = options.find(o => o.label === value);
      const previousExistingOption = options[existingOptionIndex];
      if (
        existingOption &&
        previousExistingOption.label !== existingOption.label
      ) {
        return Promise.reject(new Error("template name already exists"));
      }
    }
    return Promise.resolve();
  };

  return (
    <Suspense fallback={<Spin size="large" />}>
      <Form
        {...defaultFormItemLayout}
        component={false}
        form={form}
        labelAlign="right"
        layout="horizontal"
        name="createTemplateForm"
        initialValues={{
          name: `${campaignStepName} Template`,
          html: getDefaultHtmlTemplate({
            campaignStepType,
            campaignLink,
            companyName,
            platform,
          }),
        }}
      >
        <Form.Item
          label="Template Name"
          name="name"
          rules={[
            {
              validator: (rule, value) => validateTemplateName(value),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="html"
          rules={[{ required: true, message: "required" }]}
        >
          <EmailTemplateEditor />
        </Form.Item>
        <Form.Item>
          <div className={styles.rightAligned}>
            <Button onClick={onSubmit}>Submit</Button>
          </div>
        </Form.Item>
      </Form>
    </Suspense>
  );
};

SecondForm.propTypes = {
  options: array,
  setSelectedOption: func,
  toggleVisibleComponent: func,
  setOptions: func,
  campaignStepName: string,
  campaignStepType: string,
  companyName: string,
  platform: string,
  campaignLink: string,
  existingOptionIndex: number,
};

function EmailTemplateForm({
  campaignStepName,
  onSubmit,
  name,
  value,
  onBack,
  campaignStepType,
  campaignLink,
  platform,
  companyName,
  emailTemplates,
}) {
  const [options, setOptions] = useState([]);
  const [form] = Form.useForm();
  const [existingOptionIndex, setExistingOptionIndex] = useState(-1);

  useEffect(() => {
    if (emailTemplates) {
      setOptions(
        Object.values(emailTemplates).map(template => {
          return {
            value: JSON.stringify({
              emailTemplateId: template.emailTemplateId,
              name: template.name,
              html: template.html,
            }),
            label: template.name,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplates]);

  useEffect(() => {
    if (value && name) {
      form.setFieldsValue({ [name]: JSON.stringify(value) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, value]);

  const _onSwitch = newActiveComponent => {
    if (newActiveComponent === 1) {
      setExistingOptionIndex(-1);
    }
  };

  return (
    <TwoComponentSwitch
      className={styles.fullWidth}
      activateFirstText="Select Existing Template"
      activateSecondText="Create New Template"
      onSwitch={_onSwitch}
      firstComponent={
        <FirstForm
          form={form}
          options={options}
          name={name}
          onSubmit={onSubmit}
          onBack={onBack}
          setExistingOptionIndex={setExistingOptionIndex}
        />
      }
      secondComponent={
        <SecondForm
          options={options}
          setOptions={setOptions}
          setSelectedOption={o => {
            form.setFieldsValue({ [name]: o.value });
          }}
          campaignStepName={campaignStepName}
          campaignStepType={campaignStepType}
          campaignLink={campaignLink}
          platform={platform}
          companyName={companyName}
          existingOptionIndex={existingOptionIndex}
        />
      }
    />
  );
}

EmailTemplateForm.propTypes = {
  campaignStepName: string,
  onSubmit: func.isRequired,
  name: string,
  value: object,
  onBack: func,
  campaignStepType: string,
  campaignLink: string,
  platform: string,
  companyName: string,
  emailTemplates: object,
};

export default EmailTemplateForm;
