import { Modal } from "antd";
import { any, bool, func, string } from "prop-types";
import React from "react";

export default function GenericModal({
  title,
  visible,
  onOk,
  onCancel,
  loading,
  message,
}) {
  return (
    <Modal
      title={<span>{title}</span>}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={loading}
    >
      <p>{message}</p>
    </Modal>
  );
}

GenericModal.propTypes = {
  title: any,
  visible: bool,
  onOk: func,
  onCancel: func,
  loading: bool,
  message: string,
};
