import React, { useState } from "react";
import { Form, Button, Typography, Tag } from "antd";
import { object } from "prop-types";
import { useInfluencerToken, useUpdateUserEligibility } from "../../api/users";

function SelectionForm({ user }) {
  // Fetch token
  const {
    data: tokenResp,
    isLoading: loadingUserToken,
    isError: tokenError,
  } = useInfluencerToken({
    userId: user?.userId,
    enabled: !!user,
  });

  // Mutator to update eligibility for user
  const updateUserEligibility = useUpdateUserEligibility();
  const [updatingEligibility, setUpdatingEligibility] = useState(false);
  const updateEligibility = async () => {
    setUpdatingEligibility(true);
    try {
      await updateUserEligibility.mutateAsync({
        userId: user?.userId,
      });
    } finally {
      setUpdatingEligibility(false);
    }
  };

  const selectLink = `${process.env.REACT_APP_INFLUENCER_BRAND_HOST}influencer/select?token=${tokenResp?.token}`;
  const campaignsLink = `${process.env.REACT_APP_INFLUENCER_BRAND_HOST}influencer/campaigns?token=${tokenResp?.token}`;

  return (
    <Form.Item label="Selection">
      {tokenResp && (
        <>
          <Form.Item style={{ marginBottom: 0 }}>
            <Typography.Text
              copyable={{ text: selectLink }}
              style={{ marginRight: "10px" }}
            >
              <a target="_blank" rel="noopener noreferrer" href={selectLink}>
                {`${process.env.REACT_APP_INFLUENCER_BRAND_HOST}influencer/select?token=`}
              </a>
            </Typography.Text>
            <Typography.Text
              type="secondary"
              copyable={{ text: tokenResp.token }}
            >
              (token)
            </Typography.Text>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Typography.Text
              copyable={{ text: campaignsLink }}
              style={{ marginRight: "10px" }}
            >
              <a target="_blank" rel="noopener noreferrer" href={campaignsLink}>
                {`${process.env.REACT_APP_INFLUENCER_BRAND_HOST}influencer/campaigns?token=`}
              </a>
            </Typography.Text>
          </Form.Item>
        </>
      )}
      <Form.Item>
        {user?.relations?.userEligibility?.length > 0
          ? user.relations.userEligibility
              .sort((a, b) => a.localeCompare(b))
              .map(ue => <Tag key={ue}>{ue}</Tag>)
          : "No eligibility"}
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Button
          disabled={!user || updatingEligibility}
          type="primary"
          onClick={updateEligibility}
        >
          Update Eligibility
        </Button>
      </Form.Item>
    </Form.Item>
  );
}

SelectionForm.propTypes = {
  user: object,
};

export default SelectionForm;
