import React from "react";
import { Button, Col, Popover, Row } from "antd";
import clsx from "clsx";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import styles from "./ActionTable.module.css";
import { badMessage, goodMessage } from "./ManualValidation";
import { useValidateInboundUser } from "../../api/users";

export default function ActionTable({ userId }) {
  const validateInboundUser = useValidateInboundUser();

  return (
    <div className={styles.tableWrapper}>
      <SelectionRegion
        good
        onClick={async () => {
          await validateInboundUser.mutateAsync({
            accepted: true,
            _id: userId,
          });
          goodMessage(userId);
        }}
      >
        Good (1)
      </SelectionRegion>
      <SelectionRegion
        bad
        onClick={async () => {
          await validateInboundUser.mutateAsync({
            accepted: false,
            _id: userId,
          });
          badMessage(userId);
        }}
      >
        Bad (2)
      </SelectionRegion>
    </div>
  );
}

function ThreeImageListFromKeyword({ keyword }) {
  const numberOfImages = 3;
  const imageArray = [];

  for (let i = 0; i < numberOfImages; i++) {
    const imgSrc = `${process.env.REACT_APP_EXAMPLE_HOST_URL}${keyword}${
      i + 1
    }.png`;
    imageArray.push(<img key={keyword + i} alt="" src={imgSrc} />);
  }
  return (
    <div className={styles.threeImageList} key={keyword}>
      {imageArray}
    </div>
  );
}

//see CSS spec https://github.com/yiminghe/dom-align
const align = {
  points: ["l", "l"], // align top left point of sourceNode with top right point of targetNode
  targetOffset: [500], // the offset targetNode by 30% of targetNode width in x and 40% of targetNode height in y,
  overflow: { adjustX: true }, // auto adjust position when sourceNode is overflowed
};
function TextSquareWithPopover({ title, keyword }) {
  const popoverContent = ThreeImageListFromKeyword({ keyword });

  return (
    <Popover
      key={title}
      className={styles.square}
      content={popoverContent}
      trigger={["click", "hover"]}
      align={align}
    >
      <Col span={8}>
        <Text className={styles.wordText}>{title}</Text>
      </Col>
    </Popover>
  );
}

const helperData = {
  Good: [
    [
      {
        component: item => (
          <Col key="goodColumn1" span={20}>
            <Title level={4}> >= 2 of Same Human/Dog Face</Title>
          </Col>
        ),
      },
      {
        component: props => (
          <Col key="goodColumn2" span={20}>
            <ThreeImageListFromKeyword {...props} />
          </Col>
        ),
        keyword: "good",
      },
    ],
  ],
  Bad: [
    [
      {
        component: TextSquareWithPopover,
        title: "Profanity 🖕",
        keyword: "profanity",
      },
      {
        component: TextSquareWithPopover,
        title: "Nudity 🍑",
        keyword: "nude",
      },
      {
        component: TextSquareWithPopover,
        title: "Money, Guns, Smoking 🚬",
        keyword: "guns",
      },
    ],
    [
      {
        component: TextSquareWithPopover,
        title: "Meme, Text Page",
        keyword: "meme",
      },
      {
        component: TextSquareWithPopover,
        title: "Celebrity Fan Page 🤴🏻",
        keyword: "celeb",
      },
    ],
  ],
};

function SelectionRegion({ onClick, good, bad, children, ...rest }) {
  const title = good ? "Good" : "Bad";
  const isBad = title === "Bad";
  return (
    <div
      className={clsx(styles.selectionButton, {
        [styles.good]: good,
        [styles.bad]: bad,
      })}
      {...rest}
    >
      <Row className={styles.mb8}>
        <Col xs={{ span: 24 }}>
          {isBad ? (
            // BAD
            <Text>
              If any of these categories, mark as <b>Bad</b>:
            </Text>
          ) : (
            // GOOD
            <Text>
              Required to mark as <b>Good</b>:
            </Text>
          )}
        </Col>
      </Row>

      {helperData[title].map((key, i) => (
        <Row
          key={i}
          type="flex"
          align="middle"
          justify="space-between"
          className={styles.mb8}
        >
          {key.map(item => {
            //render based on component specified in helperData
            // this allows us to have different, dynamic types of content
            // based on the helperData[title] in each row
            return item.component({
              keyword: item.keyword,
              title: item.title,
            });
          })}
        </Row>
      ))}
      <Row className={styles.mb8}>
        <Button
          onClick={onClick}
          type={isBad ? "danger" : "primary"}
          block
          className={styles.button}
        >
          {children}
        </Button>
      </Row>
    </div>
  );
}
