import React from "react";
import { Button } from "antd";
import {
  PlayCircleOutlined,
  MailOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { func } from "prop-types";
import {
  resetSendState,
  updateSendState,
  submitInvitesPreview,
} from "../../../../redux/invites/sendInvitesActions";
import {
  pushInvites,
  pushUsersToEmailGroup,
} from "../../../../redux/invites/invitesActions";

export const PreviewButton = () => {
  //redux
  const dispatch = useDispatch();
  const inviteGroupSelectionsLength = useSelector(
    state => state.invites.inviteGroupSelections.length
  );

  const inviteType = useSelector(state => state.invites.type);

  const joinEmailGroup = useSelector(state => state.invites.joinEmailGroup);

  return (
    <Button
      icon={<PlayCircleOutlined />}
      key="preview"
      type="primary"
      //enabled if an inviteGroup is selected
      disabled={
        (inviteType === "invite" && !inviteGroupSelectionsLength) ||
        (inviteType === "join" && !joinEmailGroup)
      }
      onClick={
        //transform values in state.invites.send and send request
        () => dispatch(submitInvitesPreview())
      }
    >
      Preview
    </Button>
  );
};

export const SubmitButton = () => {
  //redux
  const dispatch = useDispatch();
  const sendState = useSelector(state => state.invites.send);
  const isLoading = useSelector(state => state.shared.isLoading);

  return (
    <Button
      icon={<MailOutlined />}
      key="submit"
      type="primary"
      disabled={isLoading}
      onClick={() => {
        if (sendState.sendEmails) {
          dispatch(pushUsersToEmailGroup());
        } else {
          dispatch(pushInvites());
        }
      }}
    >
      Submit
    </Button>
  );
};

export const ResetButton = () => {
  //redux
  const dispatch = useDispatch();

  return (
    <Button
      icon={<CloseOutlined />}
      key="reset"
      type="secondary"
      onClick={
        //reset all preview form values stored in redux
        () => {
          dispatch(resetSendState());
          dispatch(updateSendState({ tab: "form" }));
        }
      }
    >
      Reset
    </Button>
  );
};

export const AddWoodpeckerCampaignButton = ({ onClick }) => {
  return (
    <Button
      icon={<PlusOutlined />}
      key="reset"
      type="secondary"
      onClick={onClick}
    >
      Add Woodpecker Campaigns
    </Button>
  );
};

AddWoodpeckerCampaignButton.propTypes = {
  onClick: func,
};
