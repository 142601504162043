import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import {
  createAuthenticatedRequest,
  defaultQueryOptions,
  defaultMutationOptions,
  executeRequest,
} from "./utils";
import { flattenContracts } from "../redux/contracts/contractUtils";
import { COMPANIES_PATH } from "./companies";
import { B2C_USERS_PATH } from "./b2cUsers";
import { BILLING_CONTRACTS_PATH } from "./billingContract";
import { CAMPAIGNS_PATH } from "./campaigns";
import { getContractSuccess } from "../redux/contracts/contractActions";

export const CONTRACTS_PATH = "contracts";

// Simple wrapper to ensure we're managing cache keys properly. Can also override
// default options here if wanted. Probably a good idea to pass through override options
// as well.
export function useContracts({ includes, params, ...overrideOptions }) {
  const dispatch = useDispatch();

  return useQuery(
    [CONTRACTS_PATH, includes, params],
    () => fetchContracts(dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}
// Execute fetch request for contracts
export async function fetchContracts(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    CONTRACTS_PATH,
    dispatch,
    options
  );

  const contractData = await executeRequest(requestObject);
  // Make sure we keep contract data in sync with the redux store
  if (contractData.data) {
    dispatch(getContractSuccess(contractData.data));
    contractData.data = flattenContracts(contractData.data);
    return contractData;
  }
  dispatch(getContractSuccess(contractData));
  return flattenContracts(contractData);
}

export function useCreateContract() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(newContract => createContract(newContract, dispatch), {
    ...defaultMutationOptions,
    onSuccess: () => {
      queryClient.invalidateQueries(CONTRACTS_PATH);
      queryClient.invalidateQueries(COMPANIES_PATH);
      queryClient.invalidateQueries(B2C_USERS_PATH);
      queryClient.invalidateQueries(BILLING_CONTRACTS_PATH);
    },
  });
}

// Execute a POST to create a new contract
export async function createContract(contract, dispatch) {
  const requestObject = await createAuthenticatedRequest(
    CONTRACTS_PATH,
    dispatch,
    {
      method: "POST",
      body: JSON.stringify(contract),
    }
  );

  return executeRequest(requestObject);
}

export function useContractById({
  contractId,
  includes,
  params,
  ...overrideOptions
}) {
  const dispatch = useDispatch();

  const contractByIdPath = `${CONTRACTS_PATH}/${contractId}`;

  return useQuery(
    [CONTRACTS_PATH, contractId, includes, params],
    () => fetchContractById(contractByIdPath, dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}
// Execute fetch request for single contract by id
export async function fetchContractById(contractByIdPath, dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    contractByIdPath,
    dispatch,
    options
  );

  const contractData = await executeRequest(requestObject);
  const [flattenedContract] = flattenContracts([contractData]);
  return flattenedContract;
}

export function useUpdateContract(contractId) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({ body }) => updateContract({ contractId, body }, dispatch),
    {
      ...defaultMutationOptions,
      onSuccess: () => {
        queryClient.invalidateQueries(CONTRACTS_PATH);
        queryClient.invalidateQueries(BILLING_CONTRACTS_PATH);
        // We refetch campaigns when the contract is updated because updates to contract
        // can change campaign state
        queryClient.invalidateQueries(CAMPAIGNS_PATH);
      },
    }
  );
}

// Execute a PUT request to update contract
export async function updateContract({ contractId, body }, dispatch) {
  const requestObject = await createAuthenticatedRequest(
    `${CONTRACTS_PATH}/${contractId}`,
    dispatch,
    {
      method: "PUT",
      body: JSON.stringify(body),
    }
  );

  return executeRequest(requestObject);
}
