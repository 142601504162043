import React, { useState } from "react";
import { CalculatorOutlined } from "@ant-design/icons";
import { Space, InputNumber, Typography } from "antd";
//utils
import clipboard from "../../../utils/clipboard";
import amountToSendIncludingFees from "../utils/amountToSendIncludingFees";

export default props => {
  const [amountToSend, setAmountToSend] = useState();

  return (
    <Space size="small">
      <span onClick={() => clipboard(amountToSend)}>{amountToSend}</span>
      <CalculatorOutlined />
      <InputNumber
        style={{ width: 64 }}
        onChange={val => setAmountToSend(amountToSendIncludingFees(val))}
      />
    </Space>
  );
};
