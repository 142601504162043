import moment from "moment";
import { pushLoading, popLoading, result } from "../shared/sharedActions";
import { getToken } from "../auth/authActions";
import { csvIncludeString } from "../contracts/contractActions";
import { parseCampaignPosts } from "./campaignUtils";

export const types = {
  GET_CAMPAIGN_SUCCESS: "GET_CAMPAIGN_SUCCESS", //on successful GET fetch call
  GET_CAMPAIGN_POSTS_SUCCESS: "GET_CAMPAIGN_POSTS_SUCCESS", //on successful GET by ID fetch call
};

/*
 * ACTIONS
 */
export const getCampaignSuccess = data => {
  return { type: types.GET_CAMPAIGN_SUCCESS, data };
};

export const getCampaignPostsSuccess = (campaignId, data) => {
  return { type: types.GET_CAMPAIGN_POSTS_SUCCESS, campaignId, data };
};

export const getCampaignById = campaignId => async dispatch => {
  //loading
  const loadingId = `getCampaignById${campaignId}`;
  dispatch(pushLoading(loadingId));
  try {
    //auth
    const token = await dispatch(getToken());
    //get url from function
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/campaigns/${campaignId}?stats=true&include=onboards,shortcodes,products,contracts`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    dispatch(getCampaignSuccess(json));
  } catch (err) {
    dispatch(
      result({
        status: `error`,
        title: `Get campaign by ID failed`,
        subTitle: err.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};

export const putCampaignById = (campaignId, body) => async dispatch => {
  //loading
  const loadingId = `putCampaignById${campaignId}`;
  dispatch(pushLoading(loadingId));
  try {
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/campaigns/${campaignId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body),
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    dispatch(getCampaignById(campaignId));
  } catch (err) {
    dispatch(
      result({
        status: `error`,
        title: `PUT campaign by ID failed`,
        subTitle: err.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};

//called after onboards are already loaded from getCampaignById
//paginate client side (before db migration this action called server side pagination)
export const getCampaignPosts =
  (campaignId, currentTab, qs = {}) =>
  (dispatch, getState) => {
    //get campaigns from state
    const state = getState();
    const campaign = state.campaigns.data
      ? state.campaigns.data.find(obj => obj.campaignId === campaignId)
      : null;

    const campaignPosts = parseCampaignPosts(
      campaign,
      currentTab,
      onboard => onboard.state === currentTab,
      qs
    );
    dispatch(getCampaignPostsSuccess(campaignId, campaignPosts));
  };

//download all pages to csv
export const downloadCampaignPosts = campaign => async dispatch => {
  console.log("campaign", campaign);
  //loading
  const loadingId = `downloadCampaignPosts${campaign.campaignId}`;
  dispatch(pushLoading(loadingId));
  //auth
  const token = await dispatch(getToken());
  return fetch(
    `${process.env.REACT_APP_API_HOST}api/campaigns/${campaign.campaignId}?csv=true&include=${csvIncludeString}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    }
  )
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${campaign.productName} - ${moment().format(
        "YYYY-MM-DD"
      )}.csv`; //filename
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    })
    .catch(err => {
      //error handling
      dispatch(
        result({
          status: `error`,
          title: `Download campaign data failed`,
          subTitle: err.message,
        })
      );
    })
    .finally(() => dispatch(popLoading(loadingId))); //finish loading
};
