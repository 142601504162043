import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  createAuthenticatedRequest,
  defaultQueryOptions,
  executeRequest,
} from "./utils";

export const CAMPAIGN_REQUIREMENTS_PATH = "campaignRequirements";

export function useCampaignRequirements({
  includes,
  params,
  ...overrideOptions
}) {
  const dispatch = useDispatch();

  return useQuery(
    [CAMPAIGN_REQUIREMENTS_PATH, includes, params],
    () => fetchCampaignRequirements(dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for all campaign requirements
export async function fetchCampaignRequirements(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    CAMPAIGN_REQUIREMENTS_PATH,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}
