/**
 * Dates are hard. This file is a collection of date-related utilities that will hopefully
 * make dealing with them as painless as possible.
 *
 * We have 2 types of dates in the app: timestamps and calendar dates. Timestamps are a
 * moment in time, like a created_at, and calendar dates are a specific day or time, like
 * a birthday.
 *
 * We deal with dates in 2 ways: We display them, and we create/update them.
 *
 * For display, we will always use the `displayDate` utility. This will take either a
 * timestamp or a calendar date and return a momentjs object that can be formatted appropriately.
 *
 * For creating and updating, it depends on whether we're producing a calendar date or
 * a timestamp.
 *
 * Timestamps: Use the output of a datepicker or timepicker directly. This will produce a
 * UTC date that can be sent to the server.
 *
 * Calendar dates: Pass the result of a datepicker or timepicker to `toCalendarDate`
 * function. This will produce a date string of the calendar date that can be sent to the
 * server.
 *
 * Because our antd version has a hard dependency on moment, we will use moment for our
 * dates.
 */

import moment from "moment";

// Accepts either an ISO string or a calendar date string and returns a JS date object
// that can be formatted for correct display.
// You should use this when you're displaying a date from the backend on the frontend.
// You can also use this to create a moment from a backend date that you can use in
// a form or for comparisons.
export function displayDate(date) {
  if (!date) {
    return moment();
  }

  const _date = moment(date);

  // Can make a more sophisticated ISO8601 regex here if we want, but this will satisfy our
  // current needs of testing if an incoming date is a calendar date or timestamp.
  if (date.includes("T")) {
    // If it's a timestamp, we can just return a new date object
    return _date;
  }

  // If it's a calendar date, we display it as UTC instead of in the user's local timezone.
  return _date.utc();
}

// Accepts a JS date and returns a date string in the format "YYYY-MM-DD" that represents
// the calendar date at that point in time. Assumes that the date _is_ in the user's
// timezone, which is true of antd's datepicker and timepicker.
// You should call this when you're accepting a date _from_ a datepicker or timepicker
// that you are going to pass to the backend.
export function toCalendarDate(date) {
  if (!date) {
    return null;
  }

  const _date = moment(date).toDate();

  const year = _date.getFullYear();
  const month = _date.getMonth() + 1;
  const day = _date.getDate();

  return `${year}-${pad(month)}-${pad(day)}`;
}

function pad(num) {
  return num < 10 ? `0${num}` : num;
}

// Accepts a JS date or moment and returns an ISO date string that the backend/DB will
// use for timestamps.
export function toTimestamp(date) {
  return date.toISOString();
}
