import React from "react";
import { Col, Card, Avatar, Tooltip } from "antd";
import "./Gallery.less";
import { any } from "prop-types";
import { InfoCircleTwoTone, InfoCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { InstagramMedia } from "./InstagramMedia";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); //if Mobile
const span = isMobile ? 12 : 6;

const getWarningText = post => {
  const threeDaysAfterCreation = moment(post.createdAt).add(3, "days");
  if (moment(post.completedDate).isSameOrBefore(threeDaysAfterCreation)) {
    return `Completed date (${moment(post.completedDate).format(
      "MMMM Do YYYY"
    )}) less than 3 days AFTER the creation date ${moment(
      post.createdAt
    ).format("MMMM Do YYYY")}`;
  }
};

export default function GalleryCard(props) {
  const history = useHistory();
  const { post, meta } = props;
  const warningText = getWarningText(post);

  return (
    <Col span={span}>
      <Card
        size="small"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>
              <Avatar
                className="icon-marginRight"
                size="small"
                src={`${process.env.REACT_APP_PROFILES_HOST}${post.igHandle}.jpg`}
              />
              {post.igHandle}
            </span>
            {warningText ? (
              <Tooltip title={warningText}>
                <InfoCircleTwoTone
                  twoToneColor="orange"
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    history.push(`/onboards/${post.onboardId}`);
                  }}
                />
              </Tooltip>
            ) : (
              <InfoCircleOutlined
                style={{ fontSize: "20px" }}
                onClick={() => {
                  history.push(`/onboards/${post.onboardId}`);
                }}
              />
            )}
          </div>
        }
        bordered
        bodyStyle={{
          margin: 0,
          padding: 0,
        }}
      >
        <InstagramMedia post={post} />
        {meta}
      </Card>
    </Col>
  );
}

GalleryCard.propTypes = {
  post: any,
  meta: any,
};
