import React, { useImperativeHandle, useState, forwardRef } from "react";
import { Form, Tag, Input } from "antd";
import { array, func, string } from "prop-types";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./AddNewCampaign.module.css";

const invalidTags = ["ad", "stackinfluence"];

const TagsInput = forwardRef(({ tags, setTags, label }, ref) => {
  const [typedTag, setTypedTag] = useState("");
  const [tagsError, setTagsError] = useState("");

  const onChangeTag = value => {
    setTypedTag(value);
  };

  const addTag = () => {
    const newTags = [...tags];
    // const newTag = typedTag.replace(/#|@/g, "").toLowerCase();
    const newTag = typedTag.toLowerCase();
    if (invalidTags.includes(newTag)) {
      setTagsError(
        `${newTag} is in the list of invalid tags. You cannot include this value.`
      );
      return;
    }
    newTags.push(newTag);
    setTags(newTags);
    setTypedTag("");
    setTagsError("");
  };

  const removeTag = index => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  useImperativeHandle(ref, () => ({
    setTypedTag,
    setTagsError,
  }));

  return (
    <Form.Item label={label || "Add tag"} validateStatus={tagsError && "error"}>
      <Input
        placeholder="Type something and press enter/return to add a tag"
        value={typedTag}
        onChange={e => onChangeTag(e.target.value)}
        onPressEnter={addTag}
        suffix={typedTag ? <PlusOutlined onClick={addTag} /> : <span />}
      />
      {tagsError && <div className={styles.inputError}>{tagsError}</div>}
      <div className={styles.tagsContainer}>
        {tags.map((tag, i) => {
          return (
            <Tag key={tag + i} closable onClose={() => removeTag(i)}>
              {tag}
            </Tag>
          );
        })}
      </div>
    </Form.Item>
  );
});

TagsInput.propTypes = {
  tags: array,
  setTags: func,
  label: string,
};

TagsInput.displayName = "TagsInput";

export default TagsInput;
