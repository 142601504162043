import React, { useState } from "react";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { bool, func, string } from "prop-types";
import { useUploadImage } from "../../api/services";

const mimeTypesToExtensions = {
  "image/jpeg": ["jpg", "jpeg"],
  "image/png": ["png"],
  "image/gif": ["gif"],
};

export default function ImageUpload({
  imageName,
  imageSizeValidation,
  errorMessage,
  altText,
  onUpload,
  defaultImageUrl,
  useThumbnailPreview = true,
  containerName,
  variants,
  mimeTypes = ["image/jpeg", "image/png"],
}) {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const beforeUpload = file => {
    return new Promise((resolve, reject) => {
      const fileExtension = file.name.split(".").pop();

      if (
        !mimeTypes.some(type => type === file.type) ||
        !mimeTypesToExtensions[file.type].includes(fileExtension)
      ) {
        message.error(
          `Invalid file type. Supported: ${mimeTypes
            .map(type => type.split("image/")[1])
            .join(", ")}`
        );
        return reject();
      }

      const reader = new FileReader();

      //Read the contents of Image File.
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        const image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        if (imageSizeValidation) {
          image.onload = function () {
            const { width, height } = this;
            if (imageSizeValidation(width, height)) {
              return resolve();
            }
            message.error(errorMessage);
            return reject();
          };
        } else {
          return resolve();
        }
      };
    });
  };

  const uploadImage = useUploadImage();

  const handleChange = info => {
    if (info.file.status === "uploading") {
      setImageUrl("");
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const _uploadImage = async options => {
    const { file, onSuccess } = options;

    // If we were given a .gif, don't convert to .webp
    const extension = file.type === "image/gif" ? "gif" : "webp";
    const mimeType = file.type === "image/gif" ? "image/gif" : "image/webp";

    let _variants = variants;
    // We don't process variants for gifs because sharp does not do a good job with
    // gif resizing. Instead, we just upload the result and return it as all variants.
    if (extension === "gif") {
      _variants = {};
    }

    const urls = await uploadImage.mutateAsync({
      file,
      imageName,
      containerName,
      variants: _variants,
      extension,
      mimeType,
    });

    // We just uploaded the single unprocessed gif so we need to return it as all variants.
    if (extension === "gif") {
      urls.thumbnailImageUrl = urls.fullImageUrl;
    }

    setImageUrl(
      useThumbnailPreview ? urls.thumbnailImageUrl : urls.fullImageUrl
    );
    if (onUpload) {
      onUpload(urls);
    }
    onSuccess("ok");
  };

  // Accept takes both mime types and file extensions, so we make a big ugly list of all the
  // accepted types.
  const acceptStrings = `${mimeTypes.join(",")},${mimeTypes
    .flatMap(type => mimeTypesToExtensions[type])
    .join(",")}`;

  return (
    <Upload
      accept={acceptStrings}
      disabled={loading}
      listType="picture-card"
      showUploadList={false}
      beforeUpload={file =>
        beforeUpload(file, imageSizeValidation, errorMessage)
      }
      customRequest={_uploadImage}
      onChange={handleChange}
    >
      {defaultImageUrl || imageUrl ? (
        <img
          src={imageUrl || defaultImageUrl}
          alt={altText}
          style={{ width: "100%" }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
}

ImageUpload.propTypes = {
  imageName: string,
  onUpload: func,
  defaultImageUrl: string,
  imageSizeValidation: func,
  altText: string,
  errorMessage: string,
  useThumbnailPreview: bool,
  containerName: string,
};
