import React, { useEffect } from "react";
import { PageHeader, Space } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
//router
import { useHistory } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  getPayouts,
  downloadUnresolved,
  uploadResolvedPayouts,
} from "../../../redux/payouts/payoutActions";
//components
import DownloadUnresolved from "../ActionButtons/DownloadUnresolved";
import UploadResolved from "../ActionButtons/UploadResolved";

export default props => {
  //redux
  const dispatch = useDispatch();
  const payoutsCount = useSelector(state => state.payouts.payoutsCount);

  //pass to button for download to csv
  const downloadUnresolvedPayouts = () =>
    dispatch(downloadUnresolved("payouts"));

  //get payoutsCount on mount
  useEffect(() => {
    dispatch(getPayouts());
  }, []);

  return (
    <div className="main-no-padding border">
      <PageHeader
        extra={
          <Space align="center">
            <DownloadUnresolved
              action={downloadUnresolvedPayouts}
              text="Download Payouts"
              key="download"
            />
            <UploadResolved
              action={uploadResolvedPayouts}
              text="Upload Payouts"
              key="upload"
            />
          </Space>
        }
        ghost={false}
        title={
          <span>
            Payouts{" "}
            {Number.isInteger(payoutsCount) ? (
              payoutsCount ? (
                `(${payoutsCount})`
              ) : (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              )
            ) : (
              ""
            )}
          </span>
        }
      ></PageHeader>
    </div>
  );
};
