const {
  isPaypalTransactionId,
  isEmail,
  isUuid,
} = require("../../utils/regexUtils");

//csvtojson library options https://www.npmjs.com/package/csvtojson#parameters
export const csvToJsonOptions = {
  colParser: {
    payoutAccountId: "number",
    payout: "number",
    //custom handle transactionId with a TAB character at the end
    // so that excel doesnt convert ID 39105578948967454 to 3.91E+16
    transactionId: item => item.trim(),
  },
};

//common properties shared with payouts and paypalTransactions that should be grouped into metadata object
const metadataColumns = [
  "errorCode",
  "errorMessage",
  "comment",
  "transactionNote", //transaction only
  "onboardId", //transaction only
];

//required property and the regex function that must return true or else invalid
const paypalTransactionsRequiredRegex = {
  transactionId: value => isPaypalTransactionId(value), //PK
  email: value => isEmail(value),
  payoutAccountId: value => Number.isInteger(value),
};

export function validatePaypalTransactionsCsv(json) {
  const result = [];
  for (const [index, transaction] of Object.entries(json)) {
    const rowNumber = +index + 2; //offset reference to csv format, header = row 1 and index starts at 1

    //resolved record to push to result
    const transformedTransaction = { metadata: { resolved: true } };

    /**
     * REQUIRED IN UPDATE
     */

    if (transaction.payoutId)
      throw new Error(
        `Row ${rowNumber}: Contains a payoutId, are you sure this is not a Payouts CSV?`
      );

    for (const [requiredProperty, regexFunction] of Object.entries(
      paypalTransactionsRequiredRegex
    )) {
      //if the property is missing, or the regex returns false, throw
      if (
        !transaction[requiredProperty] ||
        !regexFunction(transaction[requiredProperty])
      )
        throw new Error(
          `Row ${rowNumber}: Invalid ${requiredProperty} (${transaction[requiredProperty]})`
        );

      //assign property if valid
      transformedTransaction[requiredProperty] = transaction[requiredProperty];
    }

    /**
     * METADATA PROPERTIES
     */

    //each row requires an explanation, so require comment or have onboardId which has a seperate automatic process
    if (
      (!transaction.onboardId || !isUuid(transaction.onboardId)) &&
      !transaction.comment
    )
      throw new Error(
        `Row ${rowNumber}: Valid onboardId OR comment required to resolve transactionId ${transaction.transactionId}`
      );

    for (const metadataProperty of metadataColumns) {
      if (transaction[metadataProperty])
        transformedTransaction.metadata[metadataProperty] =
          transaction[metadataProperty];
    }

    result.push(transformedTransaction);
  }
  return result;
}

//required property and the regex function that must return true or else invalid
const payoutsRequiredRegex = {
  payoutId: value => isUuid(value), //PK
  paypalEmail: value => isEmail(value),
  payoutAccountId: value => Number.isInteger(value),
  payout: value => !isNaN(value),
};

//optionally update transaction IDs, but validate regex
const payoutsOptionalRegex = {
  transactionId: value => isPaypalTransactionId(value),
  refundId: value => isPaypalTransactionId(value),
};

export function validatePayoutsCsv(json) {
  const result = [];
  for (const [index, payout] of Object.entries(json)) {
    const rowNumber = +index + 2; //offset reference to csv format, header = row 1 and index starts at 1

    //resolved record to push to result
    const transformedPayout = { metadata: { resolved: true } };

    if (!payout.payoutId)
      throw new Error(
        `Row ${rowNumber}: Does NOT contain a payoutId, are you sure this is not a Paypal Transactions CSV?`
      );

    /**
     * REQUIRED IN UPDATE
     */

    for (const [requiredProperty, regexFunction] of Object.entries(
      payoutsRequiredRegex
    )) {
      //if the property is missing, or the regex returns false, throw
      if (!payout[requiredProperty] || !regexFunction(payout[requiredProperty]))
        throw new Error(
          `Row ${rowNumber}: Invalid ${requiredProperty} (${payout[requiredProperty]})`
        );

      //assign property if valid
      transformedPayout[requiredProperty] = payout[requiredProperty];
    }

    /**
     * OPTIONALLY UPDATE TRANSACTION ID OR REFUND ID
     */
    for (const [optionalProperty, regexFunction] of Object.entries(
      payoutsOptionalRegex
    )) {
      if (payout[optionalProperty]) {
        //only validate if optional property is included
        if (!regexFunction(payout[optionalProperty]))
          throw new Error(
            `Row ${rowNumber}: Invalid ${optionalProperty} (${payout[optionalProperty]})`
          );

        //assign property if valid
        transformedPayout[optionalProperty] = payout[optionalProperty];
      }
    }

    /**
     * METADATA PROPERTIES
     */

    //each row requires an transactionId or a comment explaining why cant match to transactionId
    if (
      (!payout.transactionId || !isPaypalTransactionId(payout.transactionId)) &&
      !payout.comment
    )
      throw new Error(
        `Row ${rowNumber}: Valid transactionId OR comment required to resolve payoutId ${payout.payoutId}`
      );

    for (const metadataProperty of metadataColumns) {
      if (payout[metadataProperty])
        transformedPayout.metadata[metadataProperty] = payout[metadataProperty];
    }

    result.push(transformedPayout);
  }
  return result;
}
