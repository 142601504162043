import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { USERS_PATH } from "./users";
import {
  createAuthenticatedRequest,
  defaultMutationOptions,
  executeRequest,
} from "./utils";

export const INVITES_PATH = "invites";

export function useCreateInvite() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation(body => postInvite(body, dispatch), {
    onSuccess: () => {
      queryClient.invalidateQueries(INVITES_PATH);
      queryClient.invalidateQueries(USERS_PATH);
    },
    ...defaultMutationOptions,
  });
}

// Execute a POST to create an invite
export async function postInvite(body, dispatch) {
  const requestObject = await createAuthenticatedRequest(
    `${INVITES_PATH}`,
    dispatch,
    {
      method: "POST",
      body: JSON.stringify(body),
    }
  );

  return executeRequest(requestObject);
}
