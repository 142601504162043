import React, { useEffect, useState } from "react";
import moment from "moment";
import { Input } from "antd";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  searchTransactions,
  searchTransactionsSuccess,
} from "../../../redux/payouts/serviceActions";
//react router
import { useHistory, useParams } from "react-router-dom";
//components

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); //if Mobile

export default function (props) {
  //redux
  const dispatch = useDispatch();

  //router
  const history = useHistory();
  const params = useParams();
  const searchParams = new URLSearchParams(history.location.search);

  // //on unmount, reset posts
  // useEffect(() => {
  //   return () => dispatch(getPostsSuccess(null)); //cleanup
  // }, []);

  useEffect(() => {
    //if has search parameter on load, query
    if (searchParams.has("search")) {
      const searchQuery = searchParams.get("search");
      dispatchSearch(searchQuery);
    }
    return () => dispatch(searchTransactionsSuccess(null)); //cleanup
  }, []);

  const dispatchSearch = query => {
    if (!query) return;
    query = query.trim();
    if (history.location.search !== `?search=${query}`)
      history.push(history.location.pathname + "?search=" + query); //update history if query is different
    dispatch(searchTransactions(query));
  };

  return (
    <Input.Search
      allowClear
      onChange={e => {
        const { value } = e.target;
        if (searchParams.has("search") && !value) {
          //delete params and search results when value is cleared
          history.push(history.location.pathname);
          dispatch(searchTransactionsSuccess(null));
        }
      }}
      defaultValue={
        (searchParams.has("search") && searchParams.get("search")) || null
      }
      style={{ width: isMobile ? "100%" : "40vw" }}
      ref={input => input && input.focus()}
      placeholder="Paypal email, Transaction ID or name"
      enterButton="Search"
      size="large"
      onPaste={event => dispatchSearch(event.clipboardData.getData("text"))}
      // value={"ayor.bush@gmail.com"}
      onSearch={value => dispatchSearch(value)}
    />
  );
}
