import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import CampaignById from "./CampaignById";
import AddNewCampaign from "./AddNewCampaign/AddNewCampaign";
import checkAuth from "../../utils/checkAuth";
import AllCampaigns from "./AllCampaigns";

export default function Campaigns() {
  const { path } = useRouteMatch();

  const roles = useSelector(state => state.auth.roles);

  return (
    <Switch>
      {checkAuth.has(roles, ["admin"]) && (
        <Route path={`${path}/add/:companyId/:contractId`}>
          <AddNewCampaign />
        </Route>
      )}
      <Route path={`${path}/:campaignId`}>
        <CampaignById />
      </Route>
      <Route path={`${path}/`} exact>
        <AllCampaigns />
      </Route>
    </Switch>
  );
}
