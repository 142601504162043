import React from "react";
import { Tag } from "antd";

import { getTagColor } from "../../../utils/getTagColor";

export default function BillingStatusTag(billingContract) {
  // errored tag if billingContract errorState is NOT null
  if (billingContract && billingContract.errorState) {
    return <Tag color="red">error</Tag>;
    // refunded inactive tag color
  } else if (
    billingContract &&
    billingContract.depositRefunded &&
    billingContract.active === false
  ) {
    return <Tag>refunded</Tag>;
  } else if (billingContract && billingContract.billingContractId) {
    const state = billingContract.active ? "active" : "inactive";
    return <Tag color={getTagColor(state)}>{state}</Tag>;
  }
  return null;
}
