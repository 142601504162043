import React, { useEffect, useState } from "react";
//redux
import { useDispatch, useSelector } from "react-redux";
import { string } from "prop-types";
import {
  getInviteGroupsByContractId,
  newInviteGroup,
} from "../../redux/invites/invitesActions";
//components
import InviteGroupsHeader from "./InvitesHeader/InviteGroupsHeader";
import InviteGroupsDataTable from "./InviteGroupsDataTable";
import NewInviteGroupModal from "./NewInviteGroupModal";
//utils
import { newInviteGroupTransform } from "./utils/transformFormValues";

export default function InviteGroups({ contractId }) {
  //create invite group form
  const [visible, setVisible] = useState(false);
  const [additionalAction, setAdditionalAction] = useState(null);

  //redux
  const dispatch = useDispatch();
  const contract = useSelector(state => {
    if (state && state.contracts && Array.isArray(state.contracts.data))
      //find index of id matching contract
      return state.contracts.data.find(c => c.contractId === contractId);
  });

  //get inviteGroups and rows for data table
  const inviteGroups = useSelector(state => {
    if (contract && contract.relations) {
      const { inviteGroups: fetchedInviteGroups } = contract.relations;
      //find index of inviteGroups belonging to contract
      const foundInviteGroups = [];
      for (const inviteGroup of fetchedInviteGroups) {
        const { inviteGroupId } = inviteGroup;
        if (state.invites.data[inviteGroupId])
          foundInviteGroups.push(state.invites.data[inviteGroupId]);
      }
      //if not yet loaded, dispatch
      if (fetchedInviteGroups.length && !foundInviteGroups.length) return null;
      return foundInviteGroups;
    }
  });

  //fetch contractById if not already loaded (ie. visited by URL)
  useEffect(() => {
    if (contract && !inviteGroups)
      dispatch(getInviteGroupsByContractId(contract.contractId));
  }, [contract, dispatch, inviteGroups]);

  const onCreate = values => {
    const newInviteGroupBody = newInviteGroupTransform(
      contract.contractId,
      values
    );
    dispatch(newInviteGroup(newInviteGroupBody));
    setVisible(false);
  };

  //dont render if byId data not loaded
  if (!contract) return null;
  return (
    <div>
      <InviteGroupsHeader
        // currentTab={currentTab}
        // setCurrentTab={setCurrentTab}
        setVisible={setVisible}
        contract={contract}
        additionalAction={additionalAction}
      />
      <InviteGroupsDataTable
        contract={contract}
        inviteGroups={inviteGroups}
        setAdditionalAction={setAdditionalAction}
      />
      <NewInviteGroupModal
        contractName={contract.name}
        campaigns={contract.relations.campaigns}
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </div>
  );
}

InviteGroups.propTypes = {
  contractId: string,
};
