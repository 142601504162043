import React from "react";
import { CopyOutlined, FileOutlined, PictureOutlined } from "@ant-design/icons";
import { PageHeader, Button, Tabs, message, Tag } from "antd";
import { object, func, string, bool, number } from "prop-types";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import checkAuth from "../../../utils/checkAuth";
import { getBreadcrumbProps } from "../../../AppRouter";
import { downloadCampaignPosts } from "../../../redux/campaigns/campaignActions";
import EditCampaignButton from "./EditCampaignButton";
import CopyLandingPageLink from "../../Posts/ActionButtons/CopyLandingPageLink";
import { getTagColor } from "../../../utils/getTagColor";
import { displayDate } from "../../../utils/dates";

const { TabPane } = Tabs;

function CampaignByIdHeader({
  campaign,
  currentTab,
  setCurrentTab,
  hasBilling,
  numDone,
  numRefunds,
  numAudits,
}) {
  //for breadcrumbs
  const history = useHistory();
  //redux
  const dispatch = useDispatch();

  const roles = useSelector(state => state.auth.roles);
  const isAdmin = checkAuth.has(roles, ["admin"]);

  return (
    <div className="main-no-padding border">
      <PageHeader
        ghost={false}
        breadcrumb={getBreadcrumbProps(history.location)} //see https://ant.design/components/breadcrumb/#Use-with-browserHistory
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "0",
            }}
          >
            {campaign.productName}
            <Tag
              color={getTagColor(campaign.state)}
              style={{ marginLeft: "10px" }}
            >
              {campaign.state}
            </Tag>
          </div>
        }
        subTitle={`${displayDate(campaign.startDate).format("MMM Do YYYY")}${
          //conditional if end date
          campaign.endDate
            ? ` - ${displayDate(campaign.endDate).format("MMM Do YYYY")}`
            : ""
        }`}
        extra={[
          <CopyLandingPageLink
            key="CopyLandingPageLink"
            campaign={campaign}
            text="Landing Page"
          />,
          <Button
            icon={<FileOutlined />}
            key="1"
            onClick={
              () => dispatch(downloadCampaignPosts(campaign)) //download all from current tab
            }
          >
            Export Campaign to CSV
          </Button>,
          isAdmin && (
            <EditCampaignButton key="2" text="Edit" campaign={campaign} />
          ),
        ]}
        footer={
          <Tabs
            activeKey={currentTab}
            onChange={activeKey => setCurrentTab(activeKey)}
          >
            <TabPane
              tab={`All (${campaign.totalOnboards || 0})`}
              key="totalOnboards"
            />
            <TabPane
              tab={`Not Started (${campaign.not_started || 0})`}
              key="not_started"
            />
            <TabPane tab={`Started (${campaign.started || 0})`} key="started" />
            <TabPane
              tab={`Not Interested (${campaign.not_interested || 0})`}
              key="not_interested"
            />
            <TabPane
              tab={
                <span>
                  <PictureOutlined />
                  {`Done (${numDone})`}
                </span>
              }
              key="done"
            />
            {hasBilling && (
              <TabPane tab={`Audits (${numAudits})`} key="audits" />
            )}
            {hasBilling && (
              <TabPane tab={`Refunds (${numRefunds})`} key="refunds" />
            )}
            <TabPane tab="Campaign" key="campaign" />
          </Tabs>
        }
      />
    </div>
  );
}

CampaignByIdHeader.propTypes = {
  campaign: object,
  setCurrentTab: func,
  currentTab: string,
  hasBilling: bool,
  numDone: number,
  numRefunds: number,
  numAudits: number,
};

export default CampaignByIdHeader;
