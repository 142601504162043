import moment from "moment";
import { message } from "antd";
import { pushLoading, popLoading, result } from "../shared/sharedActions";
import { getToken } from "../auth/authActions";
import { readCsvFile } from "../../utils/csvUtils";
import {
  validatePaypalTransactionsCsv,
  validatePayoutsCsv,
  csvToJsonOptions,
} from "./validateCsvUpload";

export const types = {
  GET_PAYOUTS_SUCCESS: "GET_PAYOUTS_SUCCESS", //GET api/payouts?resolved=false
  GET_PAYPAL_TRANSACTIONS_SUCCESS: "GET_PAYPAL_TRANSACTIONS_SUCCESS", //GET api/paypalTransactions?resolved=false,
  GET_INVITE_PAYOUTS_SUCCESS: "GET_INVITE_PAYOUTS_SUCCESS", //GET api/invites/:id?payouts=true&inviteState=selected&sortColumn=invites.updatedAt&sortDirection=asc
};

/*
 * ACTIONS
 */

export const getPayoutsSucess = data => {
  return { type: types.GET_PAYOUTS_SUCCESS, data };
};

export const getInvitePayoutsSuccess = data => {
  return { type: types.GET_INVITE_PAYOUTS_SUCCESS, data };
};

export const getPaypalTransactionsSuccess = data => {
  return { type: types.GET_PAYPAL_TRANSACTIONS_SUCCESS, data };
};

export const getInvitePayouts = () => async dispatch => {
  const loadingId = `getInvitePayouts`;
  dispatch(pushLoading(loadingId));
  try {
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/invites?payouts=true&inviteState=selected&sortColumn=invites.updatedAt&sortDirection=asc`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);

    dispatch(getInvitePayoutsSuccess(json));
  } catch (error) {
    console.error(error);
    //handle
    dispatch(
      result({
        status: `error`,
        title: `Get Payouts data failed`,
        subTitle: error.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};

export const getPayouts = () => async dispatch => {
  const loadingId = `getPayouts`;
  dispatch(pushLoading(loadingId));
  try {
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/payouts?resolved=false`,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    let res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    dispatch(getPayoutsSucess(json));
  } catch (error) {
    console.error(error);
    //handle
    dispatch(
      result({
        status: `error`,
        title: `Get Payouts data failed`,
        subTitle: error.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};

export const getPaypalTransactions = () => async dispatch => {
  const loadingId = `getPaypalTransactions`;
  dispatch(pushLoading(loadingId));
  try {
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/paypalTransactions?resolved=false`,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    let res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    dispatch(getPaypalTransactionsSuccess(json));
  } catch (error) {
    console.error(error);
    //handle
    dispatch(
      result({
        status: `error`,
        title: `Get Paypal Transactions data failed`,
        subTitle: error.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};

/**
 * @param {String} path payouts or paypalTransactions
 */
export const downloadUnresolved = path => async dispatch => {
  //loading
  const loadingId = `downloadUnresolved${path}`;
  dispatch(pushLoading(loadingId));

  //auth
  const token = await dispatch(getToken());
  return fetch(
    `${process.env.REACT_APP_API_HOST}api/${path}?csv=true&resolved=false`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json; charset=utf-8",
      },
    }
  )
    .then(response => response.blob())
    .then(blob => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = `${path} - ${moment().format("YYYY-MM-DD")}.csv`; //filename
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    })
    .catch(error => {
      //error handling
      dispatch(
        result({
          status: `error`,
          title: `Download ${path} data failed`,
          subTitle: error.message,
        })
      );
    })
    .finally(() => dispatch(popLoading(loadingId))); //finish loading
};

export const uploadResolvedPayouts = file => async (dispatch, getState) => {
  const json = await readCsvFile(file, csvToJsonOptions);

  let transformedJson;
  //transform and validate flat JSON array to have objects with resolved = true
  try {
    transformedJson = validatePayoutsCsv(json);
  } catch (error) {
    console.error(error);
    return dispatch(
      result({
        status: `error`,
        title: error.message,
        subTitle: `Invalid Payouts CSV`,
      })
    );
  }

  /**
   * PUT api/payouts after csv parse
   */
  const loadingId = `uploadResolvedPayouts`;
  dispatch(pushLoading(loadingId));
  try {
    //auth
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/payouts`,
      {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(transformedJson), //update and send $$ via paypal
      }
    );
    let res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    //success - reload counts
    message.success(`${transformedJson.length} Payouts successfully updated`);
    dispatch(getPayouts());
  } catch (err) {
    dispatch(
      result({
        status: `error`,
        title: `Update Payouts failed`,
        subTitle: err.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};

export const uploadResolvedPaypalTransactions =
  file => async (dispatch, getState) => {
    const json = await readCsvFile(file, csvToJsonOptions);

    let transformedJson;
    //transform and validate flat JSON array to have objects with resolved = true
    try {
      transformedJson = validatePaypalTransactionsCsv(json);
    } catch (error) {
      console.error(error);
      return dispatch(
        result({
          status: `error`,
          title: error.message,
          subTitle: `Invalid Paypal Transactions CSV`,
        })
      );
    }

    /**
     * PUT api/paypalTransations after csv parse
     */
    const loadingId = `uploadResolvedPaypalTransactions`;
    dispatch(pushLoading(loadingId));
    try {
      //auth
      const token = await dispatch(getToken());
      const requestObject = new Request(
        `${process.env.REACT_APP_API_HOST}api/paypalTransactions`,
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(transformedJson), //update and send $$ via paypal
        }
      );
      let res = await fetch(requestObject);
      const json = await res.json();
      if (!res.ok) throw new Error(json.message || res.statusText);
      //success - reload counts
      message.success(
        `${transformedJson.length} Paypal transactions successfully updated`
      );
      dispatch(getPaypalTransactions());
      dispatch(getPayouts());
    } catch (err) {
      dispatch(
        result({
          status: `error`,
          title: `Update Paypal Transactions failed`,
          subTitle: err.message,
        })
      );
    } finally {
      dispatch(popLoading(loadingId));
    }
  };

export const getPayoutDetails =
  (payoutAccountId, payoutId) => async dispatch => {
    // return {
    //   batch_header: {
    //     payout_batch_id: "8M8L4P48FWBAY",
    //     batch_status: "SUCCESS",
    //     time_created: "2022-04-09T23:35:43Z",
    //     time_completed: "2022-04-09T23:35:45Z",
    //     time_closed: "2022-04-09T23:35:45Z",
    //     sender_batch_header: {
    //       sender_batch_id: "summer.setzer@gmail.com16product",
    //       email_subject: "$42 Influencer Product payment",
    //       email_message:
    //         "Elle &amp; Anther campaign Product payment. Onboard ID: 333035a0-b145-11ec-8666-000000000000",
    //     },
    //     funding_source: "BALANCE",
    //     amount: {
    //       currency: "USD",
    //       value: "42.00",
    //     },
    //     fees: {
    //       currency: "USD",
    //       value: "0.25",
    //     },
    //   },
    //   items: [
    //     {
    //       payout_item_id: "F6AUX2Y2PCAP6",
    //       transaction_id: "4TB11143FA907192S",
    //       activity_id: "4U098546WM652872E",
    //       transaction_status: "SUCCESS",
    //       payout_item_fee: {
    //         currency: "USD",
    //         value: "0.25",
    //       },
    //       payout_batch_id: "8M8L4P48FWBAY",
    //       payout_item: {
    //         recipient_type: "EMAIL",
    //         amount: {
    //           currency: "USD",
    //           value: "42.00",
    //         },
    //         receiver: "summer.setzer@gmail.com",
    //         sender_item_id: "333035a0-b145-11ec-8666-000000000000",
    //         recipient_wallet: "PAYPAL",
    //       },
    //       time_processed: "2022-04-09T23:35:45Z",
    //       links: [
    //         {
    //           href: "https://api.paypal.com/v1/payments/payouts-item/F6AUX2Y2PCAP6",
    //           rel: "item",
    //           method: "GET",
    //           encType: "application/json",
    //         },
    //       ],
    //     },
    //   ],
    //   links: [
    //     {
    //       href: "https://api.paypal.com/v1/payments/payouts/8M8L4P48FWBAY?page_size=1000&page=1",
    //       rel: "self",
    //       method: "GET",
    //       encType: "application/json",
    //     },
    //   ],
    // };
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/services/paypal/${payoutAccountId}/payout/${payoutId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    return json;
  };
