import React from "react";
import { Divider, Typography } from "antd";
import { any, bool, string } from "prop-types";

export default function DeleteCreditsInvoice({
  billingSetup,
  isGenericProductCredits,
}) {
  if (!billingSetup) {
    return null;
  }

  const { platformCreditSettings, productCreditSettings } = billingSetup;

  const containsNegativeCredits = () => {
    if (platformCreditSettings.newCredits < 0) {
      return true;
    }
    for (const productSetting of productCreditSettings) {
      if (productSetting.newCredits < 0) {
        return true;
      }
    }
    return false;
  };

  if (!containsNegativeCredits()) {
    return null;
  }

  return (
    <div>
      <Divider orientation="left">Delete Credits</Divider>
      <ul>
        {platformCreditSettings.newCredits < 0 ? (
          <li>
            <Typography.Text>{`${platformCreditSettings.newCredits} Instagram Grid Post Credits`}</Typography.Text>
          </li>
        ) : null}

        {productCreditSettings.map((productSetting, i) => {
          if (productSetting.newCredits < 0) {
            if (isGenericProductCredits) {
              return (
                <li key={i}>
                  <Typography.Text>{`${productSetting.newCredits} Generic Product Credits`}</Typography.Text>
                </li>
              );
            }

            return (
              <li key={i}>
                <Typography.Text>{`${productSetting.newCredits} ${productSetting.name} Credits`}</Typography.Text>
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
}

DeleteCreditsInvoice.propTypes = {
  billingSetup: any,
  isGenericProductCredits: bool,
};
