import React from "react";
import moment from "moment";
import { CheckCircleTwoTone, CameraFilled } from "@ant-design/icons";
import { Tag, Typography } from "antd";
import {
  shortcodeModalMouseEnter,
  shortcodeModalMouseLeave,
} from "./InstaPostModal";

const { Text } = Typography;

export const paypalPayoutId = record => {
  const payout = record.relations.payouts.find(
    payout => payout.payoutType === "product"
  );
  if (payout.upfront) return <Tag color="blue">Upfront</Tag>;
  if (!payout.upfront && !payout.payoutDate)
    return <Tag color="red">Rebate Pending</Tag>;
  return payout.paypalPayoutId;
};

export const currentStep = (text, record) => {
  switch (record.state) {
    case "followed_up":
    case "done":
      text = (
        <a
          rel="noopener noreferrer"
          style={{ fontWeight: 500 }}
          target="_blank"
          href={`http://instagram.com/p/${record.shortcodeId}`}
          onMouseEnter={event => shortcodeModalMouseEnter(record)}
          onMouseLeave={event => shortcodeModalMouseLeave()}
        >
          <CheckCircleTwoTone twoToneColor="#52c41a" /> Done
        </a>
      );
      break;
    case "started":
      text = (
        <Text type="warning" strong>
          In Progress
        </Text>
      );
      break;
    case "not_started":
      text = (
        <Text type="danger" strong>
          Pending Action
        </Text>
      );
      break;
    case "not_interested":
      text = (
        <Text type="secondary" strong>
          Not Interested
        </Text>
      );
      break;
    case "not_interested_done":
      text = (
        <Text type="secondary" strong>
          <CheckCircleTwoTone twoToneColor="#52c41a" /> Didnt Like Product
        </Text>
      );
      break;
    default:
      break;
  }
  return text;
};

export default [
  ["currentStep", { render: currentStep, path: "currentStepIndex" }],
  ["firstName", { path: "relations.users.firstName" }],
  [
    "deadlineDate",
    {
      path: "deadlineDate",
      render: (text, record) => {
        if (!text) {
          return "No Deadline Set";
        }
        return `${moment(text).format("MMM Do, YYYY")} (${
          record.extensionCount || 0
        } ${record.extensionCount === 1 ? "Extension" : "Extensions"})`;
      },
    },
  ],
  ["email", { path: "relations.users.email" }],
  ["interested"],
  //row2
  [
    "Completed Date",
    {
      path: "completedDate",
      render: (text, record) => {
        if (!text) {
          return null;
        }
        return moment(text).format("MMM Do, YYYY");
      },
    },
  ],
  ["platformCreditCharged"],
  ["productCreditCharged"],
  // [
  //   "emailsSent", //current step + warning / cases
  //   (text, record) => text && text[record.currentStep] + text[text.length - 1],
  // ],
  // ["paypalEmail", { path: "relations.payouts[0].paypalEmail" }],
  // [
  //   "payout",
  //   { render: text => `$${text}`, path: "relations.payouts[0].payout" },
  // ],
  // ["paypalPayoutId", { render: paypalPayoutId }],
  //row3
  [
    "screenshot",
    {
      render: (text, record) => {
        const screenshot =
          (text && `${process.env.REACT_APP_SCREENSHOT_HOST}${text}.png`) ||
          (record.metadata && record.metadata.manual_screenshot) ||
          "";
        return screenshot ? (
          <a rel="noopener noreferrer" href={screenshot} target="_blank">
            <CameraFilled style={{ fontSize: 21 }} />
          </a>
        ) : null;
      },
      //render using shortcodeId
      path: "shortcodeId",
    },
  ],
  ["caption", { path: "relations.shortcodes.caption" }],
];
