import React, { useEffect, useMemo } from "react";
import { Checkbox, DatePicker, Form, Space, Typography, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import moment from "moment";
import {
  resetSendState,
  updateEmailGroup,
  updateSendState,
  updateTypeState,
  uploadUserCsv,
} from "../../../redux/invites/sendInvitesActions";
import GenericInvitesForm from "./GenericInvitesForm";
import { getJoinEmailGroup } from "../../../redux/invites/invitesActions";
import QueryAutocomplete from "./QueryAutocomplete";

/*
SELECTORS
*/
const selectJoinEmailGroup = createSelector(
  state => state.invites.joinEmailGroup,
  joinEmailGroup => joinEmailGroup
);

const selectInviteType = createSelector(
  state => state.invites.type,
  type => type
);
//Antd Form w/ Redux example - https://ant.design/components/form/#components-form-demo-global-state
function JoinInvitesForm() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  //get data from redux state when new inviteGroups loaded
  const joinEmailGroup = useSelector(state => selectJoinEmailGroup(state));

  const inviteType = useSelector(state => selectInviteType(state));

  useEffect(() => {
    dispatch(getJoinEmailGroup());
    dispatch(updateTypeState("join"));
    if (inviteType === "invite") {
      dispatch(resetSendState());
    }
    dispatch(updateSendState({ sendEmails: true }));
  }, [dispatch, inviteType]);

  const sendState = useSelector(state => state.invites.send);
  const previewState = useSelector(state => state.invites.preview);

  const inPreviewTab = sendState.tab === "preview";
  const woodpeckerFields = useMemo(() => {
    if (!joinEmailGroup) {
      return [];
    }
    return joinEmailGroup.relations.woodpeckerCampaigns.map(campaign => ({
      //push each woodpecker campaign in selectedInviteGroupsData
      name: campaign.woodpeckerCampaignId,
      //if in preview tab, use results from POST invites?preview=true
      value:
        inPreviewTab && previewState.output
          ? previewState.output.woodpeckerCampaigns[
              campaign.woodpeckerCampaignId
            ]
          : sendState[campaign.woodpeckerCampaignId],
    }));
  }, [joinEmailGroup, sendState, previewState, inPreviewTab]);

  const formFields = [
    {
      name: "tagQuery",
      value: sendState.tagQuery,
      component: (
        <Form.Item
          key="tagQuery"
          name="tagQuery"
          label="Override Tags"
          extra="e.g. all=gender:female&any=niche:fitness,niche:food"
        >
          <Space size="large">
            <QueryAutocomplete
              style={{ width: "50vw" }}
              disabled={inPreviewTab}
              onChange={value => {
                form.setFieldsValue({ tagQuery: value });
                dispatch(updateSendState({ tagQuery: value }));
              }}
              value={sendState.tagQuery}
            />
            <Upload
              name="csv"
              customRequest={() => undefined}
              showUploadList={false}
              disabled={inPreviewTab}
              action={async file => dispatch(uploadUserCsv(file))}
              // onChange={(file, fileList, event) => {
              //   console.log("file,fileList,event", file, fileList, event);
              // }}
            >
              or <Typography.Link>upload a CSV with User IDs</Typography.Link>
            </Upload>
          </Space>
        </Form.Item>
      ),
    },
    {
      name: "sendSMSReminder",
      key: "sendSMSReminder",
      value: sendState.sendSMSReminder,
      component: (
        <Form.Item
          label="Send SMS Reminder"
          style={{
            marginBottom: 0,
          }}
        >
          <Form.Item
            name="sendSMSReminder"
            valuePropName="checked"
            style={{
              display: "inline-block",
            }}
          >
            <Checkbox disabled={inPreviewTab} />
          </Form.Item>
          <Form.Item
            name="reminderDate"
            style={{
              display: "inline-block",
              marginLeft: "16px",
            }}
            extra="You should only schedule it between 1 PM and 4 PM"
          >
            <DatePicker
              disabled={!sendState.sendSMSReminder}
              showNow={false}
              showTime={{
                defaultValue: moment("13:00", "HH:mm"),
                format: "HH:mm",
              }}
              style={{ width: "100%" }}
              placeholder="Select SMS Reminder Date"
              disabledDate={current => {
                const fiveDaysAhead = moment().add(5, "days").startOf("day");
                const thirteenDaysAhead = moment().add(13, "days").endOf("day");
                return (
                  current.isBefore(fiveDaysAhead) ||
                  current.isAfter(thirteenDaysAhead)
                );
              }}
              format="YYYY-MM-DD HH:mm"
              disabledTime={() => ({
                disabledHours: () => [
                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 16, 17, 18, 19, 20,
                  21, 22, 23,
                ],
              })}
            />
          </Form.Item>
        </Form.Item>
      ),
    },
    {
      name: "reminderDate",
      value: moment(sendState.reminderDate),
    },
    ...woodpeckerFields,
  ];

  const generateGroupData = () => {
    if (!joinEmailGroup) {
      return [];
    }
    const groupData = [
      {
        ...joinEmailGroup,
        woodpeckerCampaigns: joinEmailGroup.relations.woodpeckerCampaigns,
      },
    ];
    return groupData;
  };

  const onRemoveWoodpeckerCampaign = woodpeckerCampaign => {
    const newJoinEmailGroup = JSON.parse(JSON.stringify(joinEmailGroup));
    const woodpeckerCampaignIndex =
      newJoinEmailGroup.relations.woodpeckerCampaigns.findIndex(
        campaign => campaign.woodpeckerCampaignId === woodpeckerCampaign.id
      );
    newJoinEmailGroup.relations.woodpeckerCampaigns.splice(
      woodpeckerCampaignIndex
    );
    for (const campaign of newJoinEmailGroup.relations.woodpeckerCampaigns) {
      delete campaign.metadata;
    }
    dispatch(
      updateEmailGroup(newJoinEmailGroup.emailGroupId, newJoinEmailGroup)
    );
  };

  return (
    <GenericInvitesForm
      form={form}
      onRemoveWoodpeckerCampaign={onRemoveWoodpeckerCampaign}
      groupData={generateGroupData()}
      formFields={formFields}
      onValuesChange={changedValues => {
        //update invites.send form based on field value
        dispatch(updateSendState(changedValues));
      }}
    />
  );
}

export default JoinInvitesForm;
