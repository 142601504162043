import React from "react";
import { Form, Input, Button, Select, InputNumber } from "antd";
import { array, bool, func, string } from "prop-types";

import { WarningOutlined } from "@ant-design/icons";
import TwoComponentSwitch from "../../shared/TwoComponentSwitch/TwoComponentSwitch";
import styles from "./UsersForm.module.css";
import { useCreateB2CUser } from "../../../api/b2cUsers";
import {
  defaultButtonWrapperCol,
  defaultFormItemLayout,
} from "../../../utils/formLayout";
import { selectFilter } from "../../../utils/filters";

const FirstForm = ({ options, name, loading }) => {
  return (
    <Form.Item
      label="Authorized Users"
      getValueFromEvent={(value, option) => {
        return option.map(o => o.value);
      }}
      className={styles.formItem}
      name={name}
      rules={[{ required: true, message: "required" }]}
    >
      <Select
        filterOption={selectFilter}
        loading={loading}
        mode="multiple"
        showSearch
        options={options}
        className={styles.fullWidth}
        placeholder="Type to search authorized users"
      />
    </Form.Item>
  );
};

FirstForm.propTypes = {
  options: array,
  name: string,
  loading: bool,
};

const SecondForm = ({
  toggleVisibleComponent,
  options,
  setOptions,
  addSelectedOption,
}) => {
  const [form] = Form.useForm();

  const createUser = useCreateB2CUser();
  const { isError, isLoading, error } = createUser;

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue(true);
      createUser.mutate(
        {
          email: values.authorizedEmail,
          displayName: values.firstName,
          notificationDelay: values.notificationDelay,
          roles: ["client"],
        },
        {
          onSuccess: data => {
            const newOption = {
              value: data.email,
              label: `${data.email} - ${data.displayName}`,
            };
            setOptions([newOption, ...options]);
            addSelectedOption(newOption.value);
            toggleVisibleComponent();
          },
        }
      );
    } catch (error) {
      //ignore since ant design will handle validation based on form.item rules
    }
  };

  return (
    <Form
      form={form}
      component={false}
      name="createNewUser"
      {...defaultFormItemLayout}
      labelAlign="right"
      layout="horizontal"
      onFinish={onSubmit}
      initialValues={{
        notificationDelay: 4,
      }}
    >
      <Form.Item
        label="Authorized Email"
        name="authorizedEmail"
        rules={[
          {
            required: true,
            message: "a valid email is required",
            type: "email",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="First Name"
        name="firstName"
        rules={[{ required: true, message: "required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Notification Delay"
        name="notificationDelay"
        extra="Number of hours to delay sending an email to this user after clicking Submit (max = 72)"
        rules={[
          { required: true, message: "required" },
          {
            max: 72,
            type: "number",
            message:
              "The maximum number of hours to delay is 72 hours (3 days)",
          },
        ]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item wrapperCol={defaultButtonWrapperCol}>
        <Button loading={isLoading} onClick={onSubmit}>
          Submit
        </Button>
        {isError && (
          <h4 className={styles.error}>
            <WarningOutlined fill="red" /> {error.message}
          </h4>
        )}
      </Form.Item>
    </Form>
  );
};

SecondForm.propTypes = {
  options: array,
  addSelectedOption: func,
  toggleVisibleComponent: func,
  setOptions: func,
};

function UsersForm({
  options,
  setOptions,
  name,
  addSelectedOption,
  loading,
  onSwitch,
}) {
  return (
    <TwoComponentSwitch
      activateFirstText="Select Existing Users"
      activateSecondText="Create New User"
      className={styles.fullWidth}
      onSwitch={onSwitch}
      firstComponent={
        <FirstForm options={options} name={name} loading={loading} />
      }
      secondComponent={
        <SecondForm
          options={options}
          setOptions={setOptions}
          addSelectedOption={addSelectedOption}
        />
      }
    />
  );
}

UsersForm.propTypes = {
  options: array,
  setOptions: func,
  addSelectedOption: func,
  name: string,
  loading: bool,
  onSwitch: func,
};

export default UsersForm;
