import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  createAuthenticatedRequest,
  defaultQueryOptions,
  executeRequest,
} from "./utils";

export const PRODUCT_PLATFORMS_PATH = "productPlatforms";

export function useProductPlatforms({ includes, params, ...overrideOptions }) {
  const dispatch = useDispatch();

  return useQuery(
    [PRODUCT_PLATFORMS_PATH, includes, params],
    () => fetchProductPlatforms(dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for product platforms
export async function fetchProductPlatforms(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    PRODUCT_PLATFORMS_PATH,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}
