import React, { useEffect, useMemo } from "react";
import { Link, Route, Switch, Redirect, withRouter } from "react-router-dom";
import {
  SearchOutlined,
  NotificationOutlined,
  ProfileOutlined,
  MailOutlined,
  DollarOutlined,
  ExperimentOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { result } from "./redux/shared/sharedActions";
//components
import Result from "./components/shared/Result";
import Contracts from "./components/Contracts/Contracts";
import Campaigns from "./components/Campaigns/Campaigns";
import PostsSearch from "./components/Posts/Search/PostsSearch";
import Invites from "./components/Invites/Invites";
import Payouts from "./components/Payouts/Payouts";
import Test from "./components/Test/Test";
import ManualValidation from "./components/ManualValidation/ManualValidation";
//utils
import checkAuth from "./utils/checkAuth";
import clipboard from "./utils/clipboard";
import { isUuid } from "./utils/regexUtils";

//table of contents of app
export const ROUTES = {
  "/contracts": {
    title: "Contracts",
    icon: <ProfileOutlined />,
    component: Contracts,
    roles: ["supervisor", "client"],
  },
  "/campaigns": {
    title: "Campaigns",
    icon: <NotificationOutlined />,
    component: Campaigns,
    roles: ["supervisor", "client"],
  },
  "/onboards": {
    title: "Onboards",
    icon: <SearchOutlined />,
    component: PostsSearch,
    roles: ["manager"],
  },
  "/invites": {
    title: "Invites",
    icon: <MailOutlined />,
    component: Invites,
    roles: ["admin"],
  },
  "/payouts": {
    title: "Payouts",
    icon: <DollarOutlined />,
    component: Payouts,
    roles: ["admin"],
  },
  "/test": {
    title: "Test",
    icon: <ExperimentOutlined />,
    component: Test,
    roles: ["admin"],
  },
  "/validation": {
    title: "Validation",
    icon: <SafetyCertificateOutlined />,
    component: ManualValidation,
    roles: ["validate"],
  },
};
export const routerPaths = Object.keys(ROUTES); //array of paths
const originalDocTitle = document.title;

//render routes based on user roles
const selectRoles = state => state.auth.roles;
const selectRoutesToRender = createSelector(selectRoles, roles =>
  routerPaths.reduce((accum, path) => {
    //check state.auth.roles
    if (checkAuth.has(roles, ROUTES[path].roles))
      accum.push(
        <Route
          exact={path == "/"}
          key={path}
          path={path == "/" ? path : `${path}/:optionalPathParam?`} //if not home "/" use optional param
          component={ROUTES[path].component}
        />
      ); //dynamically render ROUTES
    return accum;
  }, [])
);

export default withRouter(props => {
  const dispatch = useDispatch();

  //select
  const resultState = useSelector(state => state.shared.result);
  const routesToRender = useSelector(state => selectRoutesToRender(state));
  const homepage = routesToRender[0] && routesToRender[0].key; //first available route set as homepage

  //during route change, reset result and change doc title
  useEffect(() => {
    if (resultState)
      document.title = `${resultState.title} - ${originalDocTitle}`;
    else if (ROUTES[props.match.url])
      document.title = `${ROUTES[props.match.url].title} - ${originalDocTitle}`;
    else if (homepage) document.title = `404 Not Found - ${originalDocTitle}`;
  }, [props.location.pathname, props.match.url, resultState]);

  //reset result (ie. error screens) on navigate
  useEffect(() => {
    dispatch(result(null));
  }, [props.location.pathname, props.location.search, props.match.url]);

  //performance boost see: https://reactjs.org/docs/hooks-faq.html#how-to-memoize-calculations
  //only rerender on route change
  const memoizedSwitch = useMemo(
    () => (
      <Switch>
        <Redirect exact from="/" to={`${homepage}/:optionalPathParam?`} />
        {routesToRender}
        {/* default to 404 result */}
        <Route exact>
          <Result
            //  allow clearing result by going back */
            goBack={props.history.goBack}
          />
        </Route>
      </Switch>
    ),
    [props.history.goBack, routesToRender]
  );

  //render result if resultState in redux
  if (resultState) return <Result goBack={() => dispatch(result(null))} />;
  //if no resultState, render switch
  return memoizedSwitch;
});

//getBreadcrumbProps for use in ant's PageHeader component 'breadcrumb' prop
//see PageHeader ant docs: https://ant.design/components/page-header/#components-page-header-demo-breadcrumb
//see Dreadcrumb ant docs: https://ant.design/components/breadcrumb/#Use-with-browserHistory
export const itemRender = (route, params, routes, paths) => {
  if (routes.length <= 1) return []; //dont render if only 1 path
  const isLastRoute = routes.indexOf(route) === routes.length - 1;
  const isId = isUuid(route.breadcrumbName);
  const capitalizedRouteName = isId
    ? route.breadcrumbName //dont capitalize UUID
    : route.breadcrumbName[0].toUpperCase() + route.breadcrumbName.slice(1);
  return isLastRoute ? (
    //copy to clipboard if UUID
    <span onClick={() => isId && clipboard(route.breadcrumbName)}>
      {capitalizedRouteName}
    </span>
  ) : (
    <Link to={route.path}>{capitalizedRouteName}</Link>
  );
};

export const getBreadcrumbProps = location => {
  //extract paths to display
  const pathSnippets = location.pathname.split("/").filter(i => i);
  return {
    itemRender,
    routes: pathSnippets.map((value, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      return {
        path: url,
        breadcrumbName: (ROUTES[url] && ROUTES[url].title) || value,
      };
    }),
  };
};
