import React, { useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Form, Button, Modal, Input, InputNumber, Select } from "antd";
//redux
import { useDispatch, useSelector } from "react-redux";
import { updatePayout } from "../../../redux/posts/postActions";
//utils
import checkAuth from "../../../utils/checkAuth";
import { camelCaseToSentenceCase } from "../../../utils/stringUtils";
import {
  paypalTransactionIdRegex,
  emailRegex,
} from "../../../utils/regexUtils";

//transactionId and refundId regex
const transactionValidation = [
  {
    pattern: paypalTransactionIdRegex,
    message: "Invalid ID format. Requires 17 characters",
  },
];

//transactionId and refundId regex
const emailValidation = [
  {
    pattern: emailRegex,
    message: "Invalid email format",
  },
  {
    required: true,
    message: "Required field",
  },
];

//TODO: hardcoded sequence 1-5. should use GET api/payoutAccounts and filter where active=true
const PAYOUT_ACCOUNT_IDS = [1, 2, 3, 4, 5, 6];

export default props => {
  const { payout } = props;

  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);

  //redux
  const dispatch = useDispatch();
  const roles = useSelector(state => state.auth.roles);

  if (!payout || !checkAuth.has(roles, ["supervisor"])) return null;

  const onSubmit = values => {
    dispatch(updatePayout(payout, values));
    setVisible(false);
  };

  return (
    <span>
      <Button
        key="close"
        icon={<EditOutlined />}
        onClick={() => setVisible(true)}
      >
        {props.text}
      </Button>

      <Modal
        title={`Edit ${camelCaseToSentenceCase(payout.payoutType)} payout to ${
          payout.paypalEmail
        }`}
        visible={visible}
        okText={"Submit"}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              onSubmit(values);
            })
            .catch(e => null); //do nothing on validate fail
        }}
        // confirmLoading={confirmLoading}
        onCancel={() => {
          //close and reset input
          setVisible(false);
        }}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            required={true}
            name="payoutAccountId"
            initialValue={payout.payoutAccountId}
            label="Payout Account ID"
          >
            <Select>
              {Array.from(PAYOUT_ACCOUNT_IDS).map(int => (
                <Select.Option key={int} value={int}>
                  {int}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="paypalEmail"
            initialValue={payout.paypalEmail}
            label="Paypal Email"
            rules={emailValidation}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            required={true}
            name="transactionId"
            initialValue={payout.transactionId}
            label="Transaction ID"
            rules={transactionValidation}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            required={true}
            name="refundId"
            initialValue={payout.refundId}
            label="Refund ID"
            rules={transactionValidation}
          >
            <Input allowClear />
          </Form.Item>
        </Form>
      </Modal>
    </span>
  );
};
