export const flowTypes = {
  INSTAGRAM: "instagram",
  FLIPSHOP: "flipshop",
};

export function getSubmitLink({ campaignFlowType, onboardId }) {
  const type = campaignFlowType || flowTypes.INSTAGRAM;

  return `${process.env.REACT_APP_INFLUENCER_BRAND_HOST}influencer/submit/${onboardId}/${type}`;
}
