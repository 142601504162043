import React, { useState, useEffect } from "react";
import { Form, Space, Button } from "antd";
import { any, bool, func, object, string } from "prop-types";
import ProductForm from "./ProductForm";
import styles from "./AddNewCampaign.module.css";
import {
  defaultFormItemLayout,
  defaultButtonWrapperCol,
} from "../../../utils/formLayout";

function ProductStep({
  form,
  onFormDisabled,
  products,
  loading,
  categories,
  productPlatforms,
}) {
  const [productOptions, setProductOptions] = useState([]);
  const [showingNextButton, setShowingNextButton] = useState(true);
  const [productPlatformOptions, setProductPlatformOptions] = useState([]);

  useEffect(() => {
    if (products) {
      setProductOptions(
        Object.values(products).map(product => {
          return {
            value: JSON.stringify({
              productId: product.productId,
              name: product.name,
              code: product.code,
              productPlatformId: product.productPlatformId,
            }),
            label: product.name,
          };
        })
      );
    }
  }, [products]);

  useEffect(() => {
    if (productPlatforms) {
      setProductPlatformOptions(
        Object.values(productPlatforms).map(productPlatform => {
          return {
            value: productPlatform.productPlatformId,
            label: productPlatform.name,
          };
        })
      );
    }
  }, [productPlatforms]);

  const toggleSubmitButton = activeComponent => {
    //if the product creation form is showing, hide the top-level form submission button
    setShowingNextButton(activeComponent === 1);
    if (onFormDisabled) {
      //indicate to the top level parent that this form is disabled
      onFormDisabled(activeComponent === 2);
    }
  };

  return (
    <>
      <Form
        className={styles.formContainer}
        name="productForm"
        {...defaultFormItemLayout}
        labelAlign="right"
        layout="horizontal"
        form={form}
      >
        <Space direction="vertical" className={styles.form} size="large">
          <ProductForm
            onSwitch={toggleSubmitButton}
            form={form}
            loading={loading}
            name="product"
            options={productOptions}
            productPlatformOptions={productPlatformOptions}
            setOptions={setProductOptions}
            setSelectedOption={option => {
              form.setFieldsValue({ product: option.value });
            }}
            categories={categories}
          />
          {showingNextButton && (
            <Form.Item
              className={styles.buttonsContainer}
              wrapperCol={defaultButtonWrapperCol}
            >
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
          )}
        </Space>
      </Form>
    </>
  );
}

ProductStep.propTypes = {
  companyId: string,
  form: any,
  onFormDisabled: func,
  products: object,
  loading: bool,
  categories: object,
  productPlatforms: object,
};

export default ProductStep;
