import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  createAuthenticatedRequest,
  defaultQueryOptions,
  executeRequest,
} from "./utils";
import { flattenCampaigns } from "../redux/campaigns/campaignUtils";

export const CAMPAIGN_FLOWS_PATH = "campaignFlows";

export function useCampaignFlows({ includes, params, ...overrideOptions }) {
  const dispatch = useDispatch();

  return useQuery(
    [CAMPAIGN_FLOWS_PATH, includes, params],
    () => fetchCampaignFlows(dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for all campaigns
export async function fetchCampaignFlows(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    CAMPAIGN_FLOWS_PATH,
    dispatch,
    options
  );

  const campaignFlows = await executeRequest(requestObject);
  return flattenCampaigns(campaignFlows);
}
