import { Input, Space, Select, Form, Button } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectOutlined, WarningOutlined } from "@ant-design/icons";
import { any, func } from "prop-types";
import styles from "./Payouts.module.css";
import { updateInvites } from "../../redux/invites/invitesActions";

export default function InstagramDisqualify({
  currentInvite,
  moveToNextInvite,
}) {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [form] = Form.useForm();

  const isLoading = useSelector(state => state.shared.isLoading);

  useEffect(() => {
    if (currentInvite) {
      form.setFieldsValue({
        igHandle: `@${currentInvite.relations?.users?.activeProfile}`,
        disqualifyReason: null,
      });
      setErrorMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInvite]);

  const disqualifyInvite = async () => {
    let formValues = null;
    try {
      await form.validateFields();
      formValues = form.getFieldsValue(true);
    } catch (err) {
      return;
    }
    try {
      setErrorMessage("");
      const disqualifyInviteBody = {
        reason: formValues.disqualifyReason,
      };
      const updateResult = await dispatch(
        updateInvites(
          disqualifyInviteBody,
          `${currentInvite.inviteId}/disqualify`
        )
      );
      if (updateResult?.errorCount > 0) {
        setErrorMessage(updateResult.errors[currentInvite.inviteId]);
        return;
      }
      moveToNextInvite();
    } catch (err) {
      setErrorMessage(err?.message || "An unknown error occurred");
    }
  };

  return (
    <>
      <Form
        labelAlign="right"
        layout="horizontal"
        className={styles.formContainer}
        style={{ marginBottom: "-40px" }}
        labelCol={{
          xs: { span: 8 },
          md: { span: 6 },
          lg: { span: 6 },
        }}
        wrapperCol={{
          xs: { span: 14 },
          md: { span: 16 },
          lg: { span: 14 },
        }}
        form={form}
      >
        <Space direction="vertical" size="large" className={styles.form}>
          <Form.Item label="IG Handle" name="igHandle">
            <Input
              disabled
              suffix={
                currentInvite && (
                  <SelectOutlined
                    className={styles.copyIcon}
                    onClick={() => {
                      window.open(
                        `https://www.instagram.com/${currentInvite.relations?.users?.activeProfile}`,
                        "_blank"
                      );
                    }}
                  />
                )
              }
            />
          </Form.Item>
          <Form.Item
            label="Disqualify Reason"
            name="disqualifyReason"
            rules={[{ required: true, message: "required" }]}
          >
            <Select
              showSearch
              placeholder="Select disqualify reason"
              options={[
                {
                  value: "criteria",
                },
                {
                  value: "invalid_handle",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: { offset: 14 },
              sm: { offset: 17 },
              md: { offset: 18 },
              lg: { offset: 17 },
            }}
            style={{ marginLeft: "10%" }}
          >
            <Button
              type="primary"
              onClick={disqualifyInvite}
              disabled={isLoading}
            >
              Disqualify
            </Button>
          </Form.Item>
        </Space>
      </Form>
      {errorMessage && (
        <h4 className={styles.error}>
          <WarningOutlined fill="red" /> {errorMessage}
        </h4>
      )}
    </>
  );
}

InstagramDisqualify.propTypes = {
  currentInvite: any,
  moveToNextInvite: func,
};
