import React, { useState } from "react";
import { Layout } from "antd";
import "./AppLayout.less";
//components
import SiderMenu from "./SiderMenu/SiderMenu";
import HeaderContainer from "./Header/HeaderContainer";

const { Content, Footer } = Layout;

const AppLayout = props => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Layout className="container" hasSider>
      <SiderMenu setCollapsed={setCollapsed} collapsed={collapsed} />
      <Layout id="mainLayout">
        {/* <Layout style={{ marginLeft: collapsed ? 0 : 200 }}> */}
        <HeaderContainer setCollapsed={setCollapsed} collapsed={collapsed} />
        <Content className="content">
          {
            //see App.js for children
            props.children
          }
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
