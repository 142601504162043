//SEE MSAL DOCS: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/MSAL-basics#configuration-options
import { PublicClientApplication, LogLevel } from "@azure/msal-browser"; //AZURE B2C
//TABLE OF CONTENTS
export const types = {
  GET_AUTH_SUCCESS: "GET_AUTH_SUCCESS", //on successful GET fetch call
};

export const getAuthSuccess = token => {
  return { type: types.GET_AUTH_SUCCESS, token };
};

export const getAuth = () => {
  return async dispatch => {
    await loginPromise;

    const loginRequest = {
      scopes: b2cScopes,
      account: publicClientApplication.getActiveAccount(),
    };

    return publicClientApplication
      .acquireTokenSilent(loginRequest)
      .then(token => {
        //AcquireTokenSilent Success
        dispatch(getAuthSuccess(token)); //dispatch token data
      })
      .catch(err => {
        console.log("token err", err);
        //acquireTokenSilent fail, login
        return publicClientApplication.loginRedirect(loginRequest); //redirect to login with login hint
      });
  };
};

//returns access token to be used for fetch auth
export const getToken = () => async (dispatch, getState) => {
  const { expiresOn, token } = getState().auth;
  //check if expired
  if (new Date() > expiresOn) {
    await dispatch(getAuth()); //wait for new token
    return getState().auth.token; // return new token
  }
  return token;
};

//AZURE B2C CONFIG
const b2cUrl = process.env.REACT_APP_B2C_URL;
const b2cScopes = [
  `${b2cUrl}contracts.read`,
  `${b2cUrl}contracts.write`,
  `${b2cUrl}campaigns.read`,
  `${b2cUrl}campaigns.write`,
  `${b2cUrl}posts.read`,
  `${b2cUrl}posts.write`,
  `${b2cUrl}users.read`,
  `${b2cUrl}users.write`,
];

export const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: process.env.REACT_APP_UI_HOST,
    authority: process.env.REACT_APP_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_AUTHORITY],
    b2cScopes,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  // Uncomment to debug auth
  // system: {
  //   loggerOptions: {
  //     logLevel: LogLevel.Verbose,
  //     loggerCallback: (_, message) => console.log(message),
  //   },
  // },
});

// This forces us to login on page load and sets our active account
// Explanation of login flow: https://www.loom.com/share/9d6e0c9df68d46c2b87d010f5e1accfe?from_recorder=1&focus_title=1
const loginPromise = publicClientApplication
  .handleRedirectPromise()
  .then(async resp => {
    const loginRequest = {
      scopes: b2cScopes,
      account: publicClientApplication.getActiveAccount(),
    };
    if (resp !== null) {
      // This path only gets hit once we're returning from a redirect. We set
      // the active account so we can acquire a token.
      publicClientApplication.setActiveAccount(resp.account);
    } else {
      try {
        // Attempt to fetch the credentials without login
        const ssoResp = await publicClientApplication.ssoSilent(loginRequest);
        publicClientApplication.setActiveAccount(ssoResp.account);
      } catch (e) {
        // If it fails, log in by redirecting
        await publicClientApplication.loginRedirect(loginRequest);
      }
    }
  });

export const logout = () => {
  publicClientApplication.logout();
};
