import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  createAuthenticatedRequest,
  defaultQueryOptions,
  executeRequest,
} from "./utils";

export const EMAIL_TEMPLATES_PATH = "emailTemplates";

export function useEmailTemplates({ includes, params, ...overrideOptions }) {
  const dispatch = useDispatch();

  return useQuery(
    [EMAIL_TEMPLATES_PATH, includes, params],
    () => fetchEmailTemplates(dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for all email templates
export async function fetchEmailTemplates(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    EMAIL_TEMPLATES_PATH,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}
