import React from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Button, message } from "antd";

export default props => {
  const { campaign } = props;
  if (!campaign) return null;
  return (
    <Button
      icon={<CopyOutlined />}
      key="1"
      type="default"
      onClick={async () => {
        await navigator.clipboard.writeText(
          `${process.env.REACT_APP_INFLUENCER_BRAND_HOST}campaigns/${campaign.contractId}/${campaign.campaignId}`
        );
        message.success("Link copied to clipboard");
      }}
    >
      Landing Page
    </Button>
  );
};
