export const flattenCampaigns = campaignsArray =>
  campaignsArray.map(campaign => ({
    ...(campaign.relations && campaign.relations.products),
    ...campaign,
    campaignName: campaign.name,
    productName:
      campaign.relations &&
      campaign.relations.products &&
      campaign.relations.products.name,
    //stats
    ...campaign.stats,
    done: campaign.stats?.posts || 0,
    totalOnboards: campaign.stats?.optIns || 0,
    totalNotAudited:
      campaign.relations?.onboards?.filter(onboardIsAudit).length || 0,
    totalRefunded:
      campaign.relations?.onboards?.filter(onboardIsRefund).length || 0,
  }));

export const onboardIsDone = (onboard, hasBilling) =>
  hasBilling
    ? onboard.done &&
      onboard.audited &&
      onboard.productCreditCharged &&
      onboard.platformCreditCharged
    : onboard.done;

export const onboardIsAudit = onboard => onboard.done && !onboard.audited;

export const onboardIsRefund = onboard =>
  onboard.done &&
  onboard.audited &&
  (!onboard.productCreditCharged || !onboard.platformCreditCharged);

export const selectArrayOfCampaigns = contracts => {
  if (Array.isArray(contracts) && contracts.length) {
    const arrayOfCampaigns = contracts.reduce((accum, current) => {
      return [...accum, ...current.relations.campaigns];
    }, []);
    return arrayOfCampaigns;
  }
  return [];
};

//called after onboards are already loaded from getCampaignById
//paginate client side (before db migration this action called server side pagination)
export const parseCampaignPosts = (
  campaign,
  currentTab,
  condition,
  qs = {}
) => {
  let { onboards } = campaign.relations;
  if (currentTab !== "totalOnboards") {
    if (condition) {
      onboards = onboards.filter(condition);
    }
    if (currentTab === "done") {
      //if done include both done && followed_up in filter
      onboards = onboards.filter(onboard => onboard.done);
    } else if (condition) {
      //filter based on condition
      onboards = onboards.filter(condition);
    }
  }

  onboards.sort((a, b) => {
    if (!a.completedDate && !b.completedDate) {
      return 0;
    }
    if (a.completedDate && !b.completedDate) {
      return -1;
    }
    if (!a.completedDate && b.completedDate) {
      return 1;
    }
    return (
      new Date(b.completedDate).getTime() - new Date(a.completedDate).getTime()
    );
  });

  //slice using pagination
  const { page, perPage } = qs;
  const startIndex = (page - 1) * perPage;
  const endIndex = page * perPage;
  const slicedAndFilteredOnboards = onboards.slice(startIndex, endIndex);
  return slicedAndFilteredOnboards;
};
