const { useSelector } = require("react-redux");

//checks if myRole (array) contains any requiredRoles (array) elements
const has = (authRoles = [], requiresRole = []) =>
  authRoles.some(r => requiresRole.includes(r)); //myRoles include requiredRole

const useHasRoles = roles => {
  const { auth } = useSelector(state => state);
  return has(auth?.roles, roles);
};

module.exports = {
  has,
  useHasRoles,
};
