import React, { useEffect, useState } from "react";
import { Input, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { searchPosts, getPostsSuccess } from "../../../redux/posts/postActions";
import { searchTransactions } from "../../../redux/payouts/serviceActions";
import PostSearchHeader from "../PostHeader/PostSearchHeader";
import TransactionsSearchResultTable from "../../Payouts/PaypalService/TransactionsSearchResultTable";
import PostById from "../PostById";
import PostsSearchDataTable from "./PostsSearchDataTable";
import { isEmail } from "../../../utils/regexUtils";
import { useOnboards } from "../../../api/onboards";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); //if Mobile

export default function PostsSearch() {
  //state
  const [queryIsEmail, setQueryIsEmail] = useState(false);
  const [searchingPaypalTransations, setSearchingPaypalTransations] =
    useState(false);
  const [query, setQuery] = useState("");

  //redux only for transaction search for now
  const dispatch = useDispatch();
  const transactionSearch = useSelector(
    state => state.payouts.transactionSearch
  );

  //router
  // Render post by ID if we have an ID in the path
  const { optionalPathParam } = useParams();
  const queryParams = new URLSearchParams(useHistory().location.search);
  const searchQueryParam = queryParams.get("search");

  // Query posts when search query is set
  const { data: posts, isLoading } = useOnboards({
    includes: ["payouts", "campaigns", "users", "shortcodes", "campaignFlows"],
    params: {
      search: query,
    },
    enabled: !!query,
  });

  // Keeping redux for this behavior for now
  // TODO: Test with a completed onboard
  const transactionSearchQuery = searchQueryParam || query;
  const searchPaypalTransactions = () => {
    setSearchingPaypalTransations(true);
    dispatch(searchTransactions(transactionSearchQuery));
  };

  // On mount, if there is a search query parameter, trigger search
  useEffect(() => {
    if (searchQueryParam) {
      triggerSearch(searchQueryParam);
    }
  }, [searchQueryParam]);

  // Helper to trigger our search by setting the query
  const triggerSearch = q => {
    if (!q) return;
    const _q = q.trim();
    setQuery(_q);
    //if email regex, ask if should search paypal transactions
    if (isEmail(_q)) setQueryIsEmail(true);
    else setQueryIsEmail(false);
    //reset on search
    setSearchingPaypalTransations(false);
  };

  //if id in path, render PostById component. We might have to load data for that onboard.
  if (optionalPathParam) {
    return <PostById onboardId={optionalPathParam} />;
  }

  // Otherwise, render the full posts search list
  return (
    <div>
      <PostSearchHeader>
        <Input.Search
          style={{ width: isMobile ? "100%" : "60vw" }}
          ref={input => input && input.focus()}
          placeholder="ID, emails, shortcodes, @handles"
          enterButton="Search"
          size="large"
          onPaste={event => triggerSearch(event.clipboardData.getData("text"))}
          // value={"ayor.bush@gmail.com"}
          onSearch={value => triggerSearch(value)}
        />
      </PostSearchHeader>
      {posts && <PostsSearchDataTable posts={posts} />}
      {queryIsEmail && !searchingPaypalTransations && (
        <div style={{ marginLeft: "2px" }}>
          <a onClick={searchPaypalTransactions}>
            Search Paypal transactions for "<b>{transactionSearchQuery}</b>" ?
          </a>
        </div>
      )}
      {searchingPaypalTransations && transactionSearch && (
        <TransactionsSearchResultTable transactionSearch={transactionSearch} />
      )}
    </div>
  );
}
