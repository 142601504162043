import React from "react";
import { MailOutlined } from "@ant-design/icons";
import LinkButton from "../../shared/LinkButton/LinkButton";

export default function GoToSendInvitesButton() {
  return (
    <LinkButton
      type="primary"
      linkPath="/invites/send"
      key="sendInvites"
      icon={<MailOutlined />}
    >
      Send Invites
    </LinkButton>
  );
}
