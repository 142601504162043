export const types = {
  PUSH_LOADING: "PUSH_LOADING",
  POP_LOADING: "POP_LOADING",
  IS_LOADING: "IS_LOADING", //bool: if true, App.js renders loading spinner
  RESULT: "RESULT" //obj, properties from https://ant.design/components/result/#API
};

/*
 * ACTIONS
 */

export const isLoading = bool => {
  return {
    type: "IS_LOADING",
    isLoading: bool
  };
};

//push id into loadingArray
export const pushLoading = id => {
  return {
    type: "PUSH_LOADING",
    id
  };
};

//remove id from loadingArray
export const popLoading = id => {
  return {
    type: "POP_LOADING",
    id
  };
};

export const result = resultObject => {
  return {
    type: "RESULT",
    result: resultObject
  };
};
