import React from "react";
import { Divider, Typography } from "antd";
import { any, bool, string } from "prop-types";
import { formatToMoney } from "../../../utils/stringUtils";

export default function DepositInvoice({
  platformCreditFee,
  platformCreditServiceFee,
  billingSetup,
  isGenericProductCredits,
}) {
  if (!billingSetup) {
    return null;
  }

  const { platformCreditSettings, productCreditSettings, averageProductPrice } =
    billingSetup;

  const platformCreditPrice =
    Number(platformCreditFee) + Number(platformCreditServiceFee);

  const newPlatformCredits = platformCreditSettings.newCredits;
  const hasPositiveProductCredits = productCreditSettings.some(
    pcs => pcs.newCredits > 0
  );

  if (newPlatformCredits <= 0 && !hasPositiveProductCredits) {
    return (
      <>
        <Divider orientation="left">Deposit Invoice</Divider>
        <ul>
          <li>No invoice to be created</li>
        </ul>
      </>
    );
  }

  const totalPlatformCreditsCost = platformCreditPrice * newPlatformCredits;

  const getTotalProductCreditsCost = () => {
    if (isGenericProductCredits) {
      if (productCreditSettings[0].newCredits > 0) {
        return productCreditSettings[0].newCredits * averageProductPrice;
      }
      return 0;
    }
    return productCreditSettings.reduce((partialSum, productSetting) => {
      const totalProductCost =
        Number(productSetting.fee || 0) +
        Number(productSetting.serviceFee || 0);

      const { newCredits } = productSetting;

      return partialSum + totalProductCost * (newCredits > 0 ? newCredits : 0);
    }, 0);
  };

  const totalProductCreditsCost = getTotalProductCreditsCost();

  const totalContractCost =
    (totalPlatformCreditsCost > 0 ? totalPlatformCreditsCost : 0) +
    (totalProductCreditsCost > 0 ? totalProductCreditsCost : 0);

  const depositInvoiceCost = totalContractCost * billingSetup.depositWeight;

  return (
    <div>
      <Divider orientation="left">Deposit Invoice</Divider>
      <ul>
        {newPlatformCredits > 0 ? (
          <li>
            <Typography.Text>{`+${newPlatformCredits} Instagram Grid Post Credits @ ${formatToMoney(
              platformCreditPrice
            )}/credit`}</Typography.Text>
          </li>
        ) : null}

        {totalPlatformCreditsCost >= 0 ? (
          <>
            <li>
              <Typography.Text>
                Platform Credits Estimated Cost =
                <Typography.Text strong underline>{`${formatToMoney(
                  totalPlatformCreditsCost
                )}`}</Typography.Text>
              </Typography.Text>
            </li>
            <br />
          </>
        ) : null}

        {productCreditSettings.map((productSetting, i) => {
          const productPrice = productSetting.fee || 0;
          const productServiceFee = productSetting.serviceFee || 0;
          const productCreditPrice =
            Number(productPrice) + Number(productServiceFee);
          if (productSetting.newCredits <= 0) {
            return null;
          }
          if (isGenericProductCredits) {
            return (
              <li key={i}>
                <Typography.Text>{`+${
                  productSetting.newCredits
                } Generic Product Credits ${
                  averageProductPrice
                    ? `@ ${formatToMoney(averageProductPrice)}/credit`
                    : ""
                }`}</Typography.Text>
              </li>
            );
          }

          return (
            <li key={i}>
              <Typography.Text>
                +
                {`${productSetting.newCredits} ${
                  productSetting.name
                } Credits @ ${formatToMoney(productCreditPrice)}/credit`}
              </Typography.Text>
            </li>
          );
        })}

        <li>
          <Typography.Text>
            Product Credits Estimated Cost =
            <Typography.Text strong underline>{`${formatToMoney(
              totalProductCreditsCost
            )}`}</Typography.Text>
          </Typography.Text>
        </li>
        <br />

        <li>
          <Typography.Text strong>
            Estimated Contract Cost =
            <Typography.Text underline>
              {formatToMoney(totalContractCost)}
            </Typography.Text>
          </Typography.Text>
        </li>

        <li>
          <Typography.Text>
            x Deposit Weight of
            <Typography.Text strong>{` ${
              billingSetup.depositWeight * 100
            }%`}</Typography.Text>
          </Typography.Text>
        </li>

        <Typography.Text strong>
          Deposit Invoice Cost =
          <Typography.Text underline>
            {formatToMoney(depositInvoiceCost)}
          </Typography.Text>
        </Typography.Text>
      </ul>
    </div>
  );
}

DepositInvoice.propTypes = {
  platformCreditFee: string,
  platformCreditServiceFee: string,
  billingSetup: any,
  isGenericProductCredits: bool,
};
