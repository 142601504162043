import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import {
  createAuthenticatedRequest,
  defaultMutationOptions,
  defaultQueryOptions,
  executeRequest,
} from "./utils";

export const INVITE_GROUPS_PATH = "inviteGroups";

export function useInviteGroups({
  inviteGroupId,
  includes,
  params,
  ...overrideOptions
}) {
  const dispatch = useDispatch();

  const path = inviteGroupId
    ? `${INVITE_GROUPS_PATH}/${inviteGroupId}`
    : INVITE_GROUPS_PATH;

  return useQuery(
    [path, includes, params],
    () => fetchInviteGroups(path, dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for invite groups
export async function fetchInviteGroups(path, dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    path,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}

export function useUpdateInviteGroup() {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  return useMutation(inviteGroup => putInviteGroup(inviteGroup, dispatch), {
    ...defaultMutationOptions,
    onSuccess: data => {
      const path = `${INVITE_GROUPS_PATH}/${data.inviteGroupId}`;
      queryClient.invalidateQueries(path);
    },
  });
}

// Execute put request for updating an invite group
export async function putInviteGroup(inviteGroup, dispatch) {
  const { inviteGroupId } = inviteGroup;
  if (!inviteGroupId) {
    throw new Error("inviteGroupId not provided");
  }
  const path = `${INVITE_GROUPS_PATH}/${inviteGroupId}`;
  const requestObject = await createAuthenticatedRequest(path, dispatch, {
    method: "PUT",
    body: JSON.stringify(inviteGroup),
  });

  return executeRequest(requestObject);
}
