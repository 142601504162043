import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Tooltip,
  Typography,
} from "antd";
import { any, number, string } from "prop-types";
import styles from "./BillingContract.module.css";
import MoneyInput from "../../shared/MoneyInput";
import { useUpdateCreditSettings } from "../../../api/creditSettings";

export default function PlatformCreditsConfiguration({
  platformCreditSettings,
  target,
  billingContractId,
  billingSetup,
}) {
  const [updatingCreditSettings, setUpdatingCreditSettings] = useState(false);

  const updateCreditSettings = useUpdateCreditSettings();

  const onUpdateCreditSettings = async () => {
    try {
      setUpdatingCreditSettings(true);
      await updateCreditSettings.mutateAsync({
        body: {
          billingContractId,
          creditSettingsId: platformCreditSettings.creditSettingsId,
          type: "platform",
          fee: billingSetup.fee,
          serviceFee: billingSetup.serviceFee,
          serviceFeeDescription: billingSetup.serviceFeeDescription,
        },
      });
      message.success("Successfully updated billing settings");
    } catch (err) {
      message.error(`Error: ${err.message}`);
    } finally {
      setUpdatingCreditSettings(false);
    }
  };

  const getCheckboxLabel = () => {
    const { suggestedCredits } = platformCreditSettings;
    if (suggestedCredits === 0) {
      return "Credits for current target minted";
    }
    return `${suggestedCredits > 0 ? "Add" : "Delete"} ${Math.abs(
      suggestedCredits
    )} credits?`;
  };

  return (
    <>
      <Col span={4} key="creditName" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>Credit Name: </span> Instagram Grid
          Post
        </Typography.Text>
      </Col>

      <Col span={4} key="creditPrice" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>Credit Price: </span> $
          {(Number(billingSetup.fee) + Number(billingSetup.serviceFee)).toFixed(
            2
          )}
        </Typography.Text>
      </Col>

      <Col span={4} key="target" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>Target: </span>
          {target}
        </Typography.Text>
      </Col>

      <Col span={4} key="totalCredits" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>Total Credits: </span>
          {platformCreditSettings.total}
        </Typography.Text>
      </Col>

      <Col span={4} key="availableCredits" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>Available Credits: </span>
          {platformCreditSettings.total - platformCreditSettings.consumed}
        </Typography.Text>
      </Col>

      <Col span={4} key="usedCredits" className={styles.textFormColumn}>
        <span className={styles.formLabel}>Used Credits: </span>
        <Typography.Text>{platformCreditSettings.consumed}</Typography.Text>
      </Col>

      <Col span={4} key="platformFees" className={styles.formColumn}>
        <Form.Item
          name="fee"
          label={<span className={styles.formLabel}>Platform Fees</span>}
          rules={[{ required: true, message: "required" }]}
        >
          <MoneyInput />
        </Form.Item>
      </Col>

      <Col span={4} key="platformServiceFees" className={styles.formColumn}>
        <Form.Item
          name="serviceFee"
          label={
            <span className={styles.formLabel}>Platform Service Fees</span>
          }
          rules={[{ required: true, message: "required" }]}
        >
          <MoneyInput />
        </Form.Item>
      </Col>

      <Col span={4} key="serviceFeeDescription" className={styles.formColumn}>
        <Form.Item
          name="serviceFeeDescription"
          label={
            <span className={styles.formLabel}>Service Fee Description</span>
          }
        >
          <Input />
        </Form.Item>
      </Col>

      <Col
        span={4}
        key="savePlatformCreditSettings"
        className={styles.formColumn}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Button
          type="primary"
          onClick={onUpdateCreditSettings}
          loading={updatingCreditSettings}
          disabled={updatingCreditSettings}
        >
          Save
        </Button>
      </Col>

      <Col
        span={4}
        key="newCredits"
        className={styles.formColumn}
        style={{ display: "flex", alignItems: "center" }}
      >
        {/* <Form.Item
          name="newCredits"
          label={<span className={styles.formLabel}>New Credits</span>}
          rules={[{ required: true, message: "required" }]}
        >
          {target <= platformCreditSettings.total ? (
            <Tooltip title="Cannot add more platform credits without increasing contract target">
              <InputNumber disabled />
            </Tooltip>
          ) : (
            <InputNumber min={0} precision={0} step={1} />
          )}
        </Form.Item> */}

        <span className={styles.formLabel}>{getCheckboxLabel()}</span>
        <Form.Item
          className={styles.formItem}
          name="newCredits"
          valuePropName="checked"
          style={{ marginBottom: 0 }}
        >
          <Checkbox
            disabled={platformCreditSettings.suggestedCredits === 0}
            style={{ marginLeft: "8px" }}
          />
        </Form.Item>
      </Col>
    </>
  );
}

PlatformCreditsConfiguration.propTypes = {
  platformCreditSettings: any,
  target: number,
  billingContractId: string,
  billingSetup: any,
};
