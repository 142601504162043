import React, { useState } from "react";
import { Form, Select, Button, Typography, Space } from "antd";
import { array, bool, object, string } from "prop-types";
import {
  CopyOutlined,
  WarningOutlined,
  DeleteOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { selectFilter } from "../../utils/filters";
import styles from "./Test.module.css";
import { defaultButtonWrapperCol } from "../../utils/formLayout";
import {
  useCreateOnboard,
  useDeleteOnboard,
  useSendOnboardReminderEmail,
} from "../../api/onboards";
import { getColumnsFromDataSource } from "../shared/DataTable/utils";
import DataTable from "../shared/DataTable/DataTable";
import copyToClipboard from "../../utils/copyToClipboard";
import GenericModal from "./GenericModal";
import { getSubmitLink } from "../../utils/campaignFlowTypes";

function OnboardsForm({
  loading,
  options,
  buttonDisabled,
  disabled,
  user,
  selectedCampaignId,
}) {
  const [onboardToRemove, setOnboardToRemove] = useState("");
  const [onboardToEmail, setOnboardToEmail] = useState("");

  const createOnboard = useCreateOnboard();

  const deleteOnboard = useDeleteOnboard({});

  const sendOnboardEmail = useSendOnboardReminderEmail({});

  const {
    isError: isDeleteError,
    isLoading: isLoadingDelete,
    error: deleteError,
  } = deleteOnboard;

  const {
    isError: isSendEmailError,
    isLoading: isLoadingSendEmail,
    error: sendEmailError,
  } = sendOnboardEmail;

  const onDeleteOnboard = async () => {
    deleteOnboard.mutate(onboardToRemove, {
      onSuccess: () => {
        setOnboardToRemove("");
      },
      onError: () => {
        setOnboardToRemove("");
      },
    });
  };

  const onEmailOnboard = async () => {
    sendOnboardEmail.mutate(onboardToEmail, {
      onSuccess: () => {
        setOnboardToEmail("");
      },
      onError: () => {
        setOnboardToEmail("");
      },
    });
  };

  const existingOnboards = user?.relations?.onboards?.map(onboard => {
    const selectedOption = options?.find(o => o.value === onboard.campaignId);
    const campaignName = selectedOption ? selectedOption.label : "";
    return {
      onboardId: onboard.onboardId,
      link: getSubmitLink({
        campaignFlowType: onboard.relations.campaignFlows.type,
        onboardId: onboard.onboardId,
      }),
      campaignName,
      state: onboard.state,
      actions: {
        remove: () => setOnboardToRemove(onboard.onboardId),
        sendReminderEmail: () => setOnboardToEmail(onboard.onboardId),
      },
    };
  });

  const {
    isError: isCreateError,
    isLoading: isLoadingCreate,
    error: createOnboardError,
  } = createOnboard;

  const sendOnboard = async () => {
    const onboardRequest = [
      {
        email: user.email.trim().toLowerCase(),
        firstName: user.firstName,
        igHandle: user.activeProfile.trim().toLowerCase(),
        campaignId: selectedCampaignId,
        payoutAccountId: 6,
        force: true,
      },
    ];
    createOnboard.mutate(onboardRequest);
  };

  return (
    <>
      <GenericModal
        visible={!!onboardToRemove}
        onCancel={() => setOnboardToRemove("")}
        onOk={onDeleteOnboard}
        loading={isLoadingDelete}
        message="Are you sure you want to remove this onboard campaign?"
        title={
          <>
            <WarningOutlined /> Delete Confirmation
          </>
        }
      />
      <GenericModal
        visible={!!onboardToEmail}
        onCancel={() => setOnboardToEmail("")}
        onOk={onEmailOnboard}
        loading={isLoadingSendEmail}
        message="Are you sure you want to email a reminder for this onboard campaign?"
        title={
          <>
            <WarningOutlined /> Email Confirmation
          </>
        }
      />
      <Form.Item label="Onboard">
        <Form.Item name="onboardCampaignId" className={styles.formItem}>
          <Select
            disabled={disabled}
            filterOption={selectFilter}
            loading={loading}
            showSearch
            className={styles.fullWidth}
            placeholder="Campaign"
            options={options}
          />
        </Form.Item>
      </Form.Item>
      {existingOnboards && existingOnboards.length > 0 && (
        <Form.Item label={<div />} colon={false}>
          <Space align="center">
            <Typography.Text strong>Current Onboards</Typography.Text>
          </Space>
          <div className={styles.eventsContainer}>
            <DataTable
              size="small"
              style={{ marginBottom: "12px" }}
              dataSource={existingOnboards}
              rowClassName="compact-row"
              rowKey={row => `${row.onboardId}`}
              pagination={false}
              columns={getColumnsFromDataSource(
                existingOnboards,
                ["onboardId", "campaignName", "link", "state", "actions"],
                {
                  onboardId: {
                    title: "ID",
                    // eslint-disable-next-line react/display-name
                    render: onboardId => (
                      <CopyOutlined
                        onClick={() => {
                          copyToClipboard(onboardId);
                        }}
                      />
                    ),
                  },
                  link: {
                    // eslint-disable-next-line react/display-name
                    render: value => (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                        href={value}
                      >
                        {value}
                      </a>
                    ),
                  },
                  actions: {
                    // eslint-disable-next-line react/display-name
                    render: actionMethods => {
                      const { remove, sendReminderEmail } = actionMethods;
                      return (
                        <div style={{ display: "flex" }}>
                          <Button
                            type="primary"
                            icon={<MailOutlined />}
                            onClick={sendReminderEmail}
                            style={{ marginRight: "8px" }}
                          />
                          <Button icon={<DeleteOutlined />} onClick={remove} />
                        </div>
                      );
                    },
                  },
                }
              )}
            />
          </div>
        </Form.Item>
      )}
      <Form.Item wrapperCol={defaultButtonWrapperCol}>
        <Button
          loading={isLoadingCreate}
          disabled={disabled || buttonDisabled}
          onClick={sendOnboard}
          type="primary"
        >
          Send Onboard
        </Button>
        {isCreateError && (
          <h4 className={styles.error}>
            <WarningOutlined fill="red" /> {createOnboardError.message}
          </h4>
        )}
        {isDeleteError && (
          <h4 className={styles.error}>
            <WarningOutlined fill="red" /> {deleteError.message}
          </h4>
        )}
        {isSendEmailError && (
          <h4 className={styles.error}>
            <WarningOutlined fill="red" /> {sendEmailError.message}
          </h4>
        )}
      </Form.Item>
    </>
  );
}

OnboardsForm.propTypes = {
  loading: bool,
  options: array,
  buttonDisabled: bool,
  disabled: bool,
  user: object,
  selectedCampaignId: string,
};

export default OnboardsForm;
