import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { CAMPAIGNS_PATH } from "./campaigns";
import { USERS_PATH } from "./users";
import {
  createAuthenticatedRequest,
  defaultMutationOptions,
  defaultQueryOptions,
  executeRequest,
} from "./utils";

export const ONBOARDS_PATH = "onboards";

export function useOnboards({
  onboardId,
  includes,
  params,
  ...overrideOptions
}) {
  const dispatch = useDispatch();

  const path = onboardId ? `${ONBOARDS_PATH}/${onboardId}` : ONBOARDS_PATH;

  return useQuery(
    [ONBOARDS_PATH, onboardId, includes, params],
    () => fetchOnboards(path, dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for onboards
export async function fetchOnboards(path, dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    path,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}

export function useCreateOnboard() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(body => createOnboard(body, dispatch), {
    ...defaultMutationOptions,
    onSuccess: () => {
      queryClient.invalidateQueries(ONBOARDS_PATH);
      queryClient.invalidateQueries(USERS_PATH);
    },
  });
}

// Execute a POST to create a new onboard
export async function createOnboard(body, dispatch) {
  const requestObject = await createAuthenticatedRequest(
    ONBOARDS_PATH,
    dispatch,
    {
      method: "POST",
      body: JSON.stringify(body),
    }
  );

  return executeRequest(requestObject);
}

export function useDeleteOnboard(options) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(onboardId => deleteOnboard(onboardId, dispatch, options), {
    onSuccess: () => {
      queryClient.invalidateQueries(USERS_PATH);
      queryClient.invalidateQueries(ONBOARDS_PATH);
      queryClient.invalidateQueries(CAMPAIGNS_PATH);
    },
    ...defaultMutationOptions,
  });
}

// Execute a DELETE to delete an onboard
export async function deleteOnboard(onboardId, dispatch, options) {
  const path = `${ONBOARDS_PATH}/${onboardId}`;
  const requestObject = await createAuthenticatedRequest(path, dispatch, {
    method: "DELETE",
    ...options,
  });

  return executeRequest(requestObject);
}

export function useSendOnboardReminderEmail(options) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    onboardId => sendOnboardReminderEmail(onboardId, dispatch, options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(USERS_PATH);
        queryClient.invalidateQueries(ONBOARDS_PATH);
      },
      ...defaultMutationOptions,
    }
  );
}

// Execute a POST to send a reminder email
export async function sendOnboardReminderEmail(onboardId, dispatch, options) {
  const path = `${ONBOARDS_PATH}/${onboardId}/reminderEmails`;
  const requestObject = await createAuthenticatedRequest(path, dispatch, {
    method: "POST",
    ...options,
  });

  return executeRequest(requestObject);
}

export function useUpdateOnboard() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({ onboardId, body, ...options }) =>
      updateOnboard({ onboardId, body }, dispatch, options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(USERS_PATH);
        queryClient.invalidateQueries(ONBOARDS_PATH);
        queryClient.invalidateQueries(CAMPAIGNS_PATH);
      },
      ...defaultMutationOptions,
    }
  );
}

// Execute a PUT to update an onboard
export async function updateOnboard({ onboardId, body }, dispatch, options) {
  const path = `${ONBOARDS_PATH}/${onboardId}`;

  const requestObject = await createAuthenticatedRequest(path, dispatch, {
    method: "PUT",
    body: JSON.stringify(body),
    ...options,
  });

  return executeRequest(requestObject);
}
