import React from "react";
import { SyncOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Empty, Button, Tooltip } from "antd";
import moment from "moment";
//redux
import { useSelector, useDispatch } from "react-redux";
import { refreshTransactionById } from "../../../redux/payouts/serviceActions";
//components
import DataTable from "../../shared/DataTable/DataTable";
//utils
import { getColumnsFromDataSource } from "../../shared/DataTable/utils";
import clipboard from "../../../utils/clipboard";
import { camelCaseToSentenceCase } from "../../../utils/stringUtils";
import checkAuth from "../../../utils/checkAuth";

const RefreshStatusButton = props => {
  //redux
  const dispatch = useDispatch();

  return (
    <Button
      key="refresh"
      icon={<SyncOutlined />}
      onClick={() => dispatch(refreshTransactionById(props.transaction))}
    >
      Refresh
    </Button>
  );
};

//from transaction_status: https://developer.paypal.com/docs/api/transaction-search/v1/
const renderStatus = {
  D: {
    tip: "PayPal or merchant rules denied the transaction.",
    display: "DENIED",
  },
  F: {
    tip: "The original recipient partially refunded the transaction.",
    display: "PARTIAL",
  },
  P: {
    tip: "The transaction is pending. The transaction was created but waits for another payment process to complete, such as an ACH transaction, before the status changes to SUCCESS.",
    display: "PENDING",
  },
  S: {
    tip: "The transaction successfully completed without a denial and after any pending statuses.",
    display: "SUCCESS",
  },
  V: {
    tip: "A successful transaction was reversed and funds were refunded to the original sender.",
    display: "REFUNDED",
  },
};

const TransactionsSearchResultTable = props => {
  //redux
  const isLoading = useSelector(state => state.shared.isLoading);
  const roles = useSelector(state => state.auth.roles);
  const transactionSearch = useSelector(
    state => state.payouts.transactionSearch
  );

  //only render if data loaded
  if (
    (!isLoading && (!transactionSearch || !transactionSearch.length)) ||
    !checkAuth.has(roles, ["supervisor"])
  )
    return <DataTable />;

  return (
    <div>
      <DataTable
        size="small"
        dataSource={transactionSearch}
        rowKey="transaction_id"
        pagination={false}
        columns={getColumnsFromDataSource(
          transactionSearch,
          //column names
          [
            "payoutAccountId",
            "transaction_id",
            "email_address",
            "name",
            "transaction_note",
            "value",
            "transaction_initiation_date",
            "transaction_status",
          ],
          {
            payoutAccountId: {
              title: "#",
              width: "24px",
            },
            transaction_id: {
              render: text => <span style={{ fontSize: 11 }}>{text}</span>,
              onCell: (record, rowIndex) => ({
                onClick: e => {
                  clipboard(record.transaction_id);
                }, //copy to clipboard
              }),
            },
            transaction_initiation_date: {
              title: "Date",
              width: "100px",
              render: text => moment(text).format("ll"),
            },
            value: {
              width: "90px",
              render: (text, record) =>
                `$${Math.abs(text)} ${record.currency_code}`,
            },
            transaction_note: {
              render: text => <span style={{ fontSize: 11 }}>{text}</span>,
            },
            name: {
              render: (text, record) =>
                record.given_name || record.alternate_full_name,
            },
            email_address: {
              title: "Paypal Email",
              onCell: (record, rowIndex) => ({
                onClick: e => {
                  clipboard(record.email_address);
                }, //copy to clipboard
              }),
            },

            transaction_status: {
              title: (
                <Tooltip
                  placement="left"
                  title={
                    <div>
                      <p>
                        Older transactions will need to be refreshed to retrieve
                        status.
                      </p>
                      {Object.values(renderStatus).map(status => (
                        <p key={status.display}>
                          {status.display} - {status.tip}
                        </p>
                      ))}
                    </div>
                  }
                >
                  Status <QuestionCircleOutlined />
                </Tooltip>
              ),
              render: (text, record) => {
                //if cached, should get live data so can get latest status
                return record.cached ? (
                  <RefreshStatusButton transaction={record} />
                ) : (
                  <Tooltip title={renderStatus[text].tooltip}>
                    {renderStatus[text].display}
                  </Tooltip>
                );
              },
              // render: (text, record) => <RefreshStatusButton record={record} />,
            },
          }
        )}
      />
    </div>
  );
};

export default TransactionsSearchResultTable;
