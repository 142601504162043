import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import {
  createAuthenticatedRequest,
  defaultQueryOptions,
  defaultMutationOptions,
  executeRequest,
} from "./utils";

export const B2C_USERS_PATH = "b2cUsers";

// Simple wrapper to ensure we're managing cache keys properly. Can also override
// default options here if wanted. Probably a good idea to pass through override options
// as well.
export function useB2CUsers({ includes, params, ...overrideOptions }) {
  const dispatch = useDispatch();

  return useQuery(
    [B2C_USERS_PATH, includes, params],
    () => fetchUsers(dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for b2c users
export async function fetchUsers(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    B2C_USERS_PATH,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}

// Simple wrapper to manage mutation state properly.
// See https://react-query.tanstack.com/reference/useMutation
// Usage:
//  function MyComponent(props) {
//  const createUser = useCreateUser({email: '', displayName: '', roles: ['client']});
//  const {isSuccess, isError, isLoading} = createUser;

//  useEffect(() => {
//    if(isSuccess) history.push('/success');
//  }, [isSuccess])
//
//    return {
//        <>
//          {isError && <Error>Oh no!</Error>}
//          <button disabled={isLoading} onClick={() => createUser.mutate(newContract, newCompany)}
//        </>
//    }
//  }
export function useCreateB2CUser() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({ email, displayName, roles, notificationDelay }) =>
      createB2CUser({ email, displayName, roles, notificationDelay }, dispatch),
    {
      ...defaultMutationOptions,
      onSuccess: () => {
        queryClient.invalidateQueries(B2C_USERS_PATH);
      },
    }
  );
}

// Execute a POST to create a new user
export async function createB2CUser(
  { email, displayName, roles, notificationDelay },
  dispatch
) {
  const body = {
    displayName,
    email,
    roles,
  };

  const requestObject = await createAuthenticatedRequest(
    B2C_USERS_PATH,
    dispatch,
    {
      method: "POST",
      body: JSON.stringify(body),
      params: { notificationDelay },
    }
  );

  return executeRequest(requestObject);
}
