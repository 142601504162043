import moment from "moment";
import { pushLoading, popLoading, result } from "../shared/sharedActions";
import { getToken } from "../auth/authActions";
import { getContract } from "../contracts/contractActions";
import {
  previewInputOutputInvites,
  previewInputOutputEmailGroups,
  updateSendState,
  resetSendState,
} from "./sendInvitesActions";

export const types = {
  GET_INVITE_GROUPS_SUCCESS: "GET_INVITE_GROUPS_SUCCESS", // GET contracts?include=inviteGroups,campaignInviteGroups,woodpeckerCampaigns&withMetadata=true
  GET_INVITE_GROUP_BY_ID_SUCCESS: "GET_INVITE_GROUP_BY_ID_SUCCESS", //GET inviteGroups/:id?include=inviteGroups,campaignInviteGroups,woodpeckerCampaigns&withMetadata=true
  POST_INVITES_SUCCESS: "POST_INVITES_SUCCESS", //POST invites (with optional query parameter)
  PUT_INVITE_SUCCESS: "PUT_INVITE_SUCCESS", //PUT invites
  GET_EMAIL_JOB_LOGS_SUCCESS: "GET_EMAIL_JOB_LOGS_SUCCESS", //GET emailJobLogs
  GET_JOIN_EMAIL_GROUP_SUCCESS: "GET_JOIN_EMAIL_GROUP_SUCCESS", //GET emailGroup of type join
};

//to pass for contractActions.getContracts(includeString)
export const inviteIncludeString = `&include=companies,inviteGroups`;

//to pass for getContractInviteGroups
const getInviteGroupsIncludeString =
  "include=contracts,inviteGroups,campaignInviteGroups,emailGroups,woodpeckerCampaigns&withMetadata=true";

const getJoinEmailGroupIncludeString =
  "include=woodpeckerCampaigns&withMetadata=true&type=join";

/*
 * ACTIONS
 */

export const getInviteGroupsSuccess = data => {
  return { type: types.GET_INVITE_GROUPS_SUCCESS, data };
};
export const getInviteGroupByIdSuccess = data => {
  return { type: types.GET_INVITE_GROUP_BY_ID_SUCCESS, data };
};
export const pushUsersToEmailGroupSuccess = data => {
  return { type: types.POST_INVITES_SUCCESS, data };
};
export const getEmailJobLogsSuccess = (page, data) => {
  return { type: types.GET_EMAIL_JOB_LOGS_SUCCESS, page, data };
};
export const getJoinEmailGroupSuccess = data => {
  return { type: types.GET_JOIN_EMAIL_GROUP_SUCCESS, data };
};

export const getInviteGroupById = inviteGroupId => async dispatch => {
  const loadingId = `getInviteGroupById${inviteGroupId}`;
  dispatch(pushLoading(loadingId));
  try {
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/inviteGroups/${inviteGroupId}?${getInviteGroupsIncludeString}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    dispatch(getInviteGroupByIdSuccess(json));
  } catch (err) {
    console.error(err);
    //handle
    dispatch(
      result({
        status: `error`,
        title: `Get Invite Group data by ID failed`,
        subTitle: err.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};

export const getInviteGroupsByContractId = contractId => async dispatch => {
  const loadingId = `getInviteGroupsByContractId${contractId}`;
  dispatch(pushLoading(loadingId));
  try {
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/contracts/${contractId}?${getInviteGroupsIncludeString}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    dispatch(getInviteGroupsSuccess(json));
  } catch (err) {
    console.error(err);
    //handle
    dispatch(
      result({
        status: `error`,
        title: `Get contract data failed`,
        subTitle: err.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};

export const newInviteGroup = body => async dispatch => {
  const loadingId = "newInviteGroup";
  dispatch(pushLoading(loadingId));
  try {
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/inviteGroups`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body),
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    //on success, refetch
    dispatch(getContract(inviteIncludeString));
    dispatch(getInviteGroupsByContractId(body.contractId));
  } catch (err) {
    console.error(err);
    //handle
    dispatch(
      result({
        status: `error`,
        title: `Failed to create new Invite Group`,
        subTitle: err.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};

export const updateInvites = (body, inviteIdParam) => async dispatch => {
  const loadingId = "updateInvites";
  dispatch(pushLoading(loadingId));
  let res;
  try {
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/invites/${inviteIdParam || ""}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body),
      }
    );
    res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    return json;
  } catch (err) {
    console.log(err.stack);
    throw err;
  } finally {
    dispatch(popLoading(loadingId));
  }
};

export const pushInvites =
  (previewQueryParam = false) =>
  async (dispatch, getState) => {
    const { send } = getState().invites;
    const body = { inviteGroups: send.inviteGroups, userIds: send.userIds };

    const loadingId = "pushUsersToInviteGroup";
    dispatch(pushLoading(loadingId));
    let res;
    try {
      const token = await dispatch(getToken());
      const requestObject = new Request(
        `${process.env.REACT_APP_API_HOST}api/invites${
          previewQueryParam ? "?preview=true" : ""
        }`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(body),
        }
      );
      res = await fetch(requestObject);
      const json = await res.json();
      if (!res.ok) throw new Error(json.message || res.statusText);
      //preview success
      if (previewQueryParam) {
        return dispatch(previewInputOutputInvites({ output: json }));
      }

      //reset, then go to logs
      dispatch(resetSendState());
      dispatch(updateSendState({ tab: "logs" }));
    } catch (err) {
      console.log(err.stack);
      //back to form
      dispatch(updateSendState({ tab: "form" }));
      if (res.status === 400)
        return dispatch(
          result({
            status: `warning`,
            title: err.message,
            subTitle: `Failed to send new Invites`,
          })
        );

      //500
      dispatch(
        result({
          status: `error`,
          title: `Failed to send new Invites`,
          subTitle: err.message,
        })
      );
    } finally {
      dispatch(popLoading(loadingId));
    }
  };

export const pushUsersToEmailGroup =
  (previewQueryParam = false) =>
  async (dispatch, getState) => {
    //input = values from form, output = preview response to reuse for real POST invites
    const { preview, send } = getState().invites;
    // const body = preview ? previewState.input : previewState.output;
    const body = { ...preview.input }; //always use preview state
    //if custom csv is provided
    if (Array.isArray(send.userIds)) {
      delete body.tagQuery;
      body.userIds = send.userIds;
    }

    if (send.sendSMSReminder) {
      body.sendSMSReminder = true;
      if (send.reminderDate) {
        const reminderDate = moment(send.reminderDate);
        // Spits out the date of the .reminderDate object without
        // timezone, allowing us to manually adjust to UTC-0600
        const timezoneAdjustedDateTime = `${reminderDate.format(
          "YYYY-MM-DDTHH:mm"
        )}-06:00`;
        body.reminderDate = timezoneAdjustedDateTime;
      }
    }

    const loadingId = "pushUsersToEmailGroup";
    dispatch(pushLoading(loadingId));
    //increase scope for detailed error handling
    let res;
    try {
      const token = await dispatch(getToken());
      //TODO: REMOVE FORCE AND ONLY APPLY IF RECYCLE CHECKBOX INPUT
      const requestObject = new Request(
        `${process.env.REACT_APP_API_HOST}api/emailGroups/push${
          previewQueryParam ? "?preview=true" : ""
        }`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(body),
        }
      );
      res = await fetch(requestObject);
      const json = await res.json();
      if (!res.ok) throw new Error(json.message || res.statusText);
      //preview success
      if (previewQueryParam) {
        return dispatch(previewInputOutputEmailGroups({ output: json }));
      }

      //reset, then go to logs
      dispatch(resetSendState());
      dispatch(updateSendState({ tab: "logs" }));
    } catch (err) {
      //back to form
      dispatch(updateSendState({ tab: "form" }));
      if (res.status === 400)
        return dispatch(
          result({
            status: `warning`,
            title: err.message,
            subTitle: `Failed to send new Invites`,
          })
        );

      //500
      dispatch(
        result({
          status: `error`,
          title: `Failed to send new Invites`,
          subTitle: err.message,
        })
      );
    } finally {
      dispatch(popLoading(loadingId));
    }
  };

export const getEmailJobLogs =
  (page = 1, pageSize = 50) =>
  async (dispatch, getState) => {
    const loadingId = `getEmailJobLogs`;
    dispatch(pushLoading(loadingId));
    //number of rows to offset
    const start = (page - 1) * pageSize;
    const { type } = getState().invites;
    try {
      const token = await dispatch(getToken());
      const requestObject = new Request(
        `${process.env.REACT_APP_API_HOST}api/emailJobLogs?include=emailGroups&include=inviteGroups&type=${type}&start=${start}&pageSize=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      );
      const res = await fetch(requestObject);
      const json = await res.json();
      if (!res.ok) throw new Error(json.message || res.statusText);
      //reset data if page = 1
      dispatch(getEmailJobLogsSuccess(page, json));
    } catch (err) {
      console.error(err);
      //handle
      dispatch(
        result({
          status: `error`,
          title: `Get Invite Group data by ID failed`,
          subTitle: err.message,
        })
      );
    } finally {
      dispatch(popLoading(loadingId));
    }
  };

export const getJoinEmailGroup = () => async dispatch => {
  const loadingId = `getJoinEmailGroup`;
  dispatch(pushLoading(loadingId));
  try {
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/emailGroups?${getJoinEmailGroupIncludeString}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    if (!json[0]) throw new Error("No Email Group of type Join");
    dispatch(getJoinEmailGroupSuccess(json[0]));
  } catch (err) {
    console.error(err);
    //handle
    dispatch(
      result({
        status: `error`,
        title: `Get Join Email Group failed`,
        subTitle: err.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};

export const newWoodpeckerCampaignJoin = body => async dispatch => {
  const loadingId = "newWoodpeckerCampaignJoin";
  dispatch(pushLoading(loadingId));
  try {
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/woodpeckerCampaigns`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body),
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    //on success, refetch
    dispatch(getJoinEmailGroup());
  } catch (err) {
    console.error(err);
    //handle
    dispatch(
      result({
        status: `error`,
        title: `Failed to create new WoodPecker campaign for join email group`,
        subTitle: err.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};

export const putEmailGroup = (emailGroupId, body) => async dispatch => {
  const loadingId = "newWoodpeckerCampaignJoin";
  dispatch(pushLoading(loadingId));
  try {
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/emailGroups/${emailGroupId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body),
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    //on success, refetch
    dispatch(getJoinEmailGroup());
    return json;
  } catch (err) {
    console.error(err);
    //handle
    dispatch(
      result({
        status: `error`,
        title: `Failed to create new WoodPecker campaign for join email group`,
        subTitle: err.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};
