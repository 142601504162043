import React, { useEffect, useReducer } from "react";
import {
  Descriptions,
  Avatar,
  Card,
  Spin,
  message,
  Layout,
  Button,
} from "antd";
import { any } from "prop-types";
import { CheckCircleTwoTone } from "@ant-design/icons";
import genericReducer from "../../utils/genericUseReducer";
import ActionTable from "./ActionTable";
import ThumbnailGrid from "./ThumbnailGrid";
import stylesheet from "./ManualValidation.module.css";
import { useRefreshThumbnails } from "../../api/services";
import { useNextUser, useProfileReviewCount } from "../../api/profileReview";
import { useValidateInboundUser } from "../../api/users";

//antd
const { Meta } = Card;
const { Content } = Layout;

// Held outside of React because it's not used in render and state updates are
// too slow to prevent key spamming
let locked = false;

export default function ManualValidation() {
  const numberOfColumns = 4;

  const [state, setState] = useReducer(genericReducer, {
    visible: false,
    onKeyboardCooldown: false,
    initialState: true,
  });

  const refreshThumbnails = useRefreshThumbnails();

  const validateInboundUser = useValidateInboundUser();

  const { data: profileReviewCount, isLoading: isLoadingProfileReviewCount } =
    useProfileReviewCount({});

  const { data: user, isLoading: loadingNextUser } = useNextUser({
    enabled: !state.initialState,
  });

  useEffect(() => {
    if (profileReviewCount?.count === 0) {
      setState({ initialState: false });
    }
  }, [profileReviewCount]);

  useEffect(() => {
    const handleKeyDown = ({ keyCode }) => {
      if (locked || !user || !user._id) {
        return;
      }

      // number 1
      const goodKey = 49;
      // Delete, escape, and number 2
      const badKeys = [46, 8, 50];

      // Handle good and bad keys
      if (keyCode === goodKey) {
        locked = true;
        validateInboundUser
          .mutateAsync({
            accepted: true,
            _id: user._id,
          })
          .then(
            () => {
              goodMessage(user._id);
              locked = false;
            },
            // Always update locked = false, even on error
            () => {
              locked = false;
            }
          );
      } else if (badKeys.includes(keyCode)) {
        locked = true;
        validateInboundUser
          .mutateAsync({
            accepted: false,
            _id: user._id,
          })
          .then(
            () => {
              badMessage(user._id);
              locked = false;
            },
            // Always update locked = false, even on error
            () => {
              locked = false;
            }
          );
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [user]);

  if (loadingNextUser || isLoadingProfileReviewCount) {
    return <Spin style={styles.centered} size="large" />;
  }

  if (state.initialState) {
    return (
      <div style={styles.centered}>
        <h2>{profileReviewCount.count} Users Left for Review</h2>
        <p>
          Only proceed if you are authorized. See link to{" "}
          <a
            target="_blank"
            href="https://stackinfluencedev.blob.core.windows.net/validate/flowchart.png"
            rel="noreferrer"
          >
            Flowchart
          </a>
        </p>
        <Button
          type="primary"
          onClick={() => setState({ initialState: false })}
        >
          Proceed
        </Button>
      </div>
    );
  }

  if (!user) {
    return (
      <div style={styles.centered}>
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          className={stylesheet.checkIcon}
        />{" "}
        <h2>All validations done!</h2>
      </div>
    );
  }

  return (
    <Content style={{ padding: "0 1vw", height: "90vh" }}>
      <div
        style={{
          background: "#fff",
          padding: 24,
          height: "90vh",
        }}
      >
        <div key={user._id}>
          <Descriptions
            bordered
            style={{ marginBottom: "1%" }}
            column={numberOfColumns}
            title={<Title user={user} />}
            border
            size="small"
          />
          <ThumbnailGrid
            shortcodes={user.shortcodes}
            videos={user.videos}
            userId={user._id}
            batchId={user.batchId}
            refreshThumbnails={refreshThumbnails.mutateAsync}
          />
        </div>
      </div>
    </Content>
  );
}

const Title = ({ user }) => {
  const { _id, profilePic, posts, followers, follows, fullName, biography } =
    user;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Meta
        className={stylesheet.userMeta}
        avatar={
          <Avatar size={72} src={profilePic} style={{ marginRight: "12px" }} />
        }
        title={
          <a
            target="_blank"
            href={`https://instagram.com/${user._id}`}
            rel="noreferrer"
          >
            {_id}
          </a>
        }
        description={
          <div style={styles.regularFont}>
            <span style={styles.bold}>{posts}</span> posts
            <span style={styles.followTextFormat}>{followers}</span> followers
            <span style={styles.followTextFormat}>{follows}</span> following
            <br />
            <br />
            <p style={styles.bold}>{fullName}</p>
            <p style={styles.regularFont}>{biography}</p>
          </div>
        }
      />
      <ActionTable userId={_id} />
    </div>
  );
};

Title.propTypes = {
  user: any,
};

const styles = {
  regularFont: {
    fontWeight: 300,
    fontSize: 14,
    color: "black",
  },
  bold: { fontWeight: 400 },
  followTextFormat: { fontWeight: 400, marginLeft: "12px" },
  centered: {
    //centered in the middle of screen
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
};

export function goodMessage(userId) {
  message.success(
    <>
      Marked <strong>{userId}</strong> as <strong>good</strong>
    </>,
    1
  );
}

export function badMessage(userId) {
  message.error(
    <>
      Marked <strong>{userId}</strong> as <strong>bad</strong>
    </>,
    1
  );
}
