import React, { useMemo, useEffect } from "react";
import {
  Checkbox,
  Form,
  InputNumber,
  Select,
  Space,
  Typography,
  Upload,
  DatePicker,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import moment from "moment";
import {
  resetSendState,
  setInviteGroupSelections,
  updateSendState,
  updateTypeState,
  uploadUserCsv,
} from "../../../redux/invites/sendInvitesActions";
import arrayDifference from "../../../utils/arrayDifference";
import GenericInvitesForm from "./GenericInvitesForm";
import QueryAutocomplete from "./QueryAutocomplete";

/**
 * ANTD
 */
const { Option } = Select;

/*
SELECTORS
*/
//drop down options for https://ant.design/components/select/#components-select-demo-multiple
const selectInviteGroupOptions = createSelector(
  state => state.contracts.data, //get contracts data
  contracts =>
    contracts &&
    contracts
      .map(contract => {
        const { inviteGroups } = contract.relations;
        return inviteGroups.map(invGroup => {
          const label = `${contract.name} -- ${invGroup.name}`;
          return (
            <Option
              key={`${label.toLowerCase()} ${invGroup.inviteGroupId}`}
              value={invGroup.inviteGroupId}
            >
              {label}
            </Option>
          );
        });
      })
      .flat(1)
);

const selectInviteGroupsData = createSelector(
  state => state.invites.data, //get inviteGroups data
  state => state.invites.inviteGroupSelections,
  (invitesData, inviteGroupSelections) => {
    //invite groups to render including woodpeckerCampaigns relations
    const selectedInviteGroupsData = [];

    for (const inviteGroupId of inviteGroupSelections) {
      const loadedInviteGroup = invitesData[inviteGroupId];
      if (loadedInviteGroup) selectedInviteGroupsData.push(loadedInviteGroup);
    }
    return [selectedInviteGroupsData, inviteGroupSelections];
  }
);

const selectInviteType = createSelector(
  state => state.invites.type,
  type => type
);

//Antd Form w/ Redux example - https://ant.design/components/form/#components-form-demo-global-state
function InvitesForm() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const inviteType = useSelector(state => selectInviteType(state));

  useEffect(() => {
    if (inviteType === "join") {
      dispatch(resetSendState());
    }
    dispatch(updateTypeState("invite"));
  }, [dispatch, inviteType]);

  //<Option> for drop down list of invite groups in active contracts
  const inviteGroupOptions = useSelector(state =>
    selectInviteGroupOptions(state)
  );

  //get data from redux state when new inviteGroups loaded
  const [selectedInviteGroupsData, inviteGroupSelections] = useSelector(state =>
    selectInviteGroupsData(state)
  );

  const sendState = useSelector(state => state.invites.send);
  const previewState = useSelector(state => state.invites.preview);

  const inPreviewTab = sendState.tab === "preview";
  const woodpeckerFields = useMemo(
    () =>
      selectedInviteGroupsData
        .map(iGroup =>
          iGroup.relations.emailGroups.relations.woodpeckerCampaigns.map(
            campaign => ({
              //push each woodpecker campaign in selectedInviteGroupsData
              name: campaign.woodpeckerCampaignId,
              //if in preview tab, use results from POST invites?preview=true
              value:
                inPreviewTab && previewState.output
                  ? previewState.output.woodpeckerCampaigns[
                      campaign.woodpeckerCampaignId
                    ]
                  : sendState[campaign.woodpeckerCampaignId],

              inviteGroupId: iGroup.inviteGroupId,
            })
          )
        )
        .flat(1),
    [selectedInviteGroupsData, sendState, previewState, inPreviewTab]
  );

  const allInviteGroupsHaveAtLeastOneWoodpeckerCampaign = useMemo(() => {
    // if (selectedInviteGroupsData.length === 0) {
    //   return false;
    // }
    for (const inviteGroup of selectedInviteGroupsData) {
      let foundWoodpeckerCampaign = false;
      for (const woodpeckerCampaign of woodpeckerFields) {
        if (woodpeckerCampaign.inviteGroupId === inviteGroup.inviteGroupId) {
          foundWoodpeckerCampaign = true;
          break;
        }
      }
      if (!foundWoodpeckerCampaign) {
        return false;
      }
    }
    return true;
  }, [selectedInviteGroupsData, woodpeckerFields]);

  useEffect(() => {
    if (!allInviteGroupsHaveAtLeastOneWoodpeckerCampaign) {
      dispatch(updateSendState({ sendSMSReminder: false, sendEmails: false }));
    }
  }, [allInviteGroupsHaveAtLeastOneWoodpeckerCampaign, dispatch]);

  const formFields = [
    {
      name: "inviteGroups",
      value: inviteGroupSelections,
      component: (
        <Form.Item
          key="inviteGroups"
          name="inviteGroups"
          label="Invite Groups"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            disabled={inPreviewTab}
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Select one or more Invite Groups"
            filterOption={
              //case insensitive search (option should be lowercase already)
              (inputValue, option) =>
                option.key.includes(inputValue.toLowerCase())
            }
          >
            {inviteGroupOptions}
          </Select>
        </Form.Item>
      ),
    },
    {
      name: "tagQuery",
      value: sendState.tagQuery,
      component: (
        <Form.Item
          key="tagQuery"
          name="tagQuery"
          label="Override Tags"
          extra="e.g. all=gender:female&any=niche:fitness,niche:food"
        >
          <Space size="large">
            <QueryAutocomplete
              style={{ width: "50vw" }}
              disabled={inPreviewTab || !sendState.sendEmails}
              onChange={value => {
                form.setFieldsValue({ tagQuery: value });
                dispatch(updateSendState({ tagQuery: value }));
              }}
              value={sendState.tagQuery}
            />
            <Upload
              name="csv"
              customRequest={() => undefined}
              showUploadList={false}
              disabled={inPreviewTab}
              action={async file => dispatch(uploadUserCsv(file))}
              // onChange={(file, fileList, event) => {
              //   console.log("file,fileList,event", file, fileList, event);
              // }}
            >
              or <Typography.Link>upload a CSV with User IDs</Typography.Link>
            </Upload>
          </Space>
        </Form.Item>
      ),
    },
    {
      name: "sendEmails",
      value: sendState.sendEmails,
      component: (
        <Form.Item
          key="sendEmails"
          label="Send Emails"
          style={{
            marginBottom: 0,
          }}
        >
          <Tooltip
            title={
              !allInviteGroupsHaveAtLeastOneWoodpeckerCampaign
                ? "All Invite Groups must have at least one woodpecker campaign"
                : ""
            }
          >
            <Form.Item
              name="sendEmails"
              valuePropName="checked"
              style={{
                display: "inline-block",
              }}
            >
              <Checkbox
                disabled={
                  inPreviewTab ||
                  !allInviteGroupsHaveAtLeastOneWoodpeckerCampaign
                }
              />
            </Form.Item>
          </Tooltip>
        </Form.Item>
      ),
    },
    {
      name: "sendSMSReminder",
      value: sendState.sendSMSReminder,
      component: (
        <Form.Item
          key="sendSMSReminder"
          label="Send SMS Reminder"
          style={{
            marginBottom: 0,
          }}
        >
          <Tooltip
            title={
              !allInviteGroupsHaveAtLeastOneWoodpeckerCampaign
                ? "All Invite Groups must have at least one woodpecker campaign"
                : ""
            }
          >
            <Form.Item
              name="sendSMSReminder"
              valuePropName="checked"
              style={{
                display: "inline-block",
              }}
            >
              <Checkbox
                disabled={
                  inPreviewTab ||
                  !allInviteGroupsHaveAtLeastOneWoodpeckerCampaign
                }
              />
            </Form.Item>
          </Tooltip>
          <Form.Item
            name="reminderDate"
            style={{
              display: "inline-block",
              marginLeft: "16px",
            }}
            extra="Picker is CST (-6) timezone. Must schedule between 1 PM and 4 PM"
          >
            <DatePicker
              disabled={!sendState.sendSMSReminder}
              showNow={false}
              showTime={{
                defaultValue: moment("13:00", "HH:mm"),
                format: "HH:mm",
              }}
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
              placeholder="Select SMS Reminder Date"
              disabledDate={current => {
                const fiveDaysAhead = moment().add(5, "days").startOf("day");
                const thirteenDaysAhead = moment().add(13, "days").endOf("day");
                return (
                  current.isBefore(fiveDaysAhead) ||
                  current.isAfter(thirteenDaysAhead)
                );
              }}
              disabledTime={() => ({
                disabledHours: () => [
                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 16, 17, 18, 19, 20,
                  21, 22, 23,
                ],
              })}
            />
          </Form.Item>
        </Form.Item>
      ),
    },
    {
      name: "reminderDate",
      value: moment(sendState.reminderDate),
    },
    ...woodpeckerFields,
  ];

  const generateGroupData = () => {
    return selectedInviteGroupsData.map(inviteGroup => {
      const groupData = {
        ...inviteGroup,
        link: `/invites/${inviteGroup.contractId}`,
        woodpeckerCampaigns: [],
        defaultTagQuery: inviteGroup.relations.emailGroups.defaultTagQuery,
      };
      if (sendState.sendEmails) {
        groupData.woodpeckerCampaigns =
          inviteGroup.relations.emailGroups.relations.woodpeckerCampaigns;
      } else {
        groupData.formComponents = [
          <Form.Item label="Number of Users" key={1}>
            <InputNumber
              disabled={inPreviewTab}
              style={{ maxWidth: 200 }}
              min={0}
              max={99999}
              onChange={value => {
                const inviteGroups = { ...sendState.inviteGroups };
                dispatch(
                  updateSendState({
                    inviteGroups: {
                      ...inviteGroups,
                      [inviteGroup.inviteGroupId]: value,
                    },
                  })
                );
              }}
            />
          </Form.Item>,
        ];
      }
      return groupData;
    });
  };

  return (
    <GenericInvitesForm
      form={form}
      groupData={generateGroupData()}
      formFields={formFields}
      onValuesChange={(changedValues, allValues) => {
        if (changedValues.inviteGroups) {
          //if there are inviteGroups in changedFields that are NOT in state, dispatch action
          //action fetches woodpecker relations and metadata if doesnt exist
          const difference = arrayDifference(
            changedValues.inviteGroups,
            inviteGroupSelections
          );
          if (difference[0])
            dispatch(setInviteGroupSelections(changedValues.inviteGroups));
        } else {
          //update invites.send form based on field value
          dispatch(updateSendState(changedValues));
        }
      }}
    />
  );
}

export default InvitesForm;
