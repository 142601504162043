export function getTagColor(state) {
  switch (state) {
    case "active":
      return "green";
    case "closing":
    case "paused":
      return "orange";
    case "disqualified":
      return "red";
    case "in_review":
      return "blue";
    case "done":
    default:
      return "default";
  }
}
