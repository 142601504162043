import React from "react";
//components
import SendInvitesHeader from "./SendInvitesHeader/SendInvitesHeader";
import InvitesForm from "./InvitesForm";
import EmailJobLogs from "./EmailJobLogs";
//redux
import { useSelector } from "react-redux";

export default props => {
  //TODO: share invite in redux including preview state ie. if ready to submit
  // if any data in invites.preview, show reset button in header else hide
  // only enable clicking submit tab if preview has been selected ie. allow back and forth between preview and submit
  // For example invite Groups selection checkbox could push invites
  const currentTab = useSelector(state => state.invites.send.tab);
  let tabToRender;
  switch (currentTab) {
    case "form":
    case "preview":
      tabToRender = <InvitesForm />;
      break;
    case "logs":
      tabToRender = <EmailJobLogs />;
      break;
    default:
      break;
  }

  return (
    <div>
      <SendInvitesHeader />
      {tabToRender}
    </div>
  );
};
