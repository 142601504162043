import React, { useState } from "react";
import { Button, message, Modal, Select, Tag } from "antd";
import { bool, func, string, object } from "prop-types";
import { selectFilter } from "../../../utils/filters";
import checkAuth from "../../..//utils/checkAuth";
import { useUpdateUser } from "../../../api/users";
import moment from "moment";
import { useSelector } from "react-redux";

const userStateOptions = [
  {
    label: "Disqualified - Fraud",
    value: "disqualified - fraud",
    userState: "disqualified",
    reason: "fraud",
  },
  {
    label: "Disqualified - Returns",
    value: "disqualified - returns",
    userState: "disqualified",
    reason: "returns",
  },
  {
    label: "Disqualified - Profile Review",
    value: "disqualified - profile review",
    userState: "disqualified",
    reason: "profile_review",
  },
  {
    label: "Not Interested",
    value: "not_interested",
    userState: "not_interested",
  },
];

// TODO: This is fragile if we add another dnd event in the backend. We've got a whole system for
// conditionally flagging events as dnd, that we're not using here.
const dndUserStates = ["disqualified", "not_interested"];
const uneditableUserStates = [
  "disqualified",
  "not_interested",
  "profile_review",
];

export default function UpdateUserStateModal({
  user,
  igHandle,
  visible,
  setVisible,
}) {
  const [selectedUserState, setSelectedUserState] = useState(null);
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector(state => state);

  // auth for Undo button
  const isAdmin = checkAuth.has(auth.roles, ["admin"]);

  const updateUser = useUpdateUser();

  const closeModal = () => {
    setSelectedUserState(null);
    setVisible(false);
  };

  if (!user) return;

  // sort userEvents by userEventId desc, to get the latest first
  const userEvents =
    user.relations?.userEvents?.sort((a, b) => b.userEventId - a.userEventId) ||
    [];
  const latestUserEvent = userEvents[0];

  const submitUpdateUser = async ({
    userState,
    metadata,
    forceUserState = false,
  }) => {
    try {
      setLoading(true);
      const updateBody = { userState, userStateUpdateMetadata: metadata };
      await updateUser.mutateAsync({
        userId: user.userId,
        body: updateBody,
        params: { forceUserState },
      });
      window.location.reload(false);
    } catch (err) {
      message.error(err?.message || "An error occurred.");
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  const buttons = [
    <Button key="cancel" onClick={() => closeModal()}>
      Cancel
    </Button>,
  ];

  // admins can undo a DND user state back to the previous state
  if (dndUserStates.includes(user.userState) && isAdmin) {
    buttons.push(
      <Button
        key="undo"
        type="primary"
        onClick={() =>
          submitUpdateUser({
            userState: latestUserEvent.previousUserState,
            metadata: {
              message: "Undo user state",
              manager: auth.user.username,
            },
            forceUserState: true,
          })
        }
      >
        Undo
      </Button>
    );
  }

  buttons.push(
    <Button
      key="submit"
      type="primary"
      disabled={
        !selectedUserState || uneditableUserStates.includes(user.userState)
      }
      onClick={() => {
        const metadata = {
          message: "Management disqualify",
          manager: auth.user.username,
        };

        if (selectedUserState.reason) {
          metadata.reason = [selectedUserState.reason];
        }

        return submitUpdateUser({
          userState: selectedUserState.userState,
          metadata,
        });
      }}
    >
      Submit
    </Button>
  );

  return (
    <Modal
      title={`@${igHandle} User State`}
      visible={visible}
      footer={buttons}
      onCancel={() => closeModal()}
    >
      <div>
        <UserEventSummary event={latestUserEvent} />

        <Select
          style={{ width: "100%" }}
          filterOption={selectFilter}
          options={userStateOptions}
          placeholder="Select a user state option"
          onSelect={(label, option) => setSelectedUserState(option)}
          value={userStateOptions.find(
            o => o.label === selectedUserState?.label
          )}
          disabled={uneditableUserStates.includes(user.userState)}
        />
      </div>
    </Modal>
  );
}

UpdateUserStateModal.propTypes = {
  user: object,
  igHandle: string,
  visible: bool,
  setVisible: func,
};

function UserEventSummary({ event }) {
  if (!event) return null;

  const { previousUserState, eventCode, createdAt, metadata } = event;

  return (
    <div>
      <span>
        Previous user state: <Tag>{previousUserState}</Tag> Event code:{" "}
        <Tag>{eventCode}</Tag>
      </span>
      <ul>
        <li>
          <strong>date:</strong> {moment(createdAt).format("MMM Do YYYY")}
        </li>
        {Object.entries(metadata || {}).map(([key, value]) => (
          <li>
            <strong>{key}:</strong> {prettyPrint(value)}
          </li>
        ))}
      </ul>
    </div>
  );
}

function prettyPrint(value) {
  if (Array.isArray(value)) {
    return value.map(prettyPrint).join(", ");
  }

  if (typeof value === "object") {
    return JSON.stringify(value, null, 2);
  }

  return value;
}

UserEventSummary.propTypes = {
  event: object,
};
