import React from "react";
import { Button, Modal, Form, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { bool, func } from "prop-types";

function AddWoodpeckerCampaignModal({ visible, onCreate, onCancel }) {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title="Add Woodpecker Campaigns"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={async () => {
        try {
          const values = await form.validateFields();
          form.resetFields();
          onCreate(values);
        } catch (error) {
          console.log("validation failed: ", error);
        }
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.List name="woodpeckerCampaigns">
          {(fields, { add, remove }) => {
            //create at least 1 woodpecker campaign
            if (!fields.length) {
              fields.push({ name: 0, key: 0, fieldKey: 0 });
            }
            return (
              <div>
                {fields.map(field => {
                  return (
                    <Form.Item required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Input cannot be empty",
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder="Woodpecker Campaign ID"
                          style={{ width: "85%" }}
                        />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          style={{ margin: "0 0 0 8px" }}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      ) : null}
                    </Form.Item>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      const values = form.getFieldsValue(true);
                      if (!values.woodpeckerCampaigns) {
                        add();
                      }
                      add();
                    }}
                    style={{ width: "60%" }}
                  >
                    <PlusOutlined /> Add Woodpecker Campaign
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
}

AddWoodpeckerCampaignModal.propTypes = {
  visible: bool,
  onCreate: func,
  onCancel: func,
};

export default AddWoodpeckerCampaignModal;
