import React from "react";
import { Button } from "antd";
import { string, func } from "prop-types";
//router
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import checkAuth from "../../../utils/checkAuth";
import GenericPageHeader from "../../shared/GenericPageHeader/GenericPageHeader";
import StateFilter from "./StateFilter";

export default function ContractHeader({ filter, setFilter }) {
  const history = useHistory();
  const roles = useSelector(state => state.auth.roles);

  const shouldDisplayCreateButton =
    checkAuth.has(roles, ["admin"]) &&
    history.location.pathname !== "/contracts/add";

  return (
    <GenericPageHeader
      ghost={false}
      showBreadcrumb
      title="Contracts"
      extra={
        <>
          <StateFilter filter={filter} setFilter={setFilter} />
          {shouldDisplayCreateButton && (
            <Button
              type="primary"
              onClick={() => history.push(`/contracts/add`)}
            >
              <PlusOutlined />
              New Contract
            </Button>
          )}
        </>
      }
    />
  );
}

ContractHeader.propTypes = {
  filter: string,
  setFilter: func,
};
