import { useQuery, useQueryClient, useMutation } from "react-query";
import { useDispatch } from "react-redux";
import {
  createAuthenticatedRequest,
  defaultQueryOptions,
  executeRequest,
  defaultMutationOptions,
} from "./utils";
import { flattenCampaigns } from "../redux/campaigns/campaignUtils";
import { CAMPAIGN_STEPS_PATH } from "./campaignSteps";
import { CAMPAIGN_FLOWS_PATH } from "./campaignFlows";
import { COMPANIES_PATH } from "./companies";
import { EMAIL_TEMPLATES_PATH } from "./emailTemplates";
import { CONTRACTS_PATH } from "./contracts";
import { CAMPAIGN_REQUIREMENTS_PATH } from "./campaignRequirements";
import { BILLING_CONTRACTS_PATH } from "./billingContract";

export const CAMPAIGNS_PATH = "campaigns";

export function useCampaigns({ includes, params, ...overrideOptions }) {
  const dispatch = useDispatch();

  return useQuery(
    [CAMPAIGNS_PATH, includes, params],
    () => fetchCampaigns(dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for all campaigns
export async function fetchCampaigns(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    CAMPAIGNS_PATH,
    dispatch,
    options
  );

  const campaigns = await executeRequest(requestObject);
  return flattenCampaigns(campaigns);
}

export function useCampaign({
  campaignId,
  includes,
  params,
  ...overrideOptions
}) {
  const dispatch = useDispatch();

  const requestPath = `${CAMPAIGNS_PATH}/${campaignId}`;

  return useQuery(
    [CAMPAIGNS_PATH, campaignId, includes, params],
    () => fetchCampaign(requestPath, dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for specific campaign
export async function fetchCampaign(
  path,
  dispatch,
  { flatten = true, ...options } = {}
) {
  const requestObject = await createAuthenticatedRequest(
    path,
    dispatch,
    options
  );

  const campaigns = await executeRequest(requestObject);

  if (!flatten) return campaigns;

  return flattenCampaigns([campaigns])[0];
}

export function useUpdateCampaign(campaignId) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({ body }) => updateCampaign({ campaignId, body }, dispatch),
    {
      ...defaultMutationOptions,
      onSuccess: () => {
        queryClient.invalidateQueries([CAMPAIGNS_PATH, campaignId]);
        queryClient.invalidateQueries(BILLING_CONTRACTS_PATH);
        queryClient.invalidateQueries(CONTRACTS_PATH);
      },
    }
  );
}

// Execute a PUT request to update campaign
export async function updateCampaign({ campaignId, body }, dispatch) {
  const requestObject = await createAuthenticatedRequest(
    `${CAMPAIGNS_PATH}/${campaignId}`,
    dispatch,
    {
      method: "PUT",
      body: JSON.stringify(body),
    }
  );

  return executeRequest(requestObject);
}

export function useCreateCampaign({ companyId, contractId }) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(newCampaign => createCampaign(newCampaign, dispatch), {
    ...defaultMutationOptions,
    onSuccess: () => {
      queryClient.invalidateQueries(CAMPAIGNS_PATH);
      queryClient.invalidateQueries(CAMPAIGN_STEPS_PATH);
      queryClient.invalidateQueries(CAMPAIGN_FLOWS_PATH);
      queryClient.invalidateQueries(EMAIL_TEMPLATES_PATH);
      queryClient.invalidateQueries(COMPANIES_PATH);
      queryClient.invalidateQueries(`${COMPANIES_PATH}/${companyId}`);
      queryClient.invalidateQueries(CONTRACTS_PATH);
      queryClient.invalidateQueries(`${CONTRACTS_PATH}/${contractId}`);
      queryClient.invalidateQueries(CAMPAIGN_REQUIREMENTS_PATH);
      // Just invalidate all billing contracts for now. In the future we can make
      // it more specific by billing contract ID
      queryClient.invalidateQueries(BILLING_CONTRACTS_PATH);
    },
  });
}

// Execute a POST to create a new campaign
export async function createCampaign(campaign, dispatch) {
  const requestObject = await createAuthenticatedRequest(
    CAMPAIGNS_PATH,
    dispatch,
    {
      method: "POST",
      body: JSON.stringify(campaign),
    }
  );

  return executeRequest(requestObject);
}

export function useValidRulesTables({ ...overrideOptions }) {
  const dispatch = useDispatch();

  const requestPath = `${CAMPAIGNS_PATH}/rules`;

  return useQuery(
    [CAMPAIGNS_PATH, "rules"],
    () => fetchCampaign(requestPath, dispatch, { flatten: false }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

export function useUpdateCampaigns({ ...overrideOptions }) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ campaignIds, updates }) => {
      const requestObject = await createAuthenticatedRequest(
        CAMPAIGNS_PATH,
        dispatch,
        {
          method: "PUT",
          body: JSON.stringify({ campaignIds, updates }),
        }
      );

      return executeRequest(requestObject);
    },
    {
      ...defaultMutationOptions,
      overrideOptions,
      onSuccess: () => {
        queryClient.invalidateQueries(CAMPAIGNS_PATH);
        queryClient.invalidateQueries(CONTRACTS_PATH);
      },
    }
  );
}
