import React from "react";
import { InputNumber } from "antd";

export default function MoneyInput(props) {
  return (
    <InputNumber
      addonBefore="$"
      stringMode
      min="0"
      step="0.01"
      precision={2}
      defaultValue="0.00"
      onFocus={e => e.target.select()}
      {...props}
    />
  );
}
