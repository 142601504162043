import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  createAuthenticatedRequest,
  defaultQueryOptions,
  executeRequest,
} from "./utils";

export const COMPANIES_PATH = "companies";

export function useCompanies({ includes, params, ...overrideOptions }) {
  const dispatch = useDispatch();

  return useQuery(
    [COMPANIES_PATH, includes, params],
    () => fetchCompanies(dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for companies
export async function fetchCompanies(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    COMPANIES_PATH,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}

export function useCompany({
  companyId,
  includes,
  params,
  ...overrideOptions
}) {
  const dispatch = useDispatch();

  const path = `${COMPANIES_PATH}/${companyId}`;

  return useQuery(
    [path, includes, params],
    () => fetchCompany(path, dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for a single company
export async function fetchCompany(path, dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    path,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}
