import { types } from "./postActions";

export default (state = {}, action) => {
  switch (action.type) {
    case types.GET_POSTS_SUCCESS:
      return { ...state, data: action.data };
    case types.UPLOAD_SCREENSHOT_SUCCESS:
      return {
        ...state,
        screenshot: { onboardId: action.id, url: action.url },
      };
    default:
      return state;
  }
};
