import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Upload, Button } from "antd";
//redux
import { useDispatch } from "react-redux";

export default props => {
  // const { post } = props;
  // if (!post) return null;

  //redux
  const dispatch = useDispatch();

  return (
    <Upload
      name="csv"
      customRequest={() => undefined}
      showUploadList={false}
      action={file => dispatch(props.action(file))}
    >
      <Button type="primary" key="upload" icon={<UploadOutlined />}>
        {props.text}
      </Button>
    </Upload>
  );
};
