/**
 * Wrapper component that toggles between two children based on button clicks.
 * Also provides a hook to the children to toggle the state themselves.
 *
 * Example:
 * function MyComponent() {
    const [options, setOptions] = useState([
      {
        value: "First option",
      },
      {
        value: "Second option",
      },
      {
        value: "Third option",
      },
    ]);

    const [selectedOption, setSelectedOption] = useState(null);

    // In real code, split these out into separate components (same file or new files)
    const FirstForm = ({}) => {
      // Look at InvitesForm select for example
      return (
        <AutoComplete
          options={options}
          {...(selectedOption && { defaultValue: selectedOption })}
          onSelect={setSelectedOption}
          placeholder="Type to search contracts"
          style={{ width: 200 }}
          backfill
          filterOption={(inputValue, option) => {
            return (
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            );
          }}
        />
      );
    };

    // In real code, split these out into separate components (same file or new files)
    const SecondForm = ({ toggleVisibleComponent }) => {
      const [loading, setLoading] = useState(false);
      const onSubmit = async values => {
        console.log(values);
        setLoading(true);
        //const newOption = await doSomeApiStuff(values);
        const newOption = {
          value: `${options.length + 1}th option`,
        };
        setOptions([newOption, ...options]);
        setSelectedOption(newOption.value);
        toggleVisibleComponent();
      };

      return (
        <Form name="createNewThing" onFinish={onSubmit}>
          <Form.Item
            label="Email"
            name="email"
            // Add extra email rules...
            rules={[{ required: true, message: "Email required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="First name"
            name="firstName"
            rules={[{ required: true, message: "First name required" }]}
          >
            <Input />
          </Form.Item>
          <Button htmlType="submit">Submit</Button>
          {loading && <span>Loading...</span>}
        </Form>
      );
    };

    return (
      <TwoComponentSwitch
        firstComponent={<FirstForm />}
        secondComponent={<SecondForm />}
        title="Contracts"
      />
    );
  }
 */
import React, { cloneElement, useState } from "react";
import { Button, Space } from "antd";
import { bool, element, func, number, string } from "prop-types";
import styles from "./styles.module.css";

function TwoComponentSwitch({
  firstComponent,
  secondComponent,
  activateFirstText = "Select existing",
  activateSecondText = "Create new",
  defaultComponent = 1,
  onSwitch,
  buttonHidden = false,
  ...rest
}) {
  const [activeComponent, setActiveComponent] = useState(defaultComponent);
  const primaryActive = activeComponent === defaultComponent;
  const buttonText =
    activeComponent === 1 ? activateSecondText : activateFirstText;
  const component = activeComponent === 1 ? firstComponent : secondComponent;

  const toggleVisibleComponent = () => {
    const newActiveComponent = activeComponent === 1 ? 2 : 1;
    setActiveComponent(newActiveComponent);
    if (onSwitch) {
      onSwitch(newActiveComponent);
    }
  };

  return (
    <Space direction="vertical" {...rest}>
      {!buttonHidden && (
        <div className={styles.headerButtonContainer}>
          <Button
            type={primaryActive ? "primary" : "secondary"}
            onClick={toggleVisibleComponent}
            size="small"
          >
            {buttonText}
          </Button>
        </div>
      )}
      {cloneElement(component, { toggleVisibleComponent })}
    </Space>
  );
}

TwoComponentSwitch.propTypes = {
  firstComponent: element.isRequired,
  secondComponent: element.isRequired,
  activateFirstText: string,
  activateSecondText: string,
  defaultComponent: number,
  onSwitch: func,
  buttonHidden: bool,
};

export default TwoComponentSwitch;
