const uuidRegex = new RegExp(
  /^[0-9A-F]{8}-[0-9A-F]{4}-[1][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/,
  "i"
);

export const isUuid = id => {
  return !!id.match(uuidRegex);
};

export const paypalTransactionIdRegex = new RegExp(/[\dA-Z]{17}/, "i");

export const isPaypalTransactionId = id => {
  return !!id.match(paypalTransactionIdRegex);
};

export const emailRegex = new RegExp(
  //eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  "g"
);
export const isEmail = string => {
  return !!string.match(emailRegex);
};
