//transforms NewInviteGroupModal from into input that can be dispatched to redux
export const newInviteGroupTransform = (contractId, formInput) => {
  return {
    contractId, //from InviteGroups redux
    name: formInput.name.trim(),
    relations: {
      emailGroups: {
        defaultTagQuery: formInput.defaultTagQuery.toLowerCase().trim(),
        relations: {
          woodpeckerCampaigns: formInput.woodpeckerCampaigns.map(
            woodpeckerCampaignId => ({ woodpeckerCampaignId })
          ),
        },
      },
      campaignInviteGroups: formInput.campaigns.map(campaignId => ({
        campaignId,
      })),
    },
  };
};
