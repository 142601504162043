import React from "react";
import { Checkbox, Col, Form, Typography } from "antd";
import { any } from "prop-types";
import styles from "./BillingContract.module.css";

export default function GenericProductCreditsConfiguration({
  billingSetup,
  productCreditSettings,
}) {
  if (productCreditSettings) {
    const productSetting = productCreditSettings[0];

    const getCheckboxLabel = () => {
      const { suggestedCredits } = productSetting;
      if (suggestedCredits === 0) {
        return "Credits for current target minted";
      }
      return `${suggestedCredits > 0 ? "Add" : "Delete"} ${Math.abs(
        suggestedCredits
      )} credits?`;
    };

    return (
      <>
        <Col
          span={4}
          key="averageProductPrice"
          className={styles.textFormColumn}
        >
          <Typography.Text>
            <span className={styles.formLabel}>Average Product Price: </span>$
            {billingSetup.averageProductPrice || "-"}
          </Typography.Text>
        </Col>

        <Col span={4} key="totalCredits" className={styles.textFormColumn}>
          <Typography.Text>
            <span className={styles.formLabel}>Total Credits: </span>
            {productSetting.total || 0}
          </Typography.Text>
        </Col>

        <Col span={4} key="usedCredits" className={styles.textFormColumn}>
          <Typography.Text>
            <span className={styles.formLabel}>Used Credits: </span>
            {productSetting.consumed}
          </Typography.Text>
        </Col>

        <Col span={4} key="availableCredits" className={styles.textFormColumn}>
          <Typography.Text>
            <span className={styles.formLabel}>Available Credits: </span>
            {productSetting.total - productSetting.consumed}
          </Typography.Text>
        </Col>

        <Col
          span={4}
          key="newCredits"
          className={styles.formColumn}
          style={{ display: "flex" }}
        >
          <span className={styles.formLabel}>{getCheckboxLabel()}</span>
          <Form.Item
            className={styles.formItem}
            name="newCredits-0"
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox
              disabled={productSetting.suggestedCredits === 0}
              style={{ marginLeft: "8px", marginBottom: "8px" }}
            />
          </Form.Item>
        </Col>
      </>
    );
  }

  return null;
}

GenericProductCreditsConfiguration.propTypes = {
  productCreditSettings: any,
  billingSetup: any,
};
