import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { CAMPAIGNS_PATH } from "./campaigns";
import { CONTRACTS_PATH } from "./contracts";
import {
  createAuthenticatedRequest,
  defaultQueryOptions,
  defaultMutationOptions,
  executeRequest,
} from "./utils";

export const BILLING_CONTRACTS_PATH = "billingContracts";

export function useBillingContracts({ includes, params, ...overrideOptions }) {
  const dispatch = useDispatch();

  return useQuery(
    [BILLING_CONTRACTS_PATH, includes, params],
    () => fetchBillingContracts(dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for billing contracts
export async function fetchBillingContracts(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    BILLING_CONTRACTS_PATH,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}

export function useBillingContractById({
  billingContractId,
  includes,
  params,
  ...overrideOptions
}) {
  const dispatch = useDispatch();

  return useQuery(
    [BILLING_CONTRACTS_PATH, includes, params],
    () =>
      fetchBillingContractById(billingContractId, dispatch, {
        includes,
        params,
      }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for billing contract by id
export async function fetchBillingContractById(
  billingContractId,
  dispatch,
  options
) {
  const requestObject = await createAuthenticatedRequest(
    `${BILLING_CONTRACTS_PATH}/${billingContractId}`,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}

export function useCreateBillingContract() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    newContract => createBillingContract(newContract, dispatch),
    {
      ...defaultMutationOptions,
      onSuccess: () => {
        queryClient.invalidateQueries(BILLING_CONTRACTS_PATH);
      },
    }
  );
}

// Execute a POST to create a new billing contract
export async function createBillingContract(contract, dispatch) {
  const requestObject = await createAuthenticatedRequest(
    BILLING_CONTRACTS_PATH,
    dispatch,
    {
      method: "POST",
      body: JSON.stringify(contract),
    }
  );

  return executeRequest(requestObject);
}

export function useBillingContractSetup({
  billingContractId,
  includes,
  params,
  ...overrideOptions
}) {
  const dispatch = useDispatch();

  return useQuery(
    [BILLING_CONTRACTS_PATH, billingContractId, "setup", includes, params],
    () =>
      fetchBillingContractSetup(billingContractId, dispatch, {
        includes,
        params,
      }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for billing contract setup
export async function fetchBillingContractSetup(
  billingContractId,
  dispatch,
  options
) {
  const billingContractSetupPath = `${BILLING_CONTRACTS_PATH}/${billingContractId}/setup`;
  const requestObject = await createAuthenticatedRequest(
    billingContractSetupPath,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}

export function useUpdateBillingContract() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({ billingContractId, updatedBillingContract }) =>
      updateBillingContract(
        billingContractId,
        updatedBillingContract,
        dispatch
      ),
    {
      ...defaultMutationOptions,
      onSuccess: data => {
        queryClient.invalidateQueries([
          BILLING_CONTRACTS_PATH,
          data.billingContractId,
        ]);
        queryClient.invalidateQueries(CONTRACTS_PATH);
        queryClient.invalidateQueries(CAMPAIGNS_PATH);
      },
    }
  );
}

// Execute a POST to create a new billing contract
export async function updateBillingContract(
  billingContractId,
  updatedBillingContract,
  dispatch
) {
  const billingContractUpdatePath = `${BILLING_CONTRACTS_PATH}/${billingContractId}`;

  const requestObject = await createAuthenticatedRequest(
    billingContractUpdatePath,
    dispatch,
    {
      method: "PUT",
      body: JSON.stringify(updatedBillingContract),
    }
  );

  return executeRequest(requestObject);
}

export function useMintBillingContract() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({ billingContractId, mintedBillingContract }) =>
      mintBillingContract(billingContractId, mintedBillingContract, dispatch),
    {
      ...defaultMutationOptions,
      onSuccess: () => {
        queryClient.invalidateQueries(BILLING_CONTRACTS_PATH);
        queryClient.invalidateQueries(CONTRACTS_PATH);
        queryClient.invalidateQueries(CAMPAIGNS_PATH);
      },
    }
  );
}

// Execute a POST to mint a new billing contract
export async function mintBillingContract(
  billingContractId,
  mintedBillingContract,
  dispatch
) {
  const billingContractMintPath = `${BILLING_CONTRACTS_PATH}/${billingContractId}/mint`;

  const requestObject = await createAuthenticatedRequest(
    billingContractMintPath,
    dispatch,
    {
      method: "POST",
      body: JSON.stringify(mintedBillingContract),
    }
  );

  return executeRequest(requestObject);
}
