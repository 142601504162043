import React, { useEffect, useRef, useState } from "react";
import { array, bool, func, number, string } from "prop-types";
import { Form, Select, Input, Button } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import TwoComponentSwitch from "../../shared/TwoComponentSwitch/TwoComponentSwitch";
import styles from "./AddNewCampaign.module.css";
import {
  defaultFormItemLayout,
  defaultButtonWrapperCol,
} from "../../../utils/formLayout";
import optionOrNew from "./optionOrNew";
import TagsInput from "./TagsInput";
import PhotoExamplesUpload from "./PhotoExamplesUpload";

const { TextArea } = Input;

const FirstForm = ({
  options,
  name,
  loading,
  toggleVisibleComponent,
  setExistingOption,
}) => {
  const getOptions = () => {
    const optionComponents = [];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const optionValue = JSON.parse(option.value);
      const onNewClick = createCopy => {
        setExistingOption(i, createCopy);
        toggleVisibleComponent();
      };
      optionComponents.push(
        optionOrNew({
          option,
          optionData: optionValue,
          idProperty: "campaignRequirementId",
          onNewClick: () => onNewClick(false),
          onCreateCopy: () => onNewClick(true),
        })
      );
    }
    return optionComponents;
  };

  return (
    <Form.Item
      label="Requirement Name"
      className={styles.formItem}
      name={name}
      rules={[{ required: true, message: "required" }]}
    >
      <Select
        loading={loading}
        showSearch
        className={styles.fullWidth}
        placeholder="Type to search requirements"
        filterOption={(inputValue, option) => {
          return (
            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          );
        }}
      >
        {getOptions()}
      </Select>
    </Form.Item>
  );
};

FirstForm.propTypes = {
  options: array,
  name: string,
  loading: bool,
  toggleVisibleComponent: func,
  setExistingOption: func,
};

const SecondForm = ({
  toggleVisibleComponent,
  options,
  setOptions,
  setSelectedOption,
  existingOptionIndex,
  creatingCopy,
  contractName,
}) => {
  const [form] = Form.useForm();
  const [tags, setTags] = useState([]);
  const tagsInputComponent = useRef(null);
  const [rows, setRows] = useState([]);
  const [requirementName, setRequirementName] = useState("");
  const [defaultPhotoExampleUrls, setDefaultPhotoExampleUrls] = useState(null);

  useEffect(() => {
    if (existingOptionIndex > -1) {
      const optionData = JSON.parse(options[existingOptionIndex].value);
      const formData = {
        // name: creatingCopy ? `${optionData.name} copy` : optionData.name,
        name: `${contractName} Requirements`,
      };
      setTags(optionData.tags);
      const rowData = optionData.form;
      const rowKeys = Object.keys(rowData);
      setRows(rowKeys);

      for (let i = 0; i < rowKeys.length; i++) {
        const key = rowKeys[i];
        const value = rowData[key].replace("<br>", "\n");
        formData[`rowKey-${i + 1}`] = key;
        formData[`rowValue-${i + 1}`] = value;
      }
      form.setFieldsValue(formData);
      setRequirementName(formData.name);

      const photoExamples = optionData.photoExamples
        ? optionData.photoExamples
        : {
            thumbnailUrls: optionData.photoExampleThumbnailUrls,
            fullImageUrls: optionData.photoExampleFullUrls,
          };
      form.setFieldsValue({
        photoExamples,
      });
      setDefaultPhotoExampleUrls(photoExamples);
    } else {
      form.resetFields();
      form.setFieldsValue({ name: `${contractName} Requirements` });
      setRequirementName(`${contractName} Requirements`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingOptionIndex]);

  const onSubmit = async () => {
    try {
      await form.validateFields();
      if (tags.length === 0) {
        tagsInputComponent.current.setTagsError(
          "At least one tag is required. Type something and then press enter/return"
        );
        throw Error("At least one tag is required.");
      }
      const values = form.getFieldsValue(true);
      const valueKeys = Object.keys(values);

      const requirementRows = {};
      for (const key of valueKeys) {
        if (key.includes("rowKey")) {
          const keyIndex = key.split("-")[1];
          const keyName = values[key];
          delete values[key];
          requirementRows[keyName] = values[`rowValue-${keyIndex}`].replace(
            /(?:\r\n|\r|\n)/g,
            "<br>"
          );
          delete values[`rowValue-${keyIndex}`];
        }
      }
      values.form = requirementRows;
      values.tags = tags;

      const newOption = {
        label: values.name,
        value: JSON.stringify(values),
      };
      if (existingOptionIndex >= 0 && !creatingCopy) {
        options.splice(existingOptionIndex, 1);
      }
      setOptions([newOption, ...options]);
      setSelectedOption(newOption);
      form.resetFields();
      toggleVisibleComponent();
    } catch (error) {
      //ignore as Ant Design will handle validation based on form.item rules
    }
  };

  const validateRequirementName = value => {
    if (!value) {
      return Promise.reject(new Error("required"));
    }
    if (!value.trim()) {
      return Promise.reject(new Error("requirement name can't be all spaces"));
    }
    const existingOption = options.find(o => o.label === value);
    if (existingOptionIndex < 0) {
      if (existingOption) {
        return Promise.reject(new Error("requirement name already exists"));
      }
    } else {
      const previousExistingOption = options[existingOptionIndex];
      if (
        existingOption &&
        (creatingCopy || previousExistingOption.label !== existingOption.label)
      ) {
        return Promise.reject(new Error("requirement name already exists"));
      }
    }
    return Promise.resolve();
  };

  const addRow = () => {
    const newRows = [...rows];
    newRows.push(newRows.length + 1);
    setRows(newRows);
  };

  const removeRow = index => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const validatePhotoExamples = value => {
    if (!value) {
      return Promise.reject(new Error("required"));
    }
    const validThumbnailUrls = value.thumbnailUrls.filter(Boolean);
    const validFullUrls = value.fullImageUrls.filter(Boolean);
    if (validThumbnailUrls.length < 3 || validFullUrls.length < 3) {
      return Promise.reject(
        new Error("all three photo examples must be uploaded")
      );
    }
    return Promise.resolve();
  };

  const onUploadPhotoExamples = urls => {
    form.setFieldsValue({
      photoExamples: urls,
    });
  };

  return (
    <Form
      {...defaultFormItemLayout}
      component={false}
      form={form}
      labelAlign="right"
      layout="horizontal"
      name="createRequirementsForm"
    >
      <Form.Item
        label="Requirement Name"
        name="name"
        rules={[
          {
            required: true,
            validator: (rule, value) => validateRequirementName(value),
          },
        ]}
      >
        <Input onChange={e => setRequirementName(e.target.value.trim())} />
      </Form.Item>
      {requirementName && (
        <Form.Item
          label="Photo Examples"
          name="photoExamples"
          rules={[
            {
              required: true,
              validator: (rule, value) => validatePhotoExamples(value),
            },
          ]}
        >
          <PhotoExamplesUpload
            productName={requirementName.replace(/\s/g, "_")}
            onUpload={onUploadPhotoExamples}
            defaultImageUrls={defaultPhotoExampleUrls}
          />
        </Form.Item>
      )}
      <TagsInput
        ref={tagsInputComponent}
        label="Add Tags"
        tags={tags}
        setTags={setTags}
      />
      {rows.map((row, index) => {
        return (
          <Form.Item label={`Requirement ${index + 1}`} key={index}>
            <div style={{ display: "flex" }}>
              <Form.Item
                name={`rowKey-${index + 1}`}
                rules={[{ required: true, message: "required" }]}
                style={{ display: "inline-block", width: "calc(50% - 8px)" }}
              >
                <Input placeholder="Requirement Heading" />
              </Form.Item>
              <Form.Item
                style={{
                  display: "inline-block",
                  width: "50%",
                  margin: "0 8px",
                  marginRight: "0",
                }}
              >
                <Form.Item
                  name={`rowValue-${index + 1}`}
                  rules={[{ required: true, message: "required" }]}
                  style={{ marginBottom: "4px" }}
                >
                  <TextArea
                    placeholder="Requirement Text"
                    autoSize={{ minRows: 1 }}
                  />
                </Form.Item>
                <div style={{ color: "gray" }}>
                  {`Surround any text with "<b></b>" to set it to bold`}
                </div>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => removeRow(index)}
                  style={{ marginLeft: "10px" }}
                  icon={<MinusCircleOutlined />}
                />
              </Form.Item>
            </div>
          </Form.Item>
        );
      })}
      <Form.Item wrapperCol={defaultButtonWrapperCol}>
        <Button onClick={addRow} type="dashed">
          Add Requirement
        </Button>
      </Form.Item>
      <Form.Item wrapperCol={defaultButtonWrapperCol}>
        <Button onClick={onSubmit}>Submit</Button>
      </Form.Item>
    </Form>
  );
};

SecondForm.propTypes = {
  options: array,
  setSelectedOption: func,
  toggleVisibleComponent: func,
  setOptions: func,
  existingOptionIndex: number,
  creatingCopy: bool,
  contractName: string,
};

function RequirementsForm({
  options,
  setOptions,
  setSelectedOption,
  name,
  loading,
  onSwitch,
  contractName,
}) {
  const [existingOptionIndex, setExistingOptionIndex] = useState(-1);
  const [creatingCopy, setCreatingCopy] = useState(false);

  const setExistingOption = (index, createCopy) => {
    setExistingOptionIndex(index);
    setCreatingCopy(!!createCopy);
  };

  const _onSwitch = newActiveComponent => {
    if (newActiveComponent === 1) {
      setExistingOptionIndex(-1);
    }
    onSwitch(newActiveComponent);
  };

  return (
    <TwoComponentSwitch
      className={styles.fullWidth}
      activateFirstText="Select Existing Requirement"
      activateSecondText="Create New Requirement"
      onSwitch={_onSwitch}
      firstComponent={
        <FirstForm
          options={options}
          setSelectedOption={setSelectedOption}
          name={name}
          loading={loading}
          setExistingOption={setExistingOption}
        />
      }
      secondComponent={
        <SecondForm
          existingOptionIndex={existingOptionIndex}
          options={options}
          setOptions={setOptions}
          setSelectedOption={setSelectedOption}
          creatingCopy={creatingCopy}
          contractName={contractName}
        />
      }
    />
  );
}

RequirementsForm.propTypes = {
  options: array,
  setOptions: func,
  setSelectedOption: func,
  name: string,
  loading: bool,
  onSwitch: func,
  contractName: string,
};

export default RequirementsForm;
