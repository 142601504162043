import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";

export default props => {
  // const { post } = props;
  // if (!post) return null;
  return (
    <Button
      key="download"
      icon={<DownloadOutlined />}
      onClick={() => props.action()}
    >
      {props.text}
    </Button>
  );
};
