import React from "react";
import clsx from "clsx";
import { Select, Tag } from "antd";
import styles from "./AddNewCampaign.module.css";

const { Option } = Select;

// This is a regular JS function and not a functional React component
// because AntDesign will give a warning if a custom component is used as a child of Select
export default function optionOrNew({
  optionData,
  option,
  idProperty,
  onNewClick,
  onCreateCopy,
}) {
  if (optionData[idProperty] && onCreateCopy) {
    return (
      <Option key={option.value} value={option.value} label={option.label}>
        {option.label}
        <Tag
          onClick={onCreateCopy}
          className={clsx({
            [styles.newTag]: true,
            [styles.pointerCursor]: !!onNewClick,
          })}
          color="purple"
        >
          create copy
        </Tag>
      </Option>
    );
  }

  if (optionData[idProperty] && !onCreateCopy) {
    return (
      <Option key={option.value} value={option.value} label={option.label}>
        {option.label}
      </Option>
    );
  }

  return (
    <Option key={option.value} value={option.value} label={option.label}>
      {option.label}
      <Tag
        onClick={onNewClick}
        className={clsx({
          [styles.newTag]: true,
          [styles.pointerCursor]: !!onNewClick,
        })}
        color="blue"
      >
        new
      </Tag>
    </Option>
  );
}
