import React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip, Empty } from "antd";
import moment from "moment";
//redux
import { useSelector } from "react-redux";
//router
import { useHistory } from "react-router-dom";
//components
import DataTable from "../../shared/DataTable/DataTable";
import {
  shortcodeModalMouseEnter,
  shortcodeModalMouseLeave,
} from "../InstaPostModal";
import CopyOrderLink from "../ActionButtons/CopyOrderLink";
import CopySubmitLink from "../ActionButtons/CopySubmitLink";
import InterestedToggle from "../ActionButtons/InterestedToggle";
//utils
import { getColumnsFromDataSource } from "../../shared/DataTable/utils";
import { paypalPayoutId, currentStep } from "../postDescriptionRender";
import checkAuth from "../../../utils/checkAuth";
import SubmitOnboard from "../ActionButtons/SubmitOnboard";

//TODO: Modify campaign posts API to include support for pagination
//TODO: Create PUT API that accepts pagination parameters for a campaign, loops through

export default props => {
  //redux
  const isLoading = useSelector(state => state.shared.isLoading);
  const roles = useSelector(state => state.auth.roles);

  //router
  const history = useHistory();

  //only render if data loaded
  if (!isLoading && (!props.posts || !props.posts.length))
    return <Empty className="centered" />;

  return (
    <div style={{ marginBottom: "12px" }}>
      <DataTable
        size="small"
        dataSource={props.posts}
        rowKey="onboardId"
        pagination={false}
        onRow={(record, rowIndex) => {
          return checkAuth.has(roles, ["manager"])
            ? //manager actions
              {
                //navigate to post by id
                onDoubleClick: e => {
                  if (props?.setCurrentTab) {
                    props.setCurrentTab("post");
                  }
                  history.push(`/onboards/${record.onboardId}`);
                },
              }
            : null;
        }}
        columns={getColumnsFromDataSource(
          props.posts,
          //column names
          [
            "campaign",
            "igHandle",
            // "paypalEmail",
            "step",
            "createdAt",
            "completedDate",
            "paypalPayoutId",
            "payout",
            "orderLink",
            "submitLink",
            ...(checkAuth.has(roles, ["supervisor"]) ? ["manualSubmit"] : []), //show only to supervisor
            ...(checkAuth.has(roles, ["supervisor"]) ? ["interested"] : []), //show only to supervisor
          ],
          {
            onboardId: {
              title: "Id",
              width: "24px",
              render: text => (
                <span className="ant-breadcrumb-link">{text}</span>
              ),
            },
            campaign: {
              title: "Campaign",
              render: (text, record) => (
                <span className="ant-breadcrumb-link">
                  {record.relations.campaigns.name}
                </span>
              ),
            },
            igHandle: {
              title: "Handle",
              render: text => (
                <a
                  target="_blank"
                  href={`http://instagram.com/${text}`}
                  rel="noreferrer"
                >
                  {text}
                </a>
              ),
            },
            createdAt: {
              title: "Onboarded",
              render: text => text && moment(text).format("ll"),
            },
            ordered: {
              render: text => text && moment(text).format("ll"),
            },
            completedDate: {
              title: "Completed",
              render: text => text && moment(text).format("ll"),
            },
            doneFollowupAt: {
              title: "CTR",
              render: text => text && moment(text).format("ll"),
            },
            paypalPayoutId: {
              render: (text, record) => paypalPayoutId(record), //imported from postDescriptionRender
            },
            payout: {
              render: (text, record) =>
                `$${
                  record.relations.payouts.find(
                    payout => payout.payoutType === "product"
                  ).payout
                }`,
            },
            orderLink: {
              render: (text, record) => (
                <CopyOrderLink
                  post={record}
                  campaign={record.relations.campaigns}
                  text="Copy"
                />
              ),
            },
            submitLink: {
              render: (text, record) => (
                <CopySubmitLink
                  post={record}
                  text="Copy"
                  campaignFlow={record.relations.campaignFlows}
                />
              ),
            },
            manualSubmit: {
              render: (text, record) => (
                <SubmitOnboard
                  onboard={record}
                  type={record.relations.campaignFlows.type}
                  text="Submit"
                />
              ),
            },
            interested: {
              render: (text, record) => <InterestedToggle post={record} />,
            },
            //set column props by column name
            step: {
              title: (
                <span>
                  Step{" "}
                  <Tooltip title="Hover over 'Completed' to view post">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              ),
              onCell: (record, rowIndex) => {
                return {
                  // onClick: event =>
                  //   history.push(`${history.location.pathname}/${record.onboardId}`), // click row
                  // onDoubleClick: event => {}, // double click row
                  // onContextMenu: event => {}, // right button click row
                  onMouseEnter: event => shortcodeModalMouseEnter(record),
                  onMouseLeave: event => shortcodeModalMouseLeave(),
                };
              },
              render: currentStep,
            },
            // ,remindersSent: {
            //   render: (text, record) =>
            //     record.done || !record.emailsSent.length
            //       ? null
            //       : record.emailsSent[record.currentStep] +
            //         record.emailsSent[record.emailsSent.length - 1] //sum of current step + last case emails
            // }
          }
        )}
      />
    </div>
  );
};
