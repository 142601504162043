import React, { useEffect, useState, useRef } from "react";
import { any, bool, func, object, string } from "prop-types";
import GenericStep from "./GenericStep";
import TagsInput from "./TagsInput";

function InstagramStep({
  form,
  onFormDisabled,
  contractName,
  visible,
  campaignSteps,
  companyName,
  platform,
  link,
  emailTemplates,
  addEmailTemplate,
}) {
  const tagsInputComponent = useRef(null);
  const [instaTags, setInstaTags] = useState([]);
  const [existingStep, setExistingStep] = useState(null);

  const formPreSubmit = selectedOption => {
    if (instaTags.length === 0) {
      tagsInputComponent.current.setTagsError(
        "At least one tag is required. Type something and then press enter/return"
      );
      throw Error("At least one tag is required.");
    }
    const selectedOptionValue = JSON.parse(selectedOption.value);
    selectedOptionValue.instaTags = instaTags;
    selectedOption.value = JSON.stringify(selectedOptionValue);
    setInstaTags([]);
  };

  useEffect(() => {
    if (existingStep) {
      setInstaTags(existingStep.instaTags || []);
      tagsInputComponent.current.setTagsError("");
    }
  }, [existingStep]);

  const _onFormDisabled = disabled => {
    if (!disabled) {
      setInstaTags([]);
      tagsInputComponent.current.setTypedTag("");
      tagsInputComponent.current.setTagsError("");
      setExistingStep(null);
    }
    onFormDisabled(disabled);
  };

  return (
    <GenericStep
      form={form}
      onFormDisabled={_onFormDisabled}
      stepType="instagram"
      contractName={contractName}
      onExistingFieldSelect={setExistingStep}
      invisibleFormFields={["instaTags"]}
      formPreSubmit={formPreSubmit}
      visible={visible}
      campaignSteps={campaignSteps}
      companyName={companyName}
      platform={platform}
      link={link}
      emailTemplates={emailTemplates}
      addEmailTemplate={addEmailTemplate}
    >
      <TagsInput
        ref={tagsInputComponent}
        label="Add Instagram Tags"
        tags={instaTags}
        setTags={setInstaTags}
      />
    </GenericStep>
  );
}

InstagramStep.propTypes = {
  form: any,
  onFormDisabled: func,
  contractName: string,
  visible: bool,
  campaignSteps: object,
  companyName: string,
  platform: string,
  link: string,
  emailTemplates: object,
  addEmailTemplate: func,
};

export default InstagramStep;
