import React from "react";
import CampaignsTable from "./CampaignsTable";
import { useContracts } from "../../api/contracts";

const selectArrayOfCampaigns = contracts => {
  if (Array.isArray(contracts) && contracts.length) {
    const arrayOfCampaigns = contracts.reduce((accum, current) => {
      return [...accum, ...current.relations.campaigns];
    }, []);
    return arrayOfCampaigns;
  }
  return [];
};

export default function AllCampaigns() {
  const { data: contracts, isLoading } = useContracts({
    includes: ["products", "campaigns", "companies"],
    params: { stats: true },
  });

  return (
    <CampaignsTable
      campaigns={selectArrayOfCampaigns(contracts)}
      isLoading={isLoading}
      useFilter
    />
  );
}
