import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  createAuthenticatedRequest,
  defaultQueryOptions,
  executeRequest,
} from "./utils";

export const CATEGORIES_PATH = "categories";

export const FLATTENED_CATEGORIES_PATH = "flattenedCategories";

export function useCategories({ includes, params, ...overrideOptions }) {
  const dispatch = useDispatch();

  return useQuery(
    [CATEGORIES_PATH, includes, params],
    () => fetchCategories(dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

export function useFlattenedCategories({
  includes,
  params,
  ...overrideOptions
}) {
  const dispatch = useDispatch();

  return useQuery(
    [FLATTENED_CATEGORIES_PATH, includes, params],
    async () => {
      const categories = await fetchCategories(dispatch, { includes, params });
      const flattenedCategories = [];
      for (const category of categories) {
        flattenedCategories.push(...category.children);
      }
      return flattenedCategories;
    },
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for categories
export async function fetchCategories(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    CATEGORIES_PATH,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}
