import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import ContractById from "./ContractById";
//utils
import ContractsTable from "./ContractsTable";
import AddNewContract from "./AddNewContract/AddNewContract";

import checkAuth from "../../utils/checkAuth";

export default function ContractsPage() {
  const { path } = useRouteMatch();

  const roles = useSelector(state => state.auth.roles);

  return (
    <Switch>
      {checkAuth.has(roles, ["admin"]) && (
        <Route path={`${path}/add`}>
          <AddNewContract />
        </Route>
      )}
      <Route path={`${path}/:contractId`}>
        <ContractById />
      </Route>
      <Route path={`${path}/`} exact>
        <ContractsTable />
      </Route>
    </Switch>
  );
}
