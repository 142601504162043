import React from "react";
import { any } from "prop-types";
import {
  Button,
  Checkbox,
  Tag,
  Col,
  Form,
  Input,
  InputNumber,
  Typography,
} from "antd";
import styles from "./BillingContract.module.css";
import { displayDate } from "../../../utils/dates";

export default function BillingSetup({ setup = {}, contract = {} }) {
  const testUri = process.env.NODE_ENV !== "production" ? "test/" : "";
  const stripeDashboardUrl = `https://dashboard.stripe.com/${testUri}customers/`;

  return (
    <>
      <Col span={4} key="stripeCustomerId" className={styles.formColumn}>
        <Form.Item
          className={styles.formItem}
          label={
            <>
              <span className={styles.formLabel}>Stripe Customer ID</span>
              {!setup.stripeCustomerId && (
                <Typography.Link
                  href={`${stripeDashboardUrl}`}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.labelLink}
                >
                  Create new Stripe customer
                </Typography.Link>
              )}
            </>
          }
        >
          <Form.Item
            name="stripeCustomerId"
            style={{ marginBottom: "4px" }}
            rules={[{ required: true, message: "required" }]}
          >
            <Input />
          </Form.Item>
          {setup.stripeCustomerId && (
            <Typography.Link
              target="_blank"
              rel="noreferrer"
              href={`${stripeDashboardUrl}${setup.stripeCustomerId}`}
            >
              View in Stripe
            </Typography.Link>
          )}
        </Form.Item>
      </Col>

      <Col offset={1} span={2} key="active" className={styles.formColumn}>
        <Form.Item
          className={styles.formItem}
          name="active"
          valuePropName="checked"
          label={<span className={styles.formLabel}>Active</span>}
          style={{ marginLeft: "8px", marginRight: "8px" }}
        >
          <Checkbox />
        </Form.Item>
      </Col>

      <Col span={4} key="depositWeight" className={styles.formColumn}>
        <Form.Item
          className={styles.formItem}
          name="depositWeight"
          label={<span className={styles.formLabel}>Deposit Weight</span>}
          rules={[{ required: true, message: "required" }]}
        >
          <InputNumber min={0} max={1} step={0.01} precision={2} />
        </Form.Item>
      </Col>

      <Col span={4} key="invoiceIntervalWeeks" className={styles.formColumn}>
        <Form.Item
          name="invoiceIntervalWeeks"
          label={
            <span className={styles.formLabel}>Invoice Interval Weeks</span>
          }
          rules={[{ required: true, message: "required" }]}
        >
          <InputNumber min={1} max={4} precision={0} step={1} />
        </Form.Item>
      </Col>

      <Col span={3} key="targets" className={styles.formColumn}>
        <Form.Item
          className={styles.formItem}
          name="targets"
          label={<span className={styles.formLabel}>Targets</span>}
        >
          {setup.targets === "misconfigured" ? (
            <Typography.Text style={{ color: "red" }}>
              {setup.targets}
            </Typography.Text>
          ) : (
            <Typography.Text>{setup.targets}</Typography.Text>
          )}
        </Form.Item>
      </Col>

      <Col span={2} key="lastInvoicedAt" className={styles.formColumn}>
        <Form.Item
          name="lastInvoicedAt"
          label={<span className={styles.formLabel}>Last Invoice</span>}
        >
          <Typography.Text>
            {displayDate(setup.lastInvoicedAt).format("MMM Do YYYY")}
          </Typography.Text>
        </Form.Item>
      </Col>
      <Col span={2} key="couponCode" className={styles.formColumn}>
        <Form.Item
          name="couponCode"
          label={<span className={styles.formLabel}>Coupon</span>}
        >
          {setup.couponCode && setup.couponAmount && (
            <Tag color="green">
              {`-$${setup.couponAmount}/credit • ${setup.couponCode}`}
            </Tag>
          )}
        </Form.Item>
      </Col>
    </>
  );
}

BillingSetup.propTypes = {
  setup: any,
  contract: any,
};
