import moment from "moment";
import { types as invitesTypes } from "./invitesActions";
import { types as sendInvitesTypes } from "./sendInvitesActions";

//merge 2 actions files
const types = {
  ...invitesTypes,
  ...sendInvitesTypes,
};

//primary key
const PK = "inviteGroupId";

//TODO: normalize woodpecker campaign stats by woodpecker campaign ID

const defaultsToReset = {
  send: {
    tab: "form",
    tagQuery: "",
    sendEmails: false,
    sendSMSReminder: false,
    reminderDate: moment()
      .set("hour", 15) //default to USA business hours
      .set("minute", 0)
      .add(1, "weeks")
      .toISOString(),
  },
  inviteGroupSelections: [],
  preview: {},
};

//default invites/send starts in logs
export default (
  state = {
    ...defaultsToReset,
    data: {},
  },
  action
) => {
  switch (action.type) {
    case types.GET_INVITE_GROUPS_SUCCESS:
      const inviteGroupsToPush = {};
      const { inviteGroups } = action.data.relations;
      //if empty array, exit
      if (!inviteGroups || !inviteGroups.length) return state;
      for (const inviteGroup of inviteGroups) {
        inviteGroupsToPush[inviteGroup[PK]] = inviteGroup;
      }
      return {
        ...state,
        data: {
          ...state.data,
          ...inviteGroupsToPush,
        },
      };
    case types.GET_INVITE_GROUP_BY_ID_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...{ [action.data[PK]]: action.data },
        },
      };
    case types.PREVIEW_INPUT_OUTPUT:
      return {
        ...state,
        preview: {
          ...state.preview,
          //replace from action
          ...action.data,
        },
      };
    case types.POST_INVITES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...{ [action.data[PK]]: action.data },
        },
      };
    case types.GET_EMAIL_JOB_LOGS_SUCCESS: {
      //reset if page 1, else append for infinite scroll
      const logsByCreatedDate = action.page === 1 ? {} : state.logs;

      //create map of {date: [jobLogs]}
      for (const log of action.data) {
        const flattenedLog = { ...log, ...log.relations.emailGroups };
        if (logsByCreatedDate[log.createdAt])
          logsByCreatedDate[log.createdAt].push(flattenedLog);
        else logsByCreatedDate[log.createdAt] = [flattenedLog]; //init array
      }
      return {
        ...state,
        logsPage: action.page,
        logs: logsByCreatedDate,
      };
    }
    case types.SET_INVITE_GROUP_SELECTIONS:
      return {
        ...state,
        inviteGroupSelections: action.inviteGroupSelections, //array of strings
      };
    case types.UPDATE_SEND_STATE:
      return { ...state, send: { ...state.send, ...action.update } };
    case types.RESET_SEND_STATE:
      //deep clone and reset defaults
      return {
        ...state,
        ...JSON.parse(JSON.stringify(defaultsToReset)),
      };
    case types.GET_JOIN_EMAIL_GROUP_SUCCESS:
      return {
        ...state,
        joinEmailGroup: action.data,
      };
    case types.UPDATE_TYPE_STATE:
      return {
        ...state,
        type: action.data,
      };
    default:
      return state;
  }
};
