import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Tag, Tooltip } from "antd";
import { NotificationOutlined } from "@ant-design/icons";
import { useContracts } from "../../api/contracts";
import DataTable from "../shared/DataTable/DataTable";
import { getColumnsFromDataSource } from "../shared/DataTable/utils";
import ContractHeader from "./ContractHeader/ContractHeader";
import BillingStatusTag from "./BillingContract/BillingStatusTag";
import { isLoading as setLoading } from "../../redux/shared/sharedActions";
import { getTagColor } from "../../utils/getTagColor";
import { displayDate } from "../../utils/dates";

const pageSize = 300;
export default function ContractsTable() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("not_disqualified");
  const [currentPage, setCurrentPage] = useState(1);

  const { data: contracts, isLoading } = useContracts({
    params: { stats: true, start: (currentPage - 1) * pageSize, pageSize },
    includes: ["companies", "campaigns", "products", "billingContracts"],
  });

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  //only render if data loaded
  if (!contracts || !contracts.data.length) return null;
  if (contracts.data.length === 1)
    //show by ID if only 1 contract
    history.push(`/contracts/${contracts.data[0].contractId}`);

  const filteredContracts = contracts.data.filter(contract => {
    switch (filter) {
      case "not_disqualified":
        return contract.state !== "disqualified";
      case "none":
        return true;
      default:
        return contract.state === filter;
    }
  });

  return (
    <>
      <ContractHeader filter={filter} setFilter={setFilter} />
      <DataTable
        isLoading={isLoading}
        size="small"
        dataSource={filteredContracts}
        rowKey="contractId"
        pagination={{
          pageSize,
          onChange: newPage => setCurrentPage(newPage),
          total: contracts.total,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          showSizeChanger: false,
        }}
        columns={getColumnsFromDataSource(
          contracts.data,
          //column names
          [
            "companyName",
            "name",
            "state",
            "billing",
            "startDate",
            "campaigns",
            "target",
            "totalOnboards",
            "not_started",
            "started",
            "done",
            "followed_up",
            "not_interested",
            // ...(checkAuth.has(roles, ["supervisor"]) ? ["not_interested"] : []), //show only to supervisor
          ],
          {
            name: {
              render: (text, record) => (
                <a href={`/#/contracts/${record.contractId}`}>{text}</a>
              ),
            },
            //set column props by column name
            campaigns: {
              title: <NotificationOutlined />,
              render: (text, record) => `${record.relations.campaigns.length}`,
            },
            //under score set in database
            followed_up: {
              title: "CTR",
            },
            startDate: {
              render: text => `${displayDate(text).format("MMM Do YYYY")}`,
            },
            target: {
              // eslint-disable-next-line react/display-name
              render: (targetValue, contract) => (
                <Tooltip
                  title={contract.manualTarget ? "contract" : "campaigns"}
                >
                  {targetValue}
                </Tooltip>
              ),
            },
            state: {
              // eslint-disable-next-line react/display-name
              render: text => <Tag color={getTagColor(text)}>{text}</Tag>,
            },
            billing: {
              render: (text, record) =>
                BillingStatusTag(record.relations.billingContracts),
            },
          }
        )}
        onRow={record => {
          return {
            onClick: () => history.push(`/contracts/${record.contractId}`), // click row
            // onDoubleClick: event => {}, // double click row
            // onContextMenu: event => {}, // right button click row
            // onMouseEnter: event => {}, // mouse enter row
            // onMouseLeave: event => {} // mouse leave row
          };
        }}
      />
    </>
  );
}

ContractsTable.propTypes = {};
