import { UndoOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal } from "antd";
import React, { useState } from "react";
import { string, object } from "prop-types";

import { undoSubmission } from "../../../redux/posts/postActions";
import checkAuth from "../../../utils/checkAuth";

function UndoSubmission({ onboard, text }) {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  // auth for UndoSubmission
  const roles = useSelector(state => state.auth.roles);
  const isAdmin = checkAuth.has(roles, ["admin"]);

  // dont display button if not admin or onboard is not done
  if (!isAdmin || !onboard || !onboard.done) {
    return null;
  }

  return (
    <>
      <Button
        type="primary"
        key="close"
        icon={<UndoOutlined />}
        onClick={() => setVisible(true)}
      >
        {text}
      </Button>
      <Modal
        title={`Undo Instagram step submission for ${onboard.igHandle}`}
        visible={visible}
        okText="Submit"
        onOk={() => {
          dispatch(undoSubmission(onboard.onboardId));
          setVisible(false);
        }}
        // confirmLoading={confirmLoading}
        onCancel={() => {
          //close and reset input
          setVisible(false);
        }}
      >
        This will undo the submission, deleting the currently submitted post and
        allowing you to manually submit the correct post.
      </Modal>
    </>
  );
}

UndoSubmission.propTypes = {
  onboard: object.isRequired,
  text: string.isRequired,
};

export default UndoSubmission;
