import moment from "moment";
import { pushLoading, popLoading, result } from "../shared/sharedActions";
import { getToken } from "../auth/authActions";

export const types = {
  GET_BALANCES_SUCCESS: "GET_BALANCES_SUCCESS", // balances of active payoutAccounts
  SEARCH_TRANSACTIONS_SUCCESS: "SEARCH_TRANSACTIONS_SUCCESS", //search transactions of active payoutAccounts
  REFRESH_TRANSACTION_BY_ID_SUCCESS: "REFRESH_TRANSACTION_BY_ID_SUCCESS", //refresh cached transaction by ID
};

/*
 * ACTIONS
 */
export const getBalancesSuccess = data => {
  return { type: types.GET_BALANCES_SUCCESS, data };
};

export const searchTransactionsSuccess = data => {
  return { type: types.SEARCH_TRANSACTIONS_SUCCESS, data };
};

export const refreshTransactionByIdSuccess = data => {
  return { type: types.REFRESH_TRANSACTION_BY_ID_SUCCESS, data };
};

export const getBalances = () => async dispatch => {
  const loadingId = "getBalances";
  dispatch(pushLoading(loadingId));
  try {
    const token = await dispatch(getToken());
    const requestObject = new Request(
      `${process.env.REACT_APP_API_HOST}api/services/paypal/balances`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    dispatch(getBalancesSuccess(json));
  } catch (err) {
    console.error(err);
    dispatch(
      result({
        status: `error`,
        title: `Get Paypal balances failed`,
        subTitle: err.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};

//returns startDate, endDate and cacheDates for query param
const numberOfPreviousWeeksToCache = 16; // 16 weeks = 4 months
function searchDateQueryParams() {
  // We need to split the number of responses into weekly date ranges
  // If we return more than 10k rows for any response, Paypal will throw RESULTSET_TOO_LARGE
  // So to get X weeks worth of transactions, we need to send X * 4 requests (4 weeks in a week)
  const startOfWeek = moment.utc().startOf("week");
  const middleOfWeek = moment(startOfWeek).add(3, "days");
  const endOfWeek = moment.utc().endOf("week");

  // boolean - determine if we are past the middle of the week, to fetch live data
  const isAfterMiddleOfWeek = moment().isAfter(middleOfWeek);

  const dates = {
    //search params to fetch live data. We do this for the current biweekly range so we dont have to maintain cache
    startDate: isAfterMiddleOfWeek
      ? middleOfWeek.format()
      : startOfWeek.format(),
    endDate: isAfterMiddleOfWeek ? endOfWeek.format() : middleOfWeek.format(),
  };

  let cacheDates = isAfterMiddleOfWeek
    ? `${startOfWeek.format()};${middleOfWeek.format()}`
    : "";

  //build cacheDates string, this is a comma seperated query parameter with the syntax startDate;endDate in UTC time
  for (let i = 0; i < numberOfPreviousWeeksToCache; i++) {
    const cacheStartOfWeek = startOfWeek
      .subtract(1, "weeks")
      .startOf("week")
      .format();
    const cacheMiddleOfWeek = middleOfWeek.subtract(1, "weeks").format();
    const cacheEndOfWeek = endOfWeek
      .subtract(1, "weeks")
      .endOf("week")
      .format();

    //comma seperate
    if (cacheDates) cacheDates += ",";
    //delimit start date and end date to be parsed by backend
    cacheDates += `${cacheMiddleOfWeek};${cacheEndOfWeek},${cacheStartOfWeek};${cacheMiddleOfWeek}`;
  }

  dates.cacheDates = cacheDates;
  //weeks that exclude the current week so we can maintain a fixed cache
  return dates;
}

export const refreshTransactionById = transaction => async dispatch => {
  console.log("transaction", transaction);
  const transactionDate = transaction.transaction_initiation_date;
  const loadingId = `refreshTransactionById${transactionDate}`;
  //paypal transaction search service requires start date and end date even by transactionId
  const startDate = moment(transactionDate).utc().startOf("days").format();
  const endDate = moment(transactionDate).utc().endOf("days").format();
  dispatch(pushLoading(loadingId));
  try {
    const token = await dispatch(getToken());
    const url = `${process.env.REACT_APP_API_HOST}api/services/paypal/${transaction.payoutAccountId}/transactions/${transaction.transaction_id}?startDate=${startDate}&endDate=${endDate}`;
    const requestObject = new Request(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    });
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    dispatch(refreshTransactionByIdSuccess(json));
  } catch (err) {
    console.error(err);
    //handle
    dispatch(
      result({
        status: `error`,
        title: `Search Paypal transactions failed`,
        subTitle: err.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};

export const searchTransactions = query => async dispatch => {
  console.log("query", query);
  const loadingId = `searchTransactions${query}`;
  const { startDate, endDate, cacheDates } = searchDateQueryParams();
  dispatch(pushLoading(loadingId));
  try {
    const token = await dispatch(getToken());
    const url = `${process.env.REACT_APP_API_HOST}api/services/paypal/transactions?startDate=${startDate}&endDate=${endDate}&cacheDates=${cacheDates}&search=${query}`;
    const requestObject = new Request(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    });
    const res = await fetch(requestObject);
    const json = await res.json();
    if (!res.ok) throw new Error(json.message || res.statusText);
    dispatch(searchTransactionsSuccess(json));
  } catch (err) {
    console.error(err);
    //handle
    dispatch(
      result({
        status: `error`,
        title: `Search Paypal transactions failed`,
        subTitle: err.message,
      })
    );
  } finally {
    dispatch(popLoading(loadingId));
  }
};
