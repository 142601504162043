import { types as payoutTypes } from "./payoutActions";
import { types as serviceTypes } from "./serviceActions";

//merge 2 actions files
const types = {
  ...payoutTypes,
  ...serviceTypes,
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.GET_PAYOUTS_SUCCESS:
      return { ...state, payoutsCount: action.data.length };
    case types.GET_INVITE_PAYOUTS_SUCCESS:
      return {
        ...state,
        invitePayouts: action.data,
        invitePayoutsCount: action.data.length,
      };
    case types.GET_PAYPAL_TRANSACTIONS_SUCCESS:
      return { ...state, paypalTransactionsCount: action.data.length };
    case types.GET_BALANCES_SUCCESS:
      return { ...state, balances: action.data };
    case types.SEARCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionSearch:
          action.data && action.data.map(flattenServiceTransaction),
      };
    case types.REFRESH_TRANSACTION_BY_ID_SUCCESS:
      const refreshedTransaction = flattenServiceTransaction(action.data[0]);
      const result = [];
      for (const exisitingTransaction of state.transactionSearch) {
        if (
          refreshedTransaction.transaction_id ===
            exisitingTransaction.transaction_id &&
          refreshedTransaction.transaction_initiation_date ===
            exisitingTransaction.transaction_initiation_date
        )
          result.push(refreshedTransaction);
        else result.push(exisitingTransaction);
      }
      return {
        ...state,
        transactionSearch: result,
      };
    default:
      return state;
  }
};

function flattenServiceTransaction(transaction) {
  return {
    ...transaction,
    ...transaction.transaction_info,
    ...(transaction.transaction_info.transaction_amount || {}),
    ...transaction.payer_info,
    ...(transaction.payer_info.payer_name || {}),
    transaction_info: undefined,
    transaction_amount: undefined,
    payer_info: undefined,
    payer_name: undefined,
  };
}
