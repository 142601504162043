//converts payout value to an amount that should be sent via paypal
//so that users recieve the actual payout value after fees
export default function amountToSendIncludingFees(payoutAmount) {
  const PERCENT_FEE_RATE = 0.0289; // % of payout
  const FLAT_FEE = 0.49; // Fixed USD amount charge

  const payoutPlusFees = (
    Math.round(
      (payoutAmount + payoutAmount * PERCENT_FEE_RATE + FLAT_FEE) * 100
    ) / 100
  ).toFixed(2);

  //if we sent the amount with just fees, influencers won't receive the exact amount but missing a few pennies
  // for example, we want them to receive 40.00 not 39.99
  const influencerReceives =
    payoutPlusFees - payoutPlusFees * PERCENT_FEE_RATE - FLAT_FEE;

  //add extraCents to make receiving amount match payout
  const toSendPlusExtraCents =
    Number(payoutPlusFees) + Math.abs(payoutAmount - influencerReceives);

  //always round up 2 decimal points, so influencer gets 1 cent extra rather than less
  return Math.ceil(toSendPlusExtraCents * 100) / 100;
}
